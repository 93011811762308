import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/choiceAppCardDetailsModal.module.css";
import { SingleAppCardDetailsModal } from "./SingleAppCardDetailsModal";
import { AppCardModalDetailsInfo } from "./types";

interface ChoiceAppCardDetailsModalProps {
  className?: string;
  details: AppCardModalDetailsInfo[];
  activeIndex: number;
}

export class ChoiceAppCardDetailsModal extends AyraaBaseComponent<
  ChoiceAppCardDetailsModalProps,
  {}
> {
  constructor(props: ChoiceAppCardDetailsModalProps) {
    super(props);
  }

  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    return (
      <div className={containerClassNames}>
        {this.props.details.map((choice, index) => {
          const isActive = this.props.activeIndex === index;
          const detailsClassNames = classNames(styles.details, {
            [styles.highlight]: isActive,
          });
          return (
            <div className={detailsClassNames}>
              <SingleAppCardDetailsModal {...choice} />
            </div>
          );
        })}
      </div>
    );
  }
}
