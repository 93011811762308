import React from "react";
import zenefitsLogo from "images/hris/integration/zenefits.png"

function ZenefitsIntegration() {
  return (
    <div className="p-4 m-4">
      <h2>Create API Key:</h2>
      <ol>
        <li>Login to your Zenefits account with admin credentials.</li>
        <li>From the dashboard, go to App Directory -&gt; Custom Integration</li>
        <li>Then select the Add Token.</li>
        <li>
          Select which scopes you'd like included in the access token. Mandatory scopes are Name,
          Work Email and Manager.{" "}
          A sample configuration :
        </li>
        <img src={zenefitsLogo} />
        <li>Save.</li>
        <li>Click the eye icon to unmask the token.</li>
        <li>Add this as the API Key.</li>
      </ol>
      Webhook API Key:
      <ol>
        <li>Select Add Webhook.</li>
        <li>
          Select the scopes you would like to be included in API. Choose scopes that you selected
          while creating the API Key.
        </li>
        <li>
          Select the events to be included like department.did_change, department.did_created,
          people.did_change, people.did_create
        </li>
        <li>
          Against each of these events configure the Webhook URL provided on the HRIS selector screen on Ayraa.
        </li>
        <li>Click the eye icon to unmask the Webhook API token.</li>
        <li>Add this as the Webhook API Key</li>
      </ol>
    </div>
  );
}

export default ZenefitsIntegration;
