import { useEffect } from "react";

export const useGetPrivileges = ({
  requiredPrivileges,
  componentPrivileges,
  privileges = {}
}: $TSFixMe) => {
  useEffect(() => {
    if (Object.keys(privileges).length !== componentPrivileges.length) {
      requiredPrivileges(componentPrivileges);
    }
  }, [requiredPrivileges, privileges, componentPrivileges]);
  return privileges;
};
