import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  getAvailableHRIS,
  importEmployeeDataFromHRIs,
  getCompaniesForHRISAdmin,
  mergeDevLinkToken,
  mergeDevEmployeeImport,
  getHRISWebHookURI,
} from "redux/importEmployeesSlice";
import { Alert, Button, Form, Card } from "react-bootstrap";
import Select, { components } from "react-select";
import { hriJson } from "./hriJson";
import PulseAppButton from "./Redirect/PulseAppButton";
import { toast } from "utils/toast";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {CONSTANTS} from "../utils/constants";

function SelectHRI() {
  const dispatch = useAppDispatch();
  const [selectedHRI, setSelectedHRI] = useState(null);
  const [selectedHRIObject, setSelectedHRIObject] = useState<$TSFixMe>(null);
  const [linkToken, setLinkToken] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm();
  const { listAvailableHRIS, companiesForHRISAdmin }: $TSFixMe = useAppSelector(
    (state) => state.importEmployeesManager
  );
  const { tenant }: $TSFixMe = useAppSelector((state) => state.tenantManager);
  const { user }: $TSFixMe = useAppSelector((state) => state.userManager);

  const [showSuccess, setShowSuccess] = useState(false);
  const [companyList, setCompanyList] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [webHookURI, setWebHookURI] = useState(null);

  const onSuccess = useCallback(
    async (public_token) => {
      const res = await dispatch(
        mergeDevEmployeeImport({ public_token, hrims_provider_id: selectedHRI })
      ).unwrap(); // Send public_token to server (Step 3)

      if (res.status == "error") {
        toast.error("Some error occured");
      } else {
        setShowSuccess(true);
      }
    },
    [selectedHRI]
  );

  const { open } = useMergeLink({
    linkToken,
    onSuccess,
  });

  useEffect(() => {
    dispatch(getAvailableHRIS()).unwrap();

    //checking message from the popup for Gusto
    async function receiveMessage(event: $TSFixMe) {
      if (event.data.isHRIOAuthCode) {
        switch (event.data.status) {
          case "success":
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
            dispatch(getCompaniesForHRISAdmin({ tenantId: tenant.tenantID }));
            setCompanyList(true);
            break;
          case "error":
            break;
          default:
            break;
        }
      }
    }
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const { Option } = components;
  const IconOption = (props: $TSFixMe) => (
    <Option {...props}>
      <img
        className="me-2"
        src={props.data.hris_provider_logo_url}
        style={{ width: 30, height: 30, objectFit: "contain" }}
        alt=""
      />
      {props.data.hris_provider_name}
    </Option>
  );

  const handleCompany = (selectedOption: $TSFixMe) => {
    setSelectedCompanyId(selectedOption.id);
  };

  const handleChange = async (selectedOption: $TSFixMe) => {
    setSelectedHRI(selectedOption.hris_provider_id);

    if (selectedOption.integrationType == "merge-dev") {
      const res = await dispatch(
        mergeDevLinkToken({ hris_provider_id: selectedOption.hris_provider_id })
      ).unwrap();

      setLinkToken(res.link_token);
      open();
      setSelectedHRIObject(null);
    } else if (selectedOption.hris_provider_id == "gusto") {
      //rediret to gusto on a new window
      window.open(
        `https://ui-backend-qa.ayraa.io/hris/oauth2/authorization/gusto?ru=${
          process.env.REACT_APP_CLIENT_URL + "" + process.env.PUBLIC_URL
        }/OAuthHRISuccess&t=${tenant.tenantID}`,
        "Gusto Login",
        "width=560,height=680,toolbar=0,menubar=0,location=0"
      );
      setSelectedHRIObject(null);
    } else {
      setSelectedHRIObject(
        hriJson.find((x) => x.hris_provider_id === selectedOption.hris_provider_id)
      );
      const HRISWebHookURI = await dispatch(
        getHRISWebHookURI({
          hrisProviderId: selectedOption.hris_provider_id,
          tenantId: tenant.tenantID,
        })
      ).unwrap();
      setWebHookURI(HRISWebHookURI);
    }
  };

  const onSubmitClick = async ({
    apiKey = null,
    webHookAPIKey = null,
    tenantHRISCompanyUrl = null,
    clientId = null,
    clientSecret = null,
    keyFile = null,
    keystorePassword = null,
    companyId = null,
  }) => {
    const res = await dispatch(
      importEmployeeDataFromHRIs({
        hrisProviderId: selectedHRI,
        employeeId: user.employeeID,
        tenantId: tenant.tenantID,
        apiKey,
        webHookAPIKey,
        clientId,
        clientSecret,
        tenantHRISCompanyUrl,
        companyId: companyId ? companyId : selectedCompanyId,
        keyFile: keyFile ? keyFile[0] : null,
        keystorePassword,
      })
    ).unwrap();
    if (res.status == "error" || res.errorCode || res.errorCode == 502 || res.errorCode == 503) {
      toast.error("Some error occured");
    } else {
      setShowSuccess(true);
    }
  };

  return (
    <Card className="m-5 p-5">
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmitClick)}>
          {!showSuccess && companyList && (
            <>
              {companiesForHRISAdmin.length != 0 ? (
                <>
                  <h1>List of compane(s) under the gusto Domain</h1>

                  {companiesForHRISAdmin.length == 1 && (
                    <>
                      <h5>{companiesForHRISAdmin[0].name}</h5>
                      <Form.Group>
                        <Form.Control
                          type="hidden"
                          value={companiesForHRISAdmin[0].id}
                          {...register("companyId", { required: "Input required" })}
                        />
                      </Form.Group>
                    </>
                  )}

                  {companiesForHRISAdmin.length > 1 &&
                    companiesForHRISAdmin.map((comp: $TSFixMe) => (
                      <Select
                        className="mb-3"
                        onChange={handleCompany}
                        placeholder={`List of Companies`}
                        options={companiesForHRISAdmin}
                        getOptionLabel={(option: $TSFixMe) => option.name}
                      />
                    ))}
                  <Button type="submit">Import Employees</Button>
                </>
              ) : null}
            </>
          )}
          {!showSuccess && !companyList && (
            <>
              <h1>Lets get started</h1>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Select HRI</Form.Label>
                <Select
                  onChange={handleChange}
                  components={{ Option: IconOption }}
                  placeholder={`List of all HRI`}
                  options={listAvailableHRIS}
                  getOptionLabel={(option: $TSFixMe) => option.hris_provider_name}
                  getOptionValue={(option: $TSFixMe) => option.hris_provider_id}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: (selectedOption: any) => void; c... Remove this comment to see the full error message
                  openOnFocus={true}
                />
              </Form.Group>
              {selectedHRIObject && (
                <>
                  {selectedHRIObject.form_data.map((data: $TSFixMe) => (
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>{data.label}</Form.Label>
                      {data.required ? (
                        <Form.Control
                          type={data.type}
                          {...register(data.name, { required: "Input required" })}
                          placeholder={data.placeholder}
                        />
                      ) : (
                        <Form.Control
                          type="text"
                          {...register(data.name)}
                          placeholder={data.placeholder}
                        />
                      )}
                      {formErrors[data.name] && (
                        <span className="text-danger">This field is required</span>
                      )}
                    </Form.Group>
                  ))}
                  {selectedHRIObject.integrationLink && (
                    <Alert variant={"primary"}>
                      {selectedHRIObject.webHookAPI && webHookURI && (
                        <>
                          Configure Web Hook with <b>`{webHookURI}`</b>
                          <br />
                        </>
                      )}
                      <a target="_blank" href={selectedHRIObject.integrationLink}>
                        <b>Learn</b>{" "}
                      </a>
                      how to obtain above details from your HRIS
                    </Alert>
                  )}

                  <Button type="submit">Import Employees</Button>
                </>
              )}
            </>
          )}
        </Form>
        {showSuccess && (
          <div className="">
            <h5>Employee data imported successfully</h5>
            <PulseAppButton redirectUrl={tenant.tenantUrl + CONSTANTS.REACT_PULSE_APP_CONTEXT}/>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default SelectHRI;
