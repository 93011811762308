import { Redirect, Route, Switch } from "react-router-dom";
import { ErrorPage, LoginPage } from "pages";
import { ManageSubscription } from "pages/ManageSubscription";
import WorkatoImport from "components/WorkatoImport/WorkatoImport";
import SuccessPage from "pages/SuccessPage";
import { Navbar } from "components/Navbar";
import ManageAccount from "pages/ManageAccounts/ManageAccount";
import SendInvitesPage from "pages/SendInvites/SendInvitesPage";
import PulseAppRedirect from "components/Redirect/PulseAppRedirect";
import SelectHRI from "components/SelectHRI";
import OAuthHRISuccessPage from "pages/OAuthHRISuccessPage";
import { Header } from "components/v2/Header";
import { useAppSelector } from "redux/hooks";
import {
  AdpIntegration,
  BamboohrIntegration,
  HibobIntegration,
  NamelyIntegration,
  RipplingIntegration,
  TrinetIntegration,
  ZenefitsIntegration,
} from "components/HRI";
import SignupPage from "../pages/v2/Login/SignupPage";
import React, { useEffect, useState } from "react";
import LoginPageV2 from "../pages/v2/Login/LoginPageV2";
import CreateWorkspaceWithAccessControl from "../pages/v2/Workspace/CreateWorkspace";
import JoinWorkspace from "../pages/v2/Workspace/JoinWorkspace";
import ManageSettings from "../pages/v2/Settings/ManageSettings";
import WorkspaceSettings from "../pages/v2/Settings/WorkspaceSettings";
import Sidebar from "../components/v2/Sidebar/Sidebar";
import ImportEmployeesWithAccessControl from "../pages/v2/ImportEmployees/ImportEmployees";
import CreateOrganizationWithAccessControl from "../pages/v2/Organization/CreateOrganization";
import ManageEmployeesWithAccessControl from "../pages/ManageEmployees/ManageEmployees";
import GetStarted from "../pages/v2/ImportEmployees/GetStartedPage";
import { Tenant } from "../types/userTypes";
import { CONSTANTS } from "../utils/constants";
import LabelsPage from "pages/Labels/LabelsPage";
import GitInfoPageWithAccessControl from "../utils/GitInfo/GitInfoPage";
import AyraaAdminLogin from "../pages/Admin/AyraaAdminLogin";
import AlternateAccountLogin from "../pages/v2/Login/AlternateAccountLogin";
import { getDataFromAppStore } from "../dataStore/appDataStore";
import { appConstants } from "../utils/appConstants";
import { checkIfDesktopApp } from "../utils/desktopUtils";
import AppSpinner from "../components/Spinner/AppSpinner";
import { DesktopLoginPage } from "../pages/v2/Login/DesktopLoginPage";
import { LogoutPage } from "../pages/v2/Login/LogoutPage";
import { DownloadDesktopApp } from "../pages/v2/Download/DownloadDesktopApp";
import { DesktopAppInstallationInstructions } from "../pages/v2/Download/DesktopAppInstallationInstructions";
import { GMComponent } from "../components/GM/GMComponent";
import { SafariPostLoginRedirect } from "../components/Redirect/SafariPostLoginRedirect";
import { getUserBrowser, getUserDeviceType } from "../utils/browserUtils";
import { EmailPage } from "pages/v2/EmailLogin/EmailPage";
import { NamePage } from "pages/v2/EmailLogin/NamePage";
import OTPPage from "pages/v2/EmailLogin/OTPPage";
import AccountTypeSelection from "pages/v2/EmailLogin/AccountTypeSelection";
import SlackbotPage from "pages/v2/EmailLogin/SlackbotPage";
import { AppIntegrationSuccessPage } from "pages/v2/EmailLogin/AppIntegrationSuccessPage";
import { DownloadMobileApp } from "../pages/v2/Download/DownloadMobileApp";
import { isMobileApp } from "../utils/mobileUtils";
import InstallExtensionPage from "pages/v2/EmailLogin/InstallExtensionPage";
import SignupPageV2 from "pages/v2/Login/SignupPageV2";
import InviteAyraaBotPage from "pages/v2/EmailLogin/InviteAyraaBotPage";
import SetUpPageV2 from "pages/v2/Login/SetupPageV2";
import GetStartedEnterprise from "pages/GetStartedEnterprise/GetStartedEnterprise";
import IntegrationPage from "pages/Integration/IntegrationPage";
import { AppIntegrationCancelPage } from "pages/v2/EmailLogin/AppIntegrationCancelPage";
import { AppIntegrationFailurePage } from "pages/v2/EmailLogin/AppIntegrationFailurePage";
import { getConnectAppsAndAvailabilityForUser } from "pages/Integration/appConnectorUtils";

function AppContainer({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="common-body-background bg" />
      <Header />
      {children}
    </>
  );
}

function AppContainerWithSidebar({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Sidebar>{children}</Sidebar>
    </>
  );
}

function PrivateRouteWithoutNavBar({ children, ...rest }: $TSFixMe) {
  const employeeID = useAppSelector((state) => state.userManager.user.employeeID);

  //If the flow is for Alternate login, show spinner instead of login
  if (getDataFromAppStore(appConstants.ALTERNATE_ADMIN_LOGIN_FLOW)) {
    return <AppSpinner />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (employeeID) {
          return <>{children}</>;
        } else {
          const isDesktopApp = checkIfDesktopApp();
          if (isDesktopApp) {
            return (
              <Redirect
                to={{
                  pathname: "/desktopLogin",
                  state: { from: location },
                }}
              />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

function PrivateRoute({ children, ...rest }: $TSFixMe) {
  const employeeID = useAppSelector((state) => state.userManager.user.employeeID);

  //If the flow is for Alternate login, show spinner instead of login
  if (getDataFromAppStore(appConstants.ALTERNATE_ADMIN_LOGIN_FLOW)) {
    return <AppSpinner />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (employeeID) {
          return (
            <>
              <Navbar />
              {children}
            </>
          );
        } else {
          const isDesktopApp = checkIfDesktopApp();
          if (isDesktopApp) {
            return (
              <Redirect
                to={{
                  pathname: "/desktopLogin",
                  state: { from: location },
                }}
              />
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

export default function RouterConfig() {
  const tenant: Tenant = useAppSelector((state) => state.tenantManager.tenant || {});
  const user: any = useAppSelector((state) => state.userManager.user || {});
  const [isAppIntegrated, setIsAppIntegrated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getConnectAppsAndAvailabilityForUser({ employeeID: user.employeeID }).then((res) => {
      const isConnected = res.some(({ connected }) => connected === true);
      setIsAppIntegrated(isConnected);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);// Handle the error
    })
    .finally(() => {
      // Set loading to false regardless of success or failure
      setLoading(false);
    });
  }, [user]);
 
  return (
    <Switch>
      <PrivateRouteWithoutNavBar exact path="/">
        {
          getUserBrowser() === "Safari" ? <SafariPostLoginRedirect />  :
          ((getUserDeviceType() === "mobile" || isMobileApp()) && !loading && !isAppIntegrated) ?
            <AppContainer><DownloadMobileApp /></AppContainer> :
            <PulseAppRedirect redirectUrl={tenant.tenantUrl + CONSTANTS.REACT_PULSE_APP_CONTEXT} />
        }
      </PrivateRouteWithoutNavBar>
      <Route exact path="/gm">
        <AppContainer>
          <GMComponent />
        </AppContainer>
      </Route>
      <Route exact path="/download">
        <AppContainer>
          <DownloadDesktopApp />
        </AppContainer>
      </Route>
      <Route exact path="/account-type-selection">
        <AppContainer>
          <AccountTypeSelection fetchedUser={user} />
        </AppContainer>
      </Route>
      <Route exact path="/installation/instructions">
        <AppContainer>
          <DesktopAppInstallationInstructions />
        </AppContainer>
      </Route>
      <Route exact path="/signup">
        <AppContainer>
          <SignupPageV2 />
        </AppContainer>
      </Route>
      <Route exact path="/invite-ayraa-bot">
        <AppContainer>
          <InviteAyraaBotPage />
        </AppContainer>
      </Route>
      <Route exact path="/login">
        <AppContainer>
          <LoginPageV2 />
        </AppContainer>
      </Route>
      <Route exact path="/setup">
        <AppContainer>
          <SetUpPageV2 />
        </AppContainer>
      </Route>
      <Route exact path="/emailLogin/email">
        <AppContainer>
          <EmailPage />
        </AppContainer>
      </Route>
      <Route exact path="/slackbot-integration">
        <AppContainer>
          <SlackbotPage />
        </AppContainer>
      </Route>
      <Route exact path="/integration-success">
        <AppContainer>
          <AppIntegrationSuccessPage />
        </AppContainer>
      </Route>
      <Route exact path="/integration-cancel">
        <AppContainer>
          <AppIntegrationCancelPage />
        </AppContainer>
      </Route>
      <Route exact path="/integration-failure">
        <AppContainer>
          <AppIntegrationFailurePage />
        </AppContainer>
      </Route>
      <Route exact path="/install-extension">
        <AppContainer>
          <InstallExtensionPage />
        </AppContainer>
      </Route>
      <Route exact path="/emailLogin/otp">
        <AppContainer>
          <OTPPage />
        </AppContainer>
      </Route>
      <Route exact path="/emailLogin/name">
        <AppContainer>
          <NamePage />
        </AppContainer>
      </Route>
      <Route exact path="/desktopLogin">
        <AppContainer>
          <DesktopLoginPage />
        </AppContainer>
      </Route>
      <Route exact path="/alternateLogin">
        <AppContainer>
          <AlternateAccountLogin />
        </AppContainer>
      </Route>
      <PrivateRouteWithoutNavBar exact path="/logout">
        <AppContainer>
          <LogoutPage />
        </AppContainer>
      </PrivateRouteWithoutNavBar>
      <PrivateRouteWithoutNavBar exact path="/createWorkspace">
        <AppContainer>
          <CreateWorkspaceWithAccessControl />
        </AppContainer>
      </PrivateRouteWithoutNavBar>
      <PrivateRouteWithoutNavBar exact path="/createOrganization">
        <AppContainer>
          <CreateOrganizationWithAccessControl />
        </AppContainer>
      </PrivateRouteWithoutNavBar>
      <PrivateRouteWithoutNavBar exact path="/importEmployees">
        <AppContainer>
          <ImportEmployeesWithAccessControl />
        </AppContainer>
      </PrivateRouteWithoutNavBar>
      <PrivateRouteWithoutNavBar exact path="/integration">
        <IntegrationPage />
      </PrivateRouteWithoutNavBar>
      <Route exact path="/getStarted">
        <AppContainer>
          <GetStarted />
        </AppContainer>
      </Route>
      <Route exact path="/getStarted-enterprise">
        <AppContainer>
          <GetStartedEnterprise />
        </AppContainer>
      </Route>
      <Route exact path="/v1/login">
        <LoginPage />
      </Route>
      <Route exact path="/ayraaAdminLogin">
        <AyraaAdminLogin />
      </Route>
      <Route exact path="/gc">
        <SuccessPage />
      </Route>
      <Route exact path="/authCodeFlow">
        <SuccessPage />
      </Route>
      <Route exact path="/OAuthHRISuccess">
        <OAuthHRISuccessPage />
      </Route>
      <PrivateRoute exact path="/sendinvites">
        <SendInvitesPage />
      </PrivateRoute>
      <PrivateRouteWithoutNavBar exact path="/joinworkspace">
        <AppContainer>
          <JoinWorkspace />
        </AppContainer>
      </PrivateRouteWithoutNavBar>
      <PrivateRouteWithoutNavBar exact path="/labels">
        <main>
          <LabelsPage />
        </main>
      </PrivateRouteWithoutNavBar>
      <PrivateRoute exact path="/manageAccount">
        <ManageAccount />
      </PrivateRoute>
      <PrivateRouteWithoutNavBar exact path="/settings">
        <AppContainerWithSidebar>
          <ManageSettings />
        </AppContainerWithSidebar>
      </PrivateRouteWithoutNavBar>
      <PrivateRouteWithoutNavBar exact path="/workspaceSettings">
        <AppContainer>
          <WorkspaceSettings />
        </AppContainer>
      </PrivateRouteWithoutNavBar>
      <Route exact path="/unauthorized">
        <ErrorPage error="You are not authorized." />
      </Route>
      <PrivateRoute exact path="/manageSubscription">
        <ManageSubscription />
      </PrivateRoute>
      <PrivateRoute exact path="/importEmployees">
        <WorkatoImport />
      </PrivateRoute>
      <PrivateRoute exact path="/manageEmployees">
        <ManageEmployeesWithAccessControl />
      </PrivateRoute>
      <PrivateRoute exact path="/selectHRI">
        <SelectHRI />
      </PrivateRoute>
      <PrivateRoute path="/adp-integration">
        <AdpIntegration />
      </PrivateRoute>
      <PrivateRoute path="/namely-integration">
        <NamelyIntegration />
      </PrivateRoute>
      <PrivateRoute path="/zenefits-integration">
        <ZenefitsIntegration />
      </PrivateRoute>
      <PrivateRoute path="/hibob-integration">
        <HibobIntegration />
      </PrivateRoute>
      <PrivateRoute path="/bamboohr-integration">
        <BamboohrIntegration />
      </PrivateRoute>
      <PrivateRoute path="/rippling-integration">
        <RipplingIntegration />
      </PrivateRoute>
      <PrivateRoute path="/trinet-integration">
        <TrinetIntegration />
      </PrivateRoute>
      <PrivateRoute path="/git/info">
        <GitInfoPageWithAccessControl />
      </PrivateRoute>
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );
}
