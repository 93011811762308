import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {Workspace} from "../types/userTypes";
import {Nullable} from "../types/global";

export interface WorkspaceSliceState {
  workspaceDetails: Workspace;
  status: string;
  error: Nullable<string>;
}

export const createWorkspace = createAsyncThunk("workspace/createWorkspace",
    async ({ workspaceDetails, tenantID }: { workspaceDetails: Workspace, tenantID: number }) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/workspace/createWorkspaceForTenant/${tenantID}`,
    workspaceDetails
  );
  return response.data;
});

export const updateWorkspace = createAsyncThunk("workspace/updateWorkspace",
  async ({ workspaceDetails, tenantID }: { workspaceDetails: Workspace, tenantID: number }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workspace/updateWorkspace/${tenantID}`,
      workspaceDetails
    );
    return response.data;
  });

export const getWorkspaceDetails = createAsyncThunk("workspace/getWorkspace",
  async ({tenantID}: { tenantID: number }) => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/workspace/getWorkspaceForTenant/${tenantID}`);
    return response.data;
  });

export const joinWorkspaceOrg = createAsyncThunk("workspace/joinWorkspaceOrg",
  async ({tenantId, employeeId, organizationGroupId}: { tenantId: number, employeeId: number, organizationGroupId?: Nullable<number> }) => {
   try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workspace/joinWorkspace`,
      {tenantId, employeeId}
    );
    return response.data;
   }
   catch(e) {
    console.log("Something went wrong::" + e);
   }
  });

export const workspaceSlice = createSlice({
  name: "workspaceSlice",
  initialState: {
    workspaceDetails: {},
    status: "idle",
    error: null,
  } as WorkspaceSliceState,
  reducers: {
    setWorkspaceDetails: (state, action) => {
      state.workspaceDetails = action.payload;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWorkspace.fulfilled, (state, action) => {
        state.workspaceDetails = action.payload;
        state.status = "fulfilled";
      })
      .addCase(createWorkspace.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getWorkspaceDetails.fulfilled, (state, action) => {
        state.workspaceDetails = action.payload;
        state.status = "fulfilled";
      })
      .addCase(getWorkspaceDetails.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateWorkspace.fulfilled, (state, action) => {
        state.workspaceDetails = action.payload;
        state.status = "fulfilled";
      })
      .addCase(updateWorkspace.pending, (state) => {
        state.status = "pending";
      });
  },
});

export const { setWorkspaceDetails } = workspaceSlice.actions;
export default workspaceSlice.reducer;
