import { Label } from "components/Label";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconWithTooltipProps as ComponentWithTooltipProps } from "types/global";

/**
 *  Wraps the React Compnent with tooltip and shows the tooltip based on a condition passed in showTooltip param
 */
export function ComponentWithTooltip({
  component,
  tooltipProps
}: {
  component: React.ReactElement;
  tooltipProps: ComponentWithTooltipProps;
}): JSX.Element {
  if (tooltipProps?.show) {
    return (
      <OverlayTrigger
        delay={{ show: 500, hide: 400 }}
        overlay={
          <Tooltip {...tooltipProps}>
            <Label
              name={tooltipProps.labelInfo.labelName}
              args={tooltipProps.labelInfo.args || []}
            />
          </Tooltip>
        }
        offset={[0, 10]}
        placement={tooltipProps.tooltipPlacement}
        rootClose
      >
        {component}
      </OverlayTrigger>
    );
  }

  return component;
}
