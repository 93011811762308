import axios from "axios";
import { Nullable } from "types/global";

export const joinWorkspaceOrg = async ({tenantId, employeeId, organizationGroupId}: { tenantId: number, employeeId: number, organizationGroupId?: Nullable<number> }) => {
   try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/workspace/joinWorkspace`,
      {tenantId, employeeId}
    );
    return response.data;
   }
   catch(e) {
    console.log("Something went wrong::" + e);
   }
  };

  
  export const updateEmployeeWorkspaceStatus =  async ({ userData, updatedBy }: any) => {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/updateEmployee`, {
        ...userData,
        updatedBy,
      });
      return res.data;
  }
  
  

