import {Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import GitInfo from 'ayraa-react-git-info/macro';
import buildInfo from '../../buildInfo';
import styles from "./styles/git.module.css";
import {WithAccessControl} from "../AccessControl";
import React from "react";
import {useGetPrivileges} from "../AccessControl/useGetPrivileges";
import {actions} from "../AccessControl/actions";

export function GitInfoPage({
  requiredPrivileges,
  privileges,
}: $TSFixMe) {

  const gitInfo = GitInfo();

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [actions.VIEW_GIT_INFO],
  });

  return (<>
    {
      (privileges[actions.VIEW_GIT_INFO]) ?
        <Container className={styles.container}>
          <Row className={styles.rows}>
            <Col>Branch</Col>
            <Col>{gitInfo.branch}</Col>
          </Row>
          <Row className={styles.rows}>
            <Col>Commit Date</Col>
            <Col>{gitInfo.commit.date}</Col>
          </Row>
          <Row className={styles.rows}>
            <Col>Commit Id</Col>
            <Col>{gitInfo.commit.hash}</Col>
          </Row>
          <Row className={styles.rows}>
            <Col>Commit Message</Col>
            <Col>{gitInfo.commit.message}</Col>
          </Row>
          <Row className={styles.rows}>
            <Col>Commited User</Col>
            <Col>{gitInfo.commit.user}</Col>
          </Row>
          {buildInfo ?
            <Row className={styles.rows}>
              <Col>Build Date</Col>
              <Col>{new Date(buildInfo.buildDate).toString()}</Col>
            </Row> : ""}
        </Container>
        : <Container className={styles.container}>"You do not have access to view this info"</Container>
    }
  </>);
}

export default function GitInfoPageWithAccessControl() {
  return (
    <WithAccessControl component={GitInfoPage} />
  );
}