export enum TrackerSliceEnum {
  CREATE_POST_TRACKER_SLICE,
  WINDOW_DIMENSIONS_TRACKER_SLICE,
  ABC_TRACKER_SLICE,
  SCRIBES_DETAIL_VIEW_TRACKER,
  SCRIBES_LIST,
  CREATE_MEETING_NOTES_TRACKER_SLICE,
  SCRIBE_GROUP_USER_LIST,
  ZENDESK_SUBDOMAIN_MODAL,
}
