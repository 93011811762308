import {Component} from "react";
import {withRouter} from "react-router-dom";
import {appConstants} from "../../utils/appConstants";
import {addDataToAppDataStore} from "../../dataStore/appDataStore";

class RouterHistoryObjectExtractor extends Component<any, any> {

  render() {
    const historyObject = this.props.history;
    addDataToAppDataStore(appConstants.HISTORY_OBJECT_KEY, historyObject);
    return <></>;
  }
}
export default withRouter(RouterHistoryObjectExtractor)

