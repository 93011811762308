import {forwardRef, useEffect, useRef, useState} from "react";
import {Button, Form, Overlay, Popover} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Nullable} from "types/global";
import {WithAccessControl, WithAccessControlPrivilegeProp} from "utils/AccessControl";
import {useGetPrivileges} from "utils/AccessControl/useGetPrivileges";
import {getLabel, regexPatterns} from "utils/constants";
import styles from "./styles/label.module.css";
import {BsPencilSquare} from "react-icons/bs";
import {appConstants} from "../../utils/appConstants";
import {getDataFromAppStore} from "../../dataStore/appDataStore";
import {updateLabels} from "../../dataSender/label/LabelDataSender";
import * as DOMPurify from 'dompurify';

export const checkParameterizedLabel = ({
  labels,
  value,
  name,
}: {
  labels: { [name: string]: string };
  value: string;
  name: string;
}) => {
  if (labels[name]) {
    const parameterInLabel = labels[name].match(regexPatterns.labelParameter);
    const parameterInValue = value.match(regexPatterns.labelParameter);
    if (parameterInLabel) {
      if (parameterInValue && parameterInLabel.length === parameterInValue.length) {
        return { isValid: true, message: null };
      } else {
        if (parameterInValue && parameterInLabel.length < parameterInValue.length) {
          return { isValid: false, message: `Placeholders needed: ${parameterInLabel.length}` };
        }
        return { isValid: false, message: `Missing placeholders: ${parameterInLabel.length}` };
      }
    } else {
      if (parameterInValue) {
        return { isValid: false, message: `Placeholders not needed for this label` };
      }
      return { isValid: true, message: null };
    }
  }
  return { isValid: true, message: null };
};

function EditLabel({ name, onCancelClick, ...rest }: any, ref: any) {
  const defaultLanguageId = appConstants.ENGLISH_LANGUAGE_ID;
  const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);
  const [value, setValue] = useState(labels[name] || "");
  const [validationMessage, setValidationMessage] = useState<Nullable<string>>(null);

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onSaveClick = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!value) {
      setValidationMessage("Cannot be empty");
    } else {
      if (!value.trim()) {
        setValidationMessage("Cannot contain space");
      } else {
        const { isValid, message } = checkParameterizedLabel({ labels, name, value });
        if (isValid) {
          if (defaultLanguageId) {
            await updateLabels({ name, value, languageId: defaultLanguageId });
            onCancelClick();
          }
        } else {
          setValidationMessage(message);
        }
      }
    }
  };

  return (
    <Popover {...rest} className={styles.editLabelContainer} ref={ref}>
      <Form onClick={(e) => e.stopPropagation()}>
        <Form.Group className="mb-3">
          <Form.Label className={styles.editFormLabel}>
            <code className={styles.labelName}>{name}</code>
            <Link to="/labels" className={styles.link} onClick={(e) => e.stopPropagation()}>
              <span role="button">(see all labels)</span>
            </Link>
          </Form.Label>
          <Form.Control ref={inputRef} id={name} value={value} onChange={onChange} type="text" autoFocus onClick={(e) => e.stopPropagation()} />
          {validationMessage && <Form.Text className="text-muted">{validationMessage}</Form.Text>}
        </Form.Group>
        <div className={styles.action}>
          <Button onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCancelClick();
          }} variant="secondary" size="sm">
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={onSaveClick}>
            Save
          </Button>
        </div>
      </Form>
    </Popover>
  );
}

const EditLabelWithRef = forwardRef(EditLabel);

interface LabelProps extends WithAccessControlPrivilegeProp {
  name: string;
  args: string[];
}

function Label({ args = [], name, privileges, requiredPrivileges }: LabelProps) {
  const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY, {});

  const [show, setShow] = useState(false);
  const target = useRef<HTMLSpanElement>(null);

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: ["EDIT_LABEL_TEXT"],
  });

  const onHide = () => {
    setShow(false);
  };

  const getLabelValueClosure = () => {
    const labelValue = args.length > 0 ? getLabel(labels[name], name, args) : labels[name] || name;
    return () => {
      return {__html: DOMPurify.sanitize(labelValue)};
    };
  }

  const getLabelValue = getLabelValueClosure();

  if (privileges.EDIT_LABEL_TEXT) {
    return (
      <>
        <span ref={target} dangerouslySetInnerHTML={getLabelValue()} />
        <sup>
          <BsPencilSquare
          onClick={(e) => {
            e.stopPropagation();
            setShow(true);
          }}
          className={styles.container}
          title="Edit label"
          tabIndex={0}/>
        </sup>

        <Overlay
          placement="top-start"
          target={target.current}
          rootClose
          onHide={onHide}
          show={show}
          popperConfig={{
            modifiers: [{
              name: 'flip',
              enabled: true
            }]
          }}
        >
          {(props) => {
            return <EditLabelWithRef name={name} {...props} onCancelClick={onHide} />;
          }}
        </Overlay>
      </>
    );
  }
  return <span ref={target} dangerouslySetInnerHTML={getLabelValue()} />;
}

export default function LabelWithAccessControl({
  name,
  args = [],
}: {
  name: string;
  args?: string[];
}) {
  return <WithAccessControl component={Label} props={{ name, args }} />;
}
