export const columnDefs = () => [
  {
    headerName: "Name",
    field: "employeeFirstName",
    cellRenderer: "userName",
    sortable: true,
  },
  {
    field: "workEmailID",
    headerName: "Email",
  },
  {
    field: "employeeGroups",
    headerName: "Orgs",
    cellRenderer: "groups",
  },
  {
    field: "userRole.roleName",
    headerName: "Role",
  },
  {
    field: "status",
    sortable: true,
  },
  {
    cellRenderer: "statusActions",
    width: 0,
    pinned: "right",
  }
];
