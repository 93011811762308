import React, { Component, ReactNode } from "react";
import styles from "./styles/installExtension.module.css";
import { Button } from "react-bootstrap";
import { InstallExtensionScreenshot, AlertIcon, RoundedArrowButton } from "utils/icons";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { browserName } from "react-device-detect";
import { ConfirmNextPageModal } from "./ConfirmNextPageModal";
import Label from "components/Label/Label";
import { browserData } from "utils/constants";
import { Progress } from "components/Progress/Progress";
import { checkIfExtensionIsInstalledAsync } from "pages/Integration/utils";

interface InstallExtensionPageState {
  showModal: boolean;
  showOnlyContinueButton: boolean;
}
interface InstallExtensionPageProps {}

export class InstallExtensionPage extends AyraaBaseComponent<
  InstallExtensionPageProps,
  InstallExtensionPageState
> {
  constructor(props: InstallExtensionPageProps) {
    super(props);
    this.state = {
      showModal: false,
      showOnlyContinueButton: false,
    };
  }

  public renderCore(): ReactNode {
    const Component = browserData[browserName] && browserData[browserName].icon;

    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.extensionWorkspaceLabel}>
            <Label name="ui.onboarding.install.extension.page-title" />
          </div>
          <div className={styles.extensionDescription}>
            <Label name="ui.onboarding.install.extension.page-subtext" />
          </div>
          {Component ? (
            this.state.showOnlyContinueButton ? (
              <Button
                className={styles.continueButton}
                variant="secondary"
                onClick={this.handleDirectRedirect}
              >
                Continue
              </Button>
            ) : (
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.continueButton}
                  variant="secondary"
                  onClick={this.handleOnAddToBrowser}
                >
                  {<Component className={styles.browserIcon} />} Add To{" "}
                  {browserName ? browserName : "Browser"}
                </Button>
                <div onClick={this.onSkipClick} className={styles.skipContinueButton}>
                  Skip, Continue
                </div>
              </div>
            )
          ) : (
            <>
              <div className={styles.noSupported}>
                <AlertIcon />
                <span className={styles.danger}>
                  <Label name="ui.onboarding.install.extension.borwser-not-supported" />
                </span>
                <Label name="ui.onboarding.install.extension.try-chrome" />
              </div>
              <Button
                className={styles.continueButton}
                variant="secondary"
                onClick={this.handleDirectRedirect}
              >
                <Label name="ui.onboarding.install.extension.continue-to-ayraa" />
              </Button>
            </>
          )}
        </div>
        <div className={styles.rightContainer}>
          <InstallExtensionScreenshot className={styles.image} />
        </div>
        <ConfirmNextPageModal
          show={this.state.showModal}
          handleSecondaryAction={this.handleCloseModal}
          handlePrimaryAction={this.handleDirectRedirect}
          bodyText="The browser extension helps Ayraa capture knowledge across a range of web apps. Please confirm you have installed it."
        />
        <Progress noOfSteps={3} activeStep={1} className={styles.progress} />
      </div>
    );
  }

  private handleOnAddToBrowser() {
    setTimeout(() => {
      this.setState({
        showOnlyContinueButton: true,
      });
    }, 1000);
    const popup = window.open(
      browserData[browserName].extensionLink,
      "Extension",
      `width=${1200},height=${800},top=${100},left=${100}`
    );
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    popup.focus();
  }

  private handleDirectRedirect() {
    this.history.push("/slackbot-integration");
  }

  private onSkipClick() {
    checkIfExtensionIsInstalledAsync().then((isInstalled) => {
      if (isInstalled) {
        this.history.push("/slackbot-integration");
      } else {
        this.setState({ showModal: true });
      }
    });
  }

  private handleCloseModal() {
    this.setState({ showModal: false });
  }
}

export default InstallExtensionPage;
