import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import styles from "./ayraaAdminLogin.module.css"
import {FormEvent, MouseEvent, useState} from "react";
import {ayraaAdminLogin, ayraaAdminLogout} from "../../dataSender/ayraaAdmin/AyraaAdminDataSender";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getUsersRole, setUserRole} from "../../redux/userSlice";
import {User} from "../../types/userTypes";
import {getUserInfo} from "../../redux/authSlice";

export default function AyraaAdminLogin() {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>();

    const dispatch = useAppDispatch();

    const isAyraaAdminLoggedIn: boolean = useAppSelector((state) => state.userManager.userRole?.privileges
        ?.map(privilege => privilege.privilegeName).includes('EDIT_LABEL_TEXT'));

    const adminLogin = async (e: FormEvent) => {
        e.preventDefault();
        if (username && password) {
            const status = await ayraaAdminLogin({ username, password });
            if (status === 200) {
                const fetchedUser: User = await dispatch(getUserInfo()).unwrap();
                dispatch(setUserRole(getUsersRole(fetchedUser.userRole)));
                setErrorMessage("");
            } else if (status === 401) {
                setErrorMessage("Username or password is incorrect");
            }
        }
    }

    const adminLogout = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setUsername("");
        setPassword("");
        setErrorMessage("");
        await ayraaAdminLogout();
        dispatch(setUserRole(null));
    }

    return (
        <>
            {!isAyraaAdminLoggedIn && (
                <Container fluid className={styles.container}>
                    <Row className={styles.row + " justify-content-md-center"}>
                        <Col md="auto" className={styles.column}>
                            <Form onSubmit={adminLogin}>
                                <FloatingLabel controlId="formUsername" label="Username" className="mb-3">
                                    <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                                                  placeholder="Enter username" />
                                </FloatingLabel>
                                <FloatingLabel controlId="formPassword" label="Password" className="mb-3">
                                    <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                                                  placeholder="Password" />
                                </FloatingLabel>
                                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            )}
            {isAyraaAdminLoggedIn && (
                <Container fluid className={styles.loggedInContainer}>
                    <Row>
                        <Col md={9}>
                            You are logged in.
                        </Col>
                        <Col md={1}>
                            <Button variant="secondary" onClick={adminLogout}>Logout</Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
}
