import {Image, Tab, Tabs} from "react-bootstrap";
import styles from "./workspaceSettings.module.css";
import {ManageEmployees} from "../../ManageEmployees";
import BackArrow from "../../../images/settings/BackArrow.svg"
import {useHistory} from "react-router";

const WorkspaceSettings = () => {
  const history = useHistory();
  const backClickHandler = () => {
    history.push("/settings");
  }

  return (<>
      <div className={styles.header}>
        <Image className={styles.backArrow} src={BackArrow} onClick={backClickHandler}/>
        <span className={styles.title}>Workspace Settings</span>
      </div>
      <Tabs defaultActiveKey="manageUsers" className={styles.tabs}>
        <Tab eventKey="manageUsers" title="Manage Users">
          <ManageEmployees/>
        </Tab>
        <Tab eventKey="orgs" title="Orgs">
        </Tab>
        <Tab eventKey="workspace" title="Workspace">
        </Tab>
      </Tabs>
    </>
  );
}

export default WorkspaceSettings;