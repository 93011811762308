import React from "react";
import { Card } from "react-bootstrap";
import { SubscribeButton } from "components/Subscription";
import styles from "./subscription.module.css";
import { format } from "date-fns-tz";
import { subscriptionTypes } from "utils/constants";
import { useAppSelector } from "redux/hooks";

function SubscriptionDate({ subscriptionStartDate, subscriptionInactiveFromDate }: $TSFixMe) {
  if (!subscriptionStartDate && !subscriptionInactiveFromDate) {
    return null;
  }
  return (
    <Card.Footer>
      {subscriptionStartDate && (
        <div>Start date: {format(new Date(subscriptionStartDate), "dd-MMM-yyyy")}</div>
      )}
      {subscriptionInactiveFromDate && (
        <div>Inactive from: {format(new Date(subscriptionInactiveFromDate), "dd-MMM-yyyy")}</div>
      )}
    </Card.Footer>
  );
}

export default function SubscriptionItem({
  subscription,
  disabled,
  title = null,
  className = "",
  isUpcoming = false,
}: $TSFixMe) {
  const subscribedPackage: $TSFixMe = useAppSelector(
    (state) => state.subscriptionManager.subscribedPackageState.subscribedPackage
  );
  const { tenant } = useAppSelector((state) => state.tenantManager);
  const { tenantID }: $TSFixMe = tenant;

  if (!subscription || !tenantID) {
    return null;
  }

  const {
    subscriptionType,
    subscriptionPackageID,
    subscriptionPackageName,
    subscriptionPricePerEmployee,
  } = subscription.subscriptionPackageDetails;

  const isFree = subscriptionType === subscriptionTypes.FREE;
  const isSubscribed =
    subscribedPackage &&
    subscriptionPackageID === subscribedPackage.subscriptionPackageDetails.subscriptionPackageID;

  return (
    <div className={className}>
      {title && <h5>{title}</h5>}
      <Card className={styles.subscriptionCard}>
        <Card.Img variant="top" src="https://i.imgur.com/EHyR2nP.png" />
        <Card.Body>
          <Card.Title>
            {subscriptionPackageName} - ₹{subscriptionPricePerEmployee}
            /emp/month
          </Card.Title>
          <Card.Text>
            By purchasing this package you will be charged every month in order to get access to{" "}
            {subscriptionPackageName.toLowerCase()} products.
          </Card.Text>
          {!isSubscribed && !isUpcoming && (
            <SubscribeButton
              btnText={{ default: isFree ? "Try" : "Upgrade", loading: "Upgrading..." }}
              selectedSubscription={subscription.subscriptionPackageDetails}
              updateSubscription
            />
          )}
        </Card.Body>
        {(isSubscribed || isUpcoming) && (
          <SubscriptionDate
            subscriptionStartDate={subscription.subscriptionStartDate}
            subscriptionInactiveFromDate={subscription.subscriptionInactiveFromDate}
          />
        )}
      </Card>
    </div>
  );
}
