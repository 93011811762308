import classNames from "classnames";
import CloseIcon from "components/CloseIcon/CloseIcon";
import { Label } from "components/Label";
import { Progress } from "components/Progress/Progress";
import ToastNotification from "components/Toast/ToastNotification";
import { ToastNotificationStatus } from "components/Toast/types";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { getDataFromAppStore } from "dataStore/appDataStore";
import { ConfirmNextPageModal } from "pages/v2/EmailLogin/ConfirmNextPageModal";
import { ReactNode } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Tenant, User, UserRole } from "types/userTypes";
import { appConstants } from "utils/appConstants";
import { Loader, Logo } from "utils/icons";
import {
  getConnectAppsAndAvailabilityForUser,
  getConnectionStatusForAnApp,
} from "./appConnectorUtils";
import { APPS_FOR_INTEGRATION_MAP, BrowserExtension, SlackBot } from "./appsForIntegrationConfig";
import AppsForIntegrationGrid from "./components/AppsForIntegrationGrid";
import { ConnectedApps } from "./components/ConnectedApps";
import {
  appsForIntegrationList,
  recommendedAppsForIntegrationConfig,
} from "./integrationPageConfig";
import styles from "./styles/integrationPage.module.css";
import { IntegratedAppNamesEnum } from "./types";
import { checkIfExtensionIsInstalledAsync } from "./utils";
import { getUserDeviceType } from "utils/browserUtils";
import { isMobileApp } from "utils/mobileUtils";
import ConsentModal from "components/Integration/ConsentModal";
import { addUserConsent } from "dataFetcher/Integration/consentDataSender";

interface IntegrationPageProps {
  className?: string;
  tenant: Tenant;
  user: User;
  userRole: UserRole;
}

interface IntegrationPageState {
  connectAppsList: { icon: any; email: string; connectedOn: number }[];
  confirmationModalInfo: {
    show: boolean;
    headerContent: string;
    bodyContent: string;
  };
  nextPageRoute?: string;
  fetchingAvailability: boolean;
  showConsentModal:boolean,
  continueBtnIsLoading: boolean
}

export class IntegrationPage extends AyraaBaseComponent<
  IntegrationPageProps,
  IntegrationPageState
> {
  private isIndependentMode: boolean = false;

  constructor(props: IntegrationPageProps) {
    super(props);
    this.state = {
      connectAppsList: [],
      confirmationModalInfo: {
        bodyContent: "",
        headerContent: "",
        show: false,
      },
      nextPageRoute: undefined,
      fetchingAvailability: true,
      showConsentModal: this.props.user.userConsentPageVersion ? this.props.user.userConsentPageVersion.valueOf() <= 0 : true,
      continueBtnIsLoading: false
    };

    // Flag to show progress component
    this.isIndependentMode =
      new URLSearchParams(this.history.location.search).get("independentMode") === "1";
  }

  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    const bodyClassNames = classNames(styles.body, {
      [styles.loading]: this.state.fetchingAvailability,
    });
    if (getUserDeviceType() === "mobile" || isMobileApp()) {
      this.history.push("/")
    }
    const hasConsent = this.props.user.userConsentPageVersion ? this.props.user.userConsentPageVersion.valueOf() > 0 : false;

    return (
      <>
        <div className={containerClassNames}>
          <header className={styles.header}>
            <Logo />
            {this.isIndependentMode ? (
              <CloseIcon
                onClick={() => {
                  this.history.goBack();
                }}
              />
            ) : (
              <>
                {/* <Progress className={styles.progress} noOfSteps={3} activeStep={2} /> */}
                <Button onClick={this.checkIntegrations} className={styles.continueBtn}>{this.state.continueBtnIsLoading ? <span className={styles.loaderBtn} /> : "Continue"}</Button>
              </>
            )}
          </header>
          <div className={bodyClassNames}>
            <div className={styles.titleContainer}>
              <span className={styles.title}>
                <Label name="ui.integration.page.title" />
              </span>
              <ConnectedApps list={this.state.connectAppsList} />
            </div>
            <div className={styles.subtitle}>
              <Label name="ui.integration.page.subtitle" />
            </div>
            {this.state.fetchingAvailability ? (
              <div className={styles.loaderContainer}>
                <Loader className={styles.loader} />
              </div>
            ) : (
              <div className={styles.appsGridContainer}>
                <AppsForIntegrationGrid
                  className={styles.recommendedAppsGrid}
                  onConnectClick={this.onConnectClick}
                  onDisconnectClick={this.onDisconnectClick}
                  isCompanyMode={false}
                  list={recommendedAppsForIntegrationConfig}
                />
                <AppsForIntegrationGrid
                  className={styles.recommendedAppsGrid}
                  onConnectClick={this.onConnectClick}
                  onDisconnectClick={this.onDisconnectClick}
                  isCompanyMode={false}
                  list={appsForIntegrationList}
                />
              </div>
            )}
          </div>
        </div>
        <ConfirmNextPageModal
          show={this.state.confirmationModalInfo.show}
          handleSecondaryAction={this.clearModalContentAndCloseIt}
          handlePrimaryAction={this.navigateToNextPage}
          headerLabel={this.state.confirmationModalInfo.headerContent}
          bodyText={this.state.confirmationModalInfo.bodyContent}
          secondaryButtonLabel="Take me back"
          primaryButtonLabel="Not now, continue"
        />
        <ConsentModal show={this.state.showConsentModal} handleClose={this.closeModal} handleSubmit={this.handleSubmitConsent} />
      </>
    );
  }

  private closeModal(){
    this.setState({showConsentModal:false})
  }

  private handleSubmitConsent(){
    addUserConsent({employeeId:this.props.user.employeeID, tenantId:this.props.tenant.tenantID});
    this.closeModal()
  }

  private navigateToNextPage() {
    this.history.push(this.state.nextPageRoute);
  }

  private clearModalContentAndCloseIt() {
    this.setState({
      confirmationModalInfo: {
        bodyContent: "",
        headerContent: "",
        show: false,
      },
    });
  }

  private async checkIntegrations() {
    this.setState({ continueBtnIsLoading: true });
    const isCompanyMode = getDataFromAppStore(appConstants.IS_COMPANY_MODE);
    const integrations = [
      getConnectionStatusForAnApp(IntegratedAppNamesEnum.SLACK, this.props.user.employeeID),
      checkIfExtensionIsInstalledAsync(),
    ];
    const [isSlackBotConnected, isBrowserExtenstionInstalled] = await Promise.all(integrations);
    this.setState({continueBtnIsLoading: false});
    if (!isSlackBotConnected && !isBrowserExtenstionInstalled) {
      this.setState({
        confirmationModalInfo: {
          bodyContent:
            "We recommend you to connect with Slack and install the browser extension as it is critical to Ayraa’s ability to capture knowledge.",
          headerContent: "Skip both Slack and browser extension?",
          show: true,
        },
        nextPageRoute: isCompanyMode ? "/importEmployees" : "/getStarted",
      });
    } else if (!isSlackBotConnected && isBrowserExtenstionInstalled) {
      this.setState({
        confirmationModalInfo: {
          bodyContent:
            "We recommend you to connect Slack Integration as it is critical to Ayraa’s ability to capture knowledge.",
          headerContent: "Thinking of skipping Slack integration?",
          show: true,
        },
        nextPageRoute: isCompanyMode ? "/importEmployees" : "/getStarted",
      });
    } else if (isSlackBotConnected && !isBrowserExtenstionInstalled) {
      this.setState({
        confirmationModalInfo: {
          bodyContent:
            "We recommend you to install the browser extension as it is critical to Ayraa’s ability to capture knowledge.",
          headerContent: "Thinking of skipping Browser Extension?",
          show: true,
        },
        nextPageRoute: isCompanyMode ? "/importEmployees" : "/getStarted",
      });
    } else {
      const route = isCompanyMode ? "/importEmployees" : "/getStarted";
      this.history.push(route);
    }
  }

  private async onConnectClick(name: IntegratedAppNamesEnum) {
    const { connect, label } = APPS_FOR_INTEGRATION_MAP[name];

    const loadingLabel = `Connecting ${label}...`;
    const loadingToastId = toast.loading(loadingLabel);

    const { isConnected } = (await connect?.({
      employeeId: this.props.user.employeeID,
      tenantId: this.props.tenant.tenantID,
    })) ?? { isConnected: false };
    toast.remove(loadingToastId);

    if (isConnected) {
      toast.custom(
        <ToastNotification
          status={ToastNotificationStatus.Success}
          labelName={`${label} has been connected.`}
        />
      );
    } else {
      toast.custom(
        <ToastNotification
          status={ToastNotificationStatus.Error}
          labelName={`Failed to connect ${label}.`}
        />
      );
    }

    return { isConnected };
  }

  private async onDisconnectClick(name: IntegratedAppNamesEnum) {
    const { label, disconnect } = APPS_FOR_INTEGRATION_MAP[name];

    const loadingLabel = `Disconnecting ${label}...`;
    const loadingToastId = toast.loading(loadingLabel);
    const { isDisconnected } = (await disconnect?.({ employeeId: this.props.user.employeeID })) ?? {
      isDisconnected: false,
    };
    toast.remove(loadingToastId);

    toast.custom(
      <ToastNotification
        status={ToastNotificationStatus.Success}
        labelName={`${label} has been disconnected.`}
      />
    );
    return { isDisconnected };
  }

  private doSideEffectOfMount() {
    if (this.shouldExecuteSideEffect(this.doSideEffectOfMount, [])) {
      const stopFetching = () => this.setState({ fetchingAvailability: false });
      getConnectAppsAndAvailabilityForUser({ employeeID: this.props.user.employeeID })
        .then(stopFetching)
        .catch(stopFetching);
    }
  }
}

const mapStateToProps = (state: any) => ({
  tenant: state.tenantManager.tenant,
  user: state.userManager.user,
  userRole: state.userManager.userRole,
});

export default connect(mapStateToProps)(IntegrationPage);
