export const appConstants = {
  LABEL_INFO_KEY: "labelInfo",

  ENGLISH_LANGUAGE_ID: 1,

  SERVICE_PROVIDER_PARAM: "sp",

  TENANT_ID_PARAM: "tenantId",

  VERIFICATION_TOKEN_PARAM: "vt",

  CHANNEL_PARAM: "channel",

  ALTERNATE_ADMIN_LOGIN_FLOW: "ALTERNATE_ADMIN_LOGIN_FLOW",

  HISTORY_OBJECT_KEY: "history",

  REQUESTED_APP: "REQUESTED_APP",

  SERVICE_PROVIDER_PARAM_LOGIN: "SERVICE_PROVIDER_PARAM_LOGIN",

  EMAIL_ID_LOGIN_FLOW: "EMAIL_ID_LOGIN_FLOW",

  IS_COMPANY_MODE: "IS_COMPANY_MODE",

  APP_AVAILABILITY_INFO_LIST: "APP_AVAILABILITY_INFO_LIST",

  APP_AVAILABILITY_INFO_LIST_MAP: "APP_AVAILABILITY_INFO_LIST_MAP",

  CONNECTED_APPS_LIST_FOR_USER: "CONNECTED_APPS_LIST_FOR_USER",

  CONNECTED_APPS_MAP_FOR_USER: "CONNECTED_APPS_MAP_FOR_USER",

  SHOW_ZENDESK_SUBDOMAIN_MODAL: "SHOW_ZENDESK_SUBDOMAIN_MODAL",

  ZENDESK_SUBDOMAIN_API_RESPONSE: "ZENDESK_SUBDOMAIN_API_RESPONSE",

  IS_UPGRADE_PROCESS: "IS_UPGRADE_PROCESS",
};
