import {getDataFromAppStore} from "../dataStore/appDataStore";
import {appConstants} from "../utils/appConstants";
import {CONSTANTS} from "../utils/constants";
import {Spinner} from "react-bootstrap";
import React from "react";

const SuccessPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get("code");
  let response = {
    authCode,
    isAuthCode: true,
  };
  // The MS authcode is received here. If the flow is for Alternate login show loading icon.
  // Popup will be closed from pulse, as it was opened in Pulse
  if (getDataFromAppStore(appConstants.SERVICE_PROVIDER_PARAM) === CONSTANTS.MICROSOFT) {
    return (
      <div className="app loader">
        <Spinner animation="border"/>
        <span>Fetching records...</span>
      </div>
    );
  } else {
    const parent = window.opener;
    parent.postMessage(response, process.env.REACT_APP_CLIENT_URL);
    window.close();
  }

  return <div>Success!</div>;
};

export default SuccessPage;
