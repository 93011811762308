import { Container } from "react-bootstrap";
import styles from "../Logo/logo.module.css";
import { Logo } from "utils/icons";
export default function Header() {
  return (
    <Container fluid className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
    </Container>
  );
}
