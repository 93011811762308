import {AyraaBaseComponent} from "../ayraaFramework/AyraaBaseComponent";
import {ReactNode} from "react";
import {saveDetailsForAppLogin} from "../../dataSender/auth/AuthDataSender";
import {getDesktopAppLoginURL} from "../../utils/desktopUtils";
import AppSpinner from "../Spinner/AppSpinner";
import {DesktopAppLoginMessage} from "../v2/Auth/DesktopAppLoginMessage";

type SafariPostLoginRedirectState = {
    loading: boolean
}

export class SafariPostLoginRedirect extends AyraaBaseComponent<any, SafariPostLoginRedirectState> {

    private channel: string | null;
    private verificationToken: string | null;

    constructor(props: any) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        this.channel = query.get("channel");
        this.verificationToken = query.get("vt");

        this.state = {
            loading: true
        }
    }

    renderCore(): ReactNode {
        return <>
            {this.state.loading && <AppSpinner/>}
            {this.channel === 'desktop' && <DesktopAppLoginMessage />}
        </>;
    }

    private doSideEffectForSafariHandling() {
        if (this.shouldExecuteSideEffect(this.doSideEffectForSafariHandling, [])) {
            if (this.channel === 'desktop' && this.verificationToken) {
                this.handleDesktopAppLogin();
            } else {
                this.history.push('/download');
            }
        }
    }

    private async handleDesktopAppLogin() {
        if (this.verificationToken) {
            await saveDetailsForAppLogin(this.verificationToken);
            window.location.href = getDesktopAppLoginURL(this.verificationToken);
            this.setState({ loading: false })
        }
    }

}