import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar as BsNavbar } from "react-bootstrap";
import styles from "./navbar.module.css";
import PulseAppButton from "components/Redirect/PulseAppButton";
import SecureAppButton from "components/Redirect/SecureAppButton";
import { useAppSelector } from "redux/hooks";
import Profile from "../../pages/v2/Profile/Profile";
import {Tenant, User} from "../../types/userTypes";
import {CONSTANTS} from "../../utils/constants";

export default function Navbar() {
  const user: User = useAppSelector((state) => state.userManager.user);
  const tenant: Tenant = useAppSelector((state) => state.tenantManager.tenant);
  return (
    <BsNavbar bg="dark" className={styles.nav}>
      <BsNavbar.Brand className={styles.brand}>
        <Link to="/" className={styles.navLink}>
          Ayraa Hello
        </Link>
      </BsNavbar.Brand>
      <Nav className={styles.nav}>
        {/* If user is logged in and has not imported employees then also allow him to redirect  */}
        {tenant?.tenantRegistered &&
          user &&
          Object.keys(user).length > 0 &&
          !tenant?.isEmployeeDataImported && (
            <div className={styles.appButtons}>
              <PulseAppButton redirectUrl={tenant.tenantUrl + CONSTANTS.REACT_PULSE_APP_CONTEXT}/>
              <SecureAppButton redirectUrl={tenant.tenantUrl + CONSTANTS.REACT_SECURE_APP_CONTEXT} />
            </div>
          )}
        <Link className={styles.navLink} to="/">
          Home
        </Link>

        {user && Object.keys(user).length > 0 ? (
          <>
            <Link className={styles.navLink} to="/manageAccount">
              Manage Account
            </Link>
          </>
        ) : (
          <Link className={styles.navLink} to="/login">
            Login
          </Link>
        )}
        <div>
          <Profile />
        </div>
      </Nav>
    </BsNavbar>
  );
}
