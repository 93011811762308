import LoginGoogle from "components/v2/Auth/LoginGoogle";
import LoginMS from "components/v2/Auth/LoginMS";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { Label } from "../../../components/Label";
import { RightSection } from "./RightSection";
import styles from "./loginpagev2.module.css";

export default function SetUpPageV2() {
  const history = useHistory();
  const location = useLocation();

  const navigateToEmailLoginPage = () => {
    history.push("/emailLogin/email");
  };
  const user = useAppSelector((state) => state.userManager.user);
  const tenant = useAppSelector((state) => state.tenantManager.tenant);

  if (user.employeeID) {
    history.push(`/integration`);
  }
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.titleSmall}>
          <Label name="ui.login.welcome.back" />
        </div>
        <div className={styles.title}>
          <Label name="ui.loginv2.setup.title" />
        </div>
        <div className={styles.loginButtons}>
          <LoginGoogle labelName="ui.login.google.continue-button-text" />
          <LoginMS labelName="ui.login.microsoft.continue-button-text" />
        </div>
        <div className={styles.emailContainer}>
          Not on Google or Microsoft?
          <div className={styles.emailContainerLink} onClick={navigateToEmailLoginPage}>
            <Label name="ui.login.email.signin-button-text" />
          </div>
        </div>
      </div>
      <RightSection className={styles.rightContainer} />
    </div>
  );
}
