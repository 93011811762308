export const actions = {
  DELETE_TENANT: "DELETE_TENANT",
  VIEW_TENANT: "VIEW_TENANT",
  UPDATE_ASSIGN_ACTION_ITEM: "UPDATE_ASSIGN_ACTION_ITEM",
  EDIT_PROFILE: "EDIT_PROFILE",
  VIEW_EMPLOYEES: "VIEW_EMPLOYEES",
  VIEW_ANNOUNCEMENT: "VIEW_ANNOUNCEMENT",
  CREATE_GROUP: "CREATE_GROUP",
  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  DELETE_ANNOUNCEMENT: "DELETE_ANNOUNCEMENT",
  VIEW_ARTICLE: "VIEW_ARTICLE",
  DELETE_KUDOS: "DELETE_KUDOS",
  VIEW_ACTION_ITEM: "VIEW_ACTION_ITEM",
  SET_OOO_AUTOMATIC_REPLY: "SET_OOO_AUTOMATIC_REPLY",
  MANAGE_SUBSCRIPTION: "MANAGE_SUBSCRIPTION",
  CANCEL_OOO_AUTOMATIC_REPLY: "CANCEL_OOO_AUTOMATIC_REPLY",
  UPDATE_KUDOS: "UPDATE_KUDOS",
  VIEW_KUDOS: "VIEW_KUDOS",
  CREATE_KUDOS: "CREATE_KUDOS",
  UNPIN_POST: "UNPIN_POST",
  DELETE_GROUP: "DELETE_GROUP",
  VIEW_CHECKIN: "VIEW_CHECKIN",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  CREATE_EVENT: "CREATE_EVENT",
  CREATE_ANNOUNCEMENT: "CREATE_ANNOUNCEMENT",
  UPDATE_TENANT: "UPDATE_TENANT",
  VIEW_EVENT: "VIEW_EVENT",
  UPDATE_GROUP: "UPDATE_GROUP",
  SEND_INVITES: "SEND_INVITES",
  CREATE_ARTICLE: "CREATE_ARTICLE",
  ASSIGN_ACTION_ITEM: "ASSIGN_ACTION_ITEM",
  DELETE_ARTICLE: "DELETE_ARTICLE",
  PIN_POST: "PIN_POST",
  VIEW_PROFILE: "VIEW_PROFILE",
  UPDATE_ARTICLE: "UPDATE_ARTICLE",
  IMPORT_EMPLOYEES: "IMPORT_EMPLOYEES",
  UPDATE_EVENT: "UPDATE_EVENT",
  DELETE_EVENT: "DELETE_EVENT",
  VIEW_SUBSCRIPTION: "VIEW_SUBSCRIPTION",
  UPDATE_ANNOUNCEMENT: "UPDATE_ANNOUNCEMENT",
  EXPORT_EMPLOYEES: "EXPORT_EMPLOYEES",
  CREATE_ACTION_ITEM: "CREATE_ACTION_ITEM",
  CREATE_WORKSPACE: "CREATE_WORKSPACE",
  UPDATE_WORKSPACE_SETTINGS: "UPDATE_WORKSPACE_SETTINGS",
  EDIT_LABEL_TEXT: "EDIT_LABEL_TEXT",
  VIEW_GIT_INFO: "VIEW_GIT_INFO",
};
