import adpLogo from "images/hris/adp-logo.svg"
import bambooHRLogo from "images/hris/bamboohr-logo.svg"
import gustoLogo from "images/hris/gusto-logo.svg"
import namelyLogo from "images/hris/namely-logo.svg"
import ripplingLogo from "images/hris/rippling-logo.png"
import trinetLogo from "images/hris/trinet-logo.svg"
import zenefitsLogo from "images/hris/zenefits-logo.svg"
import hibobLogo from "images/hris/hibob-logo.png"
export const hriJson = [
  {
    hris_provider_id: "adp",
    hris_provider_name: "ADP",
    hris_provider_logo_url: adpLogo,
    is_active: true,
    integrationLink: "adp-integration",
    form_data: [
      { name: "clientId", label: "Client ID", placeholder: "", required: true, type: "text" },
      {
        name: "clientSecret",
        label: "Client Secret",

        placeholder: "",
        required: true,
        type: "text",
      },
      { name: "keyFile", label: "Key Store", placeholder: "", required: true, type: "file" },
      {
        name: "keystorePassword",
        label: "Key Store Password",
        placeholder: "",
        required: true,
        type: "text",
      },
    ],
  },
  {
    hris_provider_id: "bamboohr",
    hris_provider_name: "Bamboo HR",
    hris_provider_logo_url: bambooHRLogo,
    is_active: true,
    webHookAPI: true,
    integrationLink: "bamboohr-integration",
    form_data: [
      { name: "apiKey", label: "API Key", placeholder: "", required: true, type: "text" },
      {
        name: "webHookAPIKey",
        label: "WebHook API Key",
        placeholder: "",
        required: false,
        type: "text",
      },
      {
        name: "tenantHRISCompanyUrl",
        label: "Company HR Url",
        placeholder: "https://mycompanyDomain.bamboohr.com",
        required: true,
        type: "text",
      },
    ],
  },
  {
    hris_provider_id: "gusto",
    hris_provider_name: "Gusto",
    hris_provider_logo_url: gustoLogo,
    is_active: true,
    form_data: [
      { name: "apiKey", label: "API Key", placeholder: "", required: true, type: "text" },
      {
        name: "webHookAPIKey",
        label: "WebHook API Key",
        placeholder: "",
        required: false,
        type: "text",
      },
    ],
  },
  {
    hris_provider_id: "namely",
    hris_provider_name: "Namely",
    hris_provider_logo_url: namelyLogo,
    is_active: true,
    integrationLink: "namely-integration",
    form_data: [
      { name: "apiKey", label: "API Key", placeholder: "", required: true, type: "text" },
      {
        name: "tenantHRISCompanyUrl",
        label: "Company HR Url",
        placeholder: "https://mycompanyDomain.namely.com",
        required: true,
        type: "text",
      },
    ],
  },
  {
    hris_provider_id: "rippling",
    hris_provider_name: "Rippling",
    hris_provider_logo_url: ripplingLogo,
    is_active: true,
    webHookAPI: true,
    integrationLink: "rippling-integration",
    form_data: [
      { name: "apiKey", label: "API Key", placeholder: "", required: true, type: "text" },
      {
        name: "webHookAPIKey",
        label: "WebHook API Key",
        placeholder: "",
        required: false,
        type: "text",
      },
    ],
  },
  {
    hris_provider_id: "trinet",
    hris_provider_name: "Trinet",
    hris_provider_logo_url: trinetLogo,
    is_active: true,
    webHookAPI: true,
    integrationLink: "trinet-integration",
    form_data: [
      { name: "apiKey", label: "API Key", placeholder: "", required: true, type: "text" },
      {
        name: "webHookAPIKey",
        label: "WebHook API Key",
        placeholder: "",
        required: false,
        type: "text",
      },
    ],
  },
  {
    hris_provider_id: "zenefits",
    hris_provider_name: "Zenefits",
    hris_provider_logo_url: zenefitsLogo,
    is_active: true,
    webHookAPI: true,
    integrationLink: "zenefits-integration",
    form_data: [
      { name: "apiKey", label: "API Key", placeholder: "", required: true, type: "text" },
      {
        name: "webHookAPIKey",
        label: "WebHook API Key",
        placeholder: "",
        required: false,
        type: "text",
      },
    ],
  },
  {
    hris_provider_id: "hibob",
    hris_provider_name: "Hibob",
    hris_provider_logo_url: hibobLogo,
    is_active: true,
    webHookAPI: true,
    integrationLink: "hibob-integration",
    form_data: [
      { name: "apiKey", label: "API Token", placeholder: "", required: true, type: "text" },
      {
        name: "webHookAPIKey",
        label: "WebHook API Key",
        placeholder: "",
        required: false,
        type: "text",
      },
    ],
  },
];
