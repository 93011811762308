import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import { Image } from "react-bootstrap";
import { CheckIcon, CrossIcon } from "../../assets";
import styles from "./styles/singleAppCardDetailsModal.module.css";
import { AppCardModalDetailsInfo } from "./types";

interface SingleAppCardDetailsModalProps extends AppCardModalDetailsInfo {}

export class SingleAppCardDetailsModal extends AyraaBaseComponent<
  SingleAppCardDetailsModalProps,
  {}
> {
  public renderCore(): ReactNode {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <Image src={this.props.icon} className={styles.icon} />
          </div>
          <div className={styles.label}>{this.props.label}</div>
        </div>
        <div className={styles.description}>{this.props.description}</div>
        {this.props.features && (
          <div className={styles.featuresContainer}>
            <div className={styles.featuresContainerHeader}>Features</div>
            <div className={styles.featuresList}>
              {this.props.features.map((feature) => {
                return (
                  <div className={styles.feature} key={feature.text}>
                    {feature.isMissing ? (
                      <CrossIcon className={styles.featureCrossIcon} />
                    ) : (
                      <CheckIcon className={styles.featureCheckIcon} />
                    )}
                    <div className={styles.featureText}>{feature.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {this.props.scope && (
          <div className={styles.scopeContainer}>
            <div className={styles.scopeContainerHeader}>Scope</div>
            <div className={styles.scopeText}>
              {this.props.scope.map((scope) => (
                <p>{scope}</p>
              ))}
            </div>
          </div>
        )}
        {this.props.benefitParagraphs && (
          <div className={styles.benefitsContainer}>
            <div className={styles.benefitsContainerHeader}>Benefits</div>
            <div className={styles.benefitsText}>
              {this.props.benefitParagraphs.map((benefitText) => {
                return <p>{benefitText}</p>;
              })}
            </div>
          </div>
        )}
        {this.props.designedFor && (
          <div className={styles.designedForContainer}>
            <div className={styles.designedForContainerHeader}>Designed for</div>
            <div className="shade-two b3 regular">{this.props.designedFor.join(", ")}</div>
          </div>
        )}
      </>
    );
  }
}
