import { Tenant, User } from "types/userTypes";
import { AppCardModalDetailsInfo } from "./components/AppCardDetailsModal/types";

export enum AppTag {
  Recommended = "Recommended",
  Default = "Default",
}

export enum IntegratedAppNamesEnum {
  AYRAA_X_FOR_SLACK = "AYRAA_X_FOR_SLACK",
  AYRAA_FOR_SLACK = "AYRAA_FOR_SLACK",
  SLACK = "SLACK",
  JIRA = "JIRA",
  TRELLO = "TRELLO",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  GMAIL = "GMAIL",
  NOTION = "NOTION",
  CONFLUENCE = "CONFLUENCE",
  HUBSPOT = "HUBSPOT",
  SALESFORCE = "SALESFORCE",
  INTERCOM = "INTERCOM",
  GOOGLE_DOCS = "GOOGLE_DOCS",
  BROWSER_EXTENSION = "BROWSER_EXTENSION",
  ZENDESK = "ZENDESK",
}

export enum ConnectionType {
  Choice = "Choice",
  Single = "Single",
}

export interface AppCardInfo {
  logo: any;
  name: IntegratedAppNamesEnum;
  label: string;
  description: string;
  warningText?: string;
  tag?: AppTag;
  isAvailable: boolean;
}

export interface DynamicAppInfo extends AppCardInfo {
  getConnectionStatus?: () => boolean;
  getConnectionStatusAsync?: () => Promise<boolean>;
  getIsAppEnabledStatusAsync?: () => Promise<boolean>;
  getIsAppEnabledStatus?: () => { isEnabled: boolean };
  appCardModalDetailsInfo?: AppCardModalDetailsInfo;
  connect?: ({
    employeeId,
  }: {
    employeeId: number;
    tenantId: number;
  }) => Promise<{ isConnected: boolean }>;
  disconnect?: ({ employeeId }: { employeeId: number }) => Promise<{ isDisconnected: boolean }>;
}
