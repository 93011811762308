import styles from "./sidebar_v2.module.css";
import Logo from "../Logo/Logo";
import React from "react";
import {Container} from "react-bootstrap";

export default function Sidebar({children}: { children: React.ReactNode }) {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Logo/>
      </div>
      <Container fluid>{children}</Container>
    </div>
  )
}
