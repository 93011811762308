import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllSubscriptionPackages = createAsyncThunk(
  "subscription/getAllSubscriptionPackages",
  async () => {
    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL + "/getAllSubscriptionPackages",
      {
        method: "GET",
      }
    );
    return response.data;
  }
);

export const getSubscribedPackage = createAsyncThunk(
  "subscription/getSubscribedPackage",
  async (tenantID: number) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/getCurrentSubscriptionForTenant/${tenantID}`
    );
    return response.data;
  }
);

export const updateTenantSubscription = createAsyncThunk(
  "subscription/updateTenantSubscription",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'selected_subscription' does not exist on... Remove this comment to see the full error message
  async ({ selected_subscription, tenant, old_subscription }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/updateTenantSubscription`,
      {
        packageType: selected_subscription.subscriptionPackageID,
        tenantID: tenant.tenantID,
      }
    );
    return {
      data: response.data,
      tenant: tenant,
      selected_subscription: selected_subscription,
      old_subscription: old_subscription,
    };
  }
);

export const saveDetailsToPaymentGateway = createAsyncThunk(
  "subscription/saveDetailsToPaymentGateway",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenantID' does not exist on type 'void'.
  async ({ tenantID, packageType }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/saveDetailsToPaymentGateway`,
      {
        tenantID,
        packageType,
      }
    );
    return response.data;
  }
);

export const saveSubscriptionDetails = createAsyncThunk(
  "subscription/saveSubscriptionDetails",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenant' does not exist on type 'void'.
  async ({ tenant, selected_subscription }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/saveSubscriptionDetails`,
      {
        tenantID: tenant.tenantID,
        packageType: selected_subscription.subscriptionPackageID,
      }
    );
    return {
      data: response.data,
      tenant: tenant,
      selected_subscription: selected_subscription,
    };
  }
);

export const subscriptionSlice = createSlice({
  name: "subscriptionSlice",
  initialState: {
    subscriptionPackagesState: {
      subscriptionPackages: [],
      status: "idle",
      error: null,
    },
    subscribedPackageState: {
      subscribedPackage: null,
      upcomingSubscription: null,
      status: "idle",
      error: null,
    },
  },
  reducers: {
    setSubscribedPackageState: (state, action) => {
      const { subscribedPackage = null, status = null } = action.payload;
      state.subscribedPackageState.subscribedPackage = subscribedPackage;
      if (status) {
        state.subscribedPackageState.status = status;
      }
    },
    /**
     * Resetting the status for subscribedPackageState which was set as
     * 'fulfilled' for previous session to 'idle' so that we can fetch
     * package for next login.
     */
    resetSubscribedPackageStateStatus: (state) => {
      if (state.subscribedPackageState.status === "fulfilled") {
        state.subscribedPackageState.status = "idle";
        state.subscribedPackageState.subscribedPackage = null;
      }
    },
    setUpcomingSubscriptionState: (state, action) => {
      state.subscribedPackageState.upcomingSubscription = action.payload;
    },
  },
  extraReducers: {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [saveSubscriptionDetails.fulfilled]: (state, action) => {
      const { tenant, selected_subscription } = action.payload;

    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [updateTenantSubscription.fulfilled]: (state, action) => {
      const { tenant, selected_subscription, old_subscription } = action.payload;

    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllSubscriptionPackages.pending]: (state) => {
      state.subscriptionPackagesState.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllSubscriptionPackages.fulfilled]: (state, action) => {
      state.subscriptionPackagesState.status = "fulfilled";
      state.subscriptionPackagesState.subscriptionPackages = action.payload;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllSubscriptionPackages.rejected]: (state) => {
      state.subscriptionPackagesState.status = "rejected";
      state.subscriptionPackagesState.subscriptionPackages = [];
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getSubscribedPackage.pending]: (state) => {
      state.subscribedPackageState.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getSubscribedPackage.fulfilled]: (state, action) => {
      state.subscribedPackageState.status = "fulfilled";
      const { ongoing_subscription = null, upcoming_subscription = null } = action.payload;
      if (ongoing_subscription) {
        state.subscribedPackageState.subscribedPackage = ongoing_subscription;
      }
      if (upcoming_subscription) {
        state.subscribedPackageState.upcomingSubscription = upcoming_subscription;
      }
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getSubscribedPackage.rejected]: (state) => {
      state.subscribedPackageState.status = "rejected";
      state.subscriptionPackagesState.subscribedPackage = null;
    },
  },
});

export const {
  setSubscribedPackageState,
  setUpcomingSubscriptionState,
  resetSubscribedPackageStateStatus,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
