import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/accountType.module.css";
import { Button } from "react-bootstrap";
import { accountTypes, subscriptionTypes } from "utils/constants";
import {
    getAllSubscriptionPackages,
    getSubscribedPackage,
    saveSubscriptionDetails,
  } from "dataFetcher/Subscription/subscriptionDataFetcher";
import { setUser } from "redux/userSlice";
import { resetSubscribedPackageStateStatus } from "redux/subscriptionSlice";
import { setUserAccountStatus } from "redux/userSlice";
import { connect } from "react-redux";
import classNames from "classnames";
import { Label } from "components/Label";
import ToastComponent from "../Toast/ToastComponent";
import { User  } from "types/userTypes";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { updateEmployeeWorkspaceStatus } from "dataFetcher/Workspace/workspaceDataFetcher";
interface  AccountTypeSelectionStateProps {
    accountType : string, 
    error : {
        show : boolean,
        message : string
    }
}
interface AccountTypeSelectionProps {
    fetchedUser: any,
    resetSubscribedPackageStateStatus :() => void,
    setUserAccountStatus:(status : boolean) => void,
}

class AccountTypeSelection extends AyraaBaseComponent<AccountTypeSelectionProps, AccountTypeSelectionStateProps> {

    constructor(props : AccountTypeSelectionProps) {
        super(props);
        this.state = {
            accountType : "",
            error : {
                show : false,
                message :""
            }
        }
    }

    private handleAccountTypeSelection(type : string) {
        this.setState({accountType : type});
    }
    private async triggerSubscriptionRequirements() {

        const fetchedUser =  this.props.fetchedUser;
        const tenantID: number = fetchedUser.tenant.tenantID;
        const fetchedTenant = fetchedUser.tenant;

        const subscriptionPackages = await getAllSubscriptionPackages();
        const {ongoing_subscription, upcoming_subscription} = await getSubscribedPackage(tenantID);
    
        //flow when the user doesnot have any workspace details.
        //re-direct him to the createWorkspace Flow.
        const currentSubscription = ongoing_subscription || upcoming_subscription;
        if (!currentSubscription) {
            const freeSubscription = subscriptionPackages.find(
                (item: $TSFixMe) => item.subscriptionType === subscriptionTypes.FREE
                );
                try {
                    await saveSubscriptionDetails({
                        tenant: fetchedTenant,
                        selected_subscription: freeSubscription,
                    })
                }
                catch(e) {
                    this.setState({error : {show :true ,message : "Something went wrong!"}})
                }
        }
    
    }
    private async handleAccountTypeSubmission() {
        //trigger all the subscription related requirements.
        await this.triggerSubscriptionRequirements();

        
            //1.trigger an updateEmployeeEndpoint with personal account flag set to true.
            const fetchedUser = this.props.fetchedUser;
            try {
                await updateEmployeeWorkspaceStatus({
                    userData : {personalAccount : true ,
                                tenantID : fetchedUser.tenant?.tenantID , 
                                employeeID : fetchedUser.employeeID },
                    updatedBy : fetchedUser.employeeID
                })
                this.props.setUserAccountStatus(true);
                //2.redirect user to the extension page.
                this.history.push("/install-extension")
            }
            catch(e) {
                this.setState({error : {show :true ,message : "Something went wrong!"}})
            }
        
    }
    private onToastMessageToggle() {
        this.setState({error : {show : false, message : ""}})
    }


    public renderCore(): ReactNode {
        return (
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <div className={styles.accountSelectionLabel}>
                         How do you plan to use Ayraa?
                    </div>
                    <div className={styles.selectionContainer}>
                        {Object.keys(accountTypes).map((type,index) => 
                        {
                            
                            const selectionOptionClasses = classNames(styles.selectionOption,{
                                [styles.selected] : type === this.state.accountType
                            })
                            const iconContainerClasses = classNames(styles.iconContainer ,  {
                                [styles.singleUser] : type === "personal",
                                [styles.multiUser] : type === "company"
                            })

                            const Icon = accountTypes[type].icon;


                            return (
                                <div key={accountTypes[type].title} className={selectionOptionClasses} onClick={() => this.handleAccountTypeSelection(type)}>
                                    <div className={iconContainerClasses}>
                                        <Icon />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <span className={styles.selectionTitle}>
                                            {accountTypes[type].title}
                                        </span>
                                        <span className={styles.selectionDescription}>
                                            {accountTypes[type].description}
                                        </span>
                                    </div>
                                    
                                     <ToastComponent
                                        showCloseIcon={false}
                                        type={"warning"}
                                        text={<div className="b2 medium shade-two"><Label name={this.state.error.message}/></div>}
                                        show={this.state.error.show}
                                        onClose={this.onToastMessageToggle}
                                        />
                                    
                             </div>
                            )
                        })}
                    </div>
                   <Button className={styles.continueButton} variant="primary" onClick={this.handleAccountTypeSubmission} disabled={this.state.accountType.length === 0}>
                        Continue
                   </Button>
                    
                    
                </div>
                <FadedLogoWatermark className={styles.rightContainer} />
                </div>
        )
    }

}
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
      resetSubscribedPackageStateStatus :() => dispatch(resetSubscribedPackageStateStatus()),
      setUserAccountStatus:(status : boolean ) => dispatch(setUserAccountStatus(status)),
    };
  };

export default connect(null, mapDispatchToProps)(AccountTypeSelection);



