import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { importEmployeeData } from "redux/employeeSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { checkFileExtension } from "utils/constants";
import styles from "./manageEmployees.module.css";

const validExtensions = [".xlsx"];

export default function ImportEmployeeExcel({ onImportComplete }: $TSFixMe) {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(false);

  /*const { isAdmin = false } = useAppSelector(
    (state) => state.userManager.userRole || { isAdmin: false }
  );*/

  const { employeeID } = useAppSelector((state) => state.userManager.user);
  const { tenantID } = useAppSelector((state) => state.tenantManager.tenant);

  const dispatch = useAppDispatch();

  /*if (!isAdmin) {
    return null;
  }*/

  const closeModal = () => {
    setShow(false);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    setFormError(false);
    setSelectedFile(null);
  };

  const onImportClick = async () => {
    if (!formError) {
      if (selectedFile) {
        try {
          const formData = new FormData();
          formData.append("dataFile", selectedFile);
          const data = { employeeId: employeeID, tenantId: tenantID };
          const blobData = new Blob([JSON.stringify(data)], {
            type: "application/json",
          });
          formData.append("data", blobData);
          setLoading(true);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          await dispatch(importEmployeeData({ formData })).unwrap();
          setLoading(false);
          setShow(false);
          onImportComplete();
        } catch (error: $TSFixMe) {
          setLoading(false);
          setFormError(error);
        }
      } else {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Please select a file"' is not a... Remove this comment to see the full error message
        setFormError("Please select a file");
      }
    }
  };

  const onFileSelect = (e: $TSFixMe) => {
    const { files = [] } = e.target;
    setFormError(null);
    if (files.length > 0) {
      const file = files[0];
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      if (checkFileExtension(file.name, validExtensions)) {
        setSelectedFile(file);
      } else {
        setFormError(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          `Invalid file. Please select file with ${validExtensions.join(", ")} extension(s)`
        );
      }
    }
  };

  return (
    <>
      <Button onClick={() => setShow(true)}>Import excel</Button>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Import file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Label className={styles.importExcelLabel}>
              <span>Select file</span>
              <a
                href={`${process.env.REACT_APP_SERVER_URL}/employees/exportEmployeeData/${tenantID}/${employeeID}`}
                download
                className={styles.downloadSample}
              >
                (download sample)
              </a>
            </Form.Label>
            <Form.Control
              type="file"
              size="sm"
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean | u... Remove this comment to see the full error message
              isInvalid={formError}
              accept={validExtensions.join(", ")}
              onChange={onFileSelect}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">{formError}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal} disabled={loading}>
            Close
          </Button>
          <Button variant="primary" onClick={onImportClick} disabled={loading}>
            Import
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
