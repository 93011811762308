import {useEffect, useRef, useState} from "react";
import {Alert} from "react-bootstrap";
import {generateSSOToken} from "redux/authSlice";
import {useAppDispatch} from "redux/hooks";
import {useQueryParams} from "../../hooks/useQueryParams";
import {getDesktopAppLoginURL} from "../../utils/desktopUtils";
import {DesktopAppLoginMessage} from "../v2/Auth/DesktopAppLoginMessage";
import {saveDetailsForAppLogin} from "../../dataSender/auth/AuthDataSender";

export default function TenantDomainRedirect({ redirectUrl }: { redirectUrl: string; }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [SSOToken, setSSOToken] = useState(null);
  const dispatch = useAppDispatch();

  const verifySSOTokenFormRef = useRef<HTMLFormElement>(null);

  const channel = useQueryParams("channel");
  const verificationToken = useQueryParams("vt");

  useEffect(() => {
    const generateSSOTokenFunc = async () => {
      const generateSSOTokenResponse = await dispatch(
        generateSSOToken({ target_app_url: redirectUrl, target_app_code: "ui-frontend" })
      ).unwrap();
      const { sso_token, status } = generateSSOTokenResponse;
      if (!sso_token && status === "FAILED") {
        setError("Something went wrong! Please try again later.");
        setLoading(false);
      } else {
        setSSOToken(sso_token);
      }
    };
    generateSSOTokenFunc();
  }, [dispatch, redirectUrl]);

  const handleDesktopAppLogin = async () => {
    if (verificationToken) {
      await saveDetailsForAppLogin(verificationToken);
      window.location.href = getDesktopAppLoginURL(verificationToken);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (SSOToken) {
      if (channel === 'desktop' && verificationToken) {
        handleDesktopAppLogin();
      } else {
        verifySSOTokenFormRef.current?.submit();
      }
    }
  }, [SSOToken, verifySSOTokenFormRef]);

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {channel === 'desktop' && <DesktopAppLoginMessage />}
      {channel !== 'desktop' && <form
        action={`${process.env.REACT_APP_PULSE_BACKEND_URL}/auth/verifySSOToken`}
        method="post"
        id="theForm"
        ref={verifySSOTokenFormRef}
      >
        <input type="hidden" name="sso_token" value={SSOToken || ""} />
      </form>}
    </>
  );
}
