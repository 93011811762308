import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import {
  customerConfig,
  getCustomerImportStatus,
  getToken,
  importEmployeeData,
} from "redux/importEmployeesSlice";
import styles from "./workatoimport.module.css";
import PulseAppButton from "../Redirect/PulseAppButton";
import { updateTenantisEmployeeDataImportedFlag } from "redux/tenantSlice";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {Tenant} from "../../types/userTypes";
import {CONSTANTS} from "../../utils/constants";

export default function WorkatoImport() {
  // @ts-ignore
  const {
    user: { employeeFirstName: displayName, workEmailID: mail },
  } = useAppSelector((state) => state.userManager);
  const { importEmployeeDataStatus } = useAppSelector((state) => state.importEmployeesManager);
  const tenant: Tenant = useAppSelector((state) => state.tenantManager.tenant);

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("Import employee data from Zenefits");
  const [iframeUrl, setIFrameUrl] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pageError, setPageError] = useState<$TSFixMe>(null);

  const [customerAccountId, setCustomerAccountId] = useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function receiveMessage(event: $TSFixMe) {
      if (event.origin === "https://app.workato.com" && typeof event.data === "string") {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case "connectionStatusChange":
            if (
              customerAccountId &&
              data.payload.connected &&
              importEmployeeDataStatus === "idle"
            ) {
              setLoading(true);
              setIFrameUrl(null);
              setText("Importing employee data...");
              await dispatch(
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
                importEmployeeData({ tenantId: tenantID, adminUserId: mail, customerAccountId })
              );
              dispatch(updateTenantisEmployeeDataImportedFlag());
              setLoading(false);
              setShowSuccess(true);
            }
            break;
          case "error":
            break;
          default:
            break;
        }
      }
    }
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [customerAccountId, importEmployeeDataStatus, dispatch, mail, tenant.tenantID]);

  const poll = async ({ params, interval, maxAttempts }: $TSFixMe) => {
    let attempts = 0;

    const executePoll = async (resolve: $TSFixMe, reject: $TSFixMe) => {
      const { custAccId, packageId } = params;
      const result = await dispatch(
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        getCustomerImportStatus({ customerAccountId: custAccId, packageId })
      ).unwrap();
      attempts++;
      if (result.importStatus === "completed") {
        return resolve(true);
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error("Exceeded max attempts"));
      } else {
        setTimeout(executePoll, interval, resolve, reject);
      }
    };

    return new Promise(executePoll);
  };

  const executeCustomerConfig = () => {
    setText("Configuring customer...");
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    return dispatch(customerConfig({ customerName: displayName, customerEmail: mail })).unwrap();
  };

  const executeCustomerImportStatus = ({ custAccId, packageId }: $TSFixMe) => {
    setText("Getting import status...");
    return poll({
      params: { custAccId, packageId },
      interval: 500,
      maxAttempts: 50,
    });
  };

  const executeGetToken = ({ custAccId }: $TSFixMe) => {
    setText("Getting token...");
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    return dispatch(getToken({ customerAccountId: custAccId })).unwrap();
  };

  const onImportClick = async () => {
    try {
      setLoading(true);
      setPageError(null);
      const customerConfigResponse = await executeCustomerConfig();
      const { customerAccountId: custAccId, packageId } = customerConfigResponse;
      setCustomerAccountId(custAccId);
      const success = await executeCustomerImportStatus({ custAccId, packageId });
      if (success) {
        const { jwt, connectionId } = await executeGetToken({ custAccId });
        setIFrameUrl(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          `https://www.workato.com/direct_link/embedded/connections/${connectionId}?workato_dl_token=${jwt}`
        );
        setText("Waiting for iframe event");
        setLoading(false);
      }
    } catch (error: $TSFixMe) {
      setLoading(false);
      setPageError(error.message);
      setText("Import employee data from Zenefits");
    }
  };

  return (
    <div className={styles.container}>
      {pageError && <Alert variant="danger">{pageError}</Alert>}
      {loading && (
        <div className={styles.loadingContainer}>
          <Spinner animation="border" />
          <div>{text}</div>
        </div>
      )}
      {!loading &&
        (!showSuccess ? (
          <>
            {!iframeUrl && (
              <div className={styles.pageContainer}>
                <Button disabled={loading} onClick={onImportClick}>
                  {text}
                </Button>
              </div>
            )}
            {iframeUrl && (
              <div className={styles.iframeContainer}>
                <h5>
                  Connect to your Zenefits Account and allow Ayraa(Workato) access to your employee
                  data
                </h5>
                <iframe title="Workato" className={styles.workatoIframe} src={iframeUrl} />
              </div>
            )}
          </>
        ) : (
          <div className={styles.pageContainer}>
            <h5>Employee data imported successfully</h5>
            <PulseAppButton redirectUrl={tenant.tenantUrl + CONSTANTS.REACT_PULSE_APP_CONTEXT}/>
          </div>
        ))}
    </div>
  );
}
