import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "images/logo/logo-v2.svg";
import styles from "./logo.module.css"

export default function Logo() {
  const history = useHistory();

  const onClick = () => {
    history.push("/");
  };

  return <Image className={styles.logo} src={logo} onClick={onClick} />;
}
