import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateEmployee } from "redux/tenantSlice";
import { validateSpace } from "utils/constants";
import styles from "../manageEmployeesComponent.module.css";

const getUsersAddressData = (rowData: $TSFixMe) => {
  const {
    addressLine1 = null,
    addressLine2 = null,
    city = null,
    state = null,
    country = null,
    zipCode = null,
  } = rowData;
  return { addressLine1, addressLine2, state, city, country, zipCode };
};

export default forwardRef((props, ref) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
  const defaultValues = getUsersAddressData(props.data);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
  const fieldName = props.colDef.field;
  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors: formErrors },
  } = useForm({ defaultValues });

  const { employeeID }: $TSFixMe = useAppSelector((state) => state.userManager.user);

  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const formData = getValues();
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return formData[fieldName];
      },
      isCancelAfterEnd: () => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const hasErrors = Boolean(formErrors[fieldName]);
        return hasErrors || !submitClicked;
      },
      isPopup() {
        return true;
      },
    };
  });

  const onCloseClick = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
    props.api.stopEditing();
  };

  const onSubmitClick = async (addressData: $TSFixMe) => {
    try {
      setLoading(true);
      const payload = {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
        userData: { ...props.data, ...addressData },
        updatedBy: employeeID,
      };
      await dispatch(updateEmployee(payload)).unwrap();
      setSubmitClicked(true);
      setLoading(false);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type '{ children... Remove this comment to see the full error message
      props.node.setData({ ...props.data, ...addressData });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
      props.api.stopEditing();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal.Header>
        <Modal.Title>Update address</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmitClick)}>
        <Modal.Body>
          <Form.Group className={styles.formGroup}>
            <Form.Label>Address line 1</Form.Label>
            <Form.Control
              type="text"
              {...register("addressLine1", {
                required: "Address line 1 is required",
                validate: validateSpace,
              })}
              disabled={loading}
              isInvalid={formErrors?.addressLine1}
              autoFocus
            />
            <Form.Control.Feedback type="invalid" tooltip className={styles.invalidTooltip}>
              {formErrors?.addressLine1?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className={styles.formGroup}>
            <Form.Label>Address line 2</Form.Label>
            <Form.Control
              type="text"
              {...register("addressLine2", {
                required: "Address line 2 is required",
                validate: validateSpace,
              })}
              disabled={loading}
              isInvalid={formErrors?.addressLine2}
            />
            <Form.Control.Feedback type="invalid" tooltip className={styles.invalidTooltip}>
              {formErrors?.addressLine2?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <div className={styles.formGroupContainer}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                {...register("city", {
                  required: "City is required",
                  pattern: {
                    value: /^[A-Za-z ]+$/,
                    message: "Invalid city",
                  },
                  validate: validateSpace,
                })}
                disabled={loading}
                isInvalid={formErrors?.city}
              />
              <Form.Control.Feedback type="invalid" tooltip className={styles.invalidTooltip}>
                {formErrors?.city?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label>State</Form.Label>
              <Form.Control
                type="state"
                {...register("state", {
                  required: "State is required",
                  pattern: {
                    value: /^[A-Za-z ]+$/,
                    message: "Invalid state",
                  },
                  validate: validateSpace,
                })}
                disabled={loading}
                isInvalid={formErrors?.state}
              />
              <Form.Control.Feedback type="invalid" tooltip className={styles.invalidTooltip}>
                {formErrors?.state?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className={styles.formGroupContainer}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Zip code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Format: 123456"
                {...register("zipCode", {
                  required: "Zip code is required",
                  maxLength: {
                    value: 6,
                    message: "Invalid zip code",
                  },
                  minLength: {
                    value: 6,
                    message: "Invalid zip code",
                  },
                  validate: validateSpace,
                })}
                disabled={loading}
                isInvalid={formErrors?.zipCode}
              />
              <Form.Control.Feedback type="invalid" tooltip className={styles.invalidTooltip}>
                {formErrors?.zipCode?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="country"
                {...register("country", {
                  required: "Country is required",
                  pattern: {
                    value: /^[A-Za-z ]+$/,
                    message: "Invalid country",
                  },
                  validate: validateSpace,
                })}
                disabled={loading}
                isInvalid={formErrors?.country}
              />
              <Form.Control.Feedback type="invalid" tooltip className={styles.invalidTooltip}>
                {formErrors?.country?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={onCloseClick} disabled={loading}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
});
