import React, { useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { generateSSOToken } from "redux/authSlice";
import { useAppDispatch } from "redux/hooks";

export default function PulseAppButton({ redirectUrl }: $TSFixMe) {
  const [loading, setLoading] = useState(false);
  const [SSOToken, setSSOToken] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useAppDispatch();

  const verifySSOTokenFormRef = useRef();

  const onClick = async () => {
    setLoading(true);
    try {
      const generateSSOTokenResponse = await dispatch(
        generateSSOToken({ target_app_url: redirectUrl, target_app_code: "pulse-frontend" })
      ).unwrap();
      const { sso_token, status } = generateSSOTokenResponse;
      if (!sso_token && status === "FAILED") {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Failed"' is not assignable to p... Remove this comment to see the full error message
        setError("Failed");
        setLoading(false);
      } else {
        setSSOToken(sso_token);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (SSOToken && verifySSOTokenFormRef?.current) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      verifySSOTokenFormRef.current.submit();
    }
  }, [SSOToken, verifySSOTokenFormRef]);

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Button onClick={onClick} disabled={loading}>
        {loading ? "Getting token and redirecting..." : "Go to Pulse"}
      </Button>
      <form
        action={`${process.env.REACT_APP_PULSE_BACKEND_URL}/auth/verifySSOToken`}
        method="post"
        id="theForm"
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={verifySSOTokenFormRef}
      >
        <input type="hidden" name="sso_token" value={SSOToken || ""} />
      </form>
    </>
  );
}
