import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/progress.module.css";

interface ProgressProps {
  className?: string;
  noOfSteps: number;
  activeStep: number;
  completed?: boolean;
}

export class Progress extends AyraaBaseComponent<ProgressProps, {}> {
  constructor(props: ProgressProps) {
    super(props);
  }

  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    return (
      <div className={containerClassNames}>
        {new Array(this.props.noOfSteps).fill(1).map((_e, index) => {
          const activeStepClassName = classNames(styles.dotContainer, {
            [styles.highlight]: index + 1 < this.props.activeStep,
            [styles.completed]: this.props.completed,
          });

          const dotClassNames = classNames(styles.dot, {
            [styles.firstStep]: index === 0,
          });

          return (
            <div className={activeStepClassName}>
              <div className={dotClassNames} />
              <div className={styles.line} />
            </div>
          );
        })}
      </div>
    );
  }
}
