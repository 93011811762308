import {AyraaBaseComponent} from "../ayraaFramework/AyraaBaseComponent";
import { } from 'react-router-dom';
import axios from "axios";

export class GMComponent extends AyraaBaseComponent<any, any> {

  //Constructor
  constructor(props: any) {
    super(props);

    this.state = {
      switchSuccessful: false
    };
  }

  renderCore(): React.ReactNode {
    if (this.state.switchSuccessful) {
      return <>
        User switch successful. Please refresh the pulse application browser tab to see the changes.
      </>
    }
    return <>
        Switching in progress...
      </>;
  }

  private async doSideEffectForGMModeSwitching(): Promise<void> {
    if (this.shouldExecuteSideEffect(this.doSideEffectForGMModeSwitching, [])) {
      //Get the emailId
      let emailId = new URLSearchParams(window.location.search).get("emailId");

      //Invoke UI-Backend
      let response = await axios.get(process.env.REACT_APP_SERVER_URL  + `/gm?emailId=`+emailId);

      //Invoke Pulse-Backend
      response = await axios.get(process.env.REACT_APP_PULSE_BACKEND_URL + `/auth/gm?emailId=`+emailId);

      //Set the "switchSuccessful" state variable to true
      this.setState({switchSuccessful: true});
    }
  }
}
