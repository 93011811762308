import styles from "./styles/loginButton.module.css";
import { Label } from "components/Label";

export default function LoginButton({
  labelName,
  icon: Icon,
  onClick,
}: {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  labelName: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <div className={styles.button} onClick={onClick} tabIndex={0}>
      <div className={styles.container}>
        <Icon className={styles.icon} />
        <div className={styles.label}>
          <Label name={labelName} />
        </div>
      </div>
    </div>
  );
}
