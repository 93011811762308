import UAParser from 'ua-parser-js';
import {ArchMap, ArchSpec, OSMap, OSSpec} from "../types/downloadTypes";

const userAgent = new UAParser();

export function getUserBrowser() {
    return userAgent.getBrowser().name;
}

export function getUserDeviceType() {
    return userAgent.getDevice().type;
}

export async function getUserSystemInfo() {
    const osName = userAgent.getOS().name;
    let os: OSSpec | undefined;
    if (osName) {
        if (osName.includes('Windows')) {
            os = osMap.WINDOWS;
        } else if (osName.includes('Mac')) {
            os = osMap.MACOS;
        } else if (osName.includes('Android')) {
            os = osMap.ANDROID;
        } else if (osName.includes('iOS')) {
            os = osMap.IOS;
        }
    }

    let cpuArch = userAgent.getCPU().architecture;
    let arch: ArchSpec | undefined;
    if (cpuArch) {
        if (cpuArch.includes('arm64')) {
            arch = archMap.arm64;
        } else if (cpuArch.includes('amd64')) { // x64 is also called as amd64
            arch = archMap.x64;
        }
    }
    if (!cpuArch && (navigator as any).userAgentData)  {
        const userAgentDataWithArchitecture = await (navigator as any).userAgentData.getHighEntropyValues(['architecture']);
        cpuArch = userAgentDataWithArchitecture?.architecture;
        if (cpuArch?.includes('arm')) {
            arch = archMap.arm64;
        } else if (cpuArch?.includes('x86') || cpuArch?.includes('x64')) {
            arch = archMap.x64;
        }
    }
    // if nothing else matched, then use x64 build
    if (!cpuArch && os === osMap.MACOS) {
        arch = archMap.x64;
    }
    return {
        os: os || {
            name: osName,
            path: undefined
        },
        arch: arch || {
            name: cpuArch,
            path: undefined
        }
    }
}

export const osMap: OSMap = {
    WINDOWS: {
        name: 'Windows',
        path: 'win'
    },
    MACOS: {
        name: 'MacOS',
        path: 'mac'
    },
    ANDROID: {
        name: 'Android',
        path: 'android'
    },
    IOS: {
        name: 'iOS',
        path: 'ios'
    }
}

export const archMap: ArchMap = {
    x64: {
        name: '64-BIT',
        path: 'x64'
    },
    arm64: {
        name: '64-BIT',
        path: 'arm64'
    }
}