import React from "react";
import bambooHR1Logo from "images/hris/integration/bamboohr-1.png"
import bambooHR2Logo from "images/hris/integration/bamboohr-2.png"
import bambooHR3Logo from "images/hris/integration/bamboohr-3.png"

function BamboohrIntegration() {
  return (
    <div className="p-4 m-4">
      <h1>Fetching API key</h1>
      <p>
        To generate an API key, users should log in and click their name in the upper right-hand
        corner of any page to get to the user context menu. If they have sufficient permissions,
        there will be an "API Keys" option in that menu to go to the page.
      </p>

      <p>
        Each user may have one or more secret API keys that identify that user to the API. The API
        secret key is a 160-bit number expressed in hexadecimal form. This is an astronomically
        large number of unique keys, which means that guessing an API key is nearly impossible.
      </p>

      <h1>Fetching Webhook API key</h1>

      <p>
        Webhooks can be configured by an admin user in the Account Settings section by clicking on
        the link "Webhooks". Webhooks are not turned on for all clients, but can be enabled at no
        charge by <a href="https://www.bamboohr.com/contact/">contacting support.</a>
      </p>

      <p>
        By default, a BambooHR user can specify which fields they want to monitor. The list of
        fields that can be monitored is limited to the following, but it may expand in the future:
      </p>

      <img className="m-4" height={400} src={bambooHR1Logo} />

      <p>
        The user can also configure which fields will be posted by the webhook. Any field in the
        database can be posted. Fields can be posted with an alternate name, but use BambooHR's
        field names (in English) by default.
      </p>

      <p>
        Users can specify a schedule of when they want webhooks to fire (for example, only at
        12:00pm or every hour at 5 after the hour). They can also limit how often a webhook will
        fire by setting a maximum number of requests per interval in seconds.
      </p>

      <h5>Security</h5>

      <p>
        BambooHR will post to an HTTP or HTTPS url, but HTTPS is recommended. We no longer recommend
        a URL to include a token (unless a third party requires it).
      </p>

      <p>
        Instead we recommend using a private secure key, this ensures that a request comes from
        BambooHR. The webhook is secured using SHA-256 HMAC. This is setup from the webhooks edit
        page under the "Private Key" section. Click Generate, to create a key.
      </p>
      <div className="text-center">
        <img className="m-4" height={100} src={bambooHR2Logo} />
        <img className="m-4" height={100} src={bambooHR3Logo} />
      </div>
      <p>
        This key can only be copied at the point of being made, once the webhook is saved it is no
        longer available to view, and must be regenerated if lost.
      </p>
    </div>
  );
}

export default BamboohrIntegration;
