import React from "react";
import namely1Logo from "images/hris/integration/namely-1.png"

function NamelyIntegration() {
  return (
    <div className="p-4 m-4">
      <h2>Create API key</h2>
      <ol>
        <li>
          Navigate to your Namely HRIS site and click on the API menu item: If you don't see the API
          menu item, contact your Namely client success manager or Namely administrator to obtain
          the necessary permissions.
        </li>
        <img className="m-4" height={200} src={namely1Logo} />
        <li>
          After clicking on API, you'll see a tab for Personal Access Tokens. Select Personal Access
          Tokens  New Access Token.{" "}
        </li>
        <li>
          Enter a name for Access token and click on Create. API key is the value displayed on popup
          message once you click on Create button. Store the API key safely as this value cannot be
          retrieved again.
        </li>
      </ol>
    </div>
  );
}

export default NamelyIntegration;
