import React, {useEffect} from "react";
import {CONSTANTS} from "../../../utils/constants";
import Spinner from "react-bootstrap/Spinner";
import styles from "./alternateLogin.module.css";
import {getDataFromAppStore} from "../../../dataStore/appDataStore";
import {appConstants} from "../../../utils/appConstants";
import {fetchAuthCodeURL} from "../../../dataSender/auth/AuthDataSender";

export default function AlternateAccountLogin() {

  let sp: string = "";
  let verificationToken: string = "";
  let channel: string = "";
  let requestedApp: string = "";
  const params = new URLSearchParams(window.location.search);
  if (params) {
    sp = params.get("sp") as string;
    requestedApp = params.get("app") as string;
    verificationToken = params.get("vt") as string;
    channel = params.get("channel") as string;
  }

  useEffect(() => {
    //Create Authflow URL for Google login from backend. For Microsoft the Popup is triggered from Pulse
    if (sp === CONSTANTS.GOOGLE) {
      (async function () {
        const tokenValidationPayload = {
          serviceProvider: getDataFromAppStore(appConstants.SERVICE_PROVIDER_PARAM),
          authCode: "",
          idToken: "",
          tenantId: getDataFromAppStore(appConstants.TENANT_ID_PARAM),
          app: requestedApp,
          verificationToken,
          channel
        };

        const response = await fetchAuthCodeURL(tokenValidationPayload);
        if(response !== undefined && response!== "") {
          window.location.href = response + "&prompt=consent";
        }
      })();
    }
  })

  return (<>
    <Spinner className={styles.spinner} animation="border"/>
  </>)
}