import axios from "axios";

export const getAllSubscriptionPackages = async () => {
   try {
    const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/getAllSubscriptionPackages",
        {
          method: "GET",
        }
      );
      return response.data;
   }
   catch(e) {
    return {
        error : true,
        message : "something went wrong"
    }
   }
  }


export const getSubscribedPackage = 
  async (tenantID: number) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/getCurrentSubscriptionForTenant/${tenantID}`
          );
          return response.data;
    }
    catch(e) {
        return {
            error : true,
            message : "something went wrong"
        }
    }
  }

export const updateTenantSubscription = //@ts-ignore
  async ({ selected_subscription, tenant, old_subscription }) => {
   try {
    const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/updateTenantSubscription`,
        {
          packageType: selected_subscription.subscriptionPackageID,
          tenantID: tenant.tenantID,
        }
      );
      return {
        data: response.data,
        tenant: tenant,
        selected_subscription: selected_subscription,
        old_subscription: old_subscription,
      };
   }
   catch(e) {
    return {
        error : true,
        message : "Something went wrong"
    }
   }
  }

export const saveDetailsToPaymentGateway =
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenantID' does not exist on type 'void'.
  async ({ tenantID, packageType }) => {
   try {
    const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/saveDetailsToPaymentGateway`,
        {
          tenantID,
          packageType,
        }
      );
      return response.data;
   }
  
  catch(e) {
    return {
        error : true,
        message : "Something went wrong"
    }
  }
}

export const saveSubscriptionDetails =
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenant' does not exist on type 'void'.
  async ({ tenant, selected_subscription }) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/saveSubscriptionDetails`,
            {
              tenantID: tenant.tenantID,
              packageType: selected_subscription.subscriptionPackageID,
            }
          );
          return {
            data: response.data,
            tenant: tenant,
            selected_subscription: selected_subscription,
          };
    }
    catch(e) {
        return {
            error : true,
            message : "Something went wrong"
        }
    }
  }



  export const getCheckoutURL = async (packageId: number, tenantId: number, employeeId: number, companyUpgrade:boolean) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/getCheckoutURL",
        {
          packageType:packageId ,
          tenantID: tenantId,
          employeeId,
          companyUpgrade,
        },
        {
          method: "POST"
        }
      );
      return response.data;
    } catch (e) {
      return {
        error: true,
        message: "something went wrong"
      };
    }
  }