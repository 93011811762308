import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/rightSection.module.css";
import RightSectionContainer from "./assests/rightSectionContainer.svg";
import { Image } from "react-bootstrap";
import AppSnapshot from "./assests/app-snapshot.png";

interface RightSectionProps {
  className?: string;
}

export class RightSection extends AyraaBaseComponent<RightSectionProps, {}> {
  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    return (
      <div className={containerClassNames}>
        {/* <Image src={RightSectionContainer} className={styles.rightSectionContainer} /> */}
        <Image src={AppSnapshot} className={styles.appSnapshot} />
      </div>
    );
  }
}
