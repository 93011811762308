
import {  Container, Form} from "react-bootstrap";
import styles from "./styles/multiSelectTextField.module.css";
import React from "react";
import { CrossShadeThreeIcon } from "utils/icons";
import classNames from "classnames";
import { Nullable } from "types/global";
import {Label} from "components/Label";


interface multiSelectTextFieldType {
    headerLabel?: Nullable<string | JSX.Element>,
    inputObject : {
        inputText : string,
        textError : string,
        existingInputLabels : string[]
    },
    handleAddValue : (e : React.KeyboardEvent<HTMLInputElement>) => void,
    handleRemoveValue : (e : number) => void,
    handleChange : (e: React.ChangeEvent<HTMLInputElement>) => void,
    placeholder?:  string,
    className? : string,
}

const SelectedValue = ({value,  index , valueRemovalHandler  } : {value : string ,index : number,  valueRemovalHandler : (e : number) => void}) => { 

    return (<div className={styles.selectedLabel}>
        <span>{value}</span>
        <CrossShadeThreeIcon className={styles.cancelIcon} onClick={() => valueRemovalHandler(index)} />
    </div>)
}

const MultiSelectTextField = ({className="",inputObject ,headerLabel , placeholder="" , handleAddValue , handleRemoveValue , handleChange} :multiSelectTextFieldType) => {

    const containerClasses=  classNames(styles.container , className);
    const inputFormContainerClasses = classNames(styles.inputFormGroup , {
        [styles.inputFormGroupWithError] : inputObject.textError.length
    }  )

    return (
        <Container className={containerClasses}>
            <Form.Group controlId="emails" className={inputFormContainerClasses}>
              {headerLabel}
                <Form.Control autoComplete="off" className={styles.textInput} type="text" value={inputObject.inputText}
                              onKeyPress={handleAddValue} onChange={handleChange} isInvalid={inputObject.textError.length !== 0}
                              disabled={false} placeholder={placeholder}/>
                <Form.Control.Feedback type="invalid" className={styles.formError}>
                    <Label   name={inputObject.textError}  />
                </Form.Control.Feedback>
            </Form.Group>
        <div className={styles.selectedLabelsRow}>
            {inputObject.existingInputLabels.map((label:string, index : number) => {
                return (
                    <SelectedValue
                      index={index}
                      key={label.concat(`${index}-label`)}
                      value={label}
                      valueRemovalHandler={handleRemoveValue}
                     />
                )}
            )}
        </div>
      
    </Container>   
    )
}

export default MultiSelectTextField;