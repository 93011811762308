import { checkParameterizedLabel } from "components/Label/Label";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Nullable } from "types/global";
import { WithAccessControl, WithAccessControlPrivilegeProp } from "utils/AccessControl";
import { useGetPrivileges } from "utils/AccessControl/useGetPrivileges";
import styles from "./styles/labelsPage.module.css";
import {appConstants} from "../../utils/appConstants";
import {getDataFromAppStore} from "../../dataStore/appDataStore";
import {createLabel, updateLabels} from "../../dataSender/label/LabelDataSender";

interface FormState {
  name: string;
  value: string;
}

function CreateLabelForm({
  show,
  onHide,
  labelData,
}: {
  show: boolean;
  onHide: () => void;
  labelData: Nullable<FormState>;
}) {
  const [data, setData] = useState<FormState>({ name: "", value: "" });
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [validationMessage, setValidationMessage] = useState<Nullable<string>>(null);

  const defaultLanguageId = appConstants.ENGLISH_LANGUAGE_ID;
  const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);

  const nameInputRef = useRef<any>(null);

  useEffect(() => {
    if (labelData) {
      setData(labelData);
      setEditMode(true);
    } else {
      setData({ name: "", value: "" });
      setEditMode(false);
      setValidationMessage(null);
    }
  }, [labelData]);

  useEffect(() => {
    if (nameInputRef.current && show) {
      nameInputRef.current.focus();
    }
    if (!show) {
      setData({ name: "", value: "" });
      setValidationMessage(null);
    }
  }, [show]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onEditSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (defaultLanguageId) {
        await updateLabels({ ...data, languageId: defaultLanguageId });
      }
      onHide();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onCreateSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setLoading(true);
      await createLabel(data);
      setLoading(false);
      onHide();
    } catch (error) {
      setLoading(false);
    }
  };

  const executeSubmit = (e: any) => {
    const { isValid, message } = checkParameterizedLabel({
      labels,
      name: data.name,
      value: data.value,
    });
    if (isValid) {
      if (editMode) {
        onEditSubmit(e);
      } else {
        onCreateSubmit(e);
      }
    } else {
      setValidationMessage(message);
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!data.value) {
      setValidationMessage("Cannot be empty");
    } else {
      if (!data.value.trim()) {
        setValidationMessage("Cannot contain space");
      } else {
        executeSubmit(e);
      }
    }
  };

  return (
    <Modal show={show || editMode} onHide={onHide}>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            ref={nameInputRef}
            type="text"
            name="name"
            value={data.name}
            onChange={onChange}
            disabled={loading || editMode}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Value</Form.Label>
          <Form.Control
            type="text"
            name="value"
            value={data.value}
            onChange={onChange}
            disabled={loading}
          />
          {validationMessage && <Form.Text className="text-muted">{validationMessage}</Form.Text>}
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {editMode ? "Update" : "Submit"}
        </Button>
      </Form>
    </Modal>
  );
}

function LabelsPage({ privileges, requiredPrivileges }: WithAccessControlPrivilegeProp) {
  const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);
  const [show, setShow] = useState(false);
  const [editLabelData, setEditLabelData] = useState<Nullable<FormState>>(null);
  const history = useHistory();

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: ["EDIT_LABEL_TEXT"],
  });

  useEffect(() => {
    if (privileges.EDIT_LABEL_TEXT !== undefined) {
      if (!privileges.EDIT_LABEL_TEXT) {
        history.push("/");
      }
    }
  }, [privileges]);

  const onHide = () => {
    setEditLabelData(null);
    setShow(false);
  };

  const onEditClick = ({ name, value }: FormState) => () => {
    setEditLabelData({ name, value });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <h4>Labels</h4>
          </div>
          <button onClick={() => setShow(true)}>Create</button>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(labels).map((e) => {
              return (
                <tr key={e}>
                  <td>
                    <code>{e}</code>
                  </td>
                  <td>{labels[e]}</td>
                  <td>
                    <a onClick={onEditClick({ name: e, value: labels[e] })} role="button">
                      Edit
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div>
          <Link to="/">
            <span>&#8592; Home</span>
          </Link>
        </div>
      </div>
      <CreateLabelForm labelData={editLabelData} show={show} onHide={onHide} />
    </>
  );
}

export default function LabelsPageWithAccessControl() {
  return <WithAccessControl component={LabelsPage} />;
}
