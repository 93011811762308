import React from "react";

function TrinetIntegration() {
  return (
    <div className="p-4 m-4">
      <h2>Creating your API Key</h2>
      <p>To generate your Rippling API key, please visit the Trinet website.</p>
    </div>
  );
}

export default TrinetIntegration;
