import React from "react";
import {Container} from "react-bootstrap";
import {Label} from "../../Label";
import FadedLogoWatermark from "../Logo/FadedLogoWatermark";
import styles from "./styles/desktopAppLoginMessage.module.css";
import {getUserBrowser} from "../../../utils/browserUtils";

export function DesktopAppLoginMessage() {
    return (
        <Container className={styles.container}>
            <FadedLogoWatermark className={styles.logo} />
            <div className={styles.header}><Label name="ui.login.desktop.launch.message" /></div>
            <div className={styles.description}>
                {getUserBrowser() === 'Safari' && <Label name="ui.login.desktop.instructions.safari" />}
                {getUserBrowser() !== 'Safari' && <Label name="ui.login.desktop.instructions" />}
            </div>
        </Container>
    );
}