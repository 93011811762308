import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import { Tenant, User } from "types/userTypes";
import { AppCard } from "./AppCard";
import { TopRightElement } from "./TopRightElement";
import styles from "./styles/choice.module.css";
import { DynamicAppInfo, IntegratedAppNamesEnum } from "../../types";
import { AppCardModalDetailsInfo } from "../AppCardDetailsModal/types";
import { ChoiceAppCardDetailsModal } from "../AppCardDetailsModal/ChoiceAppCardDetailsModal";

interface ChoiceProps {
  className?: string;
  choices: DynamicAppInfo[];
  onConnectClick?: (name: IntegratedAppNamesEnum) => Promise<{ isConnected: boolean } | undefined>;
  onDisconnectClick?: (
    name: IntegratedAppNamesEnum
  ) => Promise<{ isDisconnected: boolean } | undefined>;
  tenant?: Tenant;
  user: User;
}

interface ChoiceState {
  activeIndexForModalDetails: number;
  indexForConnectedApp: number;
}

export class Choice extends AyraaBaseComponent<ChoiceProps, ChoiceState> {
  constructor(props: ChoiceProps) {
    super(props);
    this.state = {
      activeIndexForModalDetails: -1,
      indexForConnectedApp: -1,
    };
  }

  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    const list: AppCardModalDetailsInfo[] = [];

    this.props.choices.forEach((e) => {
      if (e.appCardModalDetailsInfo) {
        list.push(e.appCardModalDetailsInfo);
      }
    });

    return (
      <div className={containerClassNames}>
        {this.props.choices.map((choice, index) => {
          const isLast = this.props.choices.length === index + 1;

          const isConnected = index === this.state.indexForConnectedApp;

          const choiceContainerClassNames = classNames(styles.choiceContainer, {
            [styles.last]: isLast,
          });

          return (
            <div className={choiceContainerClassNames} key={choice.name}>
              <AppCard
                {...choice}
                onLearnMoreClick={(index) => this.setState({ activeIndexForModalDetails: index })}
                index={index}
                topRightElement={
                  <TopRightElement
                    isAvailable={choice.isAvailable}
                    isConnected={isConnected}
                    disable={this.state.indexForConnectedApp > -1}
                    componentToShow="connect-disconnect"
                    checkConnectionStatus={choice.getConnectionStatus}
                    checkStatusAsync={() => {
                      if (choice.getIsAppEnabledStatusAsync) {
                        return choice.getIsAppEnabledStatusAsync();
                      }
                      return Promise.resolve(false);
                    }}
                    checkStatus={choice.getIsAppEnabledStatus}
                    onConnectClick={() => this.handleOnConnectClick(choice.name)}
                    onDisconnectClick={() => this.handleOnDisconnectClick(choice.name)}
                  />
                }
                className={styles.card}
                appCardModalDetailsInfo={{
                  children: (
                    <ChoiceAppCardDetailsModal
                      activeIndex={this.state.activeIndexForModalDetails}
                      details={list}
                    />
                  ),
                  dialogClassName: styles.appCardModalDetailsDialog,
                  contentClassName: styles.appCardModalDetailsContent,
                }}
              />
              {!isLast && (
                <div className={styles.dividerContainer}>
                  <div className={styles.divider} />
                  <span className={styles.dividerLabel}>or</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  private async handleOnConnectClick(name: IntegratedAppNamesEnum) {
    return this.props.onConnectClick?.(name);
  }

  private async handleOnDisconnectClick(name: IntegratedAppNamesEnum) {
    const response = await this.props.onDisconnectClick?.(name);
    this.setState({
      indexForConnectedApp: -1,
    });
    return response;
  }

  private async doSideEffectOfMount() {
    if (this.shouldExecuteSideEffect(this.doSideEffectOfMount, [this.props.choices])) {
      const calls = this.props.choices.map((e) => e.getConnectionStatus?.());
      const res = await Promise.all(calls);
      const index = res.findIndex((e) => !!e);
      this.setState({
        indexForConnectedApp: index,
      });
    }
  }
}
