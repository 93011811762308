import {AyraaBaseComponent} from "../../../components/ayraaFramework/AyraaBaseComponent";
import React, {ReactNode} from "react";
import styles from "./downloaddesktopapp.module.css";
import {Button, Image} from "react-bootstrap";
import appScreenshot from "images/downloads/app-screenshot.png";
import {getUserSystemInfo} from "../../../utils/browserUtils";
import {downloadLatestDesktopAppRelease} from "../../../dataFetcher/download/DownloadDataFetcher";
import {Label} from "../../../components/Label";
import {ArchSpec, OSSpec} from "../../../types/downloadTypes";

type DownloadDesktopAppState = {
    userSystemInfo?: {
        os:  OSSpec | {name: string | undefined, path: undefined},
        arch:  ArchSpec | {name: string | undefined, path: undefined}
    }
}

export class DownloadDesktopApp extends AyraaBaseComponent<any, DownloadDesktopAppState> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    renderCore(): ReactNode {
        const isDownloadSupported = this.state.userSystemInfo?.os.path && this.state.userSystemInfo.arch.path;
        return (
            <div className={styles.container}>
                <div className={styles.leftColumn}>
                    {isDownloadSupported && (
                        <>
                            <div className={styles.header}><Label name="ui.desktop-app.download.title" args={[this.state.userSystemInfo?.os?.name || '']} /></div>
                            <div className={styles.description}><Label name="ui.desktop-app.download.description" /></div>
                            <Button className={styles.downloadButton} variant="primary" onClick={this.handleDownloadClick}>
                                <Label name="ui.desktop-app.download.button" args={[this.state.userSystemInfo?.arch?.name || '']} />
                            </Button>
                        </>)}
                    {!isDownloadSupported && <div className={styles.description}>
                        <Label name="ui.desktop-app.download.unsupported" args={[this.state.userSystemInfo?.os?.name || '', this.state.userSystemInfo?.arch?.name || '']} />
                    </div>}
                </div>
                <div className={styles.rightColumn}>
                    <Image className={styles.appScreenshot} src={appScreenshot} />
                    <div className={styles.ellipsis}></div>
                </div>
            </div>
        );
    }

    private doSideEffectForUserSystemInfo() {
        if (this.shouldExecuteSideEffect(this.doSideEffectForUserSystemInfo, [])) {
            getUserSystemInfo().then((userSystemInfo) => this.setState({ userSystemInfo }));
        }
    }

    private handleDownloadClick() {
        if (this.state.userSystemInfo?.os.path && this.state.userSystemInfo.arch.path) {
            downloadLatestDesktopAppRelease(this.state.userSystemInfo.os.path, this.state.userSystemInfo.arch.path)
                .then(() => this.history.push('/installation/instructions'));
        }
    }

}