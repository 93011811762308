import { Label } from "components/Label";
import { Progress } from "components/Progress/Progress";
import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import styles from "./styles/getStartedEnterprise.module.css";
import rightSideImg  from  "images/right-side-img.png";

export default function GetStartedEnterprise() {
  const history = useHistory();

  const navigate = () => {
    history.push("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.getStarted}>
          <div className="bold">
            <Label name="You are all set!" />
          </div>
          <div className="regular">
            <Label name="Welcome to your new company workspace" />
          </div>
        </div>
        <div>
          <Button onClick={navigate}>Let's get started</Button>
        </div>
      </div>
      {/* <FadedLogoWatermark className={styles.rightContainer} /> */}
      <div className={styles.rightContainer} >
        <img src={rightSideImg} className={styles.rightSideImage} alt="Right Side Artwork" />
      </div>
      {/* <Progress noOfSteps={3} activeStep={3} completed className={styles.progress} /> */}
    </div>
  );
}
