import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import styles from "./styles/inputWithFloatingLabel.module.css";
import { ReactNode } from "react";
import { FloatingLabel , Form} from "react-bootstrap";
import classNames from "classnames";

interface InputProps {
    classNames? : {
        containerClassName : string,
        inputClassName : string,
        labelClassName? : string
    },
    onChange?: (e : React.ChangeEvent<HTMLInputElement>) => void,
    inputProps : {
        placeHolder? : string,
        label? : string,
        type?: string
    },
    validationProps? : {
        error : boolean,
        message : string
    }
}

export class InputWithFloatingLabel extends AyraaBaseComponent<InputProps,{}> {
    
    public renderCore(): ReactNode {

        const containerClasses = classNames(styles.inputContainer, this.props.classNames?.containerClassName);
        const labelClasses = classNames(styles.floatingLabel , this.props.classNames?.labelClassName);
        const inputClasses = classNames(this.props.classNames?.inputClassName);
        
        return (
                <div className={containerClasses}>
                    <FloatingLabel
                    label={this.props.inputProps.label}
                    className={labelClasses}
                     >
                        <Form.Control 
                            type={this.props.inputProps.type}
                            className={inputClasses}
                            placeholder={this.props.inputProps.placeHolder}
                            onChange={this.props.onChange} 
                            isInvalid={this.props.validationProps?.error}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.props.validationProps?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </div>
        )
    }
}