import LoginGoogle from "components/v2/Auth/LoginGoogle";
import LoginMS from "components/v2/Auth/LoginMS";
import { Label } from "../../../components/Label";
import styles from "./loginpagev2.module.css";
import { useAppSelector } from "redux/hooks";
import { useHistory, useLocation } from "react-router";
import { getUserDeviceType } from "utils/browserUtils";
import { isMobileApp } from "utils/mobileUtils";
import { RightSection } from "./RightSection";

export default function SignupPageV2() {
  const history = useHistory();
  const location = useLocation();
  const user = useAppSelector((state) => state.userManager.user);
  const tenant = useAppSelector((state) => state.tenantManager.tenant);

  if (user.employeeID) {
    if (
      !tenant.workspaceDetails &&
      !user.personalAccount &&
      !(getUserDeviceType() === "mobile" && !isMobileApp())
    ) {
      history.push(`/integration`);
    } else if (user.status === "Invited" && !(getUserDeviceType() === "mobile" && !isMobileApp())) {
      history.push("/");
    } else if (getUserDeviceType() === "mobile") {
      history.push(`/`);
    } else {
      const { search } = location;
      history.push(`/${search}`);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.title}>
          <Label name="ui.signup.title" />
        </div>
        <div className={styles.loginButtons}>
          <LoginGoogle labelName="ui.login.google.signup-button-text" />
          <LoginMS labelName="ui.login.microsoft.signup-button-text" />
        </div>
        <div className={styles.emailContainer}>
          Not on Google or Microsoft?
          <a href="/emailLogin/email">
            <Label name="ui.login.email.signup-button-text" />
          </a>
        </div>
      </div>
      <RightSection className={styles.rightContainer} />
    </div>
  );
}
