import { browserName } from "react-device-detect";
import { DEFAULT_BROWSER_NAME, browserData } from "utils/constants";
import {
  ayraaForSlackConnector,
  browserExtensionConnector,
  connectToZendeskWithModal,
  genericAppConnector,
  genericAppDisconnect,
  getConnectionStatusForAnAppFromMap,
} from "./appConnectorUtils";
import {
  Confluence,
  Intercom,
  Drive,
  Gmail,
  GoogleCalendar,
  GoogleDocs,
  Jira,
  Notion,
  Hubspot,
  Slack,
  Salesforce,
  Trello,
  Zendesk
} from "./assets";
import { AppTag, DynamicAppInfo, IntegratedAppNamesEnum } from "./types";
import { checkIfExtensionIsInstalledAsync, getIsAppEnabledStatus } from "./utils";
import { addDataToAppDataStore } from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";

export const AyraaXForSlack: DynamicAppInfo = {
  logo: Slack,
  name: IntegratedAppNamesEnum.AYRAA_X_FOR_SLACK,
  label: "Ayraa X for Slack",
  description: "ui.integration.page.ayraa.x.for.slack.app.description",
  isAvailable: true,
  tag: AppTag.Recommended,
  connect: (props) => ayraaForSlackConnector(props.employeeId),
  appCardModalDetailsInfo: {
    description:
      "Now, we will centralize knowledge discovery & search. This will be a single place.",
    icon: Slack,
    label: "Ayraa X for Slack",
    features: [
      {
        text: "CC Ayraa to memorialize announcements & key outcomes to Deck & Scribe",
        isMissing: false,
      },
      {
        text: "Follow-up feature to keep moving specified threads along",
        isMissing: false,
      },
      { text: "Discovery engine works across all channels & threads", isMissing: false },
      {
        text: "Search all DM groups & private channels without having to first invite the bot there",
        isMissing: false,
      },
    ],
  },
};

export const AyraaForSlack: DynamicAppInfo = {
  logo: Slack,
  name: IntegratedAppNamesEnum.AYRAA_FOR_SLACK,
  label: "Ayraa for Slack",
  description: "ui.integration.page.ayraa.for.slack.app.description",
  isAvailable: false,
  tag: AppTag.Recommended,
  connect: ({ employeeId }) => ayraaForSlackConnector(employeeId),
  appCardModalDetailsInfo: {
    description:
      "Now, we will centralize knowledge discovery & search. This will be a single place.",
    icon: Slack,
    label: "Ayraa for Slack",
    features: [
      {
        text: "CC Ayraa to memorialize announcements & key outcomes to Deck & Scribe",
        isMissing: false,
      },
      {
        text: "Follow-up feature to keep moving specified threads along",
        isMissing: false,
      },
      {
        text: "Discovery engine only works across channels where bot has been invited",
        isMissing: true,
      },
      {
        text: "Bot needs to be invited to DM groups & private channels to be able to search inside them",
        isMissing: true,
      },
    ],
  },
};

// Need to check the difference between AyraaXForSlack, AyraaForSlack and Slack bot
// https://www.figma.com/file/GcPdMrAybjvsp5CDcgLV5B/Web-App?type=design&node-id=11944-10040&mode=design&t=n5Knb4lEaxol9tOR-4

export const SlackBot: DynamicAppInfo = {
  logo: Slack,
  name: IntegratedAppNamesEnum.SLACK,
  label: "Slack bot",
  description: "ui.integration.page.slack.app.description",
  isAvailable: true,
  tag: AppTag.Recommended,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.SLACK),
  connect: ({ employeeId }) => ayraaForSlackConnector(employeeId),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.SLACK, employeeID: employeeId }),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.SLACK),
  appCardModalDetailsInfo: {
    description:
      "A Slack bot that searches messages & files across various channels and direct messages in Slack",
    scope: [
      "This connector will cover the following fields in Slack:",
      "Messages, Attachments, Threads, Direct Messages, Mentioned Users, Emojis, Reactions, and other relevant communication details used by teams",
    ],
    benefitParagraphs: [
      "Amplify collaboration and information retrieval across the organization. Foster a culture of knowledge sharing with instant access to past discussions and decisions.",
    ],
    label: "Slack bot",
    icon: Slack,
    designedFor: ["All Teams", "Remote Workers"],
  },
};

export const BrowserExtension: DynamicAppInfo = {
  description: "ui.integration.page.extension.app.description",
  logo: browserData[browserName]?.iconSrc || browserData[DEFAULT_BROWSER_NAME].iconSrc,
  label: `${browserName || DEFAULT_BROWSER_NAME} extension`,
  name: IntegratedAppNamesEnum.BROWSER_EXTENSION,
  isAvailable: true,
  getConnectionStatusAsync: () => checkIfExtensionIsInstalledAsync(),
  tag: AppTag.Recommended,
  connect: browserExtensionConnector,
  disconnect: async () => {
    const isDisconnected = await checkIfExtensionIsInstalledAsync();
    return { isDisconnected };
  },
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.BROWSER_EXTENSION),
  appCardModalDetailsInfo: {
    icon: browserData[browserName]?.iconSrc || browserData[DEFAULT_BROWSER_NAME].iconSrc,
    label: `${browserName || DEFAULT_BROWSER_NAME} extension`,
    benefitParagraphs: [
      "Enhance knowledge sharing and discovery within your organization.",
      "Gain insights into employee workflows, leading to improved efficiency and better resource allocation.",
    ],
    description: "Effortlessly access and search through your entire web-based work activity.",
    scope: [
      "This connector comprehensively captures:",
      "URLs Visited, Page Titles, Text Content, Metadata (Author, Date, Tags), Bookmarks, Browsing History, Activities in JIRA, Notion, Confluence, Google Docs, Gmail, and other web applications.",
    ],
    designedFor: [
      "Human Resources",
      "IT Management",
      "Project Managers",
      "Research & Development Teams",
      "Marketing Departments",
    ],
  },
};

export const JIRA: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.JIRA,
  label: "Jira",
  description: "ui.integration.page.jira.app.description",
  logo: Jira,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.JIRA),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.JIRA),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.JIRA }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.JIRA, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description: "Quickly locate specific tickets across all your projects & boards on JIRA.",
    scope: [
      "This connector will cover the following fields in JIRA:",
      "Reporter, Assignee, Title, Description, Comments, Comment Authors, Status, Created & Updated Dates, and other related meta information about the JIRA that is typically used by teams.",
    ],
    benefitParagraphs: [
      "Reduce time spent on locating issues and maximize team productivity. Experience a seamless workflow with faster issue resolution and improved project tracking.",
    ],
    label: "Jira",
    icon: Jira,
    designedFor: ["Engineering", "Support", "Sales", "Product Management"],
  },
};

export const TRELLO: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.TRELLO,
  label: "Trello",
  description: "ui.integration.page.trello.app.description",
  logo: Trello,
  isAvailable: false,
  appCardModalDetailsInfo: {
    description: "Search  cards, lists, and boards in Trello from a central location",
    scope: [
      "This connector will encompass the following elements in Trello:",
      "Board Name, Card Title, Description, Comments, Commenters, Labels, Due Dates, Checklist Items, Attachment Names, Members, and activity logs.",
    ],
    benefitParagraphs: [
      "Enhance team collaboration and streamline project management with quick access to task specifics and overviews.",
    ],
    label: "Trello",
    icon: Trello,
    designedFor: ["All Teams", "Remote Workers", "Project Managers", "Operations"],
  },
};

export const GOOGLE_DRIVE: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.GOOGLE_DRIVE,
  label: "Google Drive",
  description: "ui.integration.page.google-drive.app.description",
  logo: Drive,
  isAvailable: false,
  appCardModalDetailsInfo: {
    description:
      "Search and access all types of files, folders, and shared content in Google Drive",
    scope: [
      "The connector will encompass searches within the following Google Drive attributes:",
      "Owner, Title, Description, Content, File Type, Created & Modified Dates, Sharing Status, Folder Names, and other pertinent metadata.",
    ],
    benefitParagraphs: [
      "Enhance collaboration and information sharing across the organization. Find documents swiftly, manage content effectively, and leverage existing knowledge to make informed decisions quickly.",
    ],
    label: "Google Drive",
    icon: Drive,
    designedFor: [
      "All Teams",
      "Marketing",
      "Human Resources",
      "Research & Development",
      "Legal Teams",
    ],
  },
};

export const GOOGLE_CALENDAR: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.GOOGLE_CALENDAR,
  label: "Google Calendar",
  description: "ui.integration.page.google-calendar.app.description",
  logo: GoogleCalendar,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.GOOGLE_CALENDAR),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.GOOGLE_CALENDAR),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.GOOGLE_CALENDAR }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.GOOGLE_CALENDAR, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description:
      "Search and manage all your events, appointments, and reminders in Google Calendar",
    scope: [
      "This connector will encompass the following fields in Google Calendar:",
      "Organizer, Attendees, Title, Description, Event Details, Location, Date & Time, Attachments, and other pertinent metadata associated with calendar events.",
    ],
    benefitParagraphs: [
      "Enhance team coordination and optimize scheduling by easily finding past and future events. Streamline meeting preparation and follow-ups with quick access to all event-related content.",
    ],
    label: "Google Calendar",
    icon: GoogleCalendar,
    designedFor: [
      "All Teams",
      "Project Managers",
      "Executive Assistants",
      "HR Managers",
      "Sales Teams",
    ],
  },
};

export const GMAIL: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.GMAIL,
  label: "Gmail",
  description: "ui.integration.page.gmail.app.description",
  logo: Gmail,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.GMAIL),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.GMAIL),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.GMAIL }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.GMAIL, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description: "Locate specific emails, attachments, or conversation threads in Gmail",
    scope: [
      "This connector will cover the following fields in Google Mail:",
      "Sender, Recipient, CC, Subject, Email Body, Attachments, Timestamps, Labels, and other related email metadata typically utilized by teams.",
    ],
    benefitParagraphs: [
      "Enhance collaboration and information retrieval with swift access to past conversations and email-bound knowledge. Streamline decision-making processes and ensure no critical information slips through the cracks.",
    ],
    label: "Gmail",
    icon: Gmail,
    designedFor: [
      "All Teams",
      "Executives",
      "Human Resources",
      "Legal",
      "Marketing",
      "Project Management",
    ],
  },
};

export const NOTION: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.NOTION,
  label: "Notion",
  description: "ui.integration.page.notion.app.description",
  logo: Notion,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.NOTION),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.NOTION),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.NOTION }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.NOTION, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description:
      "Search all your notes, databases, and documents in Notion to find the information you need",
    scope: [
      "This connector will cover the following fields in Notion:",
      "Page Owner, Contributors, Title, Text Content, Page Properties, Database Entries, Comments, Block Content, Created & Updated Dates, and other pertinent metadata.",
    ],
    benefitParagraphs: [
      "Facilitate a collaborative environment with instant access to shared notes, documents, and databases.",
    ],
    label: "Notion",
    icon: Notion,
    designedFor: [
      "All Teams",
      "Content Teams",
      "Research & Development",
      "Human Resources",
      "Project Management",
    ],
  },
};

export const CONFLUENCE: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.CONFLUENCE,
  label: "Confluence",
  description: "ui.integration.page.confluence.app.description",
  logo: Confluence,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.CONFLUENCE),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.CONFLUENCE),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.CONFLUENCE }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.CONFLUENCE, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description: "Search and consolidate knowledge across various pages and spaces on Confluence",
    scope: [
      "This connector will cover the following fields in Confluence:",
      "Page Creator, Last Editor, Title, Page Content, Comments, Comment Authors, Labels, Created & Updated Dates, and other relevant page metadata typically utilized by teams.",
    ],
    benefitParagraphs: [
      "Facilitate a collaborative environment with instant access to shared notes, documents, and databases.",
    ],
    label: "Confluence",
    icon: Confluence,
    designedFor: ["All Teams", "Project Managers", "Software Developers"],
  },
};
export const SALESFORCE: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.SALESFORCE,
  label: "Salesforce",
  description: "ui.integration.page.salesforce.app.description",
  logo: Salesforce,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.SALESFORCE),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.SALESFORCE),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.SALESFORCE }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.SALESFORCE, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description: "Elevate your enterprise search capabilities with our Salesforce connector.",
    scope: [
      "This connector will cover the following fields in Salesforce:",
      "Account Name, Contact Name, Opportunity Title, Description, Lead Source, Status, Created & Updated Dates, and other essential Salesforce data commonly utilized by various teams.",
    ],
    benefitParagraphs: [
      "Unlock the power of comprehensive knowledge discovery within Salesforce. Empower your Sales, Marketing, Customer Support, and Management teams to effortlessly access critical customer information, track leads and opportunities, and make data-driven decisions, all from a unified search interface. Say goodbye to time-consuming searches and hello to enhanced team efficiency.",
    ],
    label: "Salesforce",
    icon: Salesforce,
    designedFor: [
      "Sales",
      "Marketing",
      "Customer Support",
      "Management",
      "and any role dependent on Salesforce data for decision-making and customer interactions",
    ],
  },
};
export const HUBSPOT: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.HUBSPOT,
  label: "Hubspot",
  description: "ui.integration.page.hubspot.app.description",
  logo: Hubspot,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.HUBSPOT),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.HUBSPOT),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.HUBSPOT }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.HUBSPOT, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description: "Effortlessly navigate through your sales data in Sales Hub by HubSpot.",
    scope: [
      "This connector will encompass the following fields in Sales Hub:",
      "Contact Name, Company, Deal Title, Description, Sales Stage, Associated Contacts, Activities, Activity Authors, Deal Value, Closed Date, and other relevant sales data typically utilized by teams.",
    ],
    benefitParagraphs: [
      "Enhance sales efficiency and decision-making by swiftly accessing critical sales information. Foster a streamlined sales process with quicker lead follow-up and enhanced customer relationship management.",
    ],
    label: "Sales Hub by HubSpot",
    icon: Hubspot,
    designedFor: [
      "Sales Teams",
      "Marketing",
      "Customer Relationship Managers",
      "Business Development",
      "Account Management",
    ],
  },
};
export const INTERCOM: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.INTERCOM,
  label: "Intercom",
  description: "ui.integration.page.intercom.app.description",
  logo: Intercom,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.INTERCOM),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.INTERCOM),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.INTERCOM }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.INTERCOM, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description: "Effortlessly access and utilize knowledge from your Intercom Articles.",
    scope: [
      "This connector will cover the following fields in Intercom Articles:",
      "Author, Title, Article Body, Categories, Created & Updated Dates, View Counts, and other relevant metadata associated with the articles.",
    ],
    benefitParagraphs: [
      "Enhance knowledge sharing and retrieval within your organization. Streamline customer support and internal collaboration by providing quick access to comprehensive, organized information.",
    ],
    label: "Intercom",
    icon: Intercom,
    designedFor: [
      "Customer Support Teams",
      "Content Managers",
      "Sales Representatives",
      "Marketing Professionals",
    ],
  },
};

export const GOOGLE_DOCS: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.GOOGLE_DOCS,
  label: "Google docs",
  description: "ui.integration.page.google.docs.app.description",
  logo: GoogleDocs,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.GOOGLE_DOCS),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.GOOGLE_DOCS),
  connect: ({ employeeId, tenantId }) =>
    genericAppConnector({ employeeId, tenantId, name: IntegratedAppNamesEnum.GOOGLE_DOCS }),
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.GOOGLE_DOCS, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description:
      "Search and access all types of documents, folders, and shared content in Google Docs",
    scope: [
      "The connector will encompass searches within the following Google Docs attributes:",
      "Owner, Title, Description, Content, Document Type, Created & Modified Dates, Sharing Status, Folder Names, and other pertinent metadata.",
    ],
    benefitParagraphs: [
      "Simplify document management and streamline collaboration across the organization. Locate files quickly, organize content efficiently, and harness existing knowledge to facilitate prompt decision-making.",
    ],
    label: "Google docs",
    icon: GoogleDocs,
    designedFor: [
      "All Teams",
      "Marketing",
      "Human Resources",
      "Research & Development",
      "Legal Teams",
    ],
  },
};

export const ZENDESK: DynamicAppInfo = {
  name: IntegratedAppNamesEnum.ZENDESK,
  label: "Zendesk",
  description: "ui.integration.page.zendesk.app.description",
  logo: Zendesk,
  isAvailable: true,
  getConnectionStatus: () => getConnectionStatusForAnAppFromMap(IntegratedAppNamesEnum.ZENDESK),
  getIsAppEnabledStatus: () => getIsAppEnabledStatus(IntegratedAppNamesEnum.ZENDESK),
  connect: async ({ employeeId, tenantId }) => {
    return connectToZendeskWithModal({ employeeId, tenantId });
  },
  disconnect: ({ employeeId }) =>
    genericAppDisconnect({ name: IntegratedAppNamesEnum.ZENDESK, employeeID: employeeId }),
  appCardModalDetailsInfo: {
    description:
      "Enhance your customer service experience by integrating seamless search capabilities with Zendesk.",
    scope: [
      "This connector will cover the following fields in Zendesk: Reporter, Assignee, Title, Description, Comments, Comment Authors, Ticket Status, Priority, Tags, Created & Updated Dates, and other relevant ticket metadata commonly utilized in customer service workflows.",
    ],
    benefitParagraphs: [
      "Streamline support ticket management and improve response times with comprehensive search functionality. Facilitate better customer interactions and insights by easily accessing detailed ticket information and history.",
    ],
    label: "Zendesk",
    icon: Zendesk,
    designedFor: [
      "Customer Support",
      "IT Helpdesk",
      "Operations",
      "Customer Success Management",
    ],
  },
};

export const APPS_FOR_INTEGRATION_MAP: {
  [name in IntegratedAppNamesEnum]: DynamicAppInfo;
} = {
  AYRAA_FOR_SLACK: AyraaForSlack,
  AYRAA_X_FOR_SLACK: AyraaXForSlack,
  CONFLUENCE,
  HUBSPOT,
  INTERCOM,
  GMAIL,
  GOOGLE_CALENDAR,
  GOOGLE_DRIVE,
  JIRA,
  NOTION,
  TRELLO,
  BROWSER_EXTENSION: BrowserExtension,
  SLACK: SlackBot,
  SALESFORCE,
  GOOGLE_DOCS,
  ZENDESK,
};
