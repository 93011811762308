import { createSlice } from "@reduxjs/toolkit";
import {User, UserRole} from "../types/userTypes";

export const getUsersRole = (userRole: $TSFixMe) => {
  return {
    ...userRole,
  };
};

export const userSlice = createSlice({
  //Name of the slice
  name: "userSlice",

  //Initial User State
  initialState: {
    user: {} as User,
    userRole: {} as UserRole,
    isFirstSlackbotRedirection : false as boolean
  },

  //Reducers & Actions
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserAccountStatus : (state, action) => {
      state.user.personalAccount = true;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    subscribe: (state, action) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'subscription' does not exist on type 'Wr... Remove this comment to see the full error message
      state.subscription = action.payload;
    },
    updateSlackBotRedirectionStatus : (state , action) => {
      state.isFirstSlackbotRedirection = action.payload;
    }
  },
});

export const { subscribe, setUser, setUserRole , setUserAccountStatus , updateSlackBotRedirectionStatus} = userSlice.actions;
export default userSlice.reducer;
