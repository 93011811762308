import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import React, { ReactNode, RefObject } from "react";
import styles from "./styles/otpPage.module.css";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import { verifyOTP, resendOTP } from "dataSender/auth/AuthDataSender";
import { User, UserRole, Tenant } from "types/userTypes";
import { getDataFromAppStore } from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";
import { joinWorkspaceOrg } from "dataFetcher/Workspace/workspaceDataFetcher";
import { setTenant } from "redux/tenantSlice";
import { setUser, setUserRole } from "redux/userSlice";
import { resetSubscribedPackageStateStatus } from "redux/subscriptionSlice";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import ToastComponent from "../Toast/ToastComponent";
import { Label } from "components/Label";
import { triggerSubscriptionRequirements } from "utils/subscriptionUtils";
import { updateEmployeeWorkspaceStatus } from "dataFetcher/Workspace/workspaceDataFetcher";
import { setUserAccountStatus } from "redux/userSlice";
import { getUserDeviceType } from "utils/browserUtils";
interface OTPPageStateProps {
  otp: string[];
  error: {
    show: boolean;
    message: string;
  };
  showToast: boolean;
}
interface OTPPageProps {
  updateTenantInfo: (tenantInfo: Tenant) => void;
  updateUserRoleInfo: (roleInfo: UserRole) => void;
  resetSubscribedPackageStateStatus: () => void;
  updateUserInfo: (userInfo: User) => void;
  updateUserAccountTypeStatus: (status: boolean) => void;
}

interface EmailLoginDataType {
  email: string;
  firstName: string;
  lastName: string;
}

class OTPPage extends AyraaBaseComponent<OTPPageProps, OTPPageStateProps> {
  private inputRefs: RefObject<HTMLInputElement>[];
  private data: EmailLoginDataType;

  constructor(props: OTPPageProps) {
    super(props);
    this.state = {
      otp: ["", "", "", ""],
      error: {
        show: false,
        message: "",
      },
      showToast: false,
    };
    this.inputRefs = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
    this.data = getDataFromAppStore(appConstants.EMAIL_ID_LOGIN_FLOW);
  }

  private handleInputChange = (index: number, value: string) => {
    const newOtp = [...this.state.otp];
    newOtp[index] = value;

    this.setState({ otp: newOtp });

    // Move focus to the next input
    if (value !== "" && index < 3) {
      this.inputRefs[index + 1]?.current?.focus();
    }
  };

  private handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && this.state.otp[index] === "") {
      const newOtp = [...this.state.otp];
      newOtp[index - 1] = "";
      this.setState({ otp: newOtp });
      this.inputRefs[index - 1]?.current?.focus();
    }
  };

  private async handleOTPVerification(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    // Get referrer from local storage or use empty string if null
    const referrer = localStorage.getItem('originalReferrer') || '';

    const response = await verifyOTP({
      email: this.data.email,
      otp: this.state.otp.join(""),
      referrer
    });
    if (response.success) {
      // Clear the referrer from local storage if successful
      localStorage.removeItem('originalReferrer');
      
      const fetchedUser = response.data;
      const fetchedTenant = fetchedUser.tenant;
      const tenantID: number = fetchedUser.tenant.tenantID;

      this.props.updateTenantInfo(fetchedUser.tenant);
      const { userRole, ...rest } = fetchedUser;
      this.props.updateUserInfo(rest);
      this.props.updateUserRoleInfo({ ...userRole });
      this.props.resetSubscribedPackageStateStatus();

      if (!fetchedTenant.workspaceDetails && !fetchedUser.personalAccount) {
        //first time user registration. show him the account Selection screen.
        try {
          await triggerSubscriptionRequirements(fetchedTenant);
          await updateEmployeeWorkspaceStatus({
            userData: {
              personalAccount: true,
              tenantID: fetchedTenant.tenantID,
              employeeID: fetchedUser.employeeID,
            },
            updatedBy: fetchedUser.employeeID,
          });
          this.props.updateUserAccountTypeStatus(true);
          if (getUserDeviceType() === "mobile") {
            this.history.push(`/`);
          } else {
            this.history.push(`/integration`);
          }
        } catch (e) {
          console.log("Something went Wrong!");
          console.log(e);
        }
      } else if (fetchedUser.status === "Invited") {
        //currently hasOrgs is commented, thus same action is done as on invited.
        await joinWorkspaceOrg({ tenantId: tenantID, employeeId: fetchedUser.employeeID });
        this.history.push("/");
      } else {
        this.history.push("/");
      }
    } else {
      this.setState({
        error: {
          message: "Enter OTP to proceed.",
          show: true,
        },
      });
    }
  }
  private async handleResendOTP() {
    resendOTP({ email: this.data.email });
    this.setState({ showToast: true, otp: ["", "", "", ""], error: { show: false, message: "" } });
  }

  private toggleToast() {
    this.setState({ showToast: false });
  }

  public renderCore(): ReactNode {
    const inputClassNames = classNames(styles.input, {
      [styles.error]: this.state.error.show,
    });
    const inputContainerClasses = classNames(styles.inputContainer, {
      [styles.error]: this.state.error.show,
    });
    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.otpPageLabel}>Verification Code</div>
          <div className={styles.emailSentContainer}>
            Please enter the code sent to {" "}
            <span>{this.data.email}</span>
          </div>
          <div className={inputContainerClasses}>
            {this.state.otp.map((digit, index) => (
              <Form.Control
                key={`${index}-form-input`}
                ref={this.inputRefs[index]}
                type="number"
                maxLength={1}
                value={digit}
                onChange={(e) => this.handleInputChange(index, e.target.value)}
                onKeyDown={(e) => this.handleKeyDown(index, e)}
                className={inputClassNames}
                onPaste={this.handlePastedText}
              />
            ))}
          </div>
          {this.state.error.show ? (
            <div className={styles.errorMessage}>{this.state.error.message}</div>
          ) : null}
          <div className={styles.resendContainer}>
            Didn’t get one? <span onClick={this.handleResendOTP}>Resend</span>
          </div>
          <Button
            className={styles.continueButton}
            disabled={false}
            onClick={this.handleOTPVerification}
          >
            Continue
          </Button>
          <ToastComponent
            showCloseIcon={false}
            type={"success"}
            text={
              <div className="b2 medium shade-two">
                <Label name="ui.login.resent.otp.successfully" />
              </div>
            }
            show={this.state.showToast}
            onClose={this.toggleToast}
          />
        </div>
        <FadedLogoWatermark className={styles.rightContainer} />
      </div>
    );
  }

  private handlePastedText(e: React.ClipboardEvent<HTMLInputElement>) {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("Text");
    const isPastedTextANumber = !!Number(pastedText);
    const isPastedTextLengthFourChars = pastedText.length === 4;
    if (isPastedTextANumber && isPastedTextLengthFourChars) {
      Array.from(pastedText).forEach((e, index) => {
        setTimeout(() => {
          this.handleInputChange(index, e);
        }, 10);
      });
    }
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    updateTenantInfo: (tenantInfo: Tenant) => dispatch(setTenant(tenantInfo)),
    updateUserRoleInfo: (roleInfo: UserRole) => dispatch(setUserRole(roleInfo)),
    resetSubscribedPackageStateStatus: () => dispatch(resetSubscribedPackageStateStatus()),
    updateUserInfo: (userInfo: User) => dispatch(setUser(userInfo)),
    updateUserAccountTypeStatus: (status: boolean) => dispatch(setUserAccountStatus(status)),
  };
};

export default connect(null, mapDispatchToProps)(OTPPage);
