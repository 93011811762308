import toastModule from "react-hot-toast";
import { BsFillInfoCircleFill } from "react-icons/bs";
import styles from "./toast.module.css";

/**
 * Extending react-hot-toast module to have info method
 * for showing information notifications to user
 */
export const toast = {
  ...toastModule,
  // added optional `options` parameter using `?`
  info: (message: $TSFixMe, options?: $TSFixMe) =>
    toast.success(message, {
      ...options,
      icon: <BsFillInfoCircleFill className={styles.infoIcon} />,
    }),
};
