import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  saveSubscriptionDetails,
  setSubscribedPackageState,
  setUpcomingSubscriptionState,
  updateTenantSubscription,
} from "redux/subscriptionSlice";
import { WithAccessControl } from "utils/AccessControl";
import { useGetPrivileges } from "utils/AccessControl/useGetPrivileges";
import { subscriptionTypes } from "utils/constants";
import { toast } from "utils/toast";
import StripeCardInput from "./StripeCardInput";
import styles from "./subscription.module.css";
import {actions} from "../../utils/AccessControl/actions";

/**
 * Button component which executes saveSubscriptionDetails endpoint.
 * Shows stripe card input element if pgDetailsPresent property is false
 */
function SubscribeButton({
  selectedSubscription = null,
  btnText = { default: "Subscribe", loading: "Subscribing..." },
  updateSubscription = false,
  requiredPrivileges,
  privileges,
}: $TSFixMe) {
  const [loading, setLoading] = useState(false);
  const [showCardInput, setShowCardInput] = useState(false);

  const { subscribedPackage }: $TSFixMe = useAppSelector(
    (state) => state.subscriptionManager.subscribedPackageState
  );
  const { tenant } = useAppSelector((state) => state.tenantManager);
  const dispatch = useAppDispatch();

  const isFree = selectedSubscription.subscriptionType === subscriptionTypes.FREE;

  const pgDetailsPresent = subscribedPackage?.pgDetailsPresent || false;

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [actions.MANAGE_SUBSCRIPTION],
  });

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  if (!privileges[actions.MANAGE_SUBSCRIPTION]) {
    return null;
  }

  const executeUpdateTenantSubscription = async () => {
    const payload = {
      selected_subscription: selectedSubscription,
      old_subscription: subscribedPackage.subscriptionPackageDetails,
      tenant,
    };
    setLoading(true);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    const res = await dispatch(updateTenantSubscription(payload)).unwrap();
    if (subscribedPackage.subscriptionPackageDetails.subscriptionType !== subscriptionTypes.FREE) {
      dispatch(setUpcomingSubscriptionState(res.data));
    } else {
      dispatch(setSubscribedPackageState({ subscribedPackage: res.data }));
    }
    setLoading(false);
  };

  const executeSaveSubscriptionDetails = async () => {
    try {
      setLoading(true);
      await dispatch(
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        saveSubscriptionDetails({
          tenant,
          selected_subscription: selectedSubscription,
        })
      );
      setLoading(true);
    } catch (error) {
      toast.error("Problem saving subscription");
    }
  };

  const onClick = () => {
    if (isFree) {
      if (updateSubscription) {
        executeUpdateTenantSubscription();
      } else {
        executeSaveSubscriptionDetails();
      }
    } else {
      if (pgDetailsPresent) {
        if (updateSubscription) {
          executeUpdateTenantSubscription();
        } else {
          executeSaveSubscriptionDetails();
        }
      } else {
        setShowCardInput(true);
      }
    }
  };

  const onCardSetupComplete = () => {
    if (updateSubscription) {
      executeUpdateTenantSubscription();
    } else {
      executeSaveSubscriptionDetails();
    }
  };

  return (
    <>
      <Button onClick={onClick}>{loading ? btnText.loading : btnText.default}</Button>
      <Modal show={showCardInput}>
        <div className={`${styles.container} ${styles.stripeCardInput}`}>
          <h5>Enter credit card details</h5>
          <StripeCardInput
            onCardSetupComplete={onCardSetupComplete}
            onBack={() => setShowCardInput(false)}
            selectedSubscription={selectedSubscription}
          />
        </div>
      </Modal>
    </>
  );
}

export default function SubscribeButtonWithAccessControl({
  selectedSubscription = null,
  btnText = { default: "Subscribe", loading: "Subscribing..." },
  updateSubscription = false,
}) {
  return (
    <WithAccessControl
      component={SubscribeButton}
      props={{
        selectedSubscription,
        btnText,
        updateSubscription,
      }}
    />
  );
}
