import React, { useState, useEffect } from "react";
import { Row, Col, Container, Modal, Button, Image } from "react-bootstrap";
import LoginGoogle from "components/Auth/LoginGoogle";
import LoginMS from "components/Auth/LoginMS";
import { useHistory, useLocation } from "react-router";
import styles from "./loginpage.module.css";
import { getAvailableHRIS } from "redux/importEmployeesSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function LoginPage() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const { listAvailableHRIS } = useAppSelector((state) => state.importEmployeesManager);

  const handleClose = () => setShowModal(false);
  const handleYes = () => {
    const { search } = location;
    history.push(`/${search}`);
  };
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(getAvailableHRIS());
  }, [dispatch]);

  return (
    <Container className={styles.container}>
      <h3>Login to Ayraa UI</h3>
      <div className={styles.loginButtons}>
        <LoginGoogle setShowModal={setShowModal} />
        <LoginMS setShowModal={setShowModal} />
      </div>
      <Row className="p-3 displayHRIlist">
        {listAvailableHRIS.map((hris: $TSFixMe) => (
          <Col md={2} className="d-flex justify-content-center py-3">
            <Image src={hris.hris_provider_logo_url} className={`${styles.hrilogo}`}></Image>
          </Col>
        ))}
      </Row>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your organization is not registered with Ayraa. Do you wish to register now?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
