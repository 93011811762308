import axios from "axios";
import {appConstants} from "../../utils/appConstants";
import {addDataToAppDataStore} from "../../dataStore/appDataStore";
import {LabelResponse} from "../../types/global";

export async function getLabels(languageId: number) {
    const res = await axios.get(
      process.env.REACT_APP_PULSE_BACKEND_URL + `/labels/listLabels/${languageId}`
    );
    addDataToAppDataStore(appConstants.LABEL_INFO_KEY, res.data);
    return res.data as LabelResponse;
}

