export function isMobileApp() {
  return ((navigator.userAgent.indexOf("ANDROID_APP") > 0) || (navigator.userAgent.indexOf("IOS_APP") > 0));
}

export function isAndroidApp() {
  return navigator.userAgent.indexOf("ANDROID_APP") > 0;
}

export function isIOSApp() {
  return navigator.userAgent.indexOf("IOS_APP") > 0;
}