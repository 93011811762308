import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { deleteTenant, setTenant } from "redux/tenantSlice";
import { useHistory } from "react-router";
import { logout } from "redux/authSlice";
import { setSubscribedPackageState, setUpcomingSubscriptionState } from "redux/subscriptionSlice";
import { setUser, setUserRole } from "redux/userSlice";
import toast, { Toaster } from "react-hot-toast";
import manageaccountCSS from "./manageaccount.module.css";
import { WithAccessControl } from "utils/AccessControl";
import { useGetPrivileges } from "utils/AccessControl/useGetPrivileges";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {actions} from "../../utils/AccessControl/actions";

function ManageAccount({ requiredPrivileges, privileges }: $TSFixMe) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { tenant }: $TSFixMe = useAppSelector((state) => state.tenantManager);
  const { user }: $TSFixMe = useAppSelector((state) => state.userManager);

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [actions.DELETE_TENANT],
  });

  const tenantID = tenant.tenantID;
  const employeeID = user.employeeID;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className={manageaccountCSS.container}>
        <h1>Manage Account</h1>
        <Toaster />
        {privileges[actions.DELETE_TENANT] ? (
          <Button variant="primary" onClick={handleShow}>
            Delete Tenant
          </Button>
        ) : (
          "Only an admin can delete the tenant"
        )}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure</Modal.Title>
          </Modal.Header>
          <Modal.Body>This will erase all the data for this particular tenant</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                const res: $TSFixMe = await dispatch(
                  deleteTenant({ tenantID, employeeID })
                ).unwrap();
                if (res.status) {
                  dispatch(setUser(null));
                  dispatch(setTenant(null));
                  dispatch(setUserRole(null));
                  dispatch(setSubscribedPackageState({ subscribedPackage: null }));
                  dispatch(setUpcomingSubscriptionState(null));
                  await dispatch(logout());
                  history.push("/");
                } else {
                  handleClose();
                  toast.error(res.message || "The tenant couldnot be deleted");
                }
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default function ManageAccountWithAccessControl() {
  return <WithAccessControl component={ManageAccount} />;
}
