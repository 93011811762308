import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import { Switch } from "components/Switch/Switch";

interface ToggleAppProps {
  className?: string;
  onChange?: (checked: boolean) => Promise<boolean | undefined>;
  checkStatusAsync?: () => Promise<boolean>;
}

interface ToggleAppState {
  checked: boolean;
  isFetching: boolean;
}

export class ToggleApp extends AyraaBaseComponent<ToggleAppProps, ToggleAppState> {
  constructor(props: ToggleAppProps) {
    super(props);
    this.state = {
      isFetching: true,
      checked: false,
    };
  }

  public renderCore(): ReactNode {
    if (this.state.isFetching) {
      return <div className="b4 regular shade-five">Getting status...</div>;
    }
    return <Switch onChange={this.handleOnChange} checked={this.state.checked} />;
  }

  private async handleOnChange() {
    const res = await this.props.onChange?.(this.state.checked);
    this.setState({
      checked: res ?? false,
      isFetching: false,
    });
  }

  private setStatus() {
    if (this.props.checkStatusAsync) {
      this.props.checkStatusAsync().then((res) => {
        this.setState({
          checked: res,
          isFetching: false,
        });
      });
    } else {
      this.setState({
        checked: false,
        isFetching: false,
      });
    }
  }

  private doSideEffectOfMount() {
    if (this.shouldExecuteSideEffect(this.doSideEffectOfMount, [])) {
      this.setStatus();
    }
  }
}
