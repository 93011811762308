import { ConnectionType, DynamicAppInfo } from "./types";

import {
  AyraaForSlack,
  AyraaXForSlack,
  BrowserExtension,
  CONFLUENCE,
  HUBSPOT,
  INTERCOM,
  GMAIL,
  GOOGLE_CALENDAR,
  GOOGLE_DOCS,
  GOOGLE_DRIVE,
  JIRA,
  NOTION,
  SlackBot,
  SALESFORCE,
  TRELLO,
  ZENDESK,
} from "./appsForIntegrationConfig";

export const recommendedAppsForIntegrationConfig: {
  connectionType: ConnectionType;
  multipleAppsInfo?: DynamicAppInfo[];
  singleAppInfo?: DynamicAppInfo;
}[] = [
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: SlackBot,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: BrowserExtension,
  },
];

export const appsForIntegrationList: {
  connectionType: ConnectionType;
  multipleAppsInfo?: DynamicAppInfo[];
  singleAppInfo?: DynamicAppInfo;
}[] = [
  // {
  //   connectionType: ConnectionType.Choice,
  //   multipleAppsInfo: [AyraaXForSlack, AyraaForSlack],
  // },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: JIRA,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: NOTION,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: GOOGLE_DOCS,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: CONFLUENCE,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: SALESFORCE,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: HUBSPOT,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: INTERCOM,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: GMAIL,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: GOOGLE_DRIVE,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: GOOGLE_CALENDAR,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: TRELLO,
  },
  {
    connectionType: ConnectionType.Single,
    singleAppInfo: ZENDESK,
  },
];
