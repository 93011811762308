//react imports
import { useEffect, useState } from "react";
//redux imports

import {
  updateRowCount,
  updateSelectedRowData,
  sendEmailToSelectedEmployees,
  toggleConfirmationModal,
} from "redux/employeeSlice";

//styles import
import styles from "./sendinvites.module.css";

//Bs imports
import Modal from "react-bootstrap/Modal";
import { Image, Button } from "react-bootstrap";
//images imports
import placeHolderImage from "../images/stock-employee.jpg";
import { SiMinutemailer } from "react-icons/si";

//Ag-Grid imports
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { getAllEmployeesForTenant } from "redux/tenantSlice";
import toast, { Toaster } from "react-hot-toast";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { WithAccessControl } from "utils/AccessControl";
import { useGetPrivileges } from "utils/AccessControl/useGetPrivileges";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {actions} from "../../utils/AccessControl/actions";
import AppSpinner from "../../components/Spinner/AppSpinner";

function SendInvitesPage({ requiredPrivileges, privileges }: $TSFixMe) {
  //redux variables.
  const { selectedRowCount, selectedRowData, confirmationModal } = useAppSelector(
    (state) => state.employeeManager
  );
  //tenant
  const { tenant }: $TSFixMe = useAppSelector((state) => state.tenantManager);
  //tenantID
  //current logged in user.
  const { user }: $TSFixMe = useAppSelector((state) => state.userManager);

  //employeeID

  const { status, list } = useAppSelector((state) => state.tenantManager.employees);

  const [gridApi, setGridApi] = useState(null);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [actions.SEND_INVITES],
  });

  const tenantID = tenant?.tenantID;
  const employeeID = user?.employeeID;

  const loading = status === "pending";

  //dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === "idle") {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch(getAllEmployeesForTenant({ tenantID, excludePending: false }));
    }
  }, [status, dispatch, tenantID]);

  if (loading) {
    return <AppSpinner />;
  }

  //if not admin, push to dashboard.

  if (!privileges[actions.SEND_INVITES]) {
    return (
      <div className={styles.container}>You don't have sufficient privilege to view this page</div>
    );
  }

  //default Grid Options
  const gridOptions = {
    // PROPERTIES
    // Objects like myRowData and myColDefs would be created in your application
    rowData: list,
    rowStyle: { cursor: "pointer" },
    pagination: true,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    paginationPageSize: 5, //initital size.
    filter: true,
    accentedSort: true, //for case insensitive sort.
    //default grid handlers.
    isRowSelectable: (rowNode: $TSFixMe) => (rowNode.data.status !== "Active"),
    onColumnResized: (event: $TSFixMe) => event.api.refreshCells(),
    onRowSelected: (event: $TSFixMe) => handleRowSelected(event),
    getRowStyle: (rowNode: $TSFixMe) =>
      rowNode.data.status === "Active"
        ? { backgroundColor: "#f2f2f2", color: "grey", cursor: "no-drop" }
        : null,
  };

  //Custom Handlers.
  const onGridReady = (params: $TSFixMe) => {
    params.api.sizeColumnsToFit(); //fit the columns
    setGridApi(params.api);
  };

  const handleRowSelected = (e: $TSFixMe) => {
    dispatch(
      updateRowCount({
        count: e.api.getSelectedNodes().length,
      })
    );
    dispatch(
      updateSelectedRowData({
        selectedData: e.api.getSelectedNodes(),
      })
    );
  };

  //global filter.
  const onFilterTextChange = (e: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    gridApi.setQuickFilter(e.target.value);
  };

  //page size handler.
  const onPageSizeChange = (e: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    gridApi.paginationSetPageSize(e.target.value);
  };

  //employee avatar renderer.
  const EmployeeAvatarCellRenderer = (props: $TSFixMe) => {
    const url = props.value;
    return <Image rounded src={url ? url : placeHolderImage} width={"40px"} height={"35px"} />;
  };

  //send email
  const sendEmail = (e: $TSFixMe) => {
    e.preventDefault();
    dispatch(toggleConfirmationModal());
    //selectedRowData
  };
  //modal handlers
  const handleModalClose = () => dispatch(toggleConfirmationModal());

  const handleEmailTrigger = async () => {
    let payload = {
      employeeId: employeeID,
      tenantId: tenantID,
    };
    if (selectedRowCount === list.length) {
      payload = {
        ...payload,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ sendEmailsToAll: boolean; employeeId: any;... Remove this comment to see the full error message
        sendEmailsToAll: true,
      };
    } else {
      const selectedEmployeeIds = selectedRowData.map(
        (employee: $TSFixMe) => employee.data.employeeID
      );
      payload = {
        ...payload,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ sendEmailsToAll: boolean; selectedEmployee... Remove this comment to see the full error message
        sendEmailsToAll: false,
        selectedEmployeeIds,
      };
    }
    toast.loading("Sending email");
    handleModalClose();
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    gridApi.deselectAll();
    setSendEmailLoading(true);

    await dispatch(
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      sendEmailToSelectedEmployees({
        requiredPayload: payload,
      })
    );

    setSendEmailLoading(false);
    toast.dismiss();
    toast.success("Woohoo, Email Sent!");
  };

  //modal.
  const confirmationModalTemplate = (
    <Modal show={confirmationModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`You are sending email to ${selectedRowCount} employee(s), confirm the action?`}{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-outline-dark" onClick={handleEmailTrigger}>
          Confirm Action
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const statusCellStyle = (params: $TSFixMe) => {
    const style = {
      fontWeight: "bold",
    };
    if (params.value === "Active") {
      return {
        ...style,
        color: "green",
      };
    } else {
      return {
        ...style,
        color: "red",
      };
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: "100px", left: "0", zIndex: 100000000 }}></div>
      <div style={{ marginTop: "40px" }}>
        <div className="d-flex flex-column p-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="left-section d-flex flex-column">
              <input
                placeholder="filter.."
                onChange={onFilterTextChange}
                style={{ padding: "15px" }}
              ></input>

              <input
                placeholder="page size : 5"
                type="number"
                onChange={onPageSizeChange}
                onKeyDown={(e) => e.preventDefault()}
                defaultValue={"5"}
                min="5"
                max="10"
                style={{ fontSize: "15px", color: "grey" }}
              ></input>
            </div>
            <div className="right-section d-flex flex-column align-items-center">
              <button
                className={`${styles["email-button"]} ${
                  selectedRowCount <= 0 ? styles["email-button-disabled"] : null
                }`}
                onClick={(e) => sendEmail(e)}
                disabled={selectedRowCount <= 0}
              >
                <span className={`d-flex align-items-center`}>
                  <span style={{ marginRight: "2px" }}>{"Send Email"}</span>
                  <SiMinutemailer />
                </span>
              </button>
              <span style={{ fontSize: "12px" }}>{`${selectedRowCount} Row(s) are selected`}</span>
            </div>
          </div>
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              frameworkComponents={{
                employeeAvatarCellRenderer: EmployeeAvatarCellRenderer,
              }}
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              gridOptions={gridOptions}
              suppressCellSelection={sendEmailLoading}
              domLayout="autoHeight"
              onGridReady={onGridReady}
              style={{ padding: "none" }}
              defaultColDef={{
                flex: 1,
                sortable: true,
                resizable: true,
                filter: true,
                filterParams: {
                  suppressAndOrCondition: true,
                  trimInput: true,
                },
              }}
            >
              <AgGridColumn
                checkboxSelection={true}
                headerCheckboxSelection={true}
                headerCheckboxSelectionFilteredOnly={true}
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                maxWidth={"40"}
              ></AgGridColumn>
              <AgGridColumn
                field="externalEmployeeId"
                filter="agTextColumnFilter"
                floatingFilter={true}
                headerName="Id"
              ></AgGridColumn>
              <AgGridColumn field="employeeID" hide={true}></AgGridColumn>
              <AgGridColumn
                field="photoUrl"
                filter={false}
                sortable={false}
                cellRenderer="employeeAvatarCellRenderer"
                headerName="Profile picture"
              ></AgGridColumn>
              <AgGridColumn
                field="employeeFirstName"
                headerName="Name"
                filter="agTextColumnFilter"
                floatingFilter={true}
                valueGetter={(props) => {
                  const { employeeFirstName, employeeLastName } = props.data;
                  return `${employeeFirstName} ${employeeLastName}`;
                }}
              ></AgGridColumn>
              <AgGridColumn
                field="workEmailID"
                filter="agTextColumnFilter"
                floatingFilter={true}
              ></AgGridColumn>
              <AgGridColumn
                field="designation"
                filter="agTextColumnFilter"
                floatingFilter={true}
              ></AgGridColumn>
              <AgGridColumn
                field="status"
                filter="agTextColumnFilter"
                floatingFilter={true}
                cellStyle={statusCellStyle}
              ></AgGridColumn>
              <AgGridColumn field="employee_id" hide={true}></AgGridColumn>
            </AgGridReact>
            {confirmationModalTemplate}
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default function SendInvitesPageWithAccessControl() {
  return <WithAccessControl component={SendInvitesPage} />;
}
