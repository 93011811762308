import React, {Component, ReactNode} from "react";
import {DependencyArrayData} from "./DependencyArrayData";
import {appConstants} from "../../utils/appConstants";
import {areArraysEqual} from "../../utils/objectUtils";
import {AyraaGlobalStateTracker} from "../ayraaGlobalState/AyraaGlobalStateTracker";
import {TrackerSliceEnum} from "../ayraaGlobalState/TrackerSliceEnum";
import {getDataFromAppStore} from "../../dataStore/appDataStore";

export abstract class AyraaBaseComponent<P, S> extends Component<P, S> {

  protected SUBSCRIBED_TRACKER_SLICES: Array<TrackerSliceEnum> = [];

  protected sideEffectMethods:Array<()=> void> = [];
  protected sideEffectMethodDependencyArrayDataMap:Map<()=>void, DependencyArrayData> = new Map<()=>void, DependencyArrayData>();
  protected sideEffectMethodCleanupFunctionMap:Map<()=>void, ()=> void|Promise<any>> = new Map<()=>void, ()=> void|Promise<any>>();

  protected history;

  constructor(props: P) {
    super(props);

    //Get the history object from app-data-store
    this.history = getDataFromAppStore(appConstants.HISTORY_OBJECT_KEY);

    //Getting the current-object(i.e. "this") reference
    const currentObject:any = this;
    /* Binding "this" to all instance methods of this class.
           Actually, the following code doesn't make much sense as "this" should already be available.
           But if we use our methods as event-listeners inside JSX then we need to do this.
           Basically, we are just trying to work around a limitation of React-JSX */
    Object.getOwnPropertyNames(Object.getPrototypeOf(this))
        .filter(instanceMemberName => instanceMemberName !== 'constructor')
        .forEach(methodName => currentObject[methodName] = currentObject[methodName].bind(currentObject));

    //Get all side-effect method-names
    this.sideEffectMethods = Object.getOwnPropertyNames(Object.getPrototypeOf(this))
                                  .filter(methodName => methodName.startsWith("doSideEffect"))
                                  .map(sideEffectMethodName => currentObject[sideEffectMethodName]);
  }

  //Do the actual rendering of the component
  public render():ReactNode {
    try {
      let currentComponentName:string = this.constructor.name;

      if (true) {
        //Call "renderCore()" - which will contain the actual logic of rendering the component
         return this.renderCore();
      } else {
        //Return access-denied JSX
        return (
          <div>
            You don't have access
          </div>
        )
      }
    } catch(ex:any) {
      console.trace("Error occurred::"+ex);
      return (
        <div>
          Exception occurred!
        </div>
      );
    }
  }

  //Override this method in the actual components (that will extend this component)
  public renderCore():ReactNode {
    throw new Error("This method should be overridden");
  }

  public componentDidMount() {
    //Log the mounting of current component
    let currentComponentName:string = this.constructor.name;

    //Subscribe to tracker slices (tracker-slices are configured in the actual component class that will be extending "AyraaBaseComponent")
    if (this.SUBSCRIBED_TRACKER_SLICES !== undefined) {
      this.SUBSCRIBED_TRACKER_SLICES.forEach((trackerSlice)=>this.subscribeToTrackerSlice(trackerSlice));
    }

    //execute side-effects and sync dependency-array
    this.executeSideEffects();
    this.syncDependencyValues();
  }

  public componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
      this.executeSideEffects();
      this.syncDependencyValues();
  }

  public componentWillUnmount() {
    let currentComponentName:string = this.constructor.name;

    //Unsubscribe the current component from any tracker-slices that it may have subscribed to
    this.unsubscribeFromAllTrackerSlices();

    //Execute the clean-up function of all side-effects
    this.sideEffectMethodCleanupFunctionMap.forEach((cleanUpFunction: ()=>void|Promise<any>, sideEffectMethod: ()=>void) => {
      if (cleanUpFunction instanceof Promise) {
        cleanUpFunction.then((actualCleanupFunction) => {
          if (actualCleanupFunction !== undefined) {
            actualCleanupFunction();
          }
        },
        (reason) => {
          console.error("Error occurred while resolving cleanupFunction promise. Reason:: "+reason);
        })
      } else {
        cleanUpFunction();
      }
    });

  }

  public executeSideEffects() {
    //Invoke all side-effect methods
    this.sideEffectMethods.forEach(sideEffectMethod => {

      //Execute the actual side-effect (and collect the clean-up function if it is available)
      let sideEffectCleanupFunction = sideEffectMethod();

      //Store the clean-up function
      if (sideEffectCleanupFunction !== undefined) {
        this.sideEffectMethodCleanupFunctionMap.set(sideEffectMethod, sideEffectCleanupFunction);
      }
    });
  }

  private subscribeToTrackerSlice(trackerSlice: TrackerSliceEnum) {
    //Add an entry to "AyraaGlobalStateTracker"
    AyraaGlobalStateTracker.subscribeToTrackerSlice(trackerSlice, this);
  }

  private unsubscribeFromAllTrackerSlices() {
    if (this.SUBSCRIBED_TRACKER_SLICES) {
      this.SUBSCRIBED_TRACKER_SLICES.forEach((trackerSlice: TrackerSliceEnum) => {
        AyraaGlobalStateTracker.unsubscribeFromTrackerSlice(trackerSlice, this);
      });

      this.SUBSCRIBED_TRACKER_SLICES=[];
    }
  }

  protected shouldExecuteSideEffect(sideEffectMethod:()=>void, dependencyArray: Array<any>) {
      //Save the dependency-array data into the map
      let dependencyArrayData:DependencyArrayData|undefined = this.sideEffectMethodDependencyArrayDataMap.get(sideEffectMethod);
      if (dependencyArrayData === undefined) {
        let dependencyArrayDataNewObj = new DependencyArrayData();
        dependencyArrayDataNewObj.dependencyArrayOldValues = [...dependencyArray];
        dependencyArrayDataNewObj.dependencyArrayNewValues = dependencyArray;

        this.sideEffectMethodDependencyArrayDataMap.set(sideEffectMethod, dependencyArrayDataNewObj);
      } else {
        dependencyArrayData.dependencyArrayNewValues = dependencyArray;
      }

      /* Set shouldExecute flag to true if dependencyArrayData is undefined.
          OR
         set shouldExecute flag to true if dependencyArrayOldValues & dependencyArrayNewValues are different.
         This means that dependencyArray values have been changed and side effect should be executed */
      let shouldExecute: boolean = dependencyArrayData === undefined
          || !areArraysEqual(dependencyArrayData.dependencyArrayOldValues, dependencyArrayData.dependencyArrayNewValues);

      /* During componentDidMount phase shouldExecute flag will be true but side-effect cleanup function will not exist.
        During componentDidUpdate phase, if sideEffect dependencies have changed, then before side effect will be re-executed,
        execute previous cleanup function */
      if (shouldExecute) {
        /* Retrieve the clean-up function for side-effect(if it exists)
         and execute it before executing the actual side-effect. */
        let sideEffectCleanupFunction = this.sideEffectMethodCleanupFunctionMap.get(sideEffectMethod);
        if (sideEffectCleanupFunction !== undefined) {
          sideEffectCleanupFunction();
        }
      }

      return shouldExecute;
  }

  private syncDependencyValues() {
    //Sync new dependency-array values with the old dependency-array values
    this.sideEffectMethodDependencyArrayDataMap.forEach((dependencyArrayData: DependencyArrayData) => {
      dependencyArrayData.dependencyArrayOldValues = [...dependencyArrayData.dependencyArrayNewValues];
    });
  }

}
