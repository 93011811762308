import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import ModalWithNotch from "pages/v2/ModalWithNotch/ModalWithNotch";
import styles from "./styles/zendeskSubdomainModal.module.css";
import { Label } from "components/Label";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { addDataToAppDataStore, clearDataFromAppStore, getDataFromAppStore } from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";
import { TrackerSliceEnum } from "components/ayraaGlobalState/TrackerSliceEnum";
import { AyraaGlobalStateTracker } from "components/ayraaGlobalState/AyraaGlobalStateTracker";
import { zendeskAppConnector } from "../appConnectorUtils";
import { IntegratedAppNamesEnum } from "../types";
import { User } from "types/userTypes";

interface ZendeskSubdomainModalState {
  formData: {
    subDomain: string;
  };
  errors: {
    subDomain: any;
  };
}
interface ZendeskSubdomainModalProps {
  user: number,
  tenantId: any,
}
export class ZendeskSubdomainModal extends AyraaBaseComponent<ZendeskSubdomainModalProps, ZendeskSubdomainModalState> {
  protected SUBSCRIBED_TRACKER_SLICES: TrackerSliceEnum[] = [
    TrackerSliceEnum.ZENDESK_SUBDOMAIN_MODAL,
  ];
  private handleModalClose() {
    console.log("closed...", this.formValid())
    addDataToAppDataStore(appConstants.SHOW_ZENDESK_SUBDOMAIN_MODAL, false);
    this.setState({
      formData: {
        subDomain: "",
      },
      errors: {
        subDomain: null,
      },
    })
  }
  private isZendeskSubdomainModalOpen = getDataFromAppStore(
    appConstants.SHOW_ZENDESK_SUBDOMAIN_MODAL
  );
  constructor(props: ZendeskSubdomainModalProps) {
    super(props);
    this.state = {
      formData: {
        subDomain: "",
      },
      errors: {
        subDomain: null,
      },
    };
  }
  public renderCore() {
    return (
      <ModalWithNotch
        show={this.isZendeskSubdomainModalOpen}
        centered
        contentClassName={styles.customLinkModalContent}
        dialogClassName={styles.customLinkModalDialog}
        notchIconProps={{ onClick: this.handleModalClose, className: styles.notchIcon }}
        onHide={this.handleModalClose}
      >
        <Modal.Title className={styles.customLinkModalTitle}>
          <Label name="What is your Zendesk subdomain?" />
        </Modal.Title>
        <p className={styles.subtitle}>
          Your subdomain is a unique identifier of your Zendesk account.
        </p>
        <Form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <FloatingLabel label="Subdomain *" className={styles.inputContainer}>
            <Form.Control
              type="text"
              autoFocus={true}
              placeholder="Subdomain *"
              required
              name="subDomain"
              value={this.state.formData?.subDomain}
              onChange={this.handleInputChange}
              isInvalid={Boolean(this.state.errors?.subDomain)}
            />
            <Form.Control.Feedback type="invalid" tooltip className={styles.invalidTooltip}>
              {this.state.errors?.subDomain?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
          <a
            className={styles.zendeskSubdomainInfo}
            target="_blank"
            rel="noreferrer"
            href="https://support.zendesk.com/hc/en-us/articles/4409381383578-Where-can-I-find-my-Zendesk-subdomain"
          >
            Where can I find my Zendesk subdomain?
          </a>
          <Button
            variant="primary"
            type={"submit"}
            className={styles.footerButton}
          >
            <Label name="Continue" />
          </Button>
        </Form>
      </ModalWithNotch>
    );
  }
  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
      errors: {
        ...this.state.errors,
        subDomain: null,
      },
    });
  }
  private checkForRequiredFields() {
    const inputEmptyErrors = {} as any;
    for (const [key, value] of Object.entries(this.state.formData)) {
      if (value === "") {
        inputEmptyErrors[key] = {
          message: <Label name="curate.create.modal.form.validation.fieldrequired" />,
        };
      } else {
        inputEmptyErrors[key] = null;
      }
    }
    this.setState({
      errors: { ...this.state.errors, ...inputEmptyErrors },
    });
    return { ...this.state.errors, ...inputEmptyErrors };
  }
  private formValid() {
    const allErrors = this.checkForRequiredFields();
    if (allErrors) {
      let isValid = true;
      for (const [key, val] of Object.entries(allErrors)) {
        if (val != null) {
          isValid = false;
        }
      }
      return isValid;
    }
  }
  private async onSubmit(e: any) {
    e.preventDefault();
    const isFormValid = this.formValid();
    console.log({isFormValid, tenantId: this.props.tenantId});
    if (isFormValid && this.props.tenantId) {
      const connectionResult = await zendeskAppConnector({ employeeId: this.props.user, tenantId: this.props.tenantId, name: IntegratedAppNamesEnum.ZENDESK, subDomain: this.state.formData.subDomain });
      this.handleModalClose();
      addDataToAppDataStore(appConstants.ZENDESK_SUBDOMAIN_API_RESPONSE, connectionResult);
      AyraaGlobalStateTracker.publishToTrackerSlice(TrackerSliceEnum.ZENDESK_SUBDOMAIN_MODAL);
    }
  }
  private doSideEffectForZendeskModalTrigger() {
    if (
      this.shouldExecuteSideEffect(this.doSideEffectForZendeskModalTrigger, [
        getDataFromAppStore(appConstants.SHOW_ZENDESK_SUBDOMAIN_MODAL),
      ])
    ) {
      this.isZendeskSubdomainModalOpen = getDataFromAppStore(
        appConstants.SHOW_ZENDESK_SUBDOMAIN_MODAL
      );
      AyraaGlobalStateTracker.publishToTrackerSlice(TrackerSliceEnum.ZENDESK_SUBDOMAIN_MODAL);
    }
  }
}

export default ZendeskSubdomainModal;
