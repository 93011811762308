import classNames from "classnames";
import styles from "./styles/notchCloseIcon.module.css";
import {AyraaBaseComponent} from "../../../components/ayraaFramework/AyraaBaseComponent";
import {ComponentWithTooltip} from "../ComponentWithTooltip/ComponentWithTooltip";
import {CrossShadeThreeIcon} from "../../../utils/icons";

type PropType = {
  onClick?: (e: any) => void;
  className?: string;
};

export default class NotchCloseIcon extends AyraaBaseComponent<PropType, any> {
  constructor(props: PropType) {
    super(props);
  }

  renderCore() {
    const notchClassNames = classNames(this.props.className, styles.notch);
    return (
      <ComponentWithTooltip
        component={
          <div className={notchClassNames} role="button" onClick={this.props.onClick}>
            <CrossShadeThreeIcon className={styles.crossIcon} />
          </div>
        }
        tooltipProps={{
          show: true,
          tooltipPlacement: "bottom",
          labelInfo: {
            args: [],
            labelName: "pulse.tooltip.notch.close.icon",
          },
        }}
      />
    );
  }
}
