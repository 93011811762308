import { useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { generateSSOToken } from "redux/authSlice";
import { useAppDispatch } from "redux/hooks";
import AppSpinner from "../Spinner/AppSpinner";
import {useQueryParams} from "../../hooks/useQueryParams";
import {saveDetailsForAppLogin} from "../../dataSender/auth/AuthDataSender";
import {getDesktopAppLoginURL} from "../../utils/desktopUtils";
import {DesktopAppLoginMessage} from "../v2/Auth/DesktopAppLoginMessage";

export default function PulseAppRedirect({ redirectUrl }: { redirectUrl:string }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [SSOToken, setSSOToken] = useState(null);
  const dispatch = useAppDispatch();

  const verifySSOTokenFormRef = useRef<HTMLFormElement>();

  const channel = useQueryParams("channel");
  const verificationToken = useQueryParams("vt");

  useEffect(() => {
    const generateSSOTokenFunc = async () => {
      const generateSSOTokenResponse = await dispatch(
        generateSSOToken({ target_app_url: redirectUrl, target_app_code: "pulse-frontend" })
      ).unwrap();
      const { sso_token, status } = generateSSOTokenResponse;
      if (!sso_token && status === "FAILED") {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Something went wrong! Please tr... Remove this comment to see the full error message
        setError("Something went wrong! Please try again later.");
        setLoading(false);
      } else {
        setSSOToken(sso_token);
      }
    };
    generateSSOTokenFunc();
  }, [dispatch, redirectUrl]);

  const handleDesktopAppLogin = async () => {
    if (verificationToken) {
      await saveDetailsForAppLogin(verificationToken);
      window.location.href = getDesktopAppLoginURL(verificationToken);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (SSOToken) {
      if (channel === 'desktop' && verificationToken) {
        handleDesktopAppLogin();
      } else {
        verifySSOTokenFormRef.current?.submit();
      }
    }
  }, [SSOToken, verifySSOTokenFormRef]);

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {loading && <AppSpinner/>}
      {channel === 'desktop' && <DesktopAppLoginMessage />}
      {channel !== 'desktop' && <form
        action={`${process.env.REACT_APP_PULSE_BACKEND_URL}/auth/verifySSOToken`}
        method="post"
        id="theForm"
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={verifySSOTokenFormRef}
      >
        <input type="hidden" name="sso_token" value={SSOToken || ""} />
      </form>}
    </>
  );
}
