import classNames from "classnames";
import { Label } from "components/Label";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import { Image } from "react-bootstrap";
import { AppCardInfo, IntegratedAppNamesEnum } from "../../types";
import { AppCardDetailsModal } from "../AppCardDetailsModal/AppCardDetailsModal";
import styles from "./styles/appCard.module.css";
import {checkIfDesktopApp} from "../../../../utils/desktopUtils";

interface AppCardProps extends AppCardInfo {
  className?: string;
  topRightElement: React.ReactNode;
  appCardModalDetailsInfo?: {
    children?: React.ReactNode;
    dialogClassName?: string;
    contentClassName?: string;
  };
  onLearnMoreClick?: (index: number) => void;
  index?: number;
}

interface AppCardState {
  appCardDetailsModalInfo: {
    show: boolean;
  };
}

export class AppCard extends AyraaBaseComponent<AppCardProps, AppCardState> {
  constructor(props: AppCardProps) {
    super(props);
    this.state = {
      appCardDetailsModalInfo: {
        show: false,
      },
    };
  }
  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    const isDesktopApp = checkIfDesktopApp();    

    return (
      <>
        <div id={`${this.props.name}_CARD`} className={containerClassNames}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.logoContainer}>
                <Image src={this.props.logo} className={styles.logo} />
              </div>
              <div className={styles.name}>{isDesktopApp && this.props.name === IntegratedAppNamesEnum.BROWSER_EXTENSION ? "Browser extension" : this.props.label}</div>
            </div>
            {this.props.topRightElement}
          </div>
          <div className={styles.description}>
            <Label name={this.props.description} />
          </div>
          {this.props.warningText && (<div className={styles.warningText}>
            <span className={styles.warningTitle}>Unverified App Warning</span> {" - "}
            <Label name={this.props.warningText} />
            <span className={styles.warningReadMore}>
              {" "}<a href="https://intercom.help/ayraainc/en/articles/8824623-unverified-app-warning-from-google" className={styles.readMore}>
                Read more
              </a> on steps you can take until then.
            </span>
          </div>)}
          <div className={styles.footer}>
            {this.props.tag && <div className={styles.tag}>{this.props.tag}</div>}
            {this.props.appCardModalDetailsInfo?.children && (
              <div className={styles.learnMore} onClick={this.handleOnLearnMoreClick}>
                Learn more
              </div>
            )}
          </div>
        </div>
        <AppCardDetailsModal
          show={this.state.appCardDetailsModalInfo.show}
          onHide={this.hideAppCardDetailsModal}
          contentClassName={this.props.appCardModalDetailsInfo?.contentClassName}
          dialogClassName={this.props.appCardModalDetailsInfo?.dialogClassName}
        >
          <div className={styles.appCardDetailsModalBody}>
            {this.props.appCardModalDetailsInfo?.children}
          </div>
        </AppCardDetailsModal>
      </>
    );
  }

  private handleOnLearnMoreClick() {
    this.props.onLearnMoreClick?.(this.props.index ?? -1);
    this.showAppCardDetailsModal();
  }

  private showAppCardDetailsModal() {
    this.setState({
      appCardDetailsModalInfo: {
        show: true,
      },
    });
  }

  private hideAppCardDetailsModal() {
    this.setState({
      appCardDetailsModalInfo: {
        show: false,
      },
    });
  }
}
