import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default forwardRef((props, ref) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formError, setFormError] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return uploadedImageUrl;
      },
      isCancelAfterEnd: () => {
        const hasErrors = Boolean(formError);
        return hasErrors || !submitClicked;
      },
      isPopup() {
        return true;
      },
    };
  });

  const closeModal = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
    props.api.stopEditing();
  };

  const onUploadClick = () => {
    if (selectedFile) {
      const res = { selectedFile, blobUrl: URL.createObjectURL(selectedFile) };
      setLoading(true);
      setSubmitClicked(true);
      setTimeout(() => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setUploadedImageUrl(res.blobUrl);
        setLoading(false);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
        props.api.stopEditing();
      }, 2500);
    } else {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Please select a file"' is not a... Remove this comment to see the full error message
      setFormError("Please select a file");
    }
  };

  const onFileSelect = (e: $TSFixMe) => {
    if (e.target.files && e.target.files.length > 0) {
      const { files } = e.target;
      setSelectedFile(files[0]);
    }
  };

  return (
    <div>
      <Modal.Header>
        <Modal.Title>Upload image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formFileSm" className="mb-3">
          <Form.Control
            type="file"
            onChange={onFileSelect}
            size="sm"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean | u... Remove this comment to see the full error message
            isInvalid={formError}
            accept="image/*"
            disabled={loading}
          />
          <Form.Control.Feedback type="invalid">{formError}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} disabled={loading}>
          Close
        </Button>
        <Button variant="primary" onClick={onUploadClick} disabled={loading}>
          {loading ? "Uploading..." : "Upload"}
        </Button>
      </Modal.Footer>
    </div>
  );
});
