import axios from "axios";
import { browserName } from "react-device-detect";
import { AppAvailabilityInfo, ConnectedAppsInfo, Tenant } from "types/userTypes";
import { browserData } from "utils/constants";
import { IntegratedAppNamesEnum } from "./types";
import { addDataToAppDataStore, getDataFromAppStore } from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";

export const popupFeatures = `width=${1200},height=${800},top=${100},left=${100}`;

export const getAuthURL = ({
  appCode,
  employeeId,
  tenantId,
}: {
  appCode: IntegratedAppNamesEnum | string;
  employeeId: number;
  tenantId: number;
}) => {
  return `${process.env.REACT_APP_SERVER_URL}/app-integration/getAuthURL/${appCode}/${tenantId}/${employeeId}`;
};

export const getZendeskAuthURL = ({
  appCode,
  employeeId,
  tenantId,
  subDomain,
}: {
  appCode: IntegratedAppNamesEnum | string;
  employeeId: number;
  tenantId: number;
  subDomain: string;
}) => {
  // Append subDomain as a query parameter
  return `${process.env.REACT_APP_SERVER_URL}/app-integration/getAuthURL/${appCode}/${tenantId}/${employeeId}?subDomain=${subDomain}`;
};

export const openPopup = (props: {
  url: string;
  target: string;
  features: string;
}): Promise<boolean> => {
  const win = window.open(props.url, props.target, props.features);
  win?.focus();

  return new Promise((resolve, reject) => {
    const timer = setInterval(() => {
      if (win?.closed) {
        clearInterval(timer);
        resolve(true);
      }
    });
  });
};

export const checkSlackBotStatus = async (tenantID?: number): Promise<boolean> => {
  if (tenantID) {
    const response = await axios.get<Tenant>(
      `${process.env.REACT_APP_SERVER_URL}/getTenant/${tenantID}`
    );
    return !!response.data.slackBotAdded;
  }
  return false;
};

export const checkIfExtensionIsInstalledAsync = (
  timeout: number = 5000,
  interval: number = 1000
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let stopPolling = false;
    const timeoutId = setTimeout(() => {
      stopPolling = true;
    }, timeout);

    const intervalId = setInterval(() => {
      const extension = document.querySelector("#ayraa-extension-integration");
      if (stopPolling) {
        resolve(false);
      }
      if (extension) {
        clearInterval(intervalId);
        timeoutId && clearTimeout(timeoutId);
        resolve(true);
      }
    }, interval);
  });
};

export const getIsAppEnabledStatus = (name: IntegratedAppNamesEnum): { isEnabled: boolean } => {
  const connectedAppForUserMap: { [name in IntegratedAppNamesEnum]: ConnectedAppsInfo } =
    getDataFromAppStore(appConstants.CONNECTED_APPS_MAP_FOR_USER, {});

  if (connectedAppForUserMap && connectedAppForUserMap[name]) {
    return { isEnabled: connectedAppForUserMap[name].enabled } ?? { isEnabled: false };
  }
  return { isEnabled: false };
};

export const createMapForAppAvailabilityList = (list: AppAvailabilityInfo[] = []) => {
  let map = {};
  list.forEach((e) => {
    map = {
      ...map,
      [e.integratedApp]: e,
    };
  });

  addDataToAppDataStore(appConstants.APP_AVAILABILITY_INFO_LIST_MAP, map);
};

export const createMapForConnectedAppsForUser = (list: ConnectedAppsInfo[] = []) => {
  let map = {};
  list.forEach((e) => {
    map = {
      ...map,
      [e.appCode]: e,
    };
  });

  addDataToAppDataStore(appConstants.CONNECTED_APPS_MAP_FOR_USER, map);
};
