import React, { useEffect, useState } from "react";
import { getUsersRole, setUser, setUserRole } from "redux/userSlice";
import { BsGoogle } from "react-icons/bs";
import { tokenValidation, getUserInfo } from "redux/authSlice";
import { resetSubscribedPackageStateStatus } from "redux/subscriptionSlice";
import { useHistory, useLocation } from "react-router";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import styles from "./login.module.css";
import appStyles from "../../app.module.css";
import { setTenant, fetchTenant } from "redux/tenantSlice";
import { CONSTANTS } from "utils/constants";
import { useAppDispatch, useAppSelector } from "redux/hooks";

function useGoogleLogin() {
  useEffect(() => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'gapi' does not exist on type 'Window & t... Remove this comment to see the full error message
    window.gapi.load("auth2", () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'gapi' does not exist on type 'Window & t... Remove this comment to see the full error message
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
        scope: CONSTANTS.REACT_APP_GOOGLE_AUTH_SCOPE,
      });
    });
  }, []);

  const signIn = async () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'gapi' does not exist on type 'Window & t... Remove this comment to see the full error message
    const auth2 = window.gapi.auth2.getAuthInstance();
    return auth2
      .grantOfflineAccess({ prompt: CONSTANTS.REACT_APP_AUTH_PROMPT })
      .then(function (resp: $TSFixMe) {
        return resp.code;
      })
      .catch((err: $TSFixMe) => {
        throw err;
      });
  };

  return { signIn };
}

export default function LoginGoogle({ setShowModal }: $TSFixMe) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { status: tokenValidationStatus } = useAppSelector(
    (state) => state.authManager.tokenValidation
  );

  useEffect(() => {
    if (tokenValidationStatus === "rejected") {
      history.push("/login")
      setLoading(false);
    }
  }, [tokenValidationStatus, history]);

  const { signIn } = useGoogleLogin();

  const onClick = async () => {
    try {
      setLoading(true);
      const code = await signIn();
      const tokenValidationPayload = {
        serviceProvider: "GOOGLE",
        authCode: code,
        referrer: "",
      };
      // Validating token
      const user = await dispatch(tokenValidation(tokenValidationPayload)).unwrap();

      if (user) {
        const fetchedUser = await dispatch(getUserInfo()).unwrap();
        const tenantID: number = fetchedUser.tenant.tenantID;
        const fetchedTenant = await dispatch(fetchTenant(tenantID)).unwrap();
        const { userRole, ...rest } = fetchedUser;
        dispatch(setUser(rest));
        dispatch(setTenant(fetchedTenant));
        dispatch(setUserRole(getUsersRole(userRole)));
        dispatch(resetSubscribedPackageStateStatus());
        if (!fetchedTenant.tenantRegistered) {
          setShowModal(!fetchedTenant.tenantRegistered);
          setLoading(false);
        } else {
          const { search } = location;
          history.push(`/${search}`);
        }
      } else {
        //this is where tokenValidation fails !

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Button className={styles.button} onClick={onClick}>
      {loading ? <Spinner className={appStyles.spinner} animation="border" /> : <BsGoogle />}
      Google Login
    </Button>
  );
}
