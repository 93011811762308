import { useState } from "react";
import { CardElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { Alert, Button, Spinner } from "react-bootstrap";
import { saveDetailsToPaymentGateway } from "redux/subscriptionSlice";
import { loadStripe } from "@stripe/stripe-js";
import styles from "./subscription.module.css";
import appStyles from "../../app.module.css";
import { WithAccessControl } from "utils/AccessControl";
import { useGetPrivileges } from "utils/AccessControl/useGetPrivileges";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {actions} from "../../utils/AccessControl/actions";

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#000",
      color: "#000",
      fontWeight: "500",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#000",
      },
    },
    invalid: {
      iconColor: "#a22029",
      color: "#a22029",
    },
  },
};

/**
 * Stripe's CardElement implementation which add the card details for the tenant
 * if not added and has a callback when the setup is completed (onCardSetupComplete)
 */
function CardInput({
  onCardSetupComplete,
  onBack,
  selectedSubscription,
  disabled = false,
  requiredPrivileges,
  privileges,
}: $TSFixMe) {
  const { tenant }: $TSFixMe = useAppSelector((state) => state.tenantManager);

  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [cardError, setCardError] = useState<$TSFixMe>(null);

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [actions.MANAGE_SUBSCRIPTION],
  });

  const confirmCardSetup = async (clientSecret: $TSFixMe) => {
    try {
      if (clientSecret) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        const result = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'StripeCardElement | null' is not assignable ... Remove this comment to see the full error message
            card: elements.getElement(CardElement),
            billing_details: {
              phone: tenant.phone,
            },
          },
        });
        if (result.error) {
          setCardError({ message: result.error.message });
          setLoading(false);
        } else {
          if (result.setupIntent.status === "succeeded") {
            setLoading(false);
            onCardSetupComplete();
          }
          setLoading(false);
        }
      } else {
        setLoading(false);
        setCardError({ message: "clientSecret is undefined" });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const executeSaveDetailsToPaymentGateway = async () => {
    try {
      if (privileges[actions.MANAGE_SUBSCRIPTION]) {
        if (tenant && tenant.tenantID) {
          const res = await dispatch(
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
            saveDetailsToPaymentGateway({
              tenantID: tenant.tenantID,
              packageType: selectedSubscription.subscriptionPackageID,
            })
          ).unwrap();
          if (res?.clientSecret) {
            confirmCardSetup(res.clientSecret);
          } else {
            setCardError({ message: "Problem fetching stripe details" });
            setLoading(false);
          }
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onSaveClick = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const element = elements.getElement(CardElement);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const invalid = element._invalid;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const empty = element._empty;
    if (!invalid && !empty) {
      setLoading(true);
      if (tenant && tenant.tenantID) {
        executeSaveDetailsToPaymentGateway();
      } else {
        setCardError({ message: "TenantId is not defined" });
        setLoading(false);
      }
    } else {
      setCardError({ message: "Card details are incomplete" });
    }
  };

  const onCardElementChange = (event: $TSFixMe) => {
    setCardError(event.error);
  };

  return (
    <div className={styles.container}>
      {cardError?.message && <Alert variant="danger">{cardError.message}</Alert>}
      <CardElement
        className={styles.creditCard}
        id="card-element"
        options={CARD_ELEMENT_OPTIONS}
        onChange={onCardElementChange}
      />
      <div className={styles.actions}>
        <Button disabled={disabled || loading} variant="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          disabled={disabled || loading || !privileges[actions.MANAGE_SUBSCRIPTION]}
          onClick={onSaveClick}
        >
          {loading && <Spinner animation="border" className={appStyles.spinner} />}
          {loading ? "Saving" : "Save"}
        </Button>
      </div>
    </div>
  );
}

/**
 * Stripe's Elements wrapper for Stripe's CardElement
 */
export default function StripeCardInput({
  onCardSetupComplete,
  onBack,
  selectedSubscription,
  disabled,
}: $TSFixMe) {
  return (
    <Elements stripe={stripePromise}>
      <WithAccessControl
        component={CardInput}
        props={{
          onCardSetupComplete,
          onBack,
          selectedSubscription,
          disabled,
        }}
      />
    </Elements>
  );
}
