import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/slackBotPage.module.css";
import { Button } from "react-bootstrap";
import { SlackIcon, SlackBotScreenshot, RoundedArrowButton } from "utils/icons";
import { CONSTANTS } from "utils/constants";
import { ConfirmNextPageModal } from "./ConfirmNextPageModal";
import Label from "components/Label/Label";
import { connect } from "react-redux";
import { updateSlackBotRedirectionStatus } from "redux/userSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { Progress } from "components/Progress/Progress";

interface SlackbotPageState {
  accountType: string;
  showModal: boolean;
  showOnlyContinueButton: boolean;
}
interface SlackbotPageProps {
  tenant: any;
  setFirstSlackbotRedirectionFlag: (status: boolean) => void;
}

class SlackbotPage extends AyraaBaseComponent<SlackbotPageProps, SlackbotPageState> {
  constructor(props: SlackbotPageProps) {
    super(props);
    this.state = {
      accountType: "",
      showModal: false,
      showOnlyContinueButton: false,
    };
  }

  private handleAddToSlack() {
    //set this flag to true.
    this.props.setFirstSlackbotRedirectionFlag(true);
    const popup = window.open(
      `${CONSTANTS.SLACK_BOT_OAUTH_URL}?client_id=${process.env.REACT_APP_SLACK_BOT_CLIENT_ID}&scope=${CONSTANTS.SLACK_BOT_OAUTH_SCOPE}`,
      "Slack app",
      `width=${1200},height=${800},top=${100},left=${100}`
    );
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    popup.focus();
    setTimeout(() => {
      this.setState({
        showOnlyContinueButton: true,
      });
    }, 1000);
  }

  private checkIfSlackAlreadyAdded() {
    //check if the slack bot is already added. If yes redirect to get started page else show the modal

    if (this.props.tenant.slackBotAdded) {
      this.history.push("/getStarted");
    } else {
      this.setState({ showModal: true });
    }
  }

  private handleRedirectBack() {
    this.history.push("/install-extension");
  }

  private handleDirectRedirect() {
    this.history.push("/getStarted");
  }

  private handleCloseModal() {
    this.setState({ showModal: false });
  }

  public renderCore(): ReactNode {
    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.slackBotWorkspaceLabel}>
            <Label name="ui.onboarding.install.slack.page-title" />
          </div>
          <div className={styles.slackBotDescription}>
            <Label name="ui.onboarding.install.slack.page-subtext" />
          </div>
          {this.state.showOnlyContinueButton ? (
            <Button
              className={styles.continueButton}
              variant="secondary"
              onClick={this.handleDirectRedirect}
            >
              Continue
            </Button>
          ) : (
            <div className={styles.buttonContainer}>
              <Button
                className={styles.continueButton}
                variant="secondary"
                onClick={this.handleAddToSlack}
              >
                <SlackIcon />
                <Label name="ui.onboarding.install.slack.page.AddtoSlack.button" />
              </Button>
              <div onClick={this.checkIfSlackAlreadyAdded} className={styles.skipContinueButton}>
                Skip, Continue
              </div>
            </div>
          )}

          <div className={styles.navigationButtons}>
            <RoundedArrowButton onClick={this.handleRedirectBack} className={styles.back} />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <SlackBotScreenshot className={styles.image} />
        </div>
        <ConfirmNextPageModal
          show={this.state.showModal}
          handleSecondaryAction={this.handleCloseModal}
          handlePrimaryAction={this.handleDirectRedirect}
          bodyText="The Slack bot helps Ayraa capture knowledge from Slack & integrate with your workflow. Please confirm you have installed it."
        />
        <Progress noOfSteps={3} activeStep={2} className={styles.progress} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  setFirstSlackbotRedirectionFlag: (status: boolean) =>
    dispatch(updateSlackBotRedirectionStatus(status)),
});
const mapStateToProps = (state: any) => ({
  tenant: state.tenantManager.tenant,
});

export default connect(mapStateToProps, mapDispatchToProps)(SlackbotPage);
