import React from "react";
import { Button } from "react-bootstrap";
import { useAppSelector } from "redux/hooks";

export default function ExportEmployeeExcel() {
  //const { isAdmin }: $TSFixMe = useAppSelector((state) => state.userManager.userRole);
  const { employeeID }: $TSFixMe = useAppSelector((state) => state.userManager.user);
  const { tenantID }: $TSFixMe = useAppSelector((state) => state.tenantManager.tenant);

  /*if (!isAdmin) {
    return null;
  }*/

  return (
    <a
      href={`${process.env.REACT_APP_SERVER_URL}/employees/exportEmployeeData/${tenantID}/${employeeID}`}
      download
    >
      <Button>Export employee directory</Button>
    </a>
  );
}
