import CloseIcon from "components/CloseIcon/CloseIcon";
import styles from "./logowatermark.module.css";
import { useHistory } from "react-router";

export default function FadedLogoWatermark({ className = "", showCloseIcon = false }: { className?: string, showCloseIcon?: boolean }) {
  const history = useHistory();
  return (
    <div className={className}>
      {showCloseIcon && <CloseIcon className={styles.closeIcon} onClick={() => { history.goBack() }} /> }
      <video className={styles.logoWatermark} loop controls={false} autoPlay muted playsInline>
          <source src="https://website-assets.ayraa.io/" />
          Your browser does not support the video playback.
      </video>
    </div>
  );
}
