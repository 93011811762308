import { CONSTANTS, browserData } from "utils/constants";
import {
  checkIfExtensionIsInstalledAsync,
  createMapForAppAvailabilityList,
  createMapForConnectedAppsForUser,
  getAuthURL,
  getZendeskAuthURL,
  openPopup,
  popupFeatures,
} from "./utils";
import { browserName } from "react-device-detect";
import { IntegratedAppNamesEnum } from "./types";
import { AppAvailabilityInfo, ConnectedAppsInfo, Tenant, User } from "types/userTypes";
import { addDataToAppDataStore, getDataFromAppStore } from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";
import axios from "axios";
import { AyraaGlobalStateTracker } from "components/ayraaGlobalState/AyraaGlobalStateTracker";
import { TrackerSliceEnum } from "components/ayraaGlobalState/TrackerSliceEnum";

export const ayraaForSlackConnector = async (employeeID: number) => {
  await openPopup({
    features: popupFeatures,
    target: "Slack app",
    url: `${CONSTANTS.SLACK_BOT_OAUTH_URL}?client_id=${process.env.REACT_APP_SLACK_BOT_CLIENT_ID}&scope=${CONSTANTS.SLACK_BOT_OAUTH_SCOPE}&state=${employeeID}`,
  });

  const isConnected = await getConnectionStatusForAnApp(IntegratedAppNamesEnum.SLACK, employeeID);

  return { isConnected };
};

export const browserExtensionConnector = async () => {
  await openPopup({
    features: popupFeatures,
    target: "Extension",
    url: browserData[browserName].extensionLink,
  });

  const isConnected = await checkIfExtensionIsInstalledAsync(300000, 5000);

  return { isConnected };
};

export const genericAppConnector = async ({
  employeeId,
  tenantId,
  name,
}: {
  employeeId: number;
  tenantId: number;
  name: IntegratedAppNamesEnum;
}) => {
  const authURL = getAuthURL({
    appCode: name,
    tenantId,
    employeeId,
  });
  await openPopup({
    url: authURL,
    features: popupFeatures,
    target: `${name} app`,
  });

  const isConnected = await getConnectAppByNameForUser(name, employeeId);

  return { isConnected };
};

export const zendeskAppConnector = async ({
  employeeId,
  tenantId,
  name,
  subDomain,
}: {
  employeeId: number;
  tenantId: number;
  name: IntegratedAppNamesEnum;
  subDomain: string;
}) => {
  const authURL = getZendeskAuthURL({
    appCode: name,
    tenantId,
    employeeId,
    subDomain,
  });
  await openPopup({
    url: authURL,
    features: popupFeatures,
    target: `${name} app`,
  });

  const isConnected = await getConnectAppByNameForUser(name, employeeId);

  return { isConnected };
};

export const getAppAvailability = async ({ tenantID }: { tenantID: number }) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/app/getAppAvailability/${tenantID}`;
  const res = await axios.get<AppAvailabilityInfo[]>(url);

  addDataToAppDataStore(appConstants.APP_AVAILABILITY_INFO_LIST, res.data);
  createMapForAppAvailabilityList(res.data);

  return res.data;
};

export const getConnectAppsAndAvailabilityForUser = async ({
  employeeID,
}: {
  employeeID: number;
}) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/app-integration/getAllConnectedAppsForUser/${employeeID}`;
  const response = await axios.get<ConnectedAppsInfo[]>(url);

  addDataToAppDataStore(appConstants.CONNECTED_APPS_LIST_FOR_USER, response.data);
  createMapForConnectedAppsForUser(response.data);

  return response.data;
};

export const getConnectAppByNameForUser = async (
  name: IntegratedAppNamesEnum,
  employeeID: number
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/app-integration/getAllConnectedAppsForUser/${employeeID}`;
  const response = await axios.get<ConnectedAppsInfo[]>(url);

  const isConnected = response.data.find((e) => e.appCode === name)?.connected ?? false;
  return isConnected;
};

export const getConnectionStatusForAnApp = async (
  name: IntegratedAppNamesEnum,
  employeeID: number
): Promise<boolean> => {
  await getConnectAppsAndAvailabilityForUser({ employeeID });
  const connectedAppForUserMap: { [name in IntegratedAppNamesEnum]: ConnectedAppsInfo } =
    getDataFromAppStore(appConstants.CONNECTED_APPS_MAP_FOR_USER, undefined);
  return connectedAppForUserMap[name].connected;
};

export const getConnectionStatusForAnAppFromMap = (name: IntegratedAppNamesEnum) => {
  const connectedAppForUserMap: { [name in IntegratedAppNamesEnum]: ConnectedAppsInfo } =
    getDataFromAppStore(appConstants.CONNECTED_APPS_MAP_FOR_USER, undefined);
  return connectedAppForUserMap[name].connected;
};

export const genericAppDisconnect = async ({
  name,
  employeeID,
}: {
  name: IntegratedAppNamesEnum;
  employeeID: number;
}): Promise<{ isDisconnected: boolean }> => {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}/app-integration/disconnectApp/${name}/${employeeID}`;
    await axios.get(url);
    return { isDisconnected: true };
  } catch (error) {
    return { isDisconnected: false };
  }
};

export const connectToZendeskWithModal = async ({
  employeeId,
  tenantId,
}: {
  employeeId: number;
  tenantId: number;
}): Promise<{ isConnected: boolean }> => {
  return new Promise<{ isConnected: boolean }>((resolve) => {
    
    addDataToAppDataStore(appConstants.SHOW_ZENDESK_SUBDOMAIN_MODAL, true)
    addDataToAppDataStore(appConstants.ZENDESK_SUBDOMAIN_API_RESPONSE, undefined)
    AyraaGlobalStateTracker.publishToTrackerSlice(TrackerSliceEnum.ZENDESK_SUBDOMAIN_MODAL);
    const intervalId = setInterval(()=>{
      const connectionResult = getDataFromAppStore(appConstants.ZENDESK_SUBDOMAIN_API_RESPONSE);
      const isZendeskOpen = getDataFromAppStore(appConstants.SHOW_ZENDESK_SUBDOMAIN_MODAL);
      if(connectionResult){
        resolve(connectionResult);
        clearInterval(intervalId);
      } else {
        if(!isZendeskOpen){
          if(connectionResult) {
            resolve(connectionResult);
            clearInterval(intervalId);
          }
          else {
            resolve({isConnected: false});
            clearInterval(intervalId);
          }
        }
      }
    }, 1000);
  });
};
