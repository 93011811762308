import {TrackerSliceEnum} from "./TrackerSliceEnum";
import {Component} from "react";

export class AyraaGlobalStateTracker {
  private static trackerSliceSubscriberMap:Map<TrackerSliceEnum, Array<Component>> = new Map<TrackerSliceEnum, Array<Component>>();

  static publishToTrackerSlice(trackerSlice: TrackerSliceEnum): void {
    const subscriberList:Array<Component> = this.trackerSliceSubscriberMap.get(trackerSlice) || new Array();
    if (subscriberList.length >= 0) {
      for (let i = 0; i < subscriberList.length; i++) {
        let reactComponent:Component = subscriberList[i];
        reactComponent.forceUpdate();
      }
    }
  }

  static subscribeToTrackerSlice(trackerSlice: TrackerSliceEnum, reactComponent: Component): void {
    const subscriberList:Array<Component> = this.trackerSliceSubscriberMap.get(trackerSlice) || new Array();
    if (subscriberList.length == 0) {
        this.trackerSliceSubscriberMap.set(trackerSlice, subscriberList);
    }
    subscriberList.push(reactComponent);
  }

  static unsubscribeFromTrackerSlice(trackerSlice: TrackerSliceEnum, reactComponent: Component): void {
    const subscriberList:Array<Component> = this.trackerSliceSubscriberMap.get(trackerSlice) || new Array();
    if (subscriberList.length > 0) {
      const index = subscriberList.indexOf(reactComponent);
      if (index > -1) {
        subscriberList.splice(index, 1);
      }
    }
  }





}
