import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupsForTenant, updateEmployee } from "redux/tenantSlice";

export default forwardRef((props: $TSFixMe, ref: $TSFixMe) => {
  const { employeeGroups } = props.data;
  const { employeeID: currentUserEmployeeID }: $TSFixMe = useAppSelector(
    (state) => state.userManager.user
  );

  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [selectedGroups, setSelectedGroups] = useState<$TSFixMe[]>(employeeGroups || []);
  const [removedGroups, setRemovedGroups] = useState<$TSFixMe[]>([]);
  const [touched, setTouched] = useState(false);

  const dispatch = useAppDispatch();

  const {
    tenant: { tenantID },
    tenantGroups: { list, status },
  }: $TSFixMe = useAppSelector((state) => state.tenantManager);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedGroups;
      },
      isCancelAfterEnd: () => {
        return !submitClicked;
      },
      isPopup: () => {
        return true;
      },
    };
  });

  useEffect(() => {
    if (status === "idle") {
      dispatch(getGroupsForTenant({ tenantID }));
    }
  }, [tenantID, status]);

  if (status === "pending") {
    return (
      <>
        <Modal.Header>
          <Modal.Title>Update department</Modal.Title>
        </Modal.Header>
        <Modal.Body>Getting department...</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled>
            Close
          </Button>
          <Button disabled>Save</Button>
        </Modal.Footer>
      </>
    );
  }

  const onCloseClick = () => {
    props.api.stopEditing();
  };

  const onSubmit = async (e: $TSFixMe) => {
    try {
      e.preventDefault();
      setSubmitClicked(true);
      setLoading(true);
      const payload = {
        userData: {
          ...props.data,
          employeeGroups: selectedGroups,
          removedGroups,
        },
        updatedBy: currentUserEmployeeID,
      };
      await dispatch(updateEmployee(payload));
      setLoading(false);
      props.api.stopEditing();
    } catch (error) {
      setLoading(false);
    }
  };

  const onChange = (selectedGroup: $TSFixMe, checked: $TSFixMe) => {
    if (checked) {
      setSelectedGroups([...selectedGroups, selectedGroup]);
      setRemovedGroups(removedGroups.filter((e: $TSFixMe) => e !== selectedGroup.id));
    } else {
      // Only add unchecked groups if they are present in employeeGroups list
      const selectedUsersGroups = [...(employeeGroups || [])];
      const selectedGroupIndex = selectedUsersGroups.findIndex(
        (group: $TSFixMe) => group.id === selectedGroup.id
      );

      if (selectedGroupIndex > -1) {
        setRemovedGroups([selectedGroup.id, ...removedGroups]);
      }
      setSelectedGroups(selectedGroups.filter((group) => group.id !== selectedGroup.id));
    }
    setTouched(true);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Modal.Body>
        <Form.Group>
          {list.map((item: $TSFixMe) => {
            const checked = selectedGroups.findIndex((e: $TSFixMe) => e.id === item.id) > -1;
            const group = {
              id: item.id,
              name: item.name,
              status: item.status,
            };
            return (
              <Form.Check
                type="checkbox"
                checked={checked}
                label={item.name}
                onChange={() => onChange(group, !checked)}
                key={item.id}
              />
            );
          })}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          type="button"
          onClick={onCloseClick}
          disabled={loading}
        >
          Close
        </Button>
        <Button size="sm" type="submit" disabled={loading || !touched}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Form>
  );
});
