import classNames from "classnames";
import styles from "./styles/closeIcon.module.css";
import { CrossIcon } from "pages/Integration/assets";

export default function CloseIcon({
  onClick,
  className = "",
}: {
  onClick?: () => void;
  className?: string;
}) {
  const closeIconClassNames = classNames(
    styles.iconContainer,
    styles.iconContainerOnCreation,
    className
  );
  return (
    <div id="closeButton" className={closeIconClassNames} onClick={onClick}>
      <CrossIcon className={styles.icon} />
    </div>
  );
}
