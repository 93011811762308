import React, {useEffect, useRef} from 'react';
import {Label} from "../../Label";
import {ImportUser} from "../../../types/userTypes";
import {CONSTANTS} from "../../../utils/constants";
import {Buffer} from "buffer";
import { ReactComponent as GoogleIcon } from "images/login/google-icon.svg";
import { ReactComponent as MicrosoftIcon } from "images/login/microsoft-icon.svg";
import styles from './styles/linkWrapper.module.css';
import LoginButton from 'components/LoginButton/LoginButton';

export default function LinkWrapper(
  {
    link,
    setDisplayEmployees,
    setEmployees,
    serviceProvider,
    tenantID
  }: {
    link: string,
    setDisplayEmployees: React.Dispatch<React.SetStateAction<ImportUser[]>>,
    setEmployees: React.Dispatch<React.SetStateAction<ImportUser[]>>,
    serviceProvider: string,
    tenantID: number
  })
{

  let popup = useRef<Window>(null);

  const handlePopup = async (e: any) => {

    if(serviceProvider === 'MICROSOFT'){
      const stateParamPayload = {
        sp: serviceProvider,
        tenantId: tenantID,
        app: "ui",
      };
      const stateParam = Buffer.from(JSON.stringify(stateParamPayload)).toString('base64');
      const encodedRedirectUrl = encodeURIComponent(
        process.env.REACT_APP_CLIENT_URL + process.env.PUBLIC_URL + CONSTANTS.GC
      );
      const URL = `${CONSTANTS.REACT_APP_MICROSOFT_AUTH_URL}?client_id=${process.env.REACT_APP_MICROSOFT_AUTH_CLIENT_ID}
        &scope=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_SCOPE}&redirect_uri=${encodedRedirectUrl}&response_mode=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_RESPONSE_MODE}
        &response_type=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_RESPONSE_TYPE}&prompt=${CONSTANTS.REACT_APP_AUTH_PROMPT}&state=${stateParam}`;
      link = URL;
    }
    const popupWinWidth = 600;
    const popupWinHeight = 800;
    const left = Math.floor((window.screen.width - popupWinWidth) / 2);
    const top = Math.floor((window.screen.height - popupWinHeight) / 4);
    // @ts-ignore
    popup.current = window.open(
      link,
      "Microsoft Login",
      `width=${popupWinWidth},height=${popupWinHeight},top=${top},left=${left}`
    );
    popup.current?.focus();
  };

  useEffect(() => {
    async function handler(event: $TSFixMe) {
      const data = event.data;
      if (data && data.toString().indexOf("email") > 0) {
        try {
          setDisplayEmployees(JSON.parse(data));
          setEmployees(JSON.parse(data));
        } catch(e) {
          setDisplayEmployees([]);
          setEmployees([]);
        }
      }
      if (popup) {
        popup.current?.close();
      }
    }

    window.addEventListener("message", handler, false);
    // clean up
    return () => window.removeEventListener("message", handler);
  }, []);

  return (
    <div>
      <div className={styles.button} onClick={handlePopup}>
        <Label name="ui.employees.import.alternative-link-text" />
      </div>
      <LoginButton
        labelName={
          serviceProvider === "MICROSOFT"
            ? "ui.login.microsoft.signin-button-text"
            : "ui.login.google.signin-button-text"
        }
        icon={serviceProvider === "MICROSOFT"? MicrosoftIcon:GoogleIcon}
        onClick={handlePopup}
      />
    </div>
  );
}


