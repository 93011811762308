import {AsyncThunk, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {ImportAndSendInvitesRequest, ImportUser, ListUsersRequest} from "../types/userTypes";

let url: $TSFixMe = process.env.REACT_APP_SERVER_URL;
const hrisUrl = url.substring(0, url.lastIndexOf("/ui"));

export const listUsersFromProvider: AsyncThunk<{ employees: ImportUser[] }, ListUsersRequest, any> = createAsyncThunk("importEmployees/listUsersFromProvider",
    async (listUsersFromProviderRequest: ListUsersRequest) => {
    const response = await axios.post(`${url}/employees/listUsersFromProvider`, listUsersFromProviderRequest);
    return response.data;
});

export const importAndSendInvites = createAsyncThunk("importEmployees/importAndSendInvites",
    async (importAndSendInvitesRequest: ImportAndSendInvitesRequest , {rejectWithValue}) => {
       try {
        const response = await axios.post(`${url}/employees/importAndSendInvites`, importAndSendInvitesRequest);
        return response.data;
       }
       catch(e : any) {
        return {
          error : "Something went wrong"
        }
       }
    });

export const getAvailableHRIS = createAsyncThunk("getAvailableHRIS", async () => {
  const response = await axios.get(`${hrisUrl}/hris/getAllHRISProviders`);
  return response.data;
});

export const saveHRISData = createAsyncThunk(
  "saveHRISData",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'apiKey' does not exist on type 'void'.
  async ({ apiKey, employeeId, tenantId, webHookAPIKey }) => {
    const response = await axios.post(`${hrisUrl}/saveHRISData`, {
      apiKey,
      employeeId,
      tenantId,
      webHookAPIKey,
    });
    return response.data;
  }
);

export const customerConfig = createAsyncThunk(
  "importEmployees/customerConfig",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'customerName' does not exist on type 'vo... Remove this comment to see the full error message
  async ({ customerName, customerEmail }) => {
    const response = await axios.post(`${url}/customers/config`, {
      customerName,
      customerEmail,
    });
    return response.data;
  }
);

export const getToken = createAsyncThunk(
  "importEmployees/getToken",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'customerAccountId' does not exist on typ... Remove this comment to see the full error message
  async ({ customerAccountId }) => {
    const response = await axios.get(`${url}/customers/${customerAccountId}/jwt_token`);
    return response.data;
  }
);

export const importEmployeeDataFromHRIs = createAsyncThunk(
  "importEmployees/importEmployeeDataFromHRIs",
  async ({
    hrisProviderId,
    employeeId,
    tenantId,
    apiKey,
    webHookAPIKey,
    clientId,
    clientSecret,
    tenantHRISCompanyUrl,
    companyId,
    keyFile,
    keystorePassword,
  }: $TSFixMe) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              hrisProviderId,
              employeeId,
              tenantId,
              apiKey,
              webHookAPIKey,
              clientId,
              clientSecret,
              tenantHRISCompanyUrl,
              companyId,
              keystorePassword,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
      if (keyFile) {
        bodyFormData.append("keyFile", keyFile);
      }
      const response = await axios.post(
        `${url}/employees/importEmployeeDataFromHRIS`,
        bodyFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response.data;
    } catch (error) {
      return { status: "error" };
    }
  }
);

export const getCompaniesForHRISAdmin = createAsyncThunk(
  "importEmployees/getCompaniesForHRISAdmin",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenantId' does not exist on type 'void'.
  async ({ tenantId }) => {
    const response = await axios.get(`${hrisUrl}/hris/getCompaniesForHRISAdmin/${tenantId}`);
    return response.data;
  }
);

export const mergeDevLinkToken = createAsyncThunk(
  "importEmployees/mergeDevLinkToken",
  async ({ hris_provider_id }: $TSFixMe) => {
    const response = await axios.get(`${url}/merge-dev/link-token/${hris_provider_id}`);
    return response.data;
  }
);

export const mergeDevEmployeeImport = createAsyncThunk(
  "importEmployees/mergeDevEmployeeImport",
  async ({ public_token, hrims_provider_id }: $TSFixMe) => {
    try {
      const response = await axios.post(`${url}/merge-dev/employees/import`, {
        public_token,
        hrims_provider_id,
      });
      return response.data;
    } catch (error) {
      return { status: "error" };
    }
  }
);

export const importEmployeeData = createAsyncThunk(
  "importEmployees/importEmployeeData",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenantId' does not exist on type 'void'.
  async ({ tenantId, adminUserId, customerAccountId }) => {
    const response = await axios.post(`${url}/customers/importEmployeeData`, {
      tenantId,
      adminUserId,
      customerAccountId,
    });
    return response.data;
  }
);

export const importEmployeeDataFromServiceProvider = createAsyncThunk(
  "importEmployees/importEmployeeDataFromServiceProvider",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formData' does not exist on type 'void'.
  async ({ formData }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/employees/importEmployeeData`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return { status: true, message: res.data };
    } catch (error: $TSFixMe) {
      if (error) {
        return { status: false, message: error.response.data.message };
      }
    }
  }
);
export const getCustomerImportStatus = createAsyncThunk(
  "importEmployees/customerImportStatus",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'customerAccountId' does not exist on typ... Remove this comment to see the full error message
  async ({ customerAccountId, packageId }) => {
    const response = await axios.get(
      `${url}/customers/${customerAccountId}/imports/${packageId}/status`
    );
    return response.data;
  }
);

export const getHRISWebHookURI = createAsyncThunk(
  "getHRISWebHookURI",
  async ({ hrisProviderId, tenantId }: $TSFixMe) => {
    const response = await axios.get(`${url}/getHRISWebHookURI/${hrisProviderId}/${tenantId}`);
    return response.data;
  }
);

export const importEmployeesSlice = createSlice({
  name: "importEmployeesSlice",
  //Initial User State
  initialState: {
    data: null,
    status: "idle",
    error: null,
    importEmployeeDataStatus: "idle",
    importEmpDataFromServiceProvider: "idle",
    listAvailableHRIS: [],
    companiesForHRISAdmin: [],
    companiesForHRISAdminStatus: "idle",
    importEmployeeDataFromHRIsStatus: {},
  },

  //Reducers & Actions
  reducers: {},
  extraReducers: {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAvailableHRIS.fulfilled]: (state, action) => {
      state.listAvailableHRIS = action.payload;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [customerConfig.fulfilled]: (state, action) => {
      state.status = "fulfilled";
      state.data = action.payload;
    },
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [importAndSendInvites.pending]: (state) => {
          state.importEmpDataFromServiceProvider = "pending";
      },
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [importAndSendInvites.fulfilled]: (state) => {
          state.importEmpDataFromServiceProvider = "fullfilled";
      },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeData.pending]: (state) => {
      state.importEmployeeDataStatus = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeData.fulfilled]: (state) => {
      state.importEmployeeDataStatus = "fulfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeDataFromServiceProvider.fulfilled]: (state) => {
      state.importEmpDataFromServiceProvider = "fullfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeDataFromServiceProvider.pending]: (state) => {
      state.importEmpDataFromServiceProvider = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeDataFromServiceProvider.rejected]: (state) => {
      state.importEmpDataFromServiceProvider = "rejected";
    },

    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeDataFromHRIs.fulfilled]: (state, action) => {
      state.importEmployeeDataFromHRIsStatus = action.payload;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeDataFromHRIs.pending]: (state) => {
      state.importEmployeeDataFromHRIsStatus = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [importEmployeeDataFromHRIs.rejected]: (state) => {
      state.importEmployeeDataFromHRIsStatus = "rejected";
    },

    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getCompaniesForHRISAdmin.fulfilled]: (state, action) => {
      state.companiesForHRISAdmin = action.payload;
      state.companiesForHRISAdminStatus = "fulfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getCompaniesForHRISAdmin.pending]: (state) => {
      state.companiesForHRISAdminStatus = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getCompaniesForHRISAdmin.rejected]: (state) => {
      state.companiesForHRISAdminStatus = "rejected";
    },
  },
});

export default importEmployeesSlice.reducer;
