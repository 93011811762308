import {AyraaBaseComponent} from "../../../components/ayraaFramework/AyraaBaseComponent";
import React, {ReactNode} from "react";
import {Col, Container, Row} from "react-bootstrap";
import styles from "./desktoploginpage.module.css";
import {generateAppLoginToken} from "../../../dataSender/auth/AuthDataSender";
import Button from "react-bootstrap/Button";

export class DesktopLoginPage extends AyraaBaseComponent<any, any> {

    constructor(props: any) {
        super(props);
    }
    renderCore(): ReactNode {
        return (
            <Container fluid className={styles.container}>
                <Row className={styles.row}>
                    <Col className={styles.column}>
                        <div className={styles.header}>Welcome to <span>Ayraa</span></div>
                        <div className={styles.description}>Sign in with your browser</div>
                        <Button onClick={this.loginClickHandler} className={styles.loginButton}>
                            Login to Ayraa
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    private async loginClickHandler() {
        const token = await generateAppLoginToken();
        (window as any).openLoginPageInBrowser(token);
    }

}