import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/switch.module.css";

interface SwitchProps {
  className?: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  invertSwitch?: boolean;
}

export class Switch extends AyraaBaseComponent<SwitchProps, {}> {
  constructor(props: SwitchProps) {
    super(props);
    this.state = {};
  }

  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, {
      [styles.checked]: this.props.checked,
      [styles.invertSwitch]: this.props.invertSwitch,
    });

    return (
      <div className={containerClassNames}>
        <input
          type="checkbox"
          className={styles.checkboxInput}
          onChange={this.props.onChange}
          checked={this.props.checked}
        />
        <span className={styles.slider} />
      </div>
    );
  }
}
