import {AyraaBaseComponent} from "../../../components/ayraaFramework/AyraaBaseComponent";
import React, {ReactNode} from "react";
import {Col, Container, Row} from "react-bootstrap";
import styles from "./logoutpage.module.css";
import Button from "react-bootstrap/Button";
import {pulseLogout, uiLogout} from "../../../dataSender/auth/AuthDataSender";

export class LogoutPage extends AyraaBaseComponent<any, any> {

    constructor(props: any) {
        super(props);
    }

    renderCore(): ReactNode {
        return (
            <Container fluid className={styles.container}>
                <Row className={styles.row}>
                    <Col className={styles.column}>
                        <div className={styles.header}>
                            <span>Logout from Ayraa in your browser.</span>
                        </div>
                        <Button onClick={this.logUserOut}>
                            Logout from Ayraa
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    private async logUserOut() {
        try {
            await Promise.all([uiLogout(), pulseLogout()]);
        } finally {
            if (process.env.REACT_APP_CLIENT_URL) {
                window.location.href = process.env.REACT_APP_CLIENT_URL;
            }
        }
    }

}