import React from "react";
import { useHistory } from "react-router";
import { Spinner } from "react-bootstrap";
import { useGetSubscriptionPackage } from "hooks/useGetSubscriptionPackage";
import SubscriptionItem from "./SubscriptionItem";
import styles from "./subscription.module.css";
import { WithAccessControl } from "utils/AccessControl";
import { useGetPrivileges } from "utils/AccessControl/useGetPrivileges";
import { useAppSelector } from "redux/hooks";
import {actions} from "../../utils/AccessControl/actions";

const getAvailablePackages = (
  subscriptionPackages: $TSFixMe,
  subscribedPackage: $TSFixMe,
  upcomingSubscription: $TSFixMe
) => {
  return subscriptionPackages.filter((e: $TSFixMe) => {
    const { subscriptionPackageID: availablePackageID } = e;
    const { subscriptionPackageID: subscribedPackageID } =
      subscribedPackage?.subscriptionPackageDetails || { subscriptionPackageID: null };
    const { subscriptionPackageID: upcomingSubscriptionID } =
      upcomingSubscription?.subscriptionPackageDetails || { subscriptionPackageID: null };

    return (
      availablePackageID !== subscribedPackageID && availablePackageID !== upcomingSubscriptionID
    );
  });
};

function ManageSubscription({ requiredPrivileges, privileges }: $TSFixMe) {
  const { user } = useAppSelector((state) => state.userManager);
  const { loading } = useGetSubscriptionPackage();
  const { subscribedPackage, upcomingSubscription } = useAppSelector(
    (state) => state.subscriptionManager.subscribedPackageState
  );
  const subscriptionPackages = useAppSelector(
    (state) => state.subscriptionManager.subscriptionPackagesState.subscriptionPackages
  );
  const history = useHistory();

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [actions.MANAGE_SUBSCRIPTION],
  });

  if (!privileges[actions.MANAGE_SUBSCRIPTION]) {
    return (
      <div className={styles.container}>You don't have sufficient privilege to view this page</div>
    );
  }

  if (loading) {
    return (
      <div className="app loader">
        <Spinner animation="border" />
        <span>Loading...</span>
      </div>
    );
  }

  if (!user || !subscribedPackage) {
    history.push("/");
  }

  const availablePackages = getAvailablePackages(
    subscriptionPackages,
    subscribedPackage,
    upcomingSubscription
  );

  return (
    <div className={styles.container}>
      <h4>Manage subscription</h4>
      <div className={styles.subscriptionDetailsContainer}>
        <SubscriptionItem
          subscription={subscribedPackage}
          title={<div className={styles.activeSubscriptionText}>Subscribed package</div>}
        />

        {upcomingSubscription && (
          <>
            <div className={styles.divider} />
            <SubscriptionItem
              subscription={upcomingSubscription}
              title={<div className={styles.upcomingSubcriptionText}>Upcoming subscription</div>}
              isUpcoming
            />
          </>
        )}
        <div className={styles.divider} />
        <div>
          <h5>
            <div className={styles.availablePackagesText}>Available packages</div>
          </h5>
          <div className={styles.availablePackagesContainer}>
            {availablePackages.map((item: $TSFixMe) => {
              return (
                <SubscriptionItem
                  subscription={{ subscriptionPackageDetails: item }}
                  key={item.subscriptionPackageID}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ManageSubscriptionWithAccessControl() {
  return <WithAccessControl component={ManageSubscription} />;
}
