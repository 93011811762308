import {
  SingleUserIcon,
  MultiUserIcon,
  ChromeIcon,
  FirefoxIcon,
  EdgeIcon,
  SafariIcon,
} from "./icons";

import ChromeIconSrc from "images/extension-screen/chrome.svg";
import FirefoxIconSrc from "images/extension-screen/firefox.svg";
import EdgeIconSrc from "images/extension-screen/edge.svg";
import SafariIconSrc from "images/extension-screen/safari.svg";

export const DEFAULT_BROWSER_NAME = "Chrome";

export const roles = {
  Admin: "Admin",
  Member: "Member",
  SuperAdmin: "SuperAdmin",
  Owner: "Owner",
};

export const checkFileExtension = (fileName: $TSFixMe, validExtensions = []) => {
  if (validExtensions.length === 0) {
    return true;
  }
  const fileExtension = fileName.substring(fileName.lastIndexOf("."));
  return validExtensions.findIndex((e) => e === fileExtension) > -1;
};

export const subscriptionTypes = {
  PAID: "PAID",
  FREE: "FREE",
};
export const accountTypes: {
  [key: string]: {
    icon: any;
    title: string;
    description: string;
  };
} = {
  personal: {
    icon: SingleUserIcon,
    title: "For myself",
    description: "As a personal assistant at work",
  },
  company: {
    icon: MultiUserIcon,
    title: "For the company",
    description: "Roll out to all of my employees or team members at work",
  },
};

export const validateSpace = (value: $TSFixMe) => {
  if (!!value.trim()) {
    return true;
  } else {
    return "Cannot contain space";
  }
};

export const regexPatterns = {
  email: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
  labelParameter: /{\d+}/g,
  orgNamePattern: /^(?!\-)^(?!\s)[0-9a-zA-Z(\-)(\s)]+[^\-]$/, //this will admit letters, numbers and dashes
};

export const CONSTANTS = {
  DASHBOARD: "/dashboard",
  GC: "/gc",
  AUTH_CODE_FLOW_URI: "/authCodeFlow",
  REACT_APP_MICROSOFT_AUTH_URL: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
  //Google auth scopes will have only contacts scope during login, rest we'll get during the Admin login
  REACT_APP_GOOGLE_AUTH_SCOPE:
    "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
  REACT_APP_MICROSOFT_AUTH_SCOPE: "User.Read openid profile offline_access User.ReadBasic.All",
  REACT_APP_MICROSOFT_AUTH_RESPONSE_MODE: "query",
  REACT_APP_MICROSOFT_AUTH_RESPONSE_TYPE: "code",
  REACT_APP_GOOGLE_AUTH_URL: "https://accounts.google.com/o/oauth2/auth",
  REACT_APP_GOOGLE_ACCESS_TYPE: "offline",
  REACT_APP_GOOGLE_AUTH_RESPONSE_TYPE: "code",
  REACT_APP_AUTH_PROMPT: "select_account",
  REACT_PULSE_APP_CONTEXT: "/pulse",
  REACT_SECURE_APP_CONTEXT: "/secure",
  GOOGLE: "GOOGLE",
  MICROSOFT: "MICROSOFT",
  UI_ALTERNATE_LOGIN_ACTION: "/alternateLogin?sp=",
  TENANT_ID_PARAM: "&tenantId=",
  APP_ID_PARAM: "&app=ui",
  UI_LOGIN_INIT: "UI_LOGIN_INIT",
  REACT_APP_GOOGLE_AUTH_PROMPT: "&prompt=select_account",
  SLACK_BOT_OAUTH_URL: "https://slack.com/oauth/v2/authorize",
  SLACK_BOT_OAUTH_SCOPE:
    "channels:history,channels:read,chat:write,chat:write.public,commands,groups:history,groups:read,im:history,im:read,mpim:history,mpim:read,team:read,users.profile:read,users:read.email,users:read&user_scope=channels:history,channels:read,chat:write,groups:history,groups:read,mpim:history,mpim:read,im:history,im:read,search:read,team:read,users.profile:read,users:read.email,users:read",
};

export const getLabel = (label: string, name: string, args?: any[]) => {
  if (label) {
    const isParameterised = label.match(regexPatterns.labelParameter);
    if (isParameterised && isParameterised.length && args && args.length > 0) {
      return label.replace(regexPatterns.labelParameter, (text: string) => {
        const index = Number(text[1]);
        return args[index];
      });
    }
    return label;
  }
  return name;
};

export const browserData: any = {
  Chrome: {
    icon: ChromeIcon,
    iconSrc: ChromeIconSrc,
    extensionLink: process.env.REACT_APP_EXTENSION_CHROME_LINK,
    imageLink: process.env.REACT_APP_EXTENSION_CHROME_IMAGE_LINK, // dev id for testing purpose
  },
  Firefox: {
    icon: FirefoxIcon,
    iconSrc: FirefoxIconSrc,
    extensionLink: process.env.REACT_APP_EXTENSION_FIREFOX_LINK,
    imageLink: process.env.REACT_APP_EXTENSION_FIREFOX_IMAGE_LINK,
  },
  Edge: {
    icon: EdgeIcon,
    iconSrc: EdgeIconSrc,
    extensionLink: process.env.REACT_APP_EXTENSION_EDGE_LINK,
    imageLink: process.env.REACT_APP_EXTENSION_EDGE_IMAGE_LINK,
  },
  Safari: {
    icon: SafariIcon,
    iconSrc: SafariIconSrc,
    extensionLink: process.env.REACT_APP_EXTENSION_SAFARI_LINK,
    imageLink: "icons/16.png",
  },
};
