import { useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "../manageEmployeesComponent.module.css";

const render = ({ list, ...rest }: $TSFixMe) => {
  return (
    <Popover {...rest}>
      <Popover.Header as="h5">Departments</Popover.Header>
      <Popover.Body>{list.map((e: $TSFixMe) => e.name).join(", ")}</Popover.Body>
    </Popover>
  );
};

export default function GroupRenderer({ data }: $TSFixMe) {
  const { employeeGroups = [] } = data;
  const target = useRef(null);
  if (!employeeGroups || employeeGroups.length === 0) {
    return (
      <div className={styles.noGroupText}>
        <em>Assign departments</em>
      </div>
    );
  }
  if (employeeGroups.length === 1) {
    return <div>{employeeGroups[0].name}</div>;
  }
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={(props) => render({ list: employeeGroups, ...props })}
      >
        <div ref={target}>{employeeGroups[0].name}, ...</div>
      </OverlayTrigger>
    </>
  );
}
