import {AyraaBaseComponent} from "../../../components/ayraaFramework/AyraaBaseComponent";
import React, {ReactNode} from "react";
import styles from "./downloadmobileapp.module.css";
import {Label} from "../../../components/Label";
import {ArchSpec, OSSpec} from "../../../types/downloadTypes";
import {getUserSystemInfo, osMap} from "../../../utils/browserUtils";
import {Button} from "react-bootstrap";

type DownloadMobileAppState = {
    userSystemInfo?: {
        os:  OSSpec | {name: string | undefined, path: undefined},
        arch:  ArchSpec | {name: string | undefined, path: undefined}
    }
}

export class DownloadMobileApp extends AyraaBaseComponent<any, DownloadMobileAppState> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

  renderCore(): ReactNode {
    return (
      <div className={styles.iosContainer}>
        <Label name="ui.mobile-app.downloadmobileapp.text" />
        {/* Mobile apps coming soon. Until then, please visit{" "}
        <a target="_blank" href="http://ayraa.io/downloads">
          ayraa.io/downloads
        </a>{" "}
        on Mac or Windows to try our Desktop App. */}
      </div>
    );
    // const isAndroid = this.state.userSystemInfo?.os === osMap.ANDROID;
    // if (isAndroid) {
    //     return (
    //         <div className={styles.androidContainer}>
    //             <div className={styles.description}><Label name="ui.mobile-app.download.android" /></div>
    //             <Button className={styles.downloadButton} variant="primary" onClick={this.handleDownloadClick}>
    //                 <Label name="ui.mobile-app.download.android.button" />
    //             </Button>
    //         </div>
    //     );
    // } else {
    //     return (
    //         <div className={styles.iosContainer}>
    //             <Label name="ui.mobile-app.download.ios" />
    //         </div>
    //     );
    // }
  }

    private doSideEffectForUserSystemInfo() {
        if (this.shouldExecuteSideEffect(this.doSideEffectForUserSystemInfo, [])) {
            getUserSystemInfo().then((userSystemInfo) => this.setState({ userSystemInfo }));
        }
    }

    private handleDownloadClick() {
        window.open(process.env.REACT_APP_ANDROID_APP_DOWNLOAD_LINK);
    }

}