import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/connectedApps.module.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as Arrow } from "../assets/arrow.svg";

interface ConnectedAppsProps {
  className?: string;
  list: { icon: any; email: string; connectedOn: number }[];
}

export class ConnectedApps extends AyraaBaseComponent<ConnectedAppsProps, {}> {
  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    return (
      <div className={containerClassNames}>
        {this.props.list.map((item) => {
          const Icon = item.icon;
          return (
            <OverlayTrigger
              key={item.icon}
              delay={300}
              overlay={(props) => (
                <Popover {...props} className={styles.popoverContainer}>
                  <div className={styles.popoverContent}>
                    Connected as:
                    <br />
                    <span className="b4 bold">{item.email}</span>
                    <br />
                    As on: {new Intl.DateTimeFormat("en-US").format(item.connectedOn)}
                  </div>
                  <Arrow className={styles.arrow} />
                </Popover>
              )}
            >
              <div className={styles.iconContainer}>
                <Icon className={styles.icon} />
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    );
  }
}
