import React from "react";
import { Image } from "react-bootstrap";
import styles from "../manageEmployees.module.css";

export default function NameRenderer({
  data
}: $TSFixMe) {
  const { photoUrl = null } = data;
  return (
    <div className={styles.imageRendererContainer}>
      <Image width={36} height={36} src={photoUrl} roundedCircle /> <span className={styles.name}>{data.employeeFirstName + " " + data.employeeLastName}</span>
    </div>
  );
}
