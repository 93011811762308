import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import ModalWithNotch from "pages/v2/ModalWithNotch/ModalWithNotch";
import { ReactNode } from "react";
import styles from "./styles/appCardDetailsModal.module.css";
import classNames from "classnames";

interface AppCardDetailsModalProps {
  className?: string;
  show: boolean;
  onHide: () => void;
  dialogClassName?: string;
  contentClassName?: string;
}

export class AppCardDetailsModal extends AyraaBaseComponent<AppCardDetailsModalProps, {}> {
  public renderCore(): ReactNode {
    const dialogClassNames = classNames(this.props.dialogClassName, styles.modalDialog);
    const contentClassNames = classNames(this.props.contentClassName, styles.modalContent);
    return (
      <ModalWithNotch
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        dialogClassName={dialogClassNames}
        contentClassName={contentClassNames}
        notchIconProps={{
          className: styles.notchIcon,
          onClick: this.props.onHide,
        }}
      >
        {this.props.children}
      </ModalWithNotch>
    );
  }
}
