import styles from "./manageSettings.module.css"
import {Image} from "react-bootstrap";
import workspaceSettingsLogo from "images/settings/workspace-settings.svg";
import pulseSettingsLogo from "images/settings/pulse-settings.svg";
import secureSettingsLogo from "images/settings/secure-settings.svg";
import {useHistory} from "react-router";
import {WithAccessControl} from "../../../utils/AccessControl";
import {useGetPrivileges} from "../../../utils/AccessControl/useGetPrivileges";
import {actions} from "../../../utils/AccessControl/actions";

function ManageSettings({
  requiredPrivileges,
  privileges
}: $TSFixMe) {

  const history = useHistory();

  const openWorkspaceSettings = () => {
    history.push("/workspaceSettings");
  }

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [
      actions.UPDATE_WORKSPACE_SETTINGS,
    ],
  });

  return (<>
    <span className={styles.title}>Manage Settings</span>
    {(privileges[actions.UPDATE_WORKSPACE_SETTINGS]) &&
        <div className={styles.workspaceSettings}>
            <Image src={workspaceSettingsLogo} className={styles.img}/>
            <div className={styles.workspaceDesc} onClick={openWorkspaceSettings}>
                <h5>Workspace Settings</h5>
                <span style={{fontSize: "14px"}}>Manage your workspace settings</span>
            </div>
        </div>
    }
    <div className={styles.pulseSettings}>
      <Image src={pulseSettingsLogo} className={styles.img}/>
      <div className={styles.settingsDesc}>
        <h5>Pulse Settings</h5>
        <span style={{fontSize: "14px"}}>Manage your pulse settings</span>
      </div>
    </div>

    <div className={styles.secureSettings}>
      <Image src={secureSettingsLogo} className={styles.img}/>
      <div className={styles.settingsDesc}>
        <h5>Secure Settings</h5>
        <span style={{fontSize: "14px"}}>Manage your secure settings</span>
      </div>
    </div>
  </>);
}

export default function ManageSettingsWithAccessControl() {
  return <WithAccessControl component={ManageSettings}/>;
}