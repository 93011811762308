import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getUserDetailsFromAuthCode } from "redux/authSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  resetSubscribedPackageStateStatus,
} from "redux/subscriptionSlice";
import { fetchTenant, setTenant } from "redux/tenantSlice";
import { getUsersRole, setUser, setUserRole } from "redux/userSlice";
import { CONSTANTS, regexPatterns } from "utils/constants";
import { ReactComponent as MicrosoftIcon } from "images/login/microsoft-icon.svg";
import ToastComponent from "../../../pages/v2/Toast/ToastComponent";
import { msalConfig } from "../../Auth/microsoftAuthConfig";
import { Label } from "../../Label";
import {getDataFromAppStore} from "../../../dataStore/appDataStore";
import {appConstants} from "../../../utils/appConstants";
import LoginButton from "components/LoginButton/LoginButton";
import { joinWorkspaceOrg } from "redux/workspaceSlice";
import {getUserDeviceType} from "../../../utils/browserUtils";
import {isMobileApp} from "../../../utils/mobileUtils";
import { triggerSubscriptionRequirements } from "utils/subscriptionUtils";
import { updateEmployeeWorkspaceStatus } from "dataFetcher/Workspace/workspaceDataFetcher";
import { setUserAccountStatus } from "redux/userSlice";
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ auth: { clientId: string | und... Remove this comment to see the full error message
const msalInstance = new PublicClientApplication(msalConfig);

function Login({ labelName }: { labelName: string }) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [userDetailsFromAuth, setUserDetailsFromAuthCode] = useState<any>(null);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);

  const {
    status,
    errorCode,
    error: failedTokenValidationError,
  } = useAppSelector((state) => state.authManager.getUserDetailsFromAuthCode);

  useEffect(() => {
    if (status === "fulfilled" && userDetailsFromAuth) {
      (async function () {
        try {
          if (userDetailsFromAuth) {
            const tenantID: number = userDetailsFromAuth.tenant.tenantID;
            const fetchedTenant: any = await dispatch(fetchTenant(tenantID)).unwrap();
            const { userRole, ...rest }: any = userDetailsFromAuth;
            dispatch(setUser(rest));
            dispatch(setTenant(fetchedTenant));
            dispatch(setUserRole(getUsersRole(userRole)));
            dispatch(resetSubscribedPackageStateStatus());
            
            if (!fetchedTenant.workspaceDetails && !rest.personalAccount && !(getUserDeviceType() === 'mobile' && !isMobileApp())) {
              //first time user registration. show him the account Selection screen.
              try {
                await triggerSubscriptionRequirements(fetchedTenant);
                await updateEmployeeWorkspaceStatus({
                      userData : {personalAccount : true,
                                tenantID : fetchedTenant.tenantID,
                                employeeID : userDetailsFromAuth.employeeID },
                      updatedBy : userDetailsFromAuth.employeeID
                })
                dispatch(setUserAccountStatus(true));
                history.push(`/integration`);
              }
              catch(e) {
                console.log("Something went Wrong!");
                console.log(e);
              }
            } else if (userDetailsFromAuth.status === "Invited" && !(getUserDeviceType() === 'mobile' && !isMobileApp())) {
              //currently hasOrgs is commented, thus same action is done as on invited.
              await dispatch(joinWorkspaceOrg({tenantId: tenantID, employeeId: userDetailsFromAuth.employeeID}))
              history.push("/");
            }else if(getUserDeviceType() === "mobile"){
              history.push(`/`);
             } else {
              const { search } = location;
              history.push(`/${search}`);
            }
          }
        } catch (error) {
          console.log("Something went wrong");
          console.log(error);
        }
      })();
    }


    if (status === "rejected") {
      if (errorCode === "401" && failedTokenValidationError) {
        //@ts-ignore
        const id = failedTokenValidationError.match(regexPatterns.email);
        setErrorMessage(
          <div className="shade-two b3 medium">
            <Label name="ui.login.error.token.invalid" args={[id]} />
          </div>
        );
        setShowErrorToast(true);
      } else {
        //if rejected.
        history.push("/login")
      }
    }
  }, [status, errorCode, userDetailsFromAuth, dispatch, history, location]);

  useEffect(() => {
    let authCodeReceived = false;
    async function receiveMessage(event: $TSFixMe) {
      if (event.origin === process.env.REACT_APP_CLIENT_URL && !authCodeReceived && (getDataFromAppStore(appConstants.SERVICE_PROVIDER_PARAM_LOGIN) !== CONSTANTS.GOOGLE)) {
        //once authCode is recepted,
        //check if object & isAuthCode.
        const receptedObject = event.data;
        if (receptedObject?.isAuthCode) {
          //if authcode, triggerlogin.
          try {
            authCodeReceived = true;

            // Get referrer from local storage
            const referrer = localStorage.getItem('originalReferrer') || '';

            const res: $TSFixMe = await dispatch(
                getUserDetailsFromAuthCode({
                  serviceProvider: "MICROSOFT",
                  authCode: event.data.authCode,
                  referrer,
                })
            ).unwrap();
            setUserDetailsFromAuthCode(res.data);

            // Clear the referrer from local storage if successful
            localStorage.removeItem('originalReferrer');
          } catch (error) {
            console.log("error is faced");
            console.log(error);
          }
        }
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [dispatch, history]);

  const handlePopup = async () => {
    const encodedRedirectUrl = encodeURIComponent(
      process.env.REACT_APP_CLIENT_URL + process.env.PUBLIC_URL + CONSTANTS.GC
    );
    const URL = `${CONSTANTS.REACT_APP_MICROSOFT_AUTH_URL}?client_id=${process.env.REACT_APP_MICROSOFT_AUTH_CLIENT_ID}
        &scope=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_SCOPE}&redirect_uri=${encodedRedirectUrl}&response_mode=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_RESPONSE_MODE}
        &response_type=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_RESPONSE_TYPE}&prompt=${CONSTANTS.REACT_APP_AUTH_PROMPT}`;
    const popupWinWidth = 600;
    const popupWinHeight = 800;
    const left = Math.floor((window.screen.width - popupWinWidth) / 2);
    const top = Math.floor((window.screen.height - popupWinHeight) / 4);
    const popup = window.open(
      URL,
      "Microsoft Login",
      `width=${popupWinWidth},height=${popupWinHeight},top=${top},left=${left}`
    );
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    popup.focus();
  };

  return (
    <>
      <LoginButton
        labelName={labelName}
        icon={MicrosoftIcon}
        onClick={handlePopup}
      />
      <ToastComponent
        type="warning"
        text={errorMessage}
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        showCloseIcon={false}
      />
    </>
  );
}

export default function LoginMS({ labelName }: { labelName: string }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Login labelName={labelName} />
    </MsalProvider>
  );
}
