const OAuthHRISuccessPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  let response = {
    status,
    isHRIOAuthCode: true,
  };
  const parent = window.opener;
  parent.postMessage(response, process.env.REACT_APP_CLIENT_URL);
  window.close();
  return <div>Success!</div>;
};

export default OAuthHRISuccessPage;
