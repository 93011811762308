import axios, {AxiosResponse} from "axios";
import {getDataFromAppStore} from "../../dataStore/appDataStore";
import {appConstants} from "../../utils/appConstants";

interface CreateLabelResponse {
  id: number;
  label: {
    id: number;
    labelName: string;
  };
  language: {
    default: boolean;
    id: number;
    isoLanguageCode: string;
    languageName: string;
  };
  page: {
    id: number;
    pageName: string;
  };
  text: string;
}

export async function createLabel({ name, value }:
                                  { name: string; value: string }) {
    const response = await axios.post<any, AxiosResponse<CreateLabelResponse>>(
      process.env.REACT_APP_PULSE_BACKEND_URL + "/labels/createLabel",
      {
        labelName: name,
        text: value,
        pageId: 1,
        languageId: 1,
      }
    );

    const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);
    labels[name] = value;

    return response.data;
}

export async function updateLabels({ name, value, languageId }:
                          { name: string; value: string; languageId: number }) {
    await axios.post(process.env.REACT_APP_PULSE_BACKEND_URL + `/labels/updateLabel/${languageId}`, {
      labelName: name,
      labelText: value,
    });

    const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);
    labels[name] = value;

    return {name, value};
}
