import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateEmployee } from "redux/tenantSlice";

export default forwardRef((props, ref) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
  const fieldName = props.colDef.field;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type '{ ch... Remove this comment to see the full error message
  const { type, validations = {}, label, placeholder = null } = props.inputProps;
  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [error, setError] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors: formErrors },
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
  } = useForm({ defaultValues: { [fieldName]: props.value }, mode: "all" });

  const { employeeID } = useAppSelector((state) => state.userManager.user);

  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const formData = getValues();
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
        return formData[props.colDef.field];
      },
      isCancelAfterEnd: () => {
        const hasErrors = Boolean(formErrors[fieldName]);
        return hasErrors || !submitClicked;
      },
      isPopup: () => {
        return true;
      },
    };
  });

  const onCloseClick = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
    props.api.stopEditing();
  };

  if (error) {
    return <Modal.Body>Problem updating value</Modal.Body>;
  }

  const onSubmit = async (formData: $TSFixMe) => {
    try {
      setLoading(true);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type '{ ch... Remove this comment to see the full error message
      if (props.inputProps.formatValue) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type '{ ch... Remove this comment to see the full error message
        const data = props.inputProps.formatValue(formData[fieldName]);
        const payload = {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
          userData: { ...props.data, [fieldName]: data },
          updatedBy: employeeID,
        };
        await dispatch(updateEmployee(payload)).unwrap();
      } else {
        const payload = {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
          userData: { ...props.data, [fieldName]: formData[fieldName] },
          updatedBy: employeeID,
        };
        await dispatch(updateEmployee(payload)).unwrap();
      }
      setSubmitClicked(true);
      setLoading(false);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
      props.api.stopEditing();
    } catch (err) {
      setSubmitClicked(false);
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <Form.Group>
          <Form.Label as="h6">{label}</Form.Label>
          <Form.Control
            type={type}
            placeholder={placeholder}
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            {...register(fieldName, {
              ...validations,
            })}
            autoFocus={true}
            isInvalid={formErrors[fieldName]}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors[fieldName]?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          type="button"
          onClick={onCloseClick}
          disabled={loading}
        >
          Close
        </Button>
        <Button size="sm" type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Form>
  );
});
