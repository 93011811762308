import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {CreateOrganizationsRequest, Tenant} from "../types/userTypes";

export const createTenant = createAsyncThunk("tenant/createTenant", async (companyDetails) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/createTenant`,
    companyDetails
  );
  return response.data;
});

export const updateTenant = createAsyncThunk("tenant/updateTenant", async (companyDetails) => {
  await axios.post(`${process.env.REACT_APP_SERVER_URL}/updateTenant`, companyDetails);
  return companyDetails;
});

export const deleteTenant = createAsyncThunk(
  "tenant/deleteTenant",
  async ({ tenantID, employeeID }: $TSFixMe) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/deleteTenant`, {
        tenantID,
        employeeID,
      });
      return { status: true, message: response.data };
    } catch (error: $TSFixMe) {
      if (error) {
        return { status: false, message: error.response.data.message };
      }
    }
  }
);

export const validateTwitterHandle = createAsyncThunk(
  "tenant/validateTwitterHandle",
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ twitterHandle }: void, { reje... Remove this comment to see the full error message
  async ({ twitterHandle }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_PULSE_BACKEND_URL}/twitter/user/${twitterHandle}`
      );
      return Boolean(res.data);
    } catch (error) {
      rejectWithValue(false);
    }
  }
);

export const fetchTenant = createAsyncThunk("tenant/fetchTenant", async (tenantID: number) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getTenant/${tenantID}`);
  return response.data;
});

export const getAllEmployeesForTenant = createAsyncThunk(
  "tenant/getAllEmployeesForTenant",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenantID' does not exist on type 'void'.
  async ({ tenantID, excludePending = true, excludeDeactivated = true }) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/getAllEmployeesForTenant/${tenantID}?excludeDeactivated=${excludeDeactivated}&excludePending=${excludePending}`
    );
    return res.data;
  }
);

export const updateEmployee = createAsyncThunk(
  "tenant/updateEmployee",
  async ({ userData, updatedBy }: $TSFixMe) => {
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/updateEmployee`, {
      ...userData,
      updatedBy,
    });
    return res.data;
  }
);

export const getGroupsForTenant = createAsyncThunk(
  "posts/getGroups",
  async ({ tenantID }: { tenantID: number }) => {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_SERVER_URL + "/getGroupsForTenant/" + tenantID,
    });
    return response.data;
  }
);

export const createOrganizations = createAsyncThunk("groups/createOrganizationGroups",
    async (createOrganizationsRequest: CreateOrganizationsRequest) => {
  await axios.post(`${process.env.REACT_APP_SERVER_URL}/groups/createOrganizationGroups`, createOrganizationsRequest);
  return createOrganizationsRequest;
});

export const getOrganizationsForTenant = createAsyncThunk(
  "tenant/getOrgs",
  async ({ tenantID }: { tenantID: number }) => {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_SERVER_URL + "/groups/getOrgsAndEmployeesForTenant/" + tenantID,
    });
    return response.data;
  }
);

// @ts-ignore
export const tenantSlice = createSlice({
  name: "tenantSlice",
  initialState: {
    tenant: { } as Tenant,
    employees: {
      list: [],
      status: "idle",
      error: null,
    },
    tenantGroups: {
      list: [],
      status: "idle",
      error: null,
    },
    status: "idle",
    error: null,
  },
  reducers: {
    setTenant: (state, action) => {
      state.tenant = action.payload;
      state.status = "idle";
    },
    updateTenantisEmployeeDataImportedFlag: (state) => {
      state.tenant.isEmployeeDataImported = true;
    },
  },
  extraReducers: {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createOrganizations.fulfilled]: (state, action: PayloadAction<CreateOrganizationsRequest>) => {
      state.tenantGroups.list.push(action.payload.orgNames);
      state.tenantGroups.status = "fulfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getGroupsForTenant.fulfilled]: (state, action) => {
      state.tenantGroups.list = action.payload;
      state.tenantGroups.status = "fulfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getGroupsForTenant.pending]: (state) => {
      state.tenantGroups.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getOrganizationsForTenant.fulfilled]: (state, action) => {
      state.tenantGroups.list = action.payload;
      state.tenantGroups.status = "fulfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getOrganizationsForTenant.pending]: (state) => {
      state.tenantGroups.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [createTenant.fulfilled]: (state, action) => {
      state.tenant = action.payload;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [fetchTenant.pending]: (state) => {
      state.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [fetchTenant.fulfilled]: (state, action) => {
      state.status = "fulfilled";
      state.tenant = action.payload;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [updateTenant.fulfilled]: (state, action) => {
      state.status = "fulfilled";
      state.tenant = { ...state.tenant, ...action.payload };
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [fetchTenant.rejected]: (state) => {
      state.status = "rejected";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllEmployeesForTenant.pending]: (state) => {
      state.employees.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllEmployeesForTenant.fulfilled]: (state, action) => {
      state.employees.status = "fulfilled";
      state.employees.list = action.payload;
    },
    //getAllEmployeesForTenant
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllEmployeesForTenant.rejected]: (state) => {
      state.employees.status = "rejected";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllEmployeesForTenant.pending]: (state) => {
      state.employees.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getAllEmployeesForTenant.fulfilled]: (state, action) => {
      state.employees.status = "fulfilled";
      state.employees.list = action.payload;
    },
  },
});

export const { setTenant, updateTenantisEmployeeDataImportedFlag } = tenantSlice.actions;
export default tenantSlice.reducer;
