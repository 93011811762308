import React, {forwardRef, useRef, useState} from "react";
import styles from "../manageEmployees.module.css";
import kebabMenu from "../../../../images/settings/kebab-menu.svg"
import {Image, ListGroup, Overlay, Popover} from "react-bootstrap";
import {OverlayInjectedProps} from "react-bootstrap/Overlay";
import {User} from "../../../../types/userTypes";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {deleteEmployee, sendEmailToSelectedEmployees, updateEmployeeStatus} from "../../../../redux/employeeSlice";

interface EmployeeStatusActionsProps extends OverlayInjectedProps {
  onHide: () => void;
  userData: User;
}

const EmployeeStatusActions = forwardRef(
  ({onHide, userData, ...props}: EmployeeStatusActionsProps, ref: React.Ref<HTMLDivElement>) => {

    const dispatch = useAppDispatch();
    const {employeeID} = useAppSelector((state) => state.userManager.user);

    const updateStatus = (status: string) => {
      onHide();
      const payload = {
        employeeID: userData.employeeID,
        tenantID: userData.tenant.tenantID,
        updatedBy: employeeID,
        status: status,
      };
      // @ts-ignore
      dispatch(updateEmployeeStatus({requiredPayload: payload,}));
    };

    const deleteUser = () => {
      onHide();
      const payload = {
        employeeID: userData.employeeID,
        tenantID: userData.tenant.tenantID,
        updatedBy: employeeID,
      };
      // @ts-ignore
      dispatch(deleteEmployee({requiredPayload: payload,}));
    };

    const resendEmail = () => {
      onHide();
      const payload = {
        sendEmailsToAll: false,
        selectedEmployeeIds: [userData.employeeID],
        tenantId: userData.tenant.tenantID,
        employeeId: employeeID,
      };
      // @ts-ignore
      dispatch(sendEmailToSelectedEmployees({requiredPayload: payload,}));
    };

    if (userData.status === "Active") {
      return (
        <Popover {...props} className={styles.popoverContainer} ref={ref}>
          <ListGroup>
            <ListGroup.Item action onClick={() => {
              updateStatus("Deactivated")
            }}>
              Deactivate
            </ListGroup.Item>
            <ListGroup.Item action onClick={deleteUser}>
              Delete User
            </ListGroup.Item>
          </ListGroup>
        </Popover>
      );
    } else if (userData.status === "Deactivated") {
      return (
        <Popover {...props} className={styles.popoverContainer} ref={ref}>
          <ListGroup>
            <ListGroup.Item action onClick={() => {
              updateStatus("Active")
            }}>
              Activate
            </ListGroup.Item>
            <ListGroup.Item action onClick={deleteUser}>
              Delete User
            </ListGroup.Item>
          </ListGroup>
        </Popover>
      );
    } else {
      return (
        <Popover {...props} className={styles.popoverContainer} ref={ref}>
          <ListGroup>
            <ListGroup.Item action onClick={resendEmail}>
              Resend
            </ListGroup.Item>
          </ListGroup>
        </Popover>
      );
    }
  }
);

export default function StatusRenderer({
  data
}: $TSFixMe) {

  const target = useRef(null);
  const [show, setShow] = useState(false);
  const onHide = () => {
    setShow(false);
  };

  return (<>
      <div className={styles.imageRendererContainer}>
        <Image src={kebabMenu} ref={target} onClick={() => setShow(!show)}/>
      </div>
      <Overlay placement="bottom-end" target={target.current} show={show} rootClose onHide={onHide}>
        <EmployeeStatusActions onHide={onHide} userData={data}/>
      </Overlay>
    </>
  )
}
