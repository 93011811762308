import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import { connect } from "react-redux";
import { Tenant, User } from "types/userTypes";
import { ConnectionType, DynamicAppInfo, IntegratedAppNamesEnum } from "../types";
import { AppCard } from "./AppCard/AppCard";
import { Choice } from "./AppCard/Choice";
import { TopRightElement } from "./AppCard/TopRightElement";
import { SingleAppCardDetailsModal } from "./AppCardDetailsModal/SingleAppCardDetailsModal";
import styles from "./styles/appsForIntegrationGrid.module.css";
import {checkIfDesktopApp} from "../../../utils/desktopUtils";
import ZendeskSubdomainModal from "./ZendeskSubdomainModal";

interface AppsForIntegrationGridProps {
  className?: string;
  onConnectClick?: (name: IntegratedAppNamesEnum) => Promise<{ isConnected: boolean } | undefined>;
  onDisconnectClick?: (
    name: IntegratedAppNamesEnum
  ) => Promise<{ isDisconnected: boolean } | undefined>;
  onSwitchClick?: (
    name: IntegratedAppNamesEnum | string,
    label: string,
    checked: boolean
  ) => Promise<boolean>;
  tenant?: Tenant;
  user: User;
  isCompanyMode: boolean;
  list: {
    connectionType: ConnectionType;
    multipleAppsInfo?: DynamicAppInfo[];
    singleAppInfo?: DynamicAppInfo;
  }[];
}

export class AppsForIntegrationGrid extends AyraaBaseComponent<AppsForIntegrationGridProps, {}> {
  constructor(props: AppsForIntegrationGridProps){
    super(props);
  }
  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className);
    const isDesktopApp = checkIfDesktopApp();
    return (
      <div className={containerClassNames}>
        {this.props.list.map((appItem) => {
          if (appItem.connectionType === ConnectionType.Choice && appItem.multipleAppsInfo) {
            return (
              <Choice
                key={appItem.multipleAppsInfo.length}
                choices={appItem.multipleAppsInfo}
                tenant={this.props.tenant}
                user={this.props.user}
                onConnectClick={this.handleOnConnectClick}
                onDisconnectClick={this.handleOnDisconnectClick}
              />
            );
          }
          if (appItem.connectionType === ConnectionType.Single && appItem.singleAppInfo) {
            const app = appItem.singleAppInfo;
            if (isDesktopApp && app.name === IntegratedAppNamesEnum.BROWSER_EXTENSION) {
              return <AppCard
              {...app}
              tag={app.tag}
              topRightElement={
                <TopRightElement
                  isAvailable={app.isAvailable}
                  componentToShow={"view-in-browser"}
                  name={app.name}
                />
              }
              appCardModalDetailsInfo={{
                children: app.appCardModalDetailsInfo && (
                  <SingleAppCardDetailsModal {...app.appCardModalDetailsInfo} />
                ),
              }}
            />
            }
            return (
              <AppCard
                {...app}
                tag={app.tag}
                topRightElement={
                  <TopRightElement
                    isAvailable={app.isAvailable}
                    componentToShow={"connect-disconnect"}
                    checkConnectionStatus={app.getConnectionStatus}
                    checkStatus={app.getIsAppEnabledStatus}
                    checkConnectionStatusAsync={app.getConnectionStatusAsync}
                    checkStatusAsync={() => {
                      if (app.getIsAppEnabledStatusAsync) return app.getIsAppEnabledStatusAsync();
                      return Promise.resolve(false);
                    }}
                    onConnectClick={() => this.handleOnConnectClick(app.name)}
                    onDisconnectClick={() => this.handleOnDisconnectClick(app.name)}
                    onSwitchClick={(checked) =>
                      this.handleOnSwitchClick(app.name, app.label, checked)
                    }
                    name={app.name}
                  />
                }
                appCardModalDetailsInfo={{
                  children: app.appCardModalDetailsInfo && (
                    <SingleAppCardDetailsModal {...app.appCardModalDetailsInfo} />
                  ),
                }}
              />
            );
          }
          return null;
        })}
        <ZendeskSubdomainModal user={this.props.user.employeeID} tenantId={this.props.tenant?.tenantID}/>
      </div>
    );
  }

  private async handleOnDisconnectClick(name: IntegratedAppNamesEnum) {
    return this.props.onDisconnectClick?.(name);
  }

  private async handleOnSwitchClick(name: string, label: string, checked: boolean) {
    const res = await this.props.onSwitchClick?.(name, label, checked);
    return res;
  }

  private async handleOnConnectClick(name: IntegratedAppNamesEnum) {
    return this.props.onConnectClick?.(name);
  }
}

const mapStateToProps = (state: any) => ({
  tenant: state.tenantManager.tenant,
  user: state.userManager.user,
});

export default connect(mapStateToProps)(AppsForIntegrationGrid);
