import { Button} from "react-bootstrap";
import styles from "./styles/sendinvites.module.css";
import React, {useState} from "react";
import {ImportAndSendInvitesRequest} from "../../../types/userTypes";
import {importAndSendInvites} from "../../../redux/importEmployeesSlice";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getDataFromAppStore} from "../../../dataStore/appDataStore";
import {appConstants} from "../../../utils/appConstants";
import {Label} from "../../../components/Label";
import {getLabel} from "../../../utils/constants";
import ModalWithNotch from "../ModalWithNotch/ModalWithNotch";
import MultiSelectTextField from "components/MultiSelectTextField/MultiSelectTextField";
import classNames from "classnames";

export default function SendInvitesModal({ show ,setShow , setShowSuccessToast , setSuccessToastLabel ,setShowErrorToast}: { show : boolean, setShow:  React.Dispatch<React.SetStateAction<boolean>>, setShowSuccessToast :  React.Dispatch<React.SetStateAction<boolean>>, setSuccessToastLabel :  React.Dispatch<React.SetStateAction<string>> , setShowErrorToast : React.Dispatch<React.SetStateAction<boolean>>  }) {
    const handleClose = () => setShow(false);
    const dispatch = useAppDispatch();

    const { employeeID } = useAppSelector((state) => state.userManager.user);
    const { tenantID, emailDomain } = useAppSelector((state) => state.tenantManager.tenant);

    const [loading, setLoading] = useState<boolean>(false);

    const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);

    const [inputState, setInputState] = useState<any>({
        inputText : "",
        textError : "",
        existingInputLabels: []
      })

      const handleRemoveValue =  (index: number) => {

        setInputState({
          ...inputState,
          existingInputLabels : inputState.existingInputLabels.filter((_ : any, i : any) => i !== index)
        });
      }

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputState({
          ...inputState,
          inputText : value
        })
    };
    const resetErrorHandler = () =>  {
        setInputState({
          ...inputState,
          textError : ""
        });
    }
    const validateEmail = (value: string) => {
        let isValid: boolean = value.endsWith(emailDomain);
        if (isValid) {
           const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
          isValid = !!value.match(regex);
        }
        return isValid;
    };

    const handleAddValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (!validateEmail(inputState.inputText)) {
               setInputState({
                ...inputState,
                textError : "Only company emails are accepted"
               })
            } else {
                 resetErrorHandler();
                if (inputState.existingInputLabels.filter((label :string) => label.toLowerCase() === inputState.inputText.toLowerCase()).length === 0) {
                  setInputState({
                    ...inputState,
                    existingInputLabels : [...inputState.existingInputLabels, inputState.inputText] , 
                    inputText : ""
                   })
                }
                else {
                  setInputState({
                    ...inputState,
                    inputText : "",
                    textError : "Already exists"
  
                   })
  
                }
            }
        } else {
            resetErrorHandler();
        }
    }

   
    const handleInviteTeammates = async () => {
        setLoading(true);
        const importAndSendInvitesRequest: ImportAndSendInvitesRequest = {
            'employeeId': employeeID,
            'tenantId': tenantID,
            'serviceProvider': 'DIRECT',
            'selectedEmployeeEmailIds': inputState.existingInputLabels
        }
        const response = await dispatch(importAndSendInvites(importAndSendInvitesRequest)).unwrap();
        if(response?.error) {
          setSuccessToastLabel(response.error);
          setShowErrorToast(true)
        }
        else {
          setSuccessToastLabel("Invites Sent Succesfully !");
          setShowSuccessToast(true)
         
        }
        setShow(false);
    }

    return (
      <ModalWithNotch 
      centered 
      className={styles.modal} 
      show={show} 
      onHide={handleClose} 
      dialogClassName={styles.modalDialog}
      notchIconProps={{onClick: handleClose, 
      className : classNames("invite-screen-notch-icon", styles.notchIcon)}}
      bsPrefix="invite-screen-modal"
      contentClassName={styles.modalContent}>
          <div className={styles.header}><Label name='ui.employees.import.email-invites' /></div>
          <div className={styles.note}>
          <Label name='ui.employees.import.email-invites.email.note' args={[emailDomain]} /> 
          </div>
          <div className={styles.modalBody}>
            <MultiSelectTextField
               inputObject= {inputState}
               handleAddValue = {handleAddValue}
               handleRemoveValue= {handleRemoveValue}
               handleChange={handleChange}
               placeholder={getLabel(labels['ui.employees.import.email-invites.email.placeholder'],
                                            'ui.employees.import.email-invites.email.placeholder', [emailDomain])}
               className={styles.invitesContainer}
             />

          </div>
          <div className={styles.inviteButtonContainer}>
              <Button variant="primary" onClick={handleInviteTeammates} disabled={loading || inputState.existingInputLabels.length == 0}>
                  <Label name='ui.employees.import.invite-button-text' />
              </Button>
          </div>
      </ModalWithNotch>
    );
}