import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateEmployee } from "redux/tenantSlice";

export default forwardRef((props, ref) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
  const fieldName = props.colDef.field;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type '{ ch... Remove this comment to see the full error message
  const { label, options = [], validations = {} } = props.inputProps;
  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [error, setError] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors: formErrors, isDirty },
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
  } = useForm<$TSFixMe>({ defaultValues: { [fieldName]: props.data[fieldName] }, mode: "all" });

  const { employeeID } = useAppSelector((state) => state.userManager.user);

  const dispatch = useAppDispatch();

  // @ts-expect-error
  const selectedValue = watch(props.colDef.field);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const formData = getValues();
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
        return formData[props.colDef.field];
      },
      isCancelAfterEnd: () => {
        const hasErrors = Boolean(formErrors[fieldName]);
        return hasErrors || !submitClicked;
      },
      isPopup: () => {
        return true;
      },
    };
  });

  const onCloseClick = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
    props.api.stopEditing();
  };

  if (error) {
    return <Modal.Body>Problem updating value</Modal.Body>;
  }

  const onSubmit = async (formData: $TSFixMe) => {
    try {
      setLoading(true);
      const payload = {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
        userData: { ...props.data, [fieldName]: formData[fieldName] },
        updatedBy: employeeID,
      };
      await dispatch(updateEmployee(payload)).unwrap();
      setLoading(false);
      setSubmitClicked(true);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
      props.api.stopEditing();
    } catch (err) {
      setSubmitClicked(false);
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <Form.Group>
          <Form.Label as="h6">{label}</Form.Label>
          <Form.Select {...register(fieldName, { ...validations })}>
            {options.map((value: $TSFixMe) => {
              return <option>{value}</option>;
            })}
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          type="button"
          onClick={onCloseClick}
          disabled={loading}
        >
          Close
        </Button>
        <Button type="submit" disabled={loading || !isDirty || !selectedValue} size="sm">
          {loading ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Form>
  );
});
