import { Cross, GreenTick } from "./assets";
import { Label } from "components/Label";
import styles from "./styles/toastNotification.module.css";
import classNames from "classnames";
import { ToastNotificationStatus, ReactSVGComponent } from "./types";

type Size = "sm" | "lg";

type Props = {
  status: ToastNotificationStatus;
  labelName: string;
  labelArgs?: string[];
  size?: Size;
};

interface ToastNotificationStatusProps {
  label: string;
  icon: ReactSVGComponent;
}

const ErrorStatus: ToastNotificationStatusProps = {
  label: "Error",
  icon: Cross,
};

const SuccessStatus: ToastNotificationStatusProps = {
  label: "Successful",
  icon: GreenTick,
};

const toastNotificationStatusMap = new Map<ToastNotificationStatus, ToastNotificationStatusProps>();

toastNotificationStatusMap.set(ToastNotificationStatus.Success, SuccessStatus);
toastNotificationStatusMap.set(ToastNotificationStatus.Error, ErrorStatus);

const ToastNotification = ({ status, labelName, labelArgs, size = "sm" }: Props) => {
  const toastNotificationStatus = toastNotificationStatusMap.get(status) || ErrorStatus;
  const Icon = toastNotificationStatus.icon;

  if (size === "sm") {
    const iconContainerClassNames = classNames(styles.iconContainer, styles.sm);
    const containerClassNames = classNames(styles.container, styles.sm);
    return (
      <div className={containerClassNames}>
        <span className={iconContainerClassNames}>
          <Icon />
        </span>
        <span className={styles.labelText}>
          <Label name={labelName} args={labelArgs} />
        </span>
      </div>
    );
  }

  const containerClassNames = classNames(styles.container, styles.lg);

  return (
    <div className={containerClassNames}>
      <span className={styles.iconContainer}>
        <Icon />
      </span>
      <div className="w-75">
        <span className={styles.heading}>{toastNotificationStatus.label}</span>
        <p className={styles.mainText}>
          <Label name={labelName} args={labelArgs} />
        </p>
      </div>
    </div>
  );
};
export default ToastNotification;
