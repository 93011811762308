import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./styles/importemployees.module.css";
import PulseAppButton from "../../../components/v2/Redirect/PulseAppButton";
import { Label } from "../../../components/Label";
import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import { useHistory } from "react-router";
import { Progress } from "components/Progress/Progress";

export default function GetStarted() {
  const history = useHistory();

  const navigate = () => {
    history.push("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.getStarted}>
          <div className="bold">
            <Label name="You are all set!" />
          </div>
          <div className="regular">
            <Label name="Welcome to the second brain for your workspace" />
          </div>
        </div>
        <div>
          <Button onClick={navigate}>Let's get started</Button>
        </div>
      </div>
      <FadedLogoWatermark className={styles.rightContainer} />
      {/* <Progress noOfSteps={3} activeStep={3} completed className={styles.progress} /> */}
    </div>
  );
}
