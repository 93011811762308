import classNames from "classnames";
import { Label } from "components/Label";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import React, { Component, ReactNode } from "react";
import { Button } from "react-bootstrap";
import { getLabel } from "utils/constants";
import ModalWithNotch from "../ModalWithNotch/ModalWithNotch";
import styles from "./styles/confirmationmodal.module.css";

interface ConfirmNextPageModalState {}

interface ConfirmNextPageModalProps {
  show: boolean;
  handleSecondaryAction: () => void;
  handlePrimaryAction: () => void;
  bodyText: string;
  headerLabel?: string;
  secondaryButtonLabel?: string;
  primaryButtonLabel?: string;
  primaryButtonId?: string;
}

export class ConfirmNextPageModal extends AyraaBaseComponent<
  ConfirmNextPageModalProps,
  ConfirmNextPageModalState
> {
  constructor(props: ConfirmNextPageModalProps) {
    super(props);
  }

  public renderCore(): ReactNode {
    return (
      <ModalWithNotch
        centered
        className={styles.modal}
        show={this.props.show}
        onHide={this.props.handleSecondaryAction}
        dialogClassName={styles.modalDialog}
        notchIconProps={{
          onClick: this.props.handleSecondaryAction,
          className: classNames("invite-screen-notch-icon", styles.notchIcon),
        }}
        contentClassName={styles.modalContent}
      >
        <div className={styles.header}>
          <Label name={this.props.headerLabel ?? "Confirmation"} />
        </div>
        <div className={styles.note}>
          <Label name={this.props.bodyText} />
        </div>
        <div className={styles.modalBody}></div>
        <div className={styles.footer}>
          <Button variant="secondary" onClick={this.props.handleSecondaryAction}>
            <Label name={this.props.secondaryButtonLabel ?? "Take me back"} />
          </Button>
          <Button
            id={this.props.primaryButtonId}
            variant="primary"
            onClick={this.props.handlePrimaryAction}
          >
            <Label name={this.props.primaryButtonLabel ?? "Yes, I confirm"} />
          </Button>
        </div>
      </ModalWithNotch>
    );
  }
}
