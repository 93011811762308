import React from "react";
import { CloseButton, Toast, ToastContainer } from "react-bootstrap";
import { GreenTickIcon, WarningIcon } from "utils/icons";
import styles from "./toastcomponent.module.css";

type ToastTypes = "success" | "warning";

type ReactSVGComponentType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

const ToastIcons: { [key in ToastTypes]: ReactSVGComponentType } = {
  success: GreenTickIcon,
  warning: WarningIcon,
};

export default function ToastComponent({
  text,
  type,
  show,
  showCloseIcon = true,
  onClose,
    autoHide = true
}: {
  text: React.ReactNode;
  type: ToastTypes;
  show: boolean;
  showCloseIcon: boolean;
  onClose: () => void;
  autoHide?: boolean;
}) {
  const Icon = ToastIcons[type];

  return (
    <ToastContainer className={styles.toastContainer} position="bottom-end">
      <Toast
        className={styles.toast}
        bg="light"
        onClose={onClose}
        show={show}
        delay={5000}
        autohide={autoHide}
      >
        <Toast.Body className={styles.toastBody}>
          <div className={styles.icon}><Icon /></div>
          {text}
          {showCloseIcon && <CloseButton onClick={onClose} />}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
