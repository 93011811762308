import axios from "axios";
import yaml from "js-yaml";
import {AppLatestSpec} from "../../types/downloadTypes";

export async function downloadLatestDesktopAppRelease(os: string, arch: string) {
    let filename = 'latest.yml';
    if (os === 'mac') {
       filename = 'latest-mac.yml';
    }
    let response = await axios.get(`${process.env.REACT_APP_DESKTOP_APP_DOWNLOAD_HOST}/${os}/${arch}/${filename}`);

    const data = yaml.load(response.data) as AppLatestSpec;
    filename = data.files.find((file) => file.url.endsWith('.dmg') || file.url.endsWith('.exe'))?.url || data.path;

    window.location.href = `${process.env.REACT_APP_DESKTOP_APP_DOWNLOAD_HOST}/${os}/${arch}/${filename}`;
}