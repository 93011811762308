import React from "react";
import rippling1Logo from "images/hris/integration/rippling-1.png"

function RipplingIntegration() {
  return (
    <div className="p-4 m-4">
      <h2>Creating your API Key</h2>
      <p>To generate your Rippling API key, please follow these steps.</p>
      <ol>
        <li>
          <a href="https://app.rippling.com/login">
            Log in to Rippling as an admin for your organization
          </a>
        </li>
        <li>Select Company Settings</li>
        <li>
          <a href="https://app.rippling.com/login?next=%2Fcompany-settings%2Fapi-access">
            Navigate to API Access
          </a>
        </li>
        <li>Click Create API Key</li>
        <li>Provide the scopes and description of your API key</li>
        <li>Click Save</li>
      </ol>
      <img className="m-4" height={200} src={rippling1Logo} />
    </div>
  );
}

export default RipplingIntegration;
