import {AyraaBaseComponent} from "../../../components/ayraaFramework/AyraaBaseComponent";
import React, {ReactNode, MouseEvent} from "react";
import styles from "./desktopappinstallationinstructions.module.css";
import FadedLogoWatermark from "../../../components/v2/Logo/FadedLogoWatermark";
import {getUserSystemInfo} from "../../../utils/browserUtils";
import {downloadLatestDesktopAppRelease} from "../../../dataFetcher/download/DownloadDataFetcher";
import {Label} from "../../../components/Label";
import {ArchSpec, OSSpec} from "../../../types/downloadTypes";

type DesktopAppInstallationInstructionsState = {
    userSystemInfo?: {
        os:  OSSpec | {name: string | undefined, path: undefined},
        arch:  ArchSpec | {name: string | undefined, path: undefined}
    }
}
export class DesktopAppInstallationInstructions extends AyraaBaseComponent<any, DesktopAppInstallationInstructionsState> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    renderCore(): ReactNode {
        return (
            <div className={styles.container}>
                <div className={styles.leftColumn}>
                    <FadedLogoWatermark />
                </div>
                <div className={styles.rightColumn}>
                    <ul className={styles.instructionList}>
                        <li><Label name="ui.desktop-app.installation.instruction-1" /></li>
                        <li><Label name="ui.desktop-app.installation.instruction-2" /></li>
                        <li><Label name="ui.desktop-app.installation.instruction-3" /></li>
                    </ul>
                    <div className={styles.retryMessage}>
                        If your download does not start automatically, please <a href="#" onClick={this.retryDownloadClick}>try again</a>
                    </div>
                </div>
            </div>
        );
    }

    private doSideEffectForUserSystemInfo() {
        if (this.shouldExecuteSideEffect(this.doSideEffectForUserSystemInfo, [])) {
            getUserSystemInfo().then((userSystemInfo) => this.setState({ userSystemInfo }));
        }
    }

    private retryDownloadClick(event: MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        if (this.state.userSystemInfo?.os.path && this.state.userSystemInfo.arch.path) {
            downloadLatestDesktopAppRelease(this.state.userSystemInfo.os.path, this.state.userSystemInfo.arch.path);
        }
    }

}