import {
    getAllSubscriptionPackages,
    getSubscribedPackage,
    saveSubscriptionDetails,
  } from "dataFetcher/Subscription/subscriptionDataFetcher";
import { subscriptionTypes } from "utils/constants";
import {Tenant } from "types/userTypes";


export async function triggerSubscriptionRequirements (fetchedTenant : Tenant)  {

    const subscriptionPackages = await getAllSubscriptionPackages();
    const {ongoing_subscription, upcoming_subscription} = await getSubscribedPackage(fetchedTenant.tenantID);

    //flow when the user doesnot have any workspace details.
        //re-direct him to the createWorkspace Flow.
        const currentSubscription = ongoing_subscription || upcoming_subscription;
        if (!currentSubscription) {
            const freeSubscription = subscriptionPackages.find(
                (item: $TSFixMe) => item.subscriptionType === subscriptionTypes.FREE
                );
             
            await saveSubscriptionDetails({
                tenant: fetchedTenant,
                selected_subscription: freeSubscription,
            })     
        }
}