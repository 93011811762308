import classNames from "classnames";
import { Modal, ModalProps } from "react-bootstrap";
import styles from "./styles/modalWithNotch.module.css";
import { useEffect } from "react";
import NotchCloseIcon from "../NotchCloseIcon/NotchCloseIcon";

interface ModalWithNotchProps extends ModalProps {
  notchIconProps?: {
    className?: string;
    onClick?: () => void;
  };
  children: React.ReactNode;
}

export default function ModalWithNotch({
  children,
  notchIconProps = {
    className: "",
    onClick: undefined
  },
  ...modalProps
}: ModalWithNotchProps) {
  const notchIconClassNames = classNames(notchIconProps.className, styles.notch);

  useEffect(() => {
    if (modalProps.show) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "relative";
    }
  }, [modalProps]);

  return (
    <Modal {...modalProps}>
      <NotchCloseIcon className={notchIconClassNames} onClick={notchIconProps.onClick} />
      {children}
    </Modal>
  );
}
