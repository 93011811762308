import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/namePage.module.css";
import { InputWithFloatingLabel } from "components/v2/InputWithFloatingLabel/InputWithFloatingLabel";
import { Form,Button } from "react-bootstrap";
import { addDataToAppDataStore, getDataFromAppStore } from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";
import { triggerLoginWithEmail } from "dataSender/auth/AuthDataSender";
interface NamePageStateProps {
    firstName : string,
    lastName : string,
    isCheckboxChecked : boolean,
    error : {
        show : boolean,
        message : string
    }
}

export class NamePage extends AyraaBaseComponent<{},NamePageStateProps> {
    private labels: { [key: string]: string; };
    constructor(props : {}) {
        super(props);
        this.state = {
            firstName : "",
            lastName : "",
            isCheckboxChecked:false,
            error : {
                show : false,
                message : ""
            }
        }
        this.labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY)
    }
    private handleFirstNameChange(e : React.ChangeEvent<HTMLInputElement>) {
       this.setState({firstName : e.target.value})
    }
    private handleLastNameChange(e : React.ChangeEvent<HTMLInputElement>) {
        this.setState({lastName : e.target.value})
    }
    private handleCheckBoxChange(e : React.ChangeEvent<HTMLInputElement>) {
        this.setState({isCheckboxChecked : e.target.checked})
    }
    private async onNameInfoSubmit() {
        const existingData = getDataFromAppStore(appConstants.EMAIL_ID_LOGIN_FLOW);
        const { email } = existingData;

        addDataToAppDataStore(appConstants.EMAIL_ID_LOGIN_FLOW, {
            ...existingData,
            firstName : this.state.firstName,
            lastName : this.state.lastName
        })

        try {
           const response = await triggerLoginWithEmail({email ,employeeFirstName : this.state.firstName , employeeLastName : this.state.lastName });
           if (response === "401") {
               this.setState({
                   error : {
                       show : true,
                       message : this.labels['ui.login.error.token.invalid']
                   }
               })
           } else {
               this.history.push("/emailLogin/otp")
           }
        }
        catch(e) {
            this.setState({
                error : {
                    show : true,
                    message : "something went wrong."
                }
            })
        }
    }

    public renderCore(): ReactNode {
        return (
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <div className={styles.namePageLabel}>
                       How do we address you?
                    </div>
                    <div className={styles.inputNameContainer}>
                        <InputWithFloatingLabel
                            inputProps={{
                                label : "First Name *",
                                placeHolder : "Jon",
                            }}
                            validationProps={{
                                error : this.state.error.show,
                                message : ""
                            }}
                            classNames = {{
                                containerClassName : styles.inputContainer,
                                inputClassName : styles.input
                            }}
                            onChange={this.handleFirstNameChange}
                        />
                        <InputWithFloatingLabel
                            inputProps={{
                                label : "Last Name *",
                                placeHolder : "Snow",

                            }}
                            classNames = {{
                                containerClassName : styles.inputContainer,
                                inputClassName : styles.input
                            }}
                            validationProps={{
                                error : this.state.error.show,
                                message : ""
                            }}
                            onChange={this.handleLastNameChange}
                        />
                    </div>
                    {this.state.error.show && (<span className={styles.errorMessage}>
                        {this.state.error.message}
                    </span>)}
                    <div className={styles.termsAndConditionContainer}>
                        <Form.Check
                            className={styles.inputCheckbox}
                            checked={this.state.isCheckboxChecked}
                            onChange={this.handleCheckBoxChange}
                            type={"checkbox"}
                        />
                        <div className={styles.termsAndCondition}>
                            I agree to Ayraa’s <a href={`${this.labels["ui.login.terms.and.conditions"]}`} target="_blank">Term of Service</a> and acknowledge the <a href={`${this.labels["ui.login.privacy.and.policy"]}`} target="_blank">Privacy policy</a>
                        </div>
                    </div>
                     <Button className={styles.continueButton} onClick={this.onNameInfoSubmit} disabled={
                        this.state.firstName.length === 0 || this.state.lastName.length === 0 || !this.state.isCheckboxChecked
                     }>
                        Continue
                     </Button>
                </div>
                <FadedLogoWatermark className={styles.rightContainer} />
                </div>
        )
    }
}


