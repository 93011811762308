import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {User} from "../types/userTypes";

export const getUserInfo = createAsyncThunk("auth/getUserInfo", async () => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getUserInfo`);
  return response.data as User;
});

export const tokenValidation = createAsyncThunk("auth/tokenValidation",
    async ({ serviceProvider, authCode, referrer }: { serviceProvider: string, authCode: string, referrer: string }, { rejectWithValue }) => {
    try {
    const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/tokenValidation", {
      serviceProvider,
      authCode,
      referrer
    });
    return {
      data: response.data,
      service_provider: serviceProvider,
      referrer: referrer,
    };
  } catch (error: $TSFixMe) {
    if (error) {
      return rejectWithValue({
        'message': error?.response?.data?.message || "Something went wrong!",
        'errorCode': error?.response?.data?.errorCode || 0
      });
    }
  }});

export const getUserDetailsFromAuthCode = createAsyncThunk(
  "auth/gc",
  async ({ serviceProvider, authCode, referrer }: $TSFixMe, { rejectWithValue }) => {
    try {
      const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/tokenValidation", {
        serviceProvider,
        authCode,
        referrer
      });
      return {
        data: response.data,
        service_provider: serviceProvider,
        referrer: referrer,
      };
    } catch (error: $TSFixMe) {
      if (error) {
        return rejectWithValue({
          'message': error?.response?.data?.message || "Something went wrong!",
          'errorCode': error?.response?.data?.errorCode || 0
        });
      }
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/logout");
  return response.data;
});

export const generateSSOToken = createAsyncThunk(
  "auth/generateSSOToken",
  async ({ target_app_url, target_app_code } : { target_app_url: string; target_app_code: string; }) => {
    const data = JSON.stringify({
      target_app_code,
      target_app_url,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_SERVER_URL + "/generateSSOToken",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await axios(config);
    return response.data;
  }
);

export const authSlice = createSlice({
  //Name of the slice
  name: "authSlice",

  //Initial User State
  initialState: {
    tokenValidation: {
      status: "idle",
      error: null,
      errorCode: null,
      data: null,
    },
    logout: {
      status: "idle",
      error: null,
    },
    getUserInfo: {
      status: "idle",
    },
    getUserDetailsFromAuthCode: {
      status: "idle",
      error: null,
      errorCode: null
    },
  },

  //Reducers & Actions
  reducers: {},
  extraReducers: {
    //tokenValidation
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getUserInfo.pending]: (state) => {
      state.getUserInfo.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getUserInfo.fulfilled]: (state) => {
      state.getUserInfo.status = "fulfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getUserInfo.rejected]: (state) => {
      state.getUserInfo.status = "rejected";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [tokenValidation.pending]: (state) => {
      state.tokenValidation.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [tokenValidation.fulfilled]: (state, action) => {
      state.tokenValidation.status = "fulfilled";

      const { data, service_provider } = action.payload;

      state.tokenValidation.data = data;

    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [tokenValidation.rejected]: (state, action) => {
      state.tokenValidation.status = "rejected";
      state.tokenValidation.error = action.payload.message;
      state.tokenValidation.errorCode = action.payload.errorCode;
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getUserInfo.fulfilled]: (state, action) => {
      const data = action.payload;

    },
    //logout.
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [logout.pending]: (state) => {
      state.logout.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [logout.fulfilled]: (state) => {
      state.logout.status = "fulfilled";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [logout.rejected]: (state) => {
      state.logout.status = "rejected";
    },

    //getUserDetailsFromAuthCode

    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getUserDetailsFromAuthCode.pending]: (state) => {
      state.getUserDetailsFromAuthCode.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getUserDetailsFromAuthCode.fulfilled]: (state) => {
      state.getUserDetailsFromAuthCode.status = "fulfilled";

    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [getUserDetailsFromAuthCode.rejected]: (state, action) => {
      state.getUserDetailsFromAuthCode.status = "rejected";
      state.getUserDetailsFromAuthCode.error = action.payload.message;
      state.getUserDetailsFromAuthCode.errorCode = action.payload.errorCode;
    },
  },
});

// @ts-expect-error ts-migrate(2339) FIXME: Property 'setLoginStatus' does not exist on type '... Remove this comment to see the full error message
export const { setLoginStatus } = authSlice.actions;
export default authSlice.reducer;
