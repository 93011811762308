import {  Image } from "react-bootstrap";
import styles from "./styles/importemployees.module.css";
import { WithAccessControl } from "../../../utils/AccessControl";
import { useGetPrivileges } from "../../../utils/AccessControl/useGetPrivileges";
import InviteOptionsWithAccessControl from "./InviteOptions";
import BackLogo from "../../../images/backicongrey.svg";
import { useHistory } from "react-router";
import { actions } from "../../../utils/AccessControl/actions";
import { Label } from "../../../components/Label";
import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";


function ImportEmployees({ requiredPrivileges, privileges }: $TSFixMe) {
  const history = useHistory();

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [actions.IMPORT_EMPLOYEES],
  });

  const backButtonClickHandler = () => {
    history.goBack();
  };


  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.title}>
          <Label name="ui.employees.import.title" />
        </div>
        <div className={styles.description}>
          <Label name="ui.employees.import.description" />
        </div>
        <InviteOptionsWithAccessControl showSkipButton={true} />
        <div className="position-relative">
          <div className={styles.buttonContainer}  onClick={backButtonClickHandler}>
            <Image src={BackLogo} className={styles.backButton} />
          </div>
        </div>
      </div>
      <FadedLogoWatermark className={styles.rightContainer} />
      {/* <Progress activeStep={3} noOfSteps={3} className={styles.progress} /> */}
    </div>
  );
}

export default function ImportEmployeesWithAccessControl() {
  return <WithAccessControl component={ImportEmployees} />;
}
