import {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {columnDefs} from "./gridConfig";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import styles from "./manageEmployees.module.css";
import {WithAccessControl} from "utils/AccessControl";
import {useAppSelector} from "redux/hooks";
import GroupRenderer from "./Renderers/GroupRenderer";
import NameRenderer from "./Renderers/NameRenderer";
import StatusRenderer from "./Renderers/StatusRenderer";

const getAllEmployeesForTenant = async ({
  tenantID,
  excludePending = true,
  excludeDeactivated = true,
}: $TSFixMe) => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/getAllEmployeesForTenant/${tenantID}?excludeDeactivated=${excludeDeactivated}&excludePending=${excludePending}`
  );
  return res.data;
};

function ManageEmployees() {
  const {
    tenant: {tenantID},
    employees: {status},
  }: $TSFixMe = useAppSelector((state) => state.tenantManager);

  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState<any[]>([]);

  useEffect(() => {
    if (gridApi) {
      (async function () {
        const employeeList = await getAllEmployeesForTenant({
          tenantID,
          excludeDeactivated: false,
          excludePending: false,
        });
        setRowData(employeeList);
      })();
      // @ts-ignore
      gridApi.sizeColumnsToFit();
    }
  }, [status, gridApi, tenantID]);

  const onGridReady = ({api}: $TSFixMe) => {
    setGridApi(api);
  };

  const suppressEnterOrTab = (params: $TSFixMe) => {
    const KEY_ENTER = 13;
    const event = params.event;
    const key = event.which;
    return params.editing && (params.event.key === "Tab" || key === KEY_ENTER);
  };

  return (
    <div className={styles.container}>
      <span className={styles.counter}>Users({rowData.length})</span>
      <div className="ag-theme-alpine" style={{height: "calc(100vh - 197px)"}}>
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs()}
          frameworkComponents={{
            userName: NameRenderer,
            groups: GroupRenderer,
            statusActions: StatusRenderer,
          }}
          rowHeight={50}
          rowData={rowData}
          stopEditingWhenCellsLoseFocus={false}
          suppressKeyboardEvent={suppressEnterOrTab}
          colResizeDefault={"shift"}
        />
      </div>
    </div>
  );
}

export default function ManageEmployeesWithAccessControl() {
  return <WithAccessControl component={ManageEmployees}/>;
}
