import { Redirect } from "react-router";

export default function ErrorPage({
  error
}: {error ?:  string}) {
//incase at all we come here, we should be redirected. 
  return (
   <Redirect 
     to= {{
      pathname:  "/login"
     }}
   
   />
  );
}
