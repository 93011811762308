let appdataStore:{[key:string]: any} = {};

export function addDataToAppDataStore(key: string,  value: any) {
    appdataStore[key] = value;
}

export function getDataFromAppStore(key:string, defaultValue?: any) {
    let value = appdataStore[key];
    if (value === undefined) {
      value = defaultValue;
    }
    return value;
}

export function clearDataFromAppStore(key: string) {
    appdataStore[key] = undefined;
}