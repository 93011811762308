import { CONSTANTS } from "utils/constants";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_AUTH_CLIENT_ID,
    redirectUri: process.env.REACT_APP_CLIENT_URL + CONSTANTS.DASHBOARD,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
