import { ReactNode } from "react";
import { Button } from "react-bootstrap";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import styles from "./styles/inviteAyraaBot.module.css";
import { SlackIcon, AyraabotInviteScreenShot, RoundedArrowButton } from "utils/icons";
import { Label } from "components/Label";
import { Progress } from "components/Progress/Progress";
import { ConfirmNextPageModal } from "./ConfirmNextPageModal";

interface InviteAyraaBotPageState {
  showConfirmationModal: boolean;
  showOnlyContinueButton: boolean;
}
interface InviteAyraaBotPageProps {}

export class InviteAyraaBotPage extends AyraaBaseComponent<
  InviteAyraaBotPageProps,
  InviteAyraaBotPageState
> {
  constructor(props: InviteAyraaBotPageState) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      showOnlyContinueButton: false,
    };
  }

  private handleRedirectBack() {
    this.history.goBack();
  }

  private handleDirectRedirect() {
    this.history.push("/getStarted");
  }

  public renderCore(): ReactNode {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.leftContainer}>
            <div className={styles.extensionWorkspaceLabel}>
              <Label name="ui.onboarding.invite.ayraa.bot.page-subtext" />
            </div>
            <div className={styles.extensionDescription}>
              <Label name="ui.onboarding.invite.ayraa.bot.page.description.first-line" />
              <Label name="ui.onboarding.invite.ayraa.bot.page.description.second-line" />
            </div>
            {this.state.showOnlyContinueButton ? (
              <Button
                className={styles.continueButton}
                variant="secondary"
                onClick={this.handleDirectRedirect}
              >
                Continue
              </Button>
            ) : (
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.continueButton}
                  variant="secondary"
                  onClick={this.onOpenSlackClick}
                >
                  <SlackIcon /> <Label name="ui.onboarding.invite.ayraa.bot.page.slack.button" />
                </Button>
                <div onClick={this.openConfirmationModal} className={styles.skipContinueButton}>
                  Skip, Continue
                </div>
              </div>
            )}
            <div className={styles.navigationButtons}>
              <RoundedArrowButton onClick={this.handleRedirectBack} className={styles.back} />
              {/* <RoundedArrowButton onClick={this.handleDirectRedirect} className={styles.next} /> */}
            </div>
          </div>
          <div className={styles.rightContainer}>
            <AyraabotInviteScreenShot className={styles.image} />
          </div>
          <Progress noOfSteps={3} activeStep={3} className={styles.progress} />
        </div>
        <ConfirmNextPageModal
          show={this.state.showConfirmationModal}
          handleSecondaryAction={this.handleCloseModal}
          handlePrimaryAction={this.handleDirectRedirect}
          bodyText="ui.onboarding.invite.ayraa.bot.confirmation.modal.body"
          headerLabel="ui.onboarding.invite.ayraa.bot.confirmation.modal.header"
          primaryButtonLabel="ui.onboarding.invite.ayraa.bot.confirmation.modal.primary.button"
          secondaryButtonLabel="ui.onboarding.invite.ayraa.bot.confirmation.modal.secondary.button"
        />
      </>
    );
  }

  private handleCloseModal() {
    this.setState({
      showConfirmationModal: false,
    });
  }

  private openConfirmationModal() {
    this.setState({
      showConfirmationModal: true,
    });
  }

  private onOpenSlackClick() {
    window.open("slack://home", "_blank");
    setTimeout(() => {
      this.setState({
        showOnlyContinueButton: true,
      });
    }, 1000);
  }
}

export default InviteAyraaBotPage;
