import LoginGoogle from "components/v2/Auth/LoginGoogle";
import LoginMS from "components/v2/Auth/LoginMS";
import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/emailPage.module.css";
import { InputWithFloatingLabel } from "components/v2/InputWithFloatingLabel/InputWithFloatingLabel";
import { Button } from "react-bootstrap";
import {addDataToAppDataStore, getDataFromAppStore} from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";
import { checkFirstAndLastNameExist, triggerLoginWithEmail } from "dataSender/auth/AuthDataSender";
interface EmailPageStateProps {
    email : string,
    error : {
        show : boolean,
        message : string
    }
}

export class EmailPage extends AyraaBaseComponent<{},EmailPageStateProps> {
    labels: any;

    constructor(props : {}) {
        super(props);
        this.state = {
            email : "",
            error : {
                show: false,
                message : ""
            }
        }
    }
    private isEmailStringValid(e : string) {
        const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(e);
    }

    private handleEmailInputChange(e : React.ChangeEvent<HTMLInputElement>) {
        this.setState({email : e.target.value, error : {show : false , message : ""}});
    }

    private async onNameInfoSubmit() {
        const existingData = getDataFromAppStore(appConstants.EMAIL_ID_LOGIN_FLOW);
        const { email } = existingData;

        addDataToAppDataStore(appConstants.EMAIL_ID_LOGIN_FLOW, {
            ...existingData
        })

        try {
           const response = await triggerLoginWithEmail({email });
           if (response === "401") {
               this.setState({
                   error : {
                       show : true,
                       message : this.labels['ui.login.error.token.invalid']
                   }
               })
           } else {
               this.history.push("/emailLogin/otp")
           }
        }
        catch(e) {
            this.setState({
                error : {
                    show : true,
                    message : "something went wrong."
                }
            })
        }
    }

    private async handleSubmit(e : React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);
        if (!this.isEmailAllowed(this.state.email)) {
            this.setState({
                error :{
                    show :  true,
                    message : labels['ui.login.error.token.invalid']
                }
            })
        } else {
            if (this.isEmailStringValid(this.state.email)) {
                addDataToAppDataStore(appConstants.EMAIL_ID_LOGIN_FLOW, {
                    email : this.state.email,
                    firstName : "",
                    lastName : ""
                });
                const nameExist = await checkFirstAndLastNameExist(this.state.email);
                if (nameExist) {
                    this.onNameInfoSubmit()
                }else{
                    this.history.push("/emailLogin/name");
                }
            } else {
                this.setState({
                    error :{
                        show :  true,
                        message : "Email Address is invalid"
                    }
                })
            }
        }
    }

    public renderCore(): ReactNode {
        return (
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <div className={styles.workEmailLabel}>
                        What's your work email?
                    </div>
                    <InputWithFloatingLabel
                        classNames={{
                            containerClassName : styles.emailInputContainer,
                            inputClassName : styles.emailInput,
                            labelClassName: styles.emailInputLabel
                        }}
                        inputProps={{
                            placeHolder : "yash@ayraa.io",
                            label : "Work Email *",
                            type : "email"
                        }}
                        validationProps = {{
                            error : this.state.error.show,
                            message : this.state.error.message
                        }}
                        onChange={this.handleEmailInputChange}
                     />
                     <Button 
                        className={styles.continueButton} 
                        disabled={!this.state.email.length || this.state.error.show} 
                        onClick={this.handleSubmit}>
                            Continue
                     </Button>
                     <div className={styles.lineSeperator} />
                     <div className={styles.buttonContainer}>
                        <LoginGoogle labelName="ui.login.google.signup-button-text" />
                        <LoginMS labelName="ui.login.microsoft.signup-button-text" />
                     </div>
                </div>
                <FadedLogoWatermark className={styles.rightContainer} />
                </div>
        )
    }

    private isEmailAllowed(email: string) {
        const blacklistDomains = ["gmail.com", "outlook.com", "live.com", "yahoo.com", "hotmail.com"];
        return !blacklistDomains.some((domain) => email.endsWith(domain));
    }

}



