import React, { useState, useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./microsoftAuthConfig";
import { BsMicrosoft } from "react-icons/bs";
import { getUserDetailsFromAuthCode } from "redux/authSlice";
import { getUsersRole, setUser, setUserRole } from "redux/userSlice";
import { useHistory, useLocation } from "react-router";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import styles from "./login.module.css";
import appStyles from "../../app.module.css";
import { setTenant, fetchTenant } from "redux/tenantSlice";
import { resetSubscribedPackageStateStatus } from "redux/subscriptionSlice";
import { CONSTANTS } from "utils/constants";
import { useAppDispatch, useAppSelector } from "redux/hooks";

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ auth: { clientId: string | und... Remove this comment to see the full error message
const msalInstance = new PublicClientApplication(msalConfig);

function Login({ setShowModal }: $TSFixMe) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userDetailsFromAuth, setUserDetailsFromAuthCode] = useState<any>(null);

  const { status } = useAppSelector((state) => state.authManager.getUserDetailsFromAuthCode);

  useEffect(() => {
    if (status === "fulfilled" && userDetailsFromAuth) {
      (async function () {
        try {
          if (userDetailsFromAuth) {
            const tenantID: number = userDetailsFromAuth.tenant.tenantID;
            const fetchedTenant: any = await dispatch(fetchTenant(tenantID)).unwrap();
            const { userRole, ...rest }: any = userDetailsFromAuth;
            dispatch(setUser(rest));
            dispatch(setTenant(fetchedTenant));
            dispatch(setUserRole(getUsersRole(userRole)));
            dispatch(resetSubscribedPackageStateStatus());
            if (!fetchedTenant.tenantRegistered) {
              setShowModal(!fetchedTenant.tenantRegistered);
              setLoading(false);
            } else {
              const { search } = location;
              history.push(`/${search}`);
            }
          }
        } catch (error) {
          setLoading(false);
        }
      })();
    }

    if (status === "pending") {
      setLoading(true);
    }

    if (status === "rejected") {
      history.push("/login");
    }
    return () => {
      setLoading(false);
    };
  }, [status, userDetailsFromAuth, dispatch, history, location, setShowModal]);

  useEffect(() => {
    let authCodeReceived = false;
    async function receiveMessage(event: $TSFixMe) {
      if (event.origin === process.env.REACT_APP_CLIENT_URL && !authCodeReceived) {
        //once authCode is recepted,
        //check if object & isAuthCode.
        const receptedObject = event.data;
        if (receptedObject?.isAuthCode) {
          //if authcode, triggerlogin.
          try {
            authCodeReceived = true;
            const res: $TSFixMe = await dispatch(
              getUserDetailsFromAuthCode({
                serviceProvider: "MICROSOFT",
                authCode: event.data.authCode,
              })
            ).unwrap();
            setUserDetailsFromAuthCode(res.data);
          } catch (error) {
            console.trace("error:", error);
            history.push("/login")
          }
        }
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => {
      setLoading(false);
      window.removeEventListener("message", receiveMessage);
    };
  }, [dispatch, history]);

  const handlePopup = async () => {
    const encodedRedirectUrl = encodeURIComponent(
      process.env.REACT_APP_CLIENT_URL + process.env.PUBLIC_URL + CONSTANTS.GC
    );
    const URL = `${CONSTANTS.REACT_APP_MICROSOFT_AUTH_URL}?client_id=${process.env.REACT_APP_MICROSOFT_AUTH_CLIENT_ID}
        &scope=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_SCOPE}&redirect_uri=${encodedRedirectUrl}&response_mode=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_RESPONSE_MODE}
        &response_type=${CONSTANTS.REACT_APP_MICROSOFT_AUTH_RESPONSE_TYPE}&prompt=${CONSTANTS.REACT_APP_AUTH_PROMPT}`;
    const popupWinWidth = 600;
    const popupWinHeight = 800;
    const left = Math.floor((window.screen.width - popupWinWidth) / 2);
    const top = Math.floor((window.screen.height - popupWinHeight) / 4);
    const popup = window.open(
      URL,
      "Microsoft Login",
      `width=${popupWinWidth},height=${popupWinHeight},top=${top},left=${left}`
    );
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    popup.focus();
  };

  return (
    <>
      <Button className={styles.button} onClick={handlePopup}>
        {loading ? <Spinner className={appStyles.spinner} animation="border" /> : <BsMicrosoft />}
        Microsoft Login
      </Button>
    </>
  );
}

export default function LoginMS({ setShowModal }: $TSFixMe) {
  return (
    <MsalProvider instance={msalInstance}>
      <Login setShowModal={setShowModal} />
    </MsalProvider>
  );
}
