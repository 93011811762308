import {Button, Col, Container, Image, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import styles from "./styles/importemployees.module.css";
import PulseAppButton from "../../../components/v2/Redirect/PulseAppButton";
import ImportEmployeesFromProviderModal from "./ImportEmployeesFromProviderModal";
import SendInvitesModal from "./SendInvitesModal";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {WithAccessControl} from "../../../utils/AccessControl";
import {useGetPrivileges} from "../../../utils/AccessControl/useGetPrivileges";
import ToastComponent from "../Toast/ToastComponent";
import googleIcon from "../../../images/login/google-icon.svg"
import mailIcon from "../../../images/login/mail-icon.svg"
import linkIcon from "../../../images/login/link.svg"
import microsoftIcon from "../../../images/login/microsoft-icon.svg"
import {useHistory} from "react-router";
import {Tenant, User} from "../../../types/userTypes";
import {CONSTANTS} from "../../../utils/constants";
import {actions} from "../../../utils/AccessControl/actions";
import {Label} from "../../../components/Label";
import "./styles/backdrop-modal.css";
import { useAppDispatch } from "redux/hooks";
import { setUserRole, getUsersRole, setUser } from "redux/userSlice";
import { resetSubscribedPackageStateStatus } from "redux/subscriptionSlice";
import { fetchTenant, setTenant } from "redux/tenantSlice";
import { getUserInfo } from "redux/authSlice";
import { getDataFromAppStore } from "dataStore/appDataStore";
import { appConstants } from "utils/appConstants";
import { getCheckoutURL } from "dataFetcher/Subscription/subscriptionDataFetcher";

function ShowSuccessMessage({label, showSuccessToast, onToastCloseFunction}
  : { label: string, showSuccessToast: boolean, onToastCloseFunction: () => void }) {
      const dispatch = useAppDispatch();

    return (
      <ToastComponent
        showCloseIcon={false}
        type="success"
        text={<div className="b2 medium shade-two"><Label name={label}/></div>}
        show={showSuccessToast}
        onClose={onToastCloseFunction}
      />
    );
}
function ShowErrorMessage({label, showErrorToast, onToastCloseFunction}
    : { label: string, showErrorToast: boolean, onToastCloseFunction: () => void }) {
      return (
        <ToastComponent
          showCloseIcon={false}
          type="warning"
          text={<div className="b2 medium shade-two"><Label name={label}/></div>}
          show={showErrorToast}
          onClose={onToastCloseFunction}
        />
      );
  }
function InviteOptions({ requiredPrivileges, privileges, showSkipButton = false }: $TSFixMe) {
    const history = useHistory();
    const { serviceProvider, employeeID } = useAppSelector((state) => state.userManager.user);
    const tenant: Tenant = useAppSelector((state) => state.tenantManager.tenant);

    const [option, setOption] = useState<string>("");
    const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
    const [showErrorToast , setShowErrorToast] = useState<boolean>(false);
    const [successToastLabel, setSuccessToastLabel] = useState<string>("");
    const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
    const [showInvitesModal, setShowInvitesModal] = useState<boolean>(false);
    const userPrivileges: any =  useAppSelector((state) => state.userManager.userRole?.privileges);

    const isUpgradeProcess = getDataFromAppStore(appConstants.IS_UPGRADE_PROCESS, false);  
 
    useGetPrivileges({
        requiredPrivileges,
        privileges,
        componentPrivileges: [actions.IMPORT_EMPLOYEES],
    });

 
    const onImportFromProviderClick = async (option: string) => {
        if (userPrivileges.findIndex((privilege: { privilegeName: any; }) => privilege.privilegeName  === actions.IMPORT_EMPLOYEES) > -1) {
            setOption(option);
            setShowProviderModal(true);
     }
    };

    const onSendInvitesClick = async () => {
     if (userPrivileges.findIndex((privilege: { privilegeName: any; }) => privilege.privilegeName  === actions.IMPORT_EMPLOYEES) > -1) {
            setShowInvitesModal(true);
       }
    };

    const onShareLinkClick = () => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(process.env.REACT_APP_CLIENT_URL + '/login');
            setSuccessToastLabel("ui.employees.import.copy-invite-link.success");
            setShowSuccessToast(true);
        }
    };

    const goToGetStartedPage = async () => {
        if (isUpgradeProcess) {
          const checkoutURL = await getCheckoutURL(2, tenant.tenantID, employeeID, isUpgradeProcess);
          if (checkoutURL) {
            window.open(checkoutURL, "_self", "fullscreen=yes");
          }
          return;
        }
        history.push("/getStarted-enterprise");
      };

    const closeToast = () => {
        setShowSuccessToast(false);
    }
    const closeErrorToast = () => {
        setShowErrorToast(false);
    }

    const closeImportEmployeesModalAfterImport = () => {
        setShowProviderModal(false);
        setSuccessToastLabel("ui.employees.import.success");
        setShowSuccessToast(true);
    }

    return (
        <Container fluid>
            <Row className={styles.inviteOptions}>
                { serviceProvider === CONSTANTS.GOOGLE && (<Col>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip><Label name='ui.employees.import.provider' args={['Google']} /></Tooltip>}>
                        <div className={styles.buttonWrapper} onClick={() => onImportFromProviderClick('Google')}>
                            <Image src={googleIcon} className={styles.icons} />
                        </div>
                    </OverlayTrigger>
                </Col>)}
                { serviceProvider === 'MICROSOFT' && (<Col>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip><Label name='ui.employees.import.provider' args={['Microsoft']} /></Tooltip>}>
                        <div className={styles.buttonWrapper} onClick={() => onImportFromProviderClick('Microsoft')}>
                            <Image src={microsoftIcon} className={styles.icons} />
                        </div>
                    </OverlayTrigger>
                </Col>)}
                <Col>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip><Label name='ui.employees.import.email-invites' /></Tooltip>}>
                        <div className={styles.buttonWrapper} onClick={onSendInvitesClick}>
                            <Image src={mailIcon} className={styles.icons} />
                        </div>
                    </OverlayTrigger>
                </Col>
                <Col className={showSkipButton ? styles.colBorderRight : ''}>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip><Label name='ui.employees.import.copy-invite-link.tooltip' /></Tooltip>}>
                        <div className={styles.buttonWrapper} onClick={onShareLinkClick}>
                            <Image src={linkIcon} className={styles.icons} />
                        </div>
                    </OverlayTrigger>
                </Col>
                { showSkipButton && (
                    <Col>
                        <div className={styles.skipButtonWrapper} onClick={goToGetStartedPage}>
                            <span>Skip</span>
                        </div>
                    </Col>
                // <Col>
                //     <div className={styles.skipButtonWrapper}>
                //         <PulseAppButton redirectUrl={tenant.tenantUrl + CONSTANTS.REACT_PULSE_APP_CONTEXT} buttonText={"Skip"} variant={"link"} />
                //     </div>
                // </Col>
                )}
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" onClick={goToGetStartedPage}>
                        <Label name="ui.wizard.next-button-text" />
                    </Button>
                </Col>
            </Row>
            <ShowSuccessMessage showSuccessToast={showSuccessToast} onToastCloseFunction={closeToast} label={successToastLabel}/>
            <ShowErrorMessage showErrorToast={showErrorToast} onToastCloseFunction={closeErrorToast} label={successToastLabel}/>
            { showProviderModal && (<ImportEmployeesFromProviderModal provider={option} closeModalAfterImportFunction={closeImportEmployeesModalAfterImport} setShow={setShowProviderModal}/>) }
            <SendInvitesModal 
            show = {showInvitesModal} 
            setShow={setShowInvitesModal} 
            setShowSuccessToast={setShowSuccessToast}
            setSuccessToastLabel={setSuccessToastLabel} 
            setShowErrorToast = {setShowErrorToast}
            
            />
        </Container>
    );
}

 export default function InviteOptionsWithAccessControl({ showSkipButton }: { showSkipButton: boolean }) {
 
    const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedUser: User = await dispatch(getUserInfo()).unwrap();
        const { userRole, ...rest } = fetchedUser;
        dispatch(setUser(rest));
        dispatch(setUserRole(userRole));
      } catch (error) {
        console.error('Error fetching user info', error);
      }
    };

    fetchData();
  }, []);
    return (
        <WithAccessControl component={InviteOptions} props={{
            'showSkipButton': showSkipButton
        }} />
    );
}
