const TYPE_STRING = "string";
const TYPE_NUMBER = "number";
const TYPE_BOOLEAN = "boolean";
const TYPE_OBJECT = "object";



export function areArraysEqual(prevDependencyValuesArray: any[], newDependencyValuesArray: any[]): boolean {
    if (prevDependencyValuesArray.length !== newDependencyValuesArray.length) {
        return false;
    }
    for (let i = 0; i < prevDependencyValuesArray.length; i++) {
        if (!shallowEqual(prevDependencyValuesArray[i], newDependencyValuesArray[i])) {
            return false;
        }
    }
    return true;
}

export function shallowEqual(firstArgument: any, secondArgument: any) {
    let firstArgumentType:string = typeof firstArgument;
    let secondArgumentType:string = typeof secondArgument;

    if (firstArgumentType != secondArgumentType) {
      return false;
    } else {
      switch (firstArgumentType) {
        case TYPE_OBJECT:
          return shallowEqualObject(firstArgument,  secondArgument);

        case TYPE_STRING:
        case TYPE_NUMBER:
        case TYPE_BOOLEAN:
        default:
          return firstArgument === secondArgument;
      }
    }
}

function shallowEqualObject(object1: {[key: string]: object}, object2: {[key: string]: object}) {
    const object1Keys = Object.keys(object1);
    const object2Keys = Object.keys(object2);

    if (object1Keys.length !== object2Keys.length) {
        return false;
    }

    // Compare all the keys(i.e. fields) of object1 with object2
    for (let i = 0; i < object1Keys.length; i++) {
        const key:string = object1Keys[i];
        let valueForKeyFromObject1 = object1[key];
        let valueForKeyFromObject2 = object2[key];
        if (valueForKeyFromObject1 !== valueForKeyFromObject2) {
            return false;
        }
    }
    return true;
}
