import React from "react";
import hibob1Logo from "images/hris/integration/hibob-1.png"
import hibob2Logo from "images/hris/integration/hibob-2.png"

function HibobIntegration() {
  return (
    <div className="p-4 m-4">
      <h2>Get your API token</h2>
      <p>
        To access HiBob's APIs, you need to get an API token for your account. To generate your API token
      </p>
      <ol>
        <li>Click your profile picture, and then select API access. </li>
        <img className="m-4" height={300} src={hibob1Logo} />
        <li>Click Generate token </li>
        <img className="m-4" height={150} src={hibob2Logo} />
      </ol>

      <h2>Get your Webhooks API token</h2>

      <p>Login to your Hibob account.</p>
      <p>Under Settings - &gt; Integrations -&gt; Automation -&gt; Webhooks.</p>
      <p>Create a new subscriber.</p>
      <p>Add a name and configure the Webhook URL provided on the HRIS selector screen on Ayraa.</p>
      <p>Select the All following events:</p>
      <ol>
        <li>Employee Created</li>
        <li>Employee Activated</li>
        <li>Employee Inactivated</li>
        <li>Employee Deleted</li>
        <li>Employee Updated</li>
        <li>Employee Joined</li>
        <li>Employee Left</li>
      </ol>
      <p>Once done, you can get the Webhook API token by selecting the manage option on this newly configured subscriber. Click the eye icon on secret to unmask the token.</p>
      <p>Add this as the Webhook API Key</p>
    </div>
  );
}

export default HibobIntegration;
