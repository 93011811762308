import {Container} from "react-bootstrap";
import styles from "./joinworkspace.module.css";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import OrganizationDropDown from "../Organization/OrganizationDropDown";
import {Redirect} from "react-router-dom";
import {useLocation} from "react-router";
import {getOrganizationsForTenant} from "../../../redux/tenantSlice";
import {getWorkspaceDetails} from "../../../redux/workspaceSlice";
import {Label} from "../../../components/Label";
import AppSpinner from "../../../components/Spinner/AppSpinner";

export default function JoinWorkspace() {
  const dispatch = useAppDispatch();
  const {user} = useAppSelector((state) => state.userManager);

  const {
    tenant: {tenantID},
    tenantGroups: {list, status},
  }: $TSFixMe = useAppSelector((state) => state.tenantManager);

  const {workspaceDetails} = useAppSelector((state) => state.workspaceManager);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getWorkspaceDetails({tenantID}));
      dispatch(getOrganizationsForTenant({tenantID}));
    }
  }, [tenantID, status]);

  const location = useLocation();
  const numberOfEmployees: string = workspaceDetails?.numberOfEmployees as unknown as string;



  return (
    <Redirect
        to={{
          pathname: "/",
          state: {from: location},
        }}
      />
  )

 {/**
Donot delete the code.
 return (
    (user && user.status === "Active") ? <Redirect
        to={{
          pathname: "/",
          state: {from: location},
        }}
      /> :

      (workspaceDetails && workspaceDetails.workspaceOwner) ?
        <Container className={styles.container}>
          <div className={styles.body}>
            <span className={styles.title}><Label name="ui.workspace.join.greeting" args={[user.employeeFirstName]} /></span>
            {(user && user.status === "Invited") ?
              <div className={styles.description}>
                  <Label name="ui.workspace.join.invitation-text" args={[
                      workspaceDetails.workspaceOwner.employeeFirstName,
                      workspaceDetails.workspaceOwner.employeeLastName,
                      workspaceDetails.workspaceOwner.workEmailID,
                      workspaceDetails.workspaceName
                  ]} />
              </div>
              :
              <div className={styles.description}>
                  <Label name="ui.workspace.join.description" args={[workspaceDetails.workspaceName]} />
              </div>
            }
            <div className={styles.frame}>
              <OrganizationDropDown title={workspaceDetails.workspaceName} subTitle={numberOfEmployees} organizations={list}/>
            </div>
          </div>
        </Container> :
          <></>
  );*/}
}