import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getAllSubscriptionPackages } from "redux/subscriptionSlice";
import { useQueryParams } from "./useQueryParams";

export const useGetSubscriptionPackage = () => {
  const [loading, setLoading] = useState(true);
  const [subscriptionPackage, setSubscriptionPackage] = useState(null);

  const { status, subscriptionPackages }: $TSFixMe = useAppSelector(
    (state) => state.subscriptionManager.subscriptionPackagesState
  );

  const subscriptionPackageID = useQueryParams("subscriptionPackageID");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === "idle") {
      dispatch(getAllSubscriptionPackages());
    }
    if (status === "pending") {
      setLoading(true);
    }
    if (status === "rejected") {
      setLoading(false);
    }
    if (status === "fulfilled") {
      if (subscriptionPackageID) {
        setSubscriptionPackage(
          subscriptionPackages.find(
            (e: $TSFixMe) => e.subscriptionPackageID === Number(subscriptionPackageID)
          )
        );
      }
      setLoading(false);
    }
  }, [status, dispatch, subscriptionPackageID, subscriptionPackages]);

  return { loading, subscriptionPackage, subscriptionPackageID };
};
