import {Button, Container, Form} from "react-bootstrap";
import styles from "./createworkspace.module.css";
import React, {ChangeEvent, FormEvent, useEffect, useState, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import { WithAccessControl} from "../../../utils/AccessControl";
import {useGetPrivileges} from "../../../utils/AccessControl/useGetPrivileges";
import {createWorkspace, updateWorkspace, getWorkspaceDetails} from "../../../redux/workspaceSlice";
import {Workspace} from "../../../types/userTypes";
import {useHistory, useLocation} from "react-router";
import {addDataToAppDataStore, getDataFromAppStore} from "../../../dataStore/appDataStore";
import {appConstants} from "../../../utils/appConstants";
import {Label} from "../../../components/Label";
import {actions} from "../../../utils/AccessControl/actions";
import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import classNames from "classnames";
import { Progress } from "components/Progress/Progress";
import { getUserDeviceType } from "utils/browserUtils";
import { isMobileApp } from "utils/mobileUtils";

function CreateWorkspace({
                             requiredPrivileges,
                             privileges
                         }: $TSFixMe) {
    const dispatch = useAppDispatch();
    const history = useHistory();
    
    // Parse query parameters from the URL
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const upgradeParam = searchParams.get('upgrade');

    const { employeeID, employeeFirstName } = useAppSelector((state) => state.userManager.user);
    const { tenantID, emailDomain } = useAppSelector((state) => state.tenantManager.tenant);
    const {workspaceDetails, status} = useAppSelector((state) => state.workspaceManager);
    const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);

    const [loading, setLoading] = useState(false);
    const [workspaceName, setWorkspaceName] = useState("");
    const [workSpaceId, setWorkSpaceId] = useState(0);
    const [workspaceNameError, setWorkspaceNameError] = useState<{ name: string, args?: string[] } | null>(null);
    const workspaceNameMaxLength = 50;
    const workspaceNameMinLength = 3;
    const validationTimerRef = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (status === "idle") {
            dispatch(getWorkspaceDetails({tenantID}));
        }
        if (workspaceDetails && workspaceDetails.workspaceName) {
            setWorkspaceName(workspaceDetails.workspaceName)
            setWorkSpaceId(workspaceDetails.id);
        }
    }, [tenantID, status, workspaceDetails]);

     useGetPrivileges({
        requiredPrivileges,
        privileges,
        componentPrivileges: [actions.CREATE_WORKSPACE],
    });

    const onSubmitClick = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (privileges[actions.CREATE_WORKSPACE]) {
            setLoading(true);
            if (workSpaceId > 0 && workspaceDetails.workspaceName !== workspaceName) {
                const workspaceDetails: Workspace = {
                    'id': workSpaceId,
                    'workspaceName': workspaceName.trim(),
                    'createdBy': employeeID
                }
                await dispatch(updateWorkspace({workspaceDetails, tenantID})).unwrap();
            } else if (workSpaceId === 0) {
                const workspaceDetails: Workspace = {
                    'id': 0,
                    'workspaceName': workspaceName.trim(),
                    'createdBy': employeeID
                }
                await dispatch(createWorkspace({workspaceDetails, tenantID})).unwrap();
                addDataToAppDataStore(appConstants.IS_COMPANY_MODE, true);
            }
            setLoading(false);
            if (upgradeParam === "true") {
              addDataToAppDataStore(appConstants.IS_UPGRADE_PROCESS, true);
            }
              history.push("/importEmployees")
            // } else {
            //   history.push("/integration")
            // }
            
        } else {
            history.push("/login")
        }
    };

    const validateInputText = (value: string) => {
        if (!value) {
            setWorkspaceNameError({
                name: 'ui.workspace.name.validation.empty'
            });
        } else if (!validateWorkspaceName(value) || value.length < workspaceNameMinLength) {
            setWorkspaceNameError({
                name: 'ui.workspace.name.validation.length',
                args: [workspaceNameMinLength.toString()]
            });
        } else {
            setWorkspaceNameError(null);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setWorkspaceName(value);

        if(validationTimerRef.current) {
            clearTimeout(validationTimerRef.current);
        }
        validationTimerRef.current = setTimeout(() => { validateInputText(value)} , 1000)
        
    };

    const validateWorkspaceName = (value: string) => {
        return (value.length >= workspaceNameMinLength);
    };

  const formTextClassNames = classNames(styles.formText, {
    [styles.formTextWithErrors]: workspaceNameError,
  });

  if (getUserDeviceType() === "mobile" || isMobileApp()) {
    history.push("/")
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.title}>
          <Label name="ui.workspace.creation.title" args={[employeeFirstName]} />
        </div>
        <div className={styles.description}>
          <Label name="ui.workspace.creation.description" />
        </div>
        <div className={styles.info}>
          <Label name="ui.workspace.creation.note" />
        </div>
        <div className={styles.form}>
          <Form onSubmit={onSubmitClick} autoComplete="off">
            <Form.Group controlId="workspaceName" className={styles.workspaceNameFormGroup}>
              <Form.Control
                className={styles.workspaceName}
                type="text"
                value={workspaceName}
                onChange={handleChange}
                disabled={loading}
                placeholder={labels["ui.workspace.creation.name.placeholder"]}
                maxLength={workspaceNameMaxLength}
                isInvalid={!!workspaceNameError}
                style={{ backgroundImage: "none" }}
              />
              <span className={styles.charCounter}>
                {workspaceNameMaxLength - workspaceName.length}/{workspaceNameMaxLength}
              </span>
              <Form.Control.Feedback type="invalid" className={styles.formError}>
                {workspaceNameError && (
                  <Label name={workspaceNameError.name} args={workspaceNameError.args} />
                )}
              </Form.Control.Feedback>
              <Form.Text className={formTextClassNames + " text-muted"}>
                <Label name="ui.workspace.creation.form-text" args={[emailDomain]} />
              </Form.Text>
            </Form.Group>
            <Button
              type="submit"
              disabled={loading || workspaceName.length === 0 || !!workspaceNameError}
            >
              {loading ? (
                <Label name="ui.workspace.creation.workspace-creating-text" />
              ) : (
                <Label name="ui.wizard.next-button-text" />
              )}
            </Button>
          </Form>
        </div>
      </div>
      <FadedLogoWatermark className={styles.rightContainer} showCloseIcon={true}/>
      {/* <Progress activeStep={1} noOfSteps={2} className={styles.progress} /> */}
    </div>
  );
}

export default function CreateWorkspaceWithAccessControl() {
  return <WithAccessControl component={CreateWorkspace} />;
}
