import {Alert, Button, Col, Form, Image, Row} from "react-bootstrap";
import styles from "./createorganization.module.css";
import React, {ChangeEvent, KeyboardEvent, useState, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {WithAccessControl} from "../../../utils/AccessControl";
import {useGetPrivileges} from "../../../utils/AccessControl/useGetPrivileges";
import {CreateOrganizationsRequest} from "../../../types/userTypes";
import {useHistory, useLocation} from "react-router";
import cancelIcon from "images/icons/cancel-icon.svg";
import {createOrganizations} from "../../../redux/tenantSlice";
import BackLogo from "../../../images/back-icon.svg"
import {actions} from "../../../utils/AccessControl/actions";
import {getDataFromAppStore} from "../../../dataStore/appDataStore";
import {appConstants} from "../../../utils/appConstants";
import {Label} from "../../../components/Label";
import { regexPatterns } from "utils/constants";
import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import {EnterIcon} from "../../../utils/icons";
import { Redirect } from "react-router";
function CreateOrganization({
                             requiredPrivileges,
                             privileges
                         }: $TSFixMe) {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { employeeID } = useAppSelector((state) => state.userManager.user);
    const { tenantID } = useAppSelector((state) => state.tenantManager.tenant);

    const [loading, setLoading] = useState<boolean>(false);
    const [orgNameInput, setOrgNameInput] = useState<string>("");
    const [orgNames, setOrgNames] = useState<string[]>([]);
    const [orgNameError, setOrgNameError] = useState<{ name: string, args?: string[] } | null>(null);
    const orgNameMaxLength = 40;
    const orgNameMinLength = 2;
    const validationTimerRef = useRef<NodeJS.Timer>();
    const location = useLocation();


    const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);
    
    useGetPrivileges({
        requiredPrivileges,
        privileges,
        componentPrivileges: [actions.CREATE_ORGANIZATION],
    });
    const onSubmitClick = async () => {
        if(orgNames.length === 0){
            setOrgNameError({
                name: 'ui.organization.name.validation.empty'
            });
        } else if (privileges[actions.CREATE_ORGANIZATION] && orgNames && orgNames.length > 0) {
            setLoading(true);
            const createOrganizationRequest: CreateOrganizationsRequest = {
                'orgNames': orgNames,
                'tenantId': tenantID,
                'createdBy': employeeID
            }
            await dispatch(createOrganizations(createOrganizationRequest)).unwrap();
            setLoading(false);
            history.push(`/importEmployees`);
        } else{
            setOrgNameError({name: 'ui.organization.no.create.access'});
        }
    };

    const removeOrg = (index: number) => setOrgNames((items) => items.filter((_, i) => i !== index));

    const orgValidationOnChange = (text: string) => {
        if (!validateOrgName(text) || text.length < orgNameMinLength) {
            setOrgNameError({
                name: 'ui.organization.name.validation.invalid',
                args: [orgNameMinLength.toString()]
            });
        } else {
            setOrgNameError(null);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setOrgNameInput(value);
        if(validationTimerRef.current) {
            clearTimeout(validationTimerRef.current);
        }
        validationTimerRef.current = setTimeout(() => {orgValidationOnChange(value)}, 1000)
    };

    const validateOrgName = (value: string) => {
        return !!value.match(regexPatterns.orgNamePattern); //this will admit letters, numbers and dashes
    };

    const backButtonClickHandler = () => {
        history.push("/createWorkspace");
    }

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && orgNameInput.length >= orgNameMinLength && orgNameInput.length <= orgNameMaxLength
          && validateOrgName(orgNameInput)) {
            setOrgNames((oldState) => [...oldState, orgNameInput.trim()]);
            setOrgNameInput("");
        }
    };


    return (
        <Redirect
            to={{
              pathname: "/",
              state: {from: location},
            }}
          />
      )
    

   {/**


 return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <Image src={BackLogo} className={styles.backButton} onClick={backButtonClickHandler}/>
                <div className={styles.title}><Label name="ui.organization.creation.title" /></div>
                <div className={styles.description}><Label name="ui.organization.creation.description" /></div>
                <Form className={styles.form} onSubmit={e => { e.preventDefault(); }}>
                        <Form.Group controlId="orgNames" className={styles.orgNamesFormGroup}>
                            <Row>
                                <Col xs={12} className={styles.orgNameContainer}>
                                    {orgNames && orgNames.map((orgName, index) => {
                                        return (
                                            <Alert variant="secondary" className={styles.orgBubbles}>
                                                <p className={styles.orgBubbleText}>{orgName}</p>
                                                <Image className={styles.closeButton} src={cancelIcon} onClick={() => removeOrg(index)}/>
                                            </Alert>
                                        )
                                    }
                                    )}
                                    <Form.Control className={styles.orgNameText} type="text" value={orgNameInput}
                                                onChange={handleChange}
                                                onKeyUp={handleKeyUp}
                                                disabled={loading}
                                                placeholder={labels['ui.organization.name.placeholder']}
                                                maxLength={orgNameMaxLength}
                                                isInvalid={!!orgNameError} style={{ backgroundImage: "none" }}/>
                                    <EnterIcon className={styles.enterIcon} />
                                    {orgNameError && <div className={styles.formError}>
                                        <Label name={orgNameError.name} args={orgNameError.args} />
                                    </div>}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Button disabled={loading || orgNames.length === 0 || !!orgNameError} onClick={onSubmitClick}>
                            <Label name="ui.wizard.next-button-text" />
                        </Button>
                </Form>
            </div>
            <FadedLogoWatermark className={styles.rightContainer} />
        </div>
    );*/}
}

 export default function CreateOrganizationWithAccessControl() {
    return (
        <WithAccessControl component={CreateOrganization} />
    );
}