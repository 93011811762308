import axios from "axios";

export async function ayraaAdminLogin({ username, password }: { username: string; password: string }) {
    try {
        const response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/ayraaAdminLogin",
            {
                auth: {username, password},
            }
        );
        return response.status;
    } catch (error: any) {
        return error?.response.status;
    }
}

export async function ayraaAdminLogout() {
    try {
        const response = await axios.get(
            process.env.REACT_APP_SERVER_URL + "/ayraaAdminLogout"
        );
        return response.status;
    } catch (error: any) {
        return error?.response.status;
    }
}
