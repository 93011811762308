import React from "react";

function adpIntegration() {
  return (
    <div className="p-4 m-4">
      <h2>Client Credentials</h2>
      <p>
        ADP issues a unique set of credentials (Client ID and Client secret) for each organization.
        If you don't have this information, contact your ADP representative.
      </p>
      <h2>Keystore</h2>A private key and matching Web Services (WS) Certificate is required to
      access ADP web services. The WS Certificate provides client information to ADP and the
      matching private key confirms the authenticity of the client. For this, create a Certificate
      Signing Request (CSR). During the CSR generation, the private key and matching public key are
      created. The CSR must be submitted to the ADP Web Services Certificate Authority, and they
      return the WS Certificate. Many software tools store the private key and the WS Certificate in
      one Personal Information Exchange Format (PFX), also known as a P12 file. To complete a
      Certificate Signing Request, do the following:
      <ol>
        <li>
          Download OpenSSL Light for Windows at: http://slproweb.com/products/Win32OpenSSL.html. Mac
          users can open Terminal and jump to the OpenSSL commands in step 5.{" "}
        </li>
        <li>
          To install OpenSSL, follow the instructions in the Install Wizard. Open cmd.exe. Go to the
          location where you installed OpenSSL and at the command line, type cd C:\Program Files
          (x86)\OpenSSL-Win32\bin (or C:\Program Files\OpenSSL-Win64\bin for 64-bit).
        </li>
        <li>
          Generate the CSR: <b>openssl genrsa -out companyname_auth.key 2048 openssl req -new -key
          companyname_auth.key -out companyname_auth.csr</b>
        </li>

        <li>
          Enter the following information into your CSR. Leave the challenge password blank. Country
          Name State or Province Name Locality Name Organization Name (must be the same string used
          by your organization when registered with ADP) Common Name (use something meaningful, such
          as CompanyName Corp Mutual SSL or whatever best describes the usage and identifies this as
          the Mutual SSL Authentication certificate) Challenge password (leave this field blank)
        </li>
        <li>
          After you have created your CSR, follow these steps to submit your request directly to ADP
          Security Services: Open the{" "}
          <a href="https://cert-manager.com/customer/adp/device/adpwebservices">
            ADP Certificate Signing Tool
          </a>{" "}
          (no login is required). Choose Authentication and transaction signing for the certificate
          type. Enter your technical contact’s email. Enter your company name and ADP client ID.
          Note: If you are an ADP Workforce Now client, your ADP client ID is all the characters to
          the right of the @ symbol in your ADP Workforce Now login name. If you don't know your ADP
          client ID, contact your ADP representative. Enter your technical contact’s first and last
          name and a group distribution list to be automatically notified when the generated
          certificate is reaching its two-year expiration date. Paste the complete contents
          (including BEGIN CERTIFICATE REQUEST and END CERTIFICATE REQUEST) of your CSR into the CSR
          text box. Click Submit. It might take up to a week to sign the CSR.
        </li>

        <li>
          Save the signed certificate from ADP into a file named companyname_auth.pem in the same
          location that you initially created the CSR (C:\Program Files (x86)\OpenSSL-Win32\bin).
        </li>
        <li>
          If you are using Windows/IIS, use the following command to get the key and certificate in
          PKCS12 format: <b>openssl pkcs12 -export -out companyname_auth.p12 -name “Company Name Mutual
          SSL” -inkey companyname_auth.key -in companyname_auth.pem</b>
        </li>
        <li>Enter the Export Password. Remember the export password.</li>
      </ol>
      <p>
        Upload companyname_auth.p12 in Keystore section and export password set in step 8 in
        Keystore password field.
      </p>
    </div>
  );
}

export default adpIntegration;
