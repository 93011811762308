import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "./gridConfig";
import {
  ImageRenderer,
  UpdateAddress,
  UploadImage,
  UpdateText,
  SelectionUpdate,
  AsyncManagerUpdate,
} from "./components";
import ImportEmployeeExcel from "./ImportEmployeeExcel";
import ExportEmployeeExcel from "./ExportEmployeeExcel";
import axios from "axios";
import styles from "./manageEmployees.module.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { WithAccessControl } from "utils/AccessControl";
import { useGetPrivileges } from "utils/AccessControl/useGetPrivileges";
import { useAppSelector } from "redux/hooks";
import GroupRenderer from "./components/Renderers/GroupRenderer";
import AsyncGroupUpdate from "./components/Editors/AsyncGroupUpdate";
import {actions} from "../../utils/AccessControl/actions";

const getAllEmployeesForTenant = async ({
  tenantID,
  excludePending = true,
  excludeDeactivated = true,
}: $TSFixMe) => {
  const res = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/getAllEmployeesForTenant/${tenantID}?excludeDeactivated=${excludeDeactivated}&excludePending=${excludePending}`
  );
  return res.data;
};

function ManageEmployees({ requiredPrivileges, privileges }: $TSFixMe) {
  const {
    tenant: { tenantID },
    employees: { status },
  }: $TSFixMe = useAppSelector((state) => state.tenantManager);

  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState(null);

  useGetPrivileges({
    requiredPrivileges,
    privileges,
    componentPrivileges: [
      actions.EXPORT_EMPLOYEES,
      actions.IMPORT_EMPLOYEES,
      actions.UPDATE_EMPLOYEE,
    ],
  });

  useEffect(() => {
    if (gridApi) {
      (async function () {
        const employeeList = await getAllEmployeesForTenant({
          tenantID,
          excludeDeactivated: false,
          excludePending: false,
        });
        setRowData(employeeList);
      })();
    }
  }, [status, gridApi, tenantID]);

  const onGridReady = ({ api }: $TSFixMe) => {
    setGridApi(api);
  };

  const suppressEnterOrTab = (params: $TSFixMe) => {
    const KEY_ENTER = 13;
    const event = params.event;
    const key = event.which;
    return params.editing && (params.event.key === "Tab" || key === KEY_ENTER);
  };

  const onImportComplete = async () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    gridApi.showLoadingOverlay();
    const employeeList = await getAllEmployeesForTenant({
      tenantID,
      excludeDeactivated: false,
      excludePending: false,
    });
    setRowData(employeeList);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    gridApi.hideOverlay();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h4>Manage employees</h4>
          <small>Note: Columns with (*) are editable</small>
        </div>
        <div className={styles.gridActions}>
          {privileges[actions.IMPORT_EMPLOYEES] && (
            <ImportEmployeeExcel onImportComplete={onImportComplete} />
          )}
          {privileges[actions.EXPORT_EMPLOYEES] && <ExportEmployeeExcel />}
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "calc(100vh - 197px)" }}>
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columnDefs({ editable: privileges[actions.UPDATE_EMPLOYEE] })}
          frameworkComponents={{
            showProfileImage: ImageRenderer,
            groups: GroupRenderer,
            uploadImage: UploadImage,
            updateText: UpdateText,
            selectionUpdate: SelectionUpdate,
            updateAddress: UpdateAddress,
            asyncManagerUpdate: AsyncManagerUpdate,
            groupUpdate: AsyncGroupUpdate,
          }}
          rowHeight={50}
          rowData={rowData}
          stopEditingWhenCellsLoseFocus={false}
          suppressKeyboardEvent={suppressEnterOrTab}
        />
      </div>
    </div>
  );
}

export default function ManageEmployeesWithAccessControl() {
  return <WithAccessControl component={ManageEmployees} />;
}
