import { format } from "date-fns-tz";
import { differenceInYears } from "date-fns";

const formatDate = (date = null) => {
  if (date) {
    return format(new Date(date), "P");
  }
  return null;
};

const maxForDOB = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return currentDate;
};

const whiteSpaceValidation = (value: $TSFixMe) => {
  if (!value.trim())
    return {
      isValid: false,
      message: "Field cannot be only space(s)",
    };
  return {
    isValid: true,
    message: "",
  };
};

const ageLimitValidation = (value: $TSFixMe) => {
  const ageInYears = differenceInYears(new Date(), new Date(value));
  if (ageInYears <= 13)
    return {
      isValid: false,
      message: "age needs to be above 13",
    };

  return { isValid: true, message: "" };
};

export const columnDefs = ({ editable }: $TSFixMe) => [
  { field: "externalEmployeeId", headerName: "ID" },
  {
    field: "gender",
    cellEditor: "selectionUpdate",
    cellEditorParams: {
      inputProps: {
        label: "Select gender",
        options: ["Male", "Female"],
      },
    },
    editable,
    headerName: "Gender*",
  },
  {
    field: "employeeTitle",
    cellEditor: "selectionUpdate",
    cellEditorParams: {
      inputProps: {
        label: "Select title",
        options: ["Mr", "Miss", "Mrs"],
      },
    },
    editable,
    headerName: "Title*",
  },
  {
    headerName: "First name*",
    field: "employeeFirstName",
    editable,
    sortable: true,
    cellEditor: "updateText",
    cellEditorParams: {
      inputProps: {
        type: "text",
        validations: { required: "First name is required" },
        label: "Update first name",
        placeholder: null,
      },
    },
  },
  {
    headerName: "Last name*",
    field: "employeeLastName",
    editable,
    sortable: true,
    cellEditor: "updateText",
    cellEditorParams: {
      inputProps: {
        type: "text",
        validations: { required: "Last name is required" },
        label: "Update last name",
        placeholder: null,
      },
    },
  },
  {
    field: "workEmailID",
    headerName: "Email Id",
  },
  {
    field: "addressLine1",
    cellEditor: "updateAddress",
    editable,
    headerName: "Address line 1*",
  },
  {
    field: "addressLine2",
    cellEditor: "updateAddress",
    editable,
    headerName: "Address line 2*",
  },
  { field: "city", cellEditor: "updateAddress", editable, headerName: "City*" },
  { field: "state", cellEditor: "updateAddress", editable, headerName: "State*" },
  { field: "country", cellEditor: "updateAddress", editable, headerName: "Country*" },
  { field: "zipCode", cellEditor: "updateAddress", editable, headerName: "Zip code*" },
  {
    field: "managerEmailID",
    editable,
    cellEditor: "asyncManagerUpdate",
    cellEditorParams: {
      inputProps: {
        label: "Update manager",
      },
    },
    headerName: "Manager*",
  },
  {
    field: "workPhone",
    headerName: "Work phone*",
    editable,
    cellEditor: "updateText",
    cellEditorParams: {
      inputProps: {
        type: "tel",
        validations: {
          required: "Phone number is required",
          maxLength: { value: 10, message: "Invalid phone number" },
          pattern: { value: /\d{10}/, message: "Invalid phone number" },
        },
        label: "Update phone number",
        placeholder: null,
      },
    },
  },
  {
    field: "designation",
    headerName: "Designation*",
    editable,
    cellEditor: "updateText",
    cellEditorParams: {
      inputProps: {
        type: "text",
        validations: {
          required: "Designation is required",
          validate: (value: $TSFixMe) => {
            const validatedValue = whiteSpaceValidation(value);
            if (validatedValue.isValid) return true;
            return validatedValue.message;
          },
        },
        label: "Update designation",
        placeholder: null,
      },
    },
  },
  {
    field: "employeeGroups",
    headerName: "Department*",
    cellRenderer: "groups",
    editable,
    cellEditor: "groupUpdate",
  },
  {
    field: "dateOfBirth",
    headerName: "Date of birth*",
    valueGetter: ({ data }: $TSFixMe) => formatDate(data.dateOfBirth),
    editable,
    cellEditor: "updateText",
    cellEditorParams: {
      inputProps: {
        type: "date",
        label: "Update date of birth",
        validations: {
          required: "Date of birth is required",
          max: {
            value: format(maxForDOB(), "yyyy-MM-dd"),
            message: "Invalid date",
          },
          validate: (value: $TSFixMe) => {
            const validatedValue = ageLimitValidation(value);
            if (validatedValue.isValid) return true;
            return validatedValue.message;
          },
        },
        placeholder: null,
        formatValue: (data: $TSFixMe) => new Date(data).toISOString(),
      },
    },
  },
  {
    headerName: "Personal email Id*",
    field: "personalEmailID",
    editable,
    cellEditor: "updateText",
    cellEditorParams: {
      inputProps: {
        type: "email",
        validations: {
          required: "Email is required",
          pattern: {
            value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            message: "Invalid email",
          },
        },
        label: "Update email",
        placeholder: null,
      },
    },
  },
  {
    headerName: "Personal phone*",
    field: "personalPhone",
    editable,
    cellEditor: "updateText",
    cellEditorParams: {
      inputProps: {
        type: "tel",
        validations: {
          required: "Phone number is required",
          maxLength: { value: 10, message: "Invalid phone number" },
          pattern: { value: /\d{10}/, message: "Invalid phone number" },
        },
        label: "Update phone number",
        placeholder: null,
      },
    },
  },
  {
    field: "photoUrl",
    cellRenderer: "showProfileImage",
    // cellEditor: "uploadImage",
    // editable,
  },
  { field: "status" },
];
