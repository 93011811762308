import {useState} from "react";
import {Modal} from "react-bootstrap";
import {useAppSelector} from "redux/hooks";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
export default function WithAccessControl({ component: Component, props = {} }) {
  const [privileges, setPrivileges] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const currentUserPrivileges: $TSFixMe = useAppSelector((state) => state.userManager.userRole?.privileges);

  const getRequiredPrivileges = (componentPrivilegesList = []) => {
    if (componentPrivilegesList) {
      if (componentPrivilegesList.length > 0) {
        const componentPrivileges = {};
        componentPrivilegesList.forEach((componentPrivilegeName) => {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
          componentPrivileges[componentPrivilegeName] = currentUserPrivileges
              && currentUserPrivileges.findIndex((currentUserPrivilege: $TSFixMe) =>
                  currentUserPrivilege.privilegeName === componentPrivilegeName
              ) > -1;
        });
        setPrivileges(componentPrivileges);
      } else {
        setShowWarning(true);
      }
    }
  };

  if (showWarning) {
    return (
      <Modal show>
        <Modal.Body>
          Add privilege list to <code>requiredPrivileges</code> callback for{" "}
          <strong>{Component.name}</strong> component.
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Component {...props} requiredPrivileges={getRequiredPrivileges} privileges={privileges} />
  );
}
