  import React, { useEffect, useState } from "react";
import { getUsersRole, setUser, setUserAccountStatus, setUserRole } from "redux/userSlice";
import {tokenValidation, getUserInfo} from "redux/authSlice";
import {
  resetSubscribedPackageStateStatus,
} from "redux/subscriptionSlice";
import {useHistory, useLocation} from "react-router";
import { setTenant, fetchTenant } from "redux/tenantSlice";
import { CONSTANTS, regexPatterns } from "utils/constants";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ToastComponent from "../../../pages/v2/Toast/ToastComponent";
import { User } from "../../../types/userTypes";
import { Label } from "../../Label";
import {addDataToAppDataStore, getDataFromAppStore} from "../../../dataStore/appDataStore";
import {appConstants} from "../../../utils/appConstants";
import { ReactComponent as GoogleIcon } from "images/login/google-icon.svg";
import LoginButton from "components/LoginButton/LoginButton";
import { joinWorkspaceOrg } from "redux/workspaceSlice";
import {getUserDeviceType} from "../../../utils/browserUtils";
import {isMobileApp} from "../../../utils/mobileUtils";
import { triggerSubscriptionRequirements } from "utils/subscriptionUtils";
import { updateEmployeeWorkspaceStatus } from "dataFetcher/Workspace/workspaceDataFetcher";

export default function LoginGoogle({ labelName }: { labelName: string }) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [userDetailsFromAuth, setUserDetailsFromAuthCode] = useState<any>(null);

  const {
    status: tokenValidationStatus,
    errorCode,
    error: failedTokenValidationError,
  } = useAppSelector((state :any) => state.authManager.tokenValidation);

  const handlePopup = async () => {
    addDataToAppDataStore(appConstants.SERVICE_PROVIDER_PARAM_LOGIN, CONSTANTS.GOOGLE);
    const redirectUrl = process.env.REACT_APP_CLIENT_URL + process.env.PUBLIC_URL + CONSTANTS.AUTH_CODE_FLOW_URI;
    const state = new Date().getTime();
    const URL = `${CONSTANTS.REACT_APP_GOOGLE_AUTH_URL}?access_type=${CONSTANTS.REACT_APP_GOOGLE_ACCESS_TYPE}&client_id=${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}&redirect_uri=${redirectUrl}&response_type=${CONSTANTS.REACT_APP_GOOGLE_AUTH_RESPONSE_TYPE}&scope=${CONSTANTS.REACT_APP_GOOGLE_AUTH_SCOPE}&prompt=${CONSTANTS.REACT_APP_AUTH_PROMPT}&state=${state}`;
    const popupWinWidth = 600;
    const popupWinHeight = 800;
    const left = Math.floor((window.screen.width - popupWinWidth) / 2);
    const top = Math.floor((window.screen.height - popupWinHeight) / 4);
    const popup = window.open(
        URL,
        "Google Login",
        `width=${popupWinWidth},height=${popupWinHeight},top=${top},left=${left}`
    );
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    popup.focus();
  };

  useEffect(() => {
    if (tokenValidationStatus === "rejected") {
      if (errorCode === "401" && failedTokenValidationError) {
        //@ts-ignore
        const id = failedTokenValidationError.match(regexPatterns.email);
        setErrorMessage(
          <div className="shade-two b3 medium">
            <Label name="ui.login.error.token.invalid" args={[id]} />
          </div>
        );
        setShowErrorToast(true);
      } else {
        //if rejected.
        history.push("/login");
      }
    }
  }, [tokenValidationStatus, errorCode, history]);

  useEffect(() => {
    if (userDetailsFromAuth) {
      loginTheUser();
    }
  }, [userDetailsFromAuth]);

  useEffect(() => {
    let authCodeReceived = false;
    async function receiveMessage(event: $TSFixMe) {
      if (event.origin === process.env.REACT_APP_CLIENT_URL && !authCodeReceived
          && getDataFromAppStore(appConstants.SERVICE_PROVIDER_PARAM_LOGIN) === CONSTANTS.GOOGLE) {
        //once authCode is recepted,
        //check if object & isAuthCode.
        const receptedObject = event.data;
        if (receptedObject?.isAuthCode) {
          //if authcode, triggerlogin.
          try {
            // Get referrer from local storage
            const referrer = localStorage.getItem('originalReferrer') || '';

            authCodeReceived = true;
            const tokenValidationPayload = {
              serviceProvider: CONSTANTS.GOOGLE,
              authCode: event.data.authCode,
              referrer: referrer
            };
            // Validating token
            const res = await dispatch(tokenValidation(tokenValidationPayload)).unwrap();
            setUserDetailsFromAuthCode(res?.data);

            // Clear the referrer from local storage if successful
            localStorage.removeItem('originalReferrer');

            addDataToAppDataStore(appConstants.SERVICE_PROVIDER_PARAM_LOGIN, undefined);
          } catch (error) {
            authCodeReceived = false;
            
          }
        }
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [dispatch, history]);

  const loginTheUser = async() => {
    const fetchedUser: User = await dispatch(getUserInfo()).unwrap();
    const tenantID: number = fetchedUser.tenant.tenantID;
    const fetchedTenant = await dispatch(fetchTenant(tenantID)).unwrap();
    const { userRole, ...rest } = fetchedUser;
    dispatch(setUser(rest));
    dispatch(setTenant(fetchedTenant));
    dispatch(setUserRole(getUsersRole(userRole)));
    dispatch(resetSubscribedPackageStateStatus());

    if(!fetchedTenant.workspaceDetails && !fetchedUser.personalAccount && !(getUserDeviceType() === 'mobile' && !isMobileApp())) {
      //first time user registration. show him the account Selection screen.
      try {
        await triggerSubscriptionRequirements(fetchedTenant);
        await updateEmployeeWorkspaceStatus({
              userData : {personalAccount : true,
                        tenantID : fetchedTenant.tenantID,
                        employeeID : fetchedUser.employeeID },
              updatedBy : fetchedUser.employeeID
        })
        dispatch(setUserAccountStatus(true));
        history.push(`/integration`);
      }
      catch(e) {
        console.log("Something went Wrong!");
        console.log(e);
      }
    } else if (fetchedUser.status === "Invited" && !(getUserDeviceType() === 'mobile' && !isMobileApp())) {
      //currently hasOrgs is commented, thus same action is done as on invited.
      await dispatch(joinWorkspaceOrg({tenantId: tenantID, employeeId: fetchedUser.employeeID}))
      history.push("/");
    } else if(getUserDeviceType() === "mobile"){
      history.push(`/`);
    }
    else {
      const { search } = location;
      history.push(`/${search}`);
    }
  };

  return (
    <>
      <LoginButton
        labelName={labelName}
        icon={GoogleIcon}
        onClick={handlePopup}
      />
      <ToastComponent
        type="warning"
        text={errorMessage}
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        showCloseIcon={false}
      />
    </>
  );
}
