export function checkIfDesktopApp() {
    return (window as any).IS_DESKTOP_APP;
}

function getDesktopAppPlatform() {
    return (window as any).DESKTOP_PLATFORM;
}

export function isDarwin() {
    return getDesktopAppPlatform() === 'darwin'
}

export function getDesktopAppLoginURL(ssoToken: string) {
    return 'ayraa://login?vt=' + encodeURIComponent(ssoToken);
}

export function getDesktopAppImportUsersURL(tenantURL: string, verificationToken: string) {
    return 'ayraa://alternate-login-import-users?vt=' + encodeURIComponent(verificationToken) + '&tenantURL='
        + encodeURIComponent(tenantURL);
}