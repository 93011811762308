import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import { Connect } from "./Connect";
import { ToggleApp } from "./ToggleApp";
import { IntegratedAppNamesEnum } from "pages/Integration/types";
import styles from "./styles/connect.module.css";

interface TopRightElementProps {
  className?: string;
  isAvailable?: boolean;
  componentToShow: "connect-disconnect" | "enable-disable" | "view-in-browser";
  checkConnectionStatus?: () => boolean;
  checkConnectionStatusAsync?: () => Promise<boolean>;
  onConnectClick?: () => Promise<{ isConnected: boolean } | undefined>;
  onDisconnectClick?: () => Promise<{ isDisconnected: boolean } | undefined>;
  onSwitchClick?: (checked: boolean) => Promise<boolean | undefined>;
  checkStatusAsync?: () => Promise<boolean>;
  checkStatus?: () => { isEnabled: boolean };
  isConnected?: boolean;
  disable?: boolean;
  name?: IntegratedAppNamesEnum;
}

export class TopRightElement extends AyraaBaseComponent<TopRightElementProps, {}> {
  public renderCore(): ReactNode {
    if (!this.props.isAvailable) {
      return <div className="shade-five b4 regular">Coming soon</div>;
    }
    if (this.props.componentToShow === "connect-disconnect") {
      if (this.props.isAvailable) {
        return (
          <Connect
            checkConnectionStatusAsync={this.props.checkConnectionStatusAsync}
            checkConnectionStatus={this.props.checkConnectionStatus}
            checkStatus={this.props.checkStatus}
            onConnectClick={this.props.onConnectClick}
            onDisconnectClick={this.props.onDisconnectClick}
            isConnected={this.props.isConnected}
            disable={this.props.disable}
            name={this.props.name}
          />
        );
      }
    }
    if (this.props.componentToShow === "enable-disable") {
      return (
        <ToggleApp
          checkStatusAsync={this.props.checkStatusAsync}
          onChange={this.props.onSwitchClick}
        />
      );
    }
    if (this.props.componentToShow === "view-in-browser") {
      return (
        <div className={styles.viewInBrowser}>
          <a
            href={process.env.REACT_APP_CLIENT_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            View in browser
          </a>
        </div>
      );
    }
    return null;
  }
}
