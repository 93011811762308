import React, { useEffect } from "react";
import RouterConfig from "navigation/RouterConfig";
import { Alert } from "react-bootstrap";
import { useGetAppData } from "hooks/useGetAppData";
import { Toaster } from "react-hot-toast";
import "./App.css";
import TenantDomainRedirect from "./components/Redirect/TenantDomainRedirect";
import { CheckAlternateLoginFlow } from "./hooks/checkAlternateLoginFlow";
import { addDataToAppDataStore, getDataFromAppStore } from "./dataStore/appDataStore";
import { appConstants } from "./utils/appConstants";
import { CONSTANTS } from "./utils/constants";
import { useLocation } from "react-router";
import { getUserBrowser, getUserDeviceType } from "./utils/browserUtils";
import { SafariPostLoginRedirect } from "./components/Redirect/SafariPostLoginRedirect";
import { ReactComponent as Spinner } from "images/spinner/app_spinner.svg";
import { DesktopAppLoginMessage } from "./components/v2/Auth/DesktopAppLoginMessage";
import { isMobileApp } from "./utils/mobileUtils";
import { browserData } from "utils/constants";

export default function App() {
  const { alternateLoginFlow, alternateLoginInProgress } = CheckAlternateLoginFlow();
  const { loading, error, ssoRedirect, tenant } = useGetAppData(alternateLoginFlow);
  addDataToAppDataStore(appConstants.ALTERNATE_ADMIN_LOGIN_FLOW, alternateLoginFlow);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const originalReferrer = searchParams.get('original-referrer');
    console.log("originalReferrer:" + originalReferrer);
    if (originalReferrer) {
      localStorage.setItem('originalReferrer', originalReferrer);
    }
  }, []);

  if (
    ssoRedirect &&
    !alternateLoginFlow &&
    location.pathname !== "/logout" &&
    location.pathname !== "/download" &&
    location.pathname !== "/installation/instructions" &&
    location.pathname !== "/gm" &&
    location.pathname !== "/slackbot-integration" &&
    location.pathname !== "/invite-ayraa-bot" &&
    location.pathname !== "/integration-success" &&
    location.pathname !== "/integration-cancel" &&
    location.pathname !== "/integration-failure" &&
    location.pathname !== "/getStarted" &&
    location.pathname !== "/install-extension" &&
    location.pathname !== "/setup" &&
    location.pathname !== "/createWorkspace" &&
    location.pathname !== "/importEmployees" &&
    location.pathname !== "/getStarted-enterprise" &&
    location.pathname !== "/integration" &&
    location.pathname !== "/integration-company" &&
    !(getUserDeviceType() === "mobile" && !isMobileApp())
  ) {
    if (getUserBrowser() === "Safari") {
      return <SafariPostLoginRedirect />;
    } else {
      return (
        <TenantDomainRedirect
          redirectUrl={tenant.tenantUrl + CONSTANTS.REACT_PULSE_APP_CONTEXT + "/"}
        />
      );
    }
  }

  if (loading && !alternateLoginFlow && !(getUserDeviceType() === "mobile" && !isMobileApp())) {
    return <></>;
  }

  // display desktop login message during alternate login flow
  const channel = getDataFromAppStore(appConstants.CHANNEL_PARAM);
  if (alternateLoginFlow && channel === "desktop") {
    return <DesktopAppLoginMessage />;
  }

  if (alternateLoginInProgress) {
    return (
      <div className="wrapper app-loading">
        <Spinner style={{ width: "100px", height: "100px" }} />{" "}
      </div>
    );
  }

  return (
    <div className="app">
      <Alert show={Boolean(error)} variant="danger">
        {error && error.message}
      </Alert>
      <Toaster position="bottom-right" />
      <RouterConfig />
    </div>
  );
}
