import {CONSTANTS} from "../utils/constants";
import {addDataToAppDataStore, clearDataFromAppStore, getDataFromAppStore} from "../dataStore/appDataStore";
import {appConstants} from "../utils/appConstants";
import {validateAlternateAccountAndImportDirectory} from "../dataSender/auth/AuthDataSender";
import {getDesktopAppImportUsersURL} from "../utils/desktopUtils";
import {useEffect, useState} from "react";

export interface StateParams {
  sp: string,
  tenantId: number,
  email?: string,
  app?:string,
  vt?: string,
  channel?: string
}

const fetchDataFromStateParam = (valArray: string[]) => {
  const state: string = valArray.find(val => val.indexOf("state") > -1) ?? "";
  const stateParam = state.split("=")[1];

  let payloadData = decodeURIComponent(stateParam);
  payloadData = Buffer.from(payloadData, 'base64').toString();
  const stateParams: StateParams = JSON.parse(payloadData);
  addDataToAppDataStore(appConstants.SERVICE_PROVIDER_PARAM, stateParams.sp);
  addDataToAppDataStore(appConstants.TENANT_ID_PARAM, stateParams.tenantId);
  addDataToAppDataStore(appConstants.VERIFICATION_TOKEN_PARAM, stateParams.vt);
  addDataToAppDataStore(appConstants.CHANNEL_PARAM, stateParams.channel);
  if(stateParams.app === "ui") {
    addDataToAppDataStore(appConstants.REQUESTED_APP, stateParams.app);
  }
}

const getAuthCodeFromURLParams = (valArray: string[]) => {
  const code: string = valArray.find(val => val.indexOf("code") > -1) ?? "";
  return decodeURIComponent(code.split("=")[1]);
}

let isImportInProgress = false;

export const CheckAlternateLoginFlow = () => {

  const [alternateLoginFlow, setAlternateLoginFlow] = useState<boolean>(false);
  const [alternateLoginInProgress, setAlternateLoginInProgress] = useState<boolean>(false);

  useEffect(() => {
    const importData = (authCode: string) => {
      (async function () {
        isImportInProgress = true;
        const verificationToken = getDataFromAppStore(appConstants.VERIFICATION_TOKEN_PARAM);
        const channel = getDataFromAppStore(appConstants.CHANNEL_PARAM);
        const validateAndImportPayload = {
          serviceProvider: getDataFromAppStore(appConstants.SERVICE_PROVIDER_PARAM),
          authCode: authCode,
          tenantId: getDataFromAppStore(appConstants.TENANT_ID_PARAM),
          verificationToken
        };
        setAlternateLoginInProgress(true);
        let redirectURL;
        try {
          validateAlternateAccountAndImportDirectory(validateAndImportPayload).then((response) => {
            setAlternateLoginInProgress(false);
            const tenantUrl = response.tenantUrl;
            const channel = getDataFromAppStore(appConstants.CHANNEL_PARAM);
            const verificationToken = getDataFromAppStore(appConstants.VERIFICATION_TOKEN_PARAM);
            // if verification token is present, then call desktop app URL and start import in it
            if (channel === 'desktop') {
              window.location.href = getDesktopAppImportUsersURL(tenantUrl, verificationToken);
            } else {
              const parent = window.opener;
              redirectURL = getDataFromAppStore(appConstants.REQUESTED_APP) ? process.env.REACT_APP_CLIENT_URL + process.env.PUBLIC_URL : tenantUrl;
              if (response.usersToImport === undefined || response.usersToImport.length === 0) {
                parent.postMessage("No email records found", redirectURL);
              } else {
                parent.postMessage(JSON.stringify(response.usersToImport), redirectURL);
              }
            }
            clearDataFromAppStore(appConstants.CHANNEL_PARAM);
            clearDataFromAppStore(appConstants.VERIFICATION_TOKEN_PARAM);
          })
        } catch (error) {
          // if invoked from desktop app, then parent window is not present because desktop app opens links in browser
          if (channel !== 'desktop') {
            const parent = window.opener;
            parent.postMessage("No email records found", redirectURL);
          }
        }
        isImportInProgress = false;
      })();
    }

    //Parse the URL params to get the serviceProvider and TenantId
    const params = new URLSearchParams(window.location.search);
    if (params && params.get(appConstants.SERVICE_PROVIDER_PARAM)) {
      addDataToAppDataStore(appConstants.SERVICE_PROVIDER_PARAM, params.get(appConstants.SERVICE_PROVIDER_PARAM) as string);
      if (params.get(appConstants.TENANT_ID_PARAM)) {
        addDataToAppDataStore(appConstants.TENANT_ID_PARAM, params.get(appConstants.TENANT_ID_PARAM) as unknown as number);
      }
      addDataToAppDataStore(appConstants.REQUESTED_APP, params.get(appConstants.REQUESTED_APP) as string);
    }
    const serviceProvider = getDataFromAppStore(appConstants.SERVICE_PROVIDER_PARAM);

    //If the URL params for ServiceProvider is present, then the call is for Alternate Login
    if (!alternateLoginFlow) {
      setAlternateLoginFlow(serviceProvider === CONSTANTS.GOOGLE || serviceProvider === CONSTANTS.MICROSOFT);
    }

    //This flow is for Alternate login for MS
    if (window.location.href.indexOf("gc?code=") > 0 && window.location.href.indexOf("&state=") >= 0) {
      setAlternateLoginFlow(true);
      const valArray: string[] = window.location.href.split("&");

      const authCode = getAuthCodeFromURLParams(valArray);
      fetchDataFromStateParam(valArray);
      if (!isImportInProgress) {
        importData(authCode);
      }
    }//The flow is for Alternate login for Google
    else if (window.location.href.indexOf("code=") >= 0 && window.location.href.indexOf("gc?code=") < 0 && window.location.href.indexOf("adminAuthCodeFlow") >= 0) {
      setAlternateLoginFlow(true);
      const valArray: string[] = window.location.href.split("&");

      const authCode = getAuthCodeFromURLParams(valArray);
      fetchDataFromStateParam(valArray);
      if (!isImportInProgress) {
        importData(authCode);
      }
    }
  }, []);

  return {alternateLoginFlow, alternateLoginInProgress};
};