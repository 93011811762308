import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/successPage.module.css";
import { ReactComponent as SlackBigLogoIcon } from "images/slackbot-screen/slack-logo-big.svg";
import { ReactComponent as JiraLogoIcon } from "images/icons/jira.svg";
import { ReactComponent as NotionLogoIcon } from "images/icons/notion.svg";
import classNames from "classnames";

interface AppIntegrationFailurePageProps {
  accountType: string;
}
interface AppIntegrationFailurePageState {}

export class AppIntegrationFailurePage extends AyraaBaseComponent<
  AppIntegrationFailurePageState,
  AppIntegrationFailurePageProps
> {
  private appCode = new URLSearchParams(this.history.location.search).get("appCode") || "Slack";
  private errorMessage = new URLSearchParams(this.history.location.search).get("errorMessage") || "Something went wrong. Please try again later.";

  private appNameandLogo: any = {
    SLACK: {
      name: "Slack",
      logo: <SlackBigLogoIcon className={styles.slackLogo} />,
    },
    JIRA: {
      name: "Jira",
      logo: <JiraLogoIcon className={styles.slackLogo} />,
    },
    NOTION: {
      name: "Notion",
      logo: <NotionLogoIcon className={styles.slackLogo} />,
    },
  };

  public renderCore(): ReactNode {
    const SelectedImage =
      this.appNameandLogo[this.appCode].logo || this.appNameandLogo["Slack"].logo;

    const containerClassName =   classNames(styles.container, styles.containerCenterAlign);

    return (
      <div className={containerClassName}>
        <div className={styles.headingTwo}>Incorrect Slack Workspace</div>
        <div className={styles.message}>
            {this.errorMessage}
        </div>
        {SelectedImage}
      </div>
    );
  }
}
