import {Image, ListGroup, Overlay, Popover} from "react-bootstrap";
import React, {forwardRef, useRef, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {BsPersonCircle} from "react-icons/bs";
import {OverlayInjectedProps} from "react-bootstrap/Overlay";
import {useHistory} from "react-router";
import styles from "./profile.module.css";
import {Logout} from "../../../components/Auth";

interface ProfileActionsProps extends OverlayInjectedProps {
  onHide: () => void;
}

const ProfileActions = forwardRef(
  ({onHide, ...props}: ProfileActionsProps, ref: React.Ref<HTMLDivElement>) => {
    const history = useHistory();
    const goToSettings = () => {
      history.push("/settings");
      onHide();
    };

    return (
      <Popover {...props} className={styles.popoverContainer} ref={ref}>
        <ListGroup>
          <ListGroup.Item action onClick={goToSettings}>
            Manage Settings
          </ListGroup.Item>
          <Logout/>
        </ListGroup>
      </Popover>
    );
  }
);

export default function Profile() {

  const {user} = useAppSelector((state) => state.userManager);
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const onHide = () => {
    setShow(false);
  };

  return (<>
    {user?.photoUrl ? (
      <Image className={styles.profileImage} src={user.photoUrl} alt="Profile photo" ref={target} onClick={() => setShow(!show)}/>
    ) : (
      <BsPersonCircle size={40} fill="#fff"/>
    )}
    <Overlay placement="bottom-end" target={target.current} rootClose show={show} onHide={onHide}>
      <ProfileActions onHide={onHide}/>
    </Overlay>
  </>);
}