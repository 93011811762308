import {ListGroup} from "react-bootstrap";
import {useHistory} from "react-router";
import {logout} from "redux/authSlice";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {setSubscribedPackageState, setUpcomingSubscriptionState} from "redux/subscriptionSlice";
import {setTenant} from "redux/tenantSlice";
import {setUser, setUserRole} from "redux/userSlice";
import React from "react";

export default function Logout() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {status} = useAppSelector((state) => state.authManager.logout);
  const loading = status === "pending";

  const handleLogout = async (e: $TSFixMe) => {
    e.preventDefault();
    dispatch(setUser(null));
    dispatch(setTenant(null));
    dispatch(setUserRole(null));
    dispatch(setSubscribedPackageState({subscribedPackage: null}));
    dispatch(setUpcomingSubscriptionState(null));
    await dispatch(logout());
    history.push("/");
  };

  return (
    <ListGroup.Item action onClick={handleLogout}>
      {loading ? "Logging out..." : "Logout"}
    </ListGroup.Item>
  );
}
