import LoginGoogle from "components/v2/Auth/LoginGoogle";
import LoginMS from "components/v2/Auth/LoginMS";
import FadedLogoWatermark from "components/v2/Logo/FadedLogoWatermark";
import { Label } from "../../../components/Label";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./loginpagev2.module.css";
import { useAppSelector } from "redux/hooks";
import { getUserDeviceType } from "utils/browserUtils";
import { isMobileApp } from "utils/mobileUtils";
import { RightSection } from "./RightSection";

export default function LoginPageV2() {
  const history = useHistory();
  const location = useLocation();

  const navigateToEmailLoginPage = () => {
    history.push("/emailLogin/email");
  };

  const user = useAppSelector((state) => state.userManager.user);
  const tenant = useAppSelector((state) => state.tenantManager.tenant);

  if (user.employeeID) {
    if (
      !tenant.workspaceDetails &&
      !user.personalAccount &&
      !(getUserDeviceType() === "mobile" && !isMobileApp())
    ) {
      history.push(`/integration`);
    } else if (user.status === "Invited" && !(getUserDeviceType() === "mobile" && !isMobileApp())) {
      history.push("/");
    } else if (getUserDeviceType() === "mobile") {
      history.push(`/`);
    } else {
      const { search } = location;
      history.push(`/${search}`);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.title}>
          <Label name="ui.loginv2.title" />
        </div>
        <div className={styles.loginButtons}>
          <LoginGoogle labelName="ui.login.google.signin-button-text" />
          <LoginMS labelName="ui.login.microsoft.signin-button-text" />
        </div>
        <div className={styles.emailContainer}>
          Not on Google or Microsoft?
          <div className={styles.emailContainerLink} onClick={navigateToEmailLoginPage}>
            <Label name="ui.login.email.signin-button-text" />
          </div>
        </div>
      </div>
      <RightSection className={styles.rightContainer} />
      {/* <FadedLogoWatermark className={styles.rightContainer} /> */}
    </div>
  );
}
