import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import styles from "./styles/successPage.module.css";
import { ReactComponent as SlackBigLogoIcon } from "images/slackbot-screen/slack-logo-big.svg";
import { ReactComponent as JiraLogoIcon } from "images/icons/jira.svg";
import { ReactComponent as NotionLogoIcon } from "images/icons/notion.svg";
import { ReactComponent as IntercomIcon } from "images/icons/intercom.svg";
import { ReactComponent as HubspotIcon } from "images/icons/hubspot.svg";
import { ReactComponent as ConfluenceIcon } from "images/icons/confluence.svg";
import { ReactComponent as GmailIcon } from "images/icons/gmail.svg";
import { ReactComponent as SalesforceIcon } from "images/icons/salesforce.svg";
import { ReactComponent as GoogleDocs } from "pages/Integration/assets/google_docs.svg";
import { ReactComponent as GoogleCalendarIcon } from "pages/Integration/assets/google-calendar.svg";
import { ReactComponent as ZendeskIcon } from "images/icons/zendesk.svg";

interface AppIntegrationSuccessPagePageStateProps {
  accountType: string;
}
interface AppIntegrationSuccessPageProps {}

export class AppIntegrationSuccessPage extends AyraaBaseComponent<
  AppIntegrationSuccessPageProps,
  AppIntegrationSuccessPagePageStateProps
> {
  private appCode = new URLSearchParams(this.history.location.search).get("appCode") || "Slack";
  private appNameandLogo: any = {
    SLACK: {
      name: "Slack",
      logo: <SlackBigLogoIcon className={styles.slackLogo} />,
    },
    JIRA: {
      name: "Jira",
      logo: <JiraLogoIcon className={styles.slackLogo} />,
    },
    NOTION: {
      name: "Notion",
      logo: <NotionLogoIcon className={styles.slackLogo} />,
    },
    GOOGLE_DOCS: {
      name: "Google docs",
      logo: <GoogleDocs className={styles.googleDocs} />,
    },
    GMAIL: {
      name: "Gmail",
      logo: <GmailIcon className={styles.googleDocs} />,
    },
    INTERCOM: {
      name: "Intercom",
      logo: <IntercomIcon className={styles.googleDocs} />,
    },
    HUBSPOT: {
      name: "Hubspot",
      logo: <HubspotIcon className={styles.googleDocs} />,
    },
    SALESFORCE: {
      name: "Salesforce",
      logo: <SalesforceIcon className={styles.googleDocs} />,
    },
    CONFLUENCE: {
      name: "Confluence",
      logo: <ConfluenceIcon className={styles.googleDocs} />,
    },
    GOOGLE_CALENDAR: {
      name: "Google Calendar",
      logo: <GoogleCalendarIcon className={styles.googleDocs} />,
    },
    ZENDESK: {
      name: "Zendesk",
      logo: <ZendeskIcon className={styles.googleDocs} />,
    }
  };

  public renderCore(): ReactNode {
    const SelectedImage =
      this.appNameandLogo[this.appCode]?.logo || this.appNameandLogo["SLACK"]?.logo;

    return (
      <div className={styles.container}>
        <div className={styles.heading}>Congratulations!</div>
        <div className={styles.message}>
          {this.appNameandLogo[this.appCode].name} has been successfully installed on your
          workspace.
        </div>
        {SelectedImage}
      </div>
    );
  }
}
