import { useEffect, useState } from "react";
import { getUserInfo } from "redux/authSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { resetSubscribedPackageStateStatus } from "redux/subscriptionSlice";
import { setTenant, fetchTenant } from "redux/tenantSlice";
import { getUsersRole, setUser, setUserRole } from "redux/userSlice";
import {appConstants} from "../utils/appConstants";
import {getLabels} from "../dataFetcher/label/LabelDataFetcher";
import {useHistory} from "react-router";
import {checkIfDesktopApp} from "../utils/desktopUtils";
import {useQueryParams} from "./useQueryParams";
import {establishAppLogin} from "../dataSender/auth/AuthDataSender";
import { joinWorkspaceOrg } from "redux/workspaceSlice";
import {getUserDeviceType} from "../utils/browserUtils";
import {isMobileApp} from "../utils/mobileUtils";
import { triggerSubscriptionRequirements } from "utils/subscriptionUtils";
import { updateEmployeeWorkspaceStatus } from "dataFetcher/Workspace/workspaceDataFetcher";
import { setUserAccountStatus } from "redux/userSlice";
import { updateSlackBotRedirectionStatus } from "redux/userSlice";
import { addDataToAppDataStore } from "dataStore/appDataStore";
import { getConnectAppsAndAvailabilityForUser } from "pages/Integration/appConnectorUtils";

export const useGetAppData = (alternateLoginFlow: boolean) => {
  const [loading, setLoading] = useState(true);


  // inline type declaration
  const [error, setError] = useState<{ message: string } | null>(null);
  const [ssoRedirect, setSsoRedirect] = useState<boolean>(false);
  const { tenant }: $TSFixMe = useAppSelector((state) => state.tenantManager);
  const isFirstSlackbotRedirection = useAppSelector((state) => state.userManager.isFirstSlackbotRedirection);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const isDesktopApp = checkIfDesktopApp();
  const verificationToken = useQueryParams("vt");
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (isDesktopApp && verificationToken) {
          await establishAppLogin(verificationToken);
        }

        const fetchedUser = await dispatch(getUserInfo()).unwrap();

        await getLabels(appConstants.ENGLISH_LANGUAGE_ID);

        if (fetchedUser && Object.keys(fetchedUser).length > 0) {
          if (fetchedUser.tenant) {
            const tenantID: number = fetchedUser.tenant.tenantID;
            const fetchedTenant = await dispatch(fetchTenant(tenantID)).unwrap();

            addDataToAppDataStore(appConstants.IS_COMPANY_MODE, !!fetchedTenant.workspaceDetails)
            dispatch(setTenant(fetchedTenant));
            if (!fetchedTenant.workspaceDetails && !fetchedUser.personalAccount &&  !(getUserDeviceType() === 'mobile' && !isMobileApp())) {
              //trigger the subscription logic here.
              try {
                await triggerSubscriptionRequirements(fetchedTenant);
                await updateEmployeeWorkspaceStatus({
                  userData : {personalAccount : true,
                    tenantID : fetchedTenant.tenantID,
                    employeeID : fetchedUser.employeeID },
                    updatedBy : fetchedUser.employeeID
                  })
                dispatch(setUserAccountStatus(true));
                history.push(`/integration`);
              }
              catch(e) {
                console.log("Something went Wrong!");
                console.log(e);
              }
            } else if (fetchedUser.status === 'Invited' && !(getUserDeviceType() === 'mobile' && !isMobileApp())) {
                //currently hasOrgs is commented, thus same action is done as on invited.
              await dispatch(joinWorkspaceOrg({tenantId: tenantID, employeeId: fetchedUser.employeeID}))
              history.push("/");
            }else if(getUserDeviceType() === "mobile"){
              history.push(`/`);
             } 
             else if(isFirstSlackbotRedirection) {
                dispatch(updateSlackBotRedirectionStatus(false));
                history.push("/getStarted");
             } 
             else {
              dispatch(resetSubscribedPackageStateStatus());
              if (window.location.href.indexOf(fetchedTenant.tenantUrl) < 0) {
                setSsoRedirect(true);
              }
              
            }
          }
          const { userRole, ...rest } = fetchedUser;
          if (rest) {
            dispatch(setUser(rest));
          }
          if (userRole) {
            dispatch(setUserRole(getUsersRole(userRole)));
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err: $TSFixMe) {
        setLoading(false);
        setError(err);
      }
    };
    if(!alternateLoginFlow) {
      fetchUser();
    } else {
      // load labels for alternate login flow
      getLabels(appConstants.ENGLISH_LANGUAGE_ID).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch]);

  return { loading, error, ssoRedirect, tenant };
};
