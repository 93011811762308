import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const sendEmailToSelectedEmployees = createAsyncThunk(
  "/subscription/sendEmailToEmployees",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'requiredPayload' does not exist on type ... Remove this comment to see the full error message
  async ({ requiredPayload }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/employees/sendInvitation`,
      requiredPayload
    );
    return response.data;
  }
);

export const updateEmployeeStatus = createAsyncThunk(
  "/subscription/sendEmailToEmployees",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'requiredPayload' does not exist on type ... Remove this comment to see the full error message
  async ({requiredPayload}) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/employees/updateEmployeeStatus`,
      requiredPayload
    );
    return response.data;
  }
);

export const deleteEmployee = createAsyncThunk(
  "/subscription/sendEmailToEmployees",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'requiredPayload' does not exist on type ... Remove this comment to see the full error message
  async ({ requiredPayload }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/employees/deleteEmployee`,
      requiredPayload
    );
    return response.data;
  }
);

export const exportEmployeeData = createAsyncThunk(
  "employees/exportEmployeeData",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'tenantID' does not exist on type 'void'.
  async ({ tenantID, employeeID }) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/employees/exportEmployeeData/${tenantID}/${employeeID}`
    );
    return res.data;
  }
);

export const importEmployeeData = createAsyncThunk(
  "employees/importEmployeeData",
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'formData' does not exist on type 'void'.
  async ({ formData }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/employees/importEmployeeData`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return res.data;
    } catch (error: $TSFixMe) {
      if (error) {
        return rejectWithValue(error?.response?.data?.message || "Something went wrong!");
      }
    }
  }
);

export const employeeSlice = createSlice({
  //Name of the slice
  name: "employeeSlice",

  initialState: {
    fetchEmployees: {
      status: "",
      employees: [],
      gridData: [],
    },
    gridApi: null,
    gridColumnApi: null,
    selectedRowCount: 0,
    selectedRowData: [],
    sendEmailToSelectedEmployees: {
      status: "na",
    },
    confirmationModal: false,
    successToast: "na",
  },

  reducers: {
    updateSelectedRowData: (state, action) => {
      const { selectedData } = action.payload;
      state.selectedRowData = selectedData;
    },
    updateRowCount: (state, action) => {
      const { count } = action.payload;
      state.selectedRowCount = count;
    },
    toggleConfirmationModal: (state) => {
      const previousModalState = state.confirmationModal;
      state.confirmationModal = !previousModalState;
    },
    toggleSuccessToast: (state) => {
      const previousToastState = state.successToast;
      if (previousToastState === "na") {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'string'.
        state.successToast = true;
      } else {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'string'.
        state.successToast = !previousToastState;
      }
    },
    resetStateValues: (state) => {
      state.successToast = "na";
      state.sendEmailToSelectedEmployees.status = "na";
    },
    setGridData: (state, action) => {
      const { gridData, employees } = action.payload;
      state.fetchEmployees.employees = employees;
      state.fetchEmployees.gridData = gridData;
    },
  },

  extraReducers: {
    //sendEmailToSelectedEmployees
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [sendEmailToSelectedEmployees.rejected]: (state) => {
      state.sendEmailToSelectedEmployees.status = "rejected";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [sendEmailToSelectedEmployees.pending]: (state) => {
      state.sendEmailToSelectedEmployees.status = "pending";
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [sendEmailToSelectedEmployees.fulfilled]: (state) => {
      state.sendEmailToSelectedEmployees.status = "fulfilled";
    },
  },
});

export const {
  updateRowCount,
  updateSelectedRowData,
  toggleConfirmationModal,
  toggleSuccessToast,
  resetStateValues,
  setGridData,
} = employeeSlice.actions;
export default employeeSlice.reducer;
