import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateEmployee } from "redux/tenantSlice";

export default forwardRef((props, ref) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
  const fieldName = props.colDef.field;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
  const { employeeID: selectedRowEmployeeID } = props.data;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type '{ ch... Remove this comment to see the full error message
  const { label, validations = {} } = props.inputProps;

  const [loading, setLoading] = useState(false);
  const [fetchOptionsLoading, setFetchOptionsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors: formErrors },
  } = useForm({ mode: "all" });

  const { employeeID }: $TSFixMe = useAppSelector((state) => state.userManager.user);
  const {
    tenant: { tenantID },
  }: $TSFixMe = useAppSelector((state) => state.tenantManager);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const formData = getValues();
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
        if (formData[props.colDef.field]) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
          const selectedManager = formData[props.colDef.field];
          const res = JSON.parse(selectedManager);
          return res.workEmailID;
        }
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
        return formData[props.colDef.field];
      },
      isCancelAfterEnd: () => {
        const hasErrors = Boolean(formErrors[fieldName]);
        return hasErrors || !submitClicked;
      },
      isPopup: () => {
        return true;
      },
    };
  });

  useEffect(() => {
    const fetchOptions = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/getAllEmployeesForTenant/${tenantID}`
      );
      if (res.data.length > 0) {
        const filteredEmployees = res.data.filter(
          (e: $TSFixMe) => e.employeeID !== selectedRowEmployeeID
        );
        filteredEmployees.sort(
          (a: $TSFixMe, b: $TSFixMe) => a.employeeFirstName > b.employeeLastName
        );
        setOptions(filteredEmployees);
      }
      setFetchOptionsLoading(false);
    };
    fetchOptions();
  }, [selectedRowEmployeeID, tenantID]);

  // HTML for loading
  if (fetchOptionsLoading) {
    return (
      <>
        <Modal.Header>
          <Modal.Title>{label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Getting employees...</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled>
            Close
          </Button>
          <Button disabled>Save</Button>
        </Modal.Footer>
      </>
    );
  }

  const onCloseClick = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
    props.api.stopEditing();
  };

  const onSubmit = async (formData: $TSFixMe) => {
    setLoading(true);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'colDef' does not exist on type '{ childr... Remove this comment to see the full error message
    const selectedManager = formData[props.colDef.field];
    const res = JSON.parse(selectedManager);
    if (res && res.employeeID) {
      const managerEmployeeId = res.employeeID;
      const payload = {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
        userData: { ...props.data, managerEmployeeId },
        updatedBy: employeeID,
      };
      await dispatch(updateEmployee(payload)).unwrap();
    }
    setLoading(false);
    setSubmitClicked(true);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'api' does not exist on type '{ children?... Remove this comment to see the full error message
    props.api.stopEditing();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body>
        <Form.Group>
          <Form.Label as="h6">{label}</Form.Label>
          <Form.Select size="sm" {...register(fieldName, { ...validations })}>
            {options.map((value) => {
              const {
                employeeFirstName,
                employeeLastName,
                employeeID: fetchedEmployeeID,
                workEmailID,
              } = value;
              const optionValue = JSON.stringify({ employeeID: fetchedEmployeeID, workEmailID });
              return (
                <option value={optionValue}>{`${employeeFirstName} ${employeeLastName}`}</option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          type="button"
          onClick={onCloseClick}
          disabled={loading}
        >
          Close
        </Button>
        <Button size="sm" type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Form>
  );
});
