import classNames from "classnames";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { ReactNode } from "react";
import { ReactComponent as External } from "../../assets/external.svg";
import { ConfirmNextPageModal } from "pages/v2/EmailLogin/ConfirmNextPageModal";
import styles from "./styles/connect.module.css";
import { IntegratedAppNamesEnum } from "pages/Integration/types";

interface ConnectProps {
  className?: string;
  isConnected?: boolean;
  disable?: boolean;
  name?: IntegratedAppNamesEnum;
  checkConnectionStatus?: () => boolean;
  checkConnectionStatusAsync?: () => Promise<boolean>;
  checkStatus?: () => { isEnabled: boolean };
  onConnectClick?: () => Promise<{ isConnected: boolean } | undefined>;
  onDisconnectClick?: () => Promise<{ isDisconnected: boolean } | undefined>;
}

interface ConnectState {
  isConnected: boolean;
  isFetching: boolean;
  disconnectModalInfo: {
    show: boolean;
  };
  disable?: boolean;
}

export class Connect extends AyraaBaseComponent<ConnectProps, ConnectState> {
  constructor(props: ConnectProps) {
    super(props);
    this.state = {
      isConnected: false,
      isFetching: true,
      disconnectModalInfo: {
        show: false,
      },
      disable: false,
    };
  }
  public renderCore(): ReactNode {
    const containerClassNames = classNames(styles.container, this.props.className, {
      [styles.disable]: this.state.disable,
      [styles.disconnect]: this.state.isConnected,
      // [styles.appIsConnected]: this.state.isConnected,
    });

    if (!this.state.isFetching) {
      if (this.state.isConnected) {
        return (
          <>
            <div className={containerClassNames} onClick={this.handleOnDisconnectClick}>
              <span>Disconnect</span>
            </div>
            <ConfirmNextPageModal
              show={this.state.disconnectModalInfo.show}
              handleSecondaryAction={this.closeModal}
              handlePrimaryAction={this.handleOnDisconnectConfirmationClick}
              headerLabel="Are you sure?"
              bodyText="Are you sure you want to disconnect"
              primaryButtonId={`${this.props.name}_PRIMARY_ACTION`}
            />
          </>
        );
      }

      return (
        <div className={containerClassNames} onClick={this.handleOnConnectClick}>
          <External className={styles.externalIcon} />
          <span>Connect</span>
        </div>
      );
    }
    return <div className="b4 regular shade-five">Getting status...</div>;
  }

  private handleOnDisconnectClick() {
    this.setState({
      disconnectModalInfo: {
        show: true,
      },
    });
  }

  private closeModal() {
    this.setState({
      disconnectModalInfo: {
        show: false,
      },
    });
  }

  private async handleOnConnectClick() {
    if (this.props.onConnectClick && !this.state.disable) {
      const { isConnected } = (await this.props.onConnectClick()) ?? { isConnected: false };
      this.setState({
        isConnected,
      });
    }
  }

  private async handleOnDisconnectConfirmationClick() {
    if (this.props.onDisconnectClick) {
      this.setState({
        disconnectModalInfo: {
          show: false,
        },
      });
      const { isDisconnected } = (await this.props.onDisconnectClick()) ?? {
        isDisconnected: false,
      };
      this.setState({
        isConnected: !isDisconnected,
      });
    }
  }

  private async setConnectionStatus() {
    const { isEnabled } = this.props.checkStatus?.() ?? { isEnabled: false };
    if (isEnabled) {
      if (this.props.checkConnectionStatusAsync) {
        const isConnected = await this.props.checkConnectionStatusAsync();
        this.setState({
          isConnected,
          isFetching: false,
        });
      } else {
        const isConnected = this.props.checkConnectionStatus?.();
        this.setState({
          isConnected: !!isConnected,
          isFetching: false,
        });
      }
    } else {
      this.setState({
        disable: true,
        isFetching: false,
      });
    }
  }

  private doSideEffectOfMount() {
    if (this.shouldExecuteSideEffect(this.doSideEffectOfMount, [this.props.isConnected])) {
      this.setConnectionStatus();
    }
  }
}
