import React, { Component, ReactNode } from "react";
import classNames from "classnames";
import { Label } from "components/Label";
import { AyraaBaseComponent } from "components/ayraaFramework/AyraaBaseComponent";
import { Button, Form, Modal } from "react-bootstrap";
import styles from "./styles/consentmodal.module.css";
import ModalWithNotch from "pages/v2/ModalWithNotch/ModalWithNotch";

interface ConsentModalProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}
interface ConsentModalState {
    isCheckboxChecked: boolean
}
export class ConsentModal extends AyraaBaseComponent<ConsentModalProps, ConsentModalState> {
  constructor(props: ConsentModalProps) {
    super(props);
    this.state = {
        isCheckboxChecked:false
    }
  }
  public renderCore(): ReactNode {
    return (
      <Modal
        centered
        backdrop="static"
        onEscapeKeyDown={(e: KeyboardEvent)=>{
            e.preventDefault();
            return false;
        }}
        className={styles.modal}
        show={this.props.show}
        onHide={this.props.handleClose}
        dialogClassName={styles.modalDialog}
        notchIconProps={{
          onClick: this.props.handleClose,
          className: classNames("consent-modal-notch-icon", styles.notchIcon),
        }}
        contentClassName={styles.modalContent}
      >
        <div className={styles.modalBody}>
          <div className="b3 shade-three">
            Before you continue, we need your consent for a few important points:
          </div>
          <div className={styles.contentContainer}>
            <div className="b1 shade-one">Privacy Policy & Terms of Service</div>
            <div className="b3 shade-three">
              Please read our Privacy Policy and Terms of Service. These documents explain how we
              collect, use, and protect your data.
            </div>
          </div>
          <div className={styles.contentContainer}>
            <div className="b1 shade-one">Sharing Data with AI Models</div>
            <div className="b3 shade-three">
              Ayraa enhances your experience using advanced AI models provided by OpenAI. To do
              this, we share your search-related data with OpenAI. This data sharing is crucial for
              delivering accurate and relevant results to your queries.
            </div>
          </div>
          <div className={styles.confirmationCheckBox}>
            <div>
            <Form.Check
                className={styles.consentCheckBox}
                checked={this.state.isCheckboxChecked}
                onChange={() => this.setState({isCheckboxChecked: !this.state.isCheckboxChecked})}
                type={"checkbox"}
            />
            </div>
            <div className="b3 shade-three">
              I have read and agree to the <a href="https://www.ayraa.io/company/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.ayraa.io/company/tos" target="_blank">Terms of Service</a>, and I explicitly
              consent to Ayraa sharing my search-related data with AI models from OpenAI for a
              better user experience
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button variant="primary" disabled={!this.state.isCheckboxChecked} onClick={this.props.handleSubmit}>
            <Label name={"Agree and continue"} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ConsentModal;
