import {Button, Form, Image, InputGroup, ListGroup} from "react-bootstrap";
import styles from "./styles/importemployees.module.css";
import React, {ChangeEvent, useEffect, useState} from "react";
import {ImportAndSendInvitesRequest, ImportUser, ListUsersRequest} from "../../../types/userTypes";
import {importAndSendInvites, listUsersFromProvider} from "../../../redux/importEmployeesSlice";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Label} from "../../../components/Label";
import {getDataFromAppStore} from "../../../dataStore/appDataStore";
import {appConstants} from "../../../utils/appConstants";
import ModalWithNotch from "../ModalWithNotch/ModalWithNotch";
import classNames from "classnames";
import {SearchShadeFourIcon} from "../../../utils/icons";
import {CONSTANTS} from "../../../utils/constants";
import LinkWrapper from "../../../components/v2/Auth/LinkWrapper";

function EmployeeListSkeleton() {
  const dummyList = new Array(4).fill(1);
  return (
    <ListGroup className={styles.list}>
      {dummyList.map(() => {
        return (
          <ListGroup.Item disabled className={styles.listItemLoading}>
            <div className={`${styles.profileImage} shimmer`}/>
            <div className={`${styles.name} shimmer`}/>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

type UserListType = {
  selectedEmployeeChecks: string[],
  importedUserList: ImportUser[],
  handleCheck: (e: ChangeEvent<HTMLInputElement>) => void
}

function checkIfUserNameNull(userName: string) {
  if (!userName || !userName.length) return "#";
  return userName.charAt(0);
}

const ProfileIcon = ({profileUrl, userName, className, index}: { profileUrl: string | null, userName: string, className: string, index: number }) => {
  const profileIconClasses = classNames(className, {
    [styles.defaultProfileIcon]: !profileUrl || profileUrl.length === 0
  })

  if (profileUrl && profileUrl.length) {
    return (<Image
      className={profileIconClasses}
      src={profileUrl}
    />)
  }

  const colorNameClasses = ['color0', 'color1', 'color2', 'color3'];
  let profileNameImageClassName = classNames(className, styles["color" + index % colorNameClasses.length]);
  let profileImageClassNames = classNames(className, styles["backgroundColor" + index % colorNameClasses.length]);

  return (<div className={profileImageClassNames}>
    <span className={profileNameImageClassName}>{checkIfUserNameNull(userName)}</span>
  </div>)

}

function UserListToShow({selectedEmployeeChecks, importedUserList, handleCheck}: UserListType) {

  if (!importedUserList.length) {
    return (
      <div className="b4 medium shade-two">
        <Label name="pulse.workspace.import.no.records.found"/>
      </div>
    )
  }

  return (<div className={styles.list}>
    {importedUserList.map((user: ImportUser, index) => {
      const id = user.email;
      return (
        <div className={styles.listItem} key={id}>
          <input
            id={id}
            className="form-check-input"
            type="checkbox"
            onChange={handleCheck}
            checked={selectedEmployeeChecks.includes(id)}
          />

          <ProfileIcon profileUrl={user.photoUrl} userName={user.employeeFirstName} className={styles["listItemImage"]} index={index}/>

          <span>{user.employeeFirstName} {user.employeeLastName}</span>
        </div>
      );
    })}
  </div>)
}

export default function ImportEmployeesFromProviderModal(
  {provider, setShow, closeModalAfterImportFunction}:
    { provider: string, setShow: React.Dispatch<React.SetStateAction<boolean>>, closeModalAfterImportFunction: () => void }
) {
  const dispatch = useAppDispatch();

  const handleClose = () => setShow(false);

  const {employeeID, serviceProvider, workEmailID} = useAppSelector((state) => state.userManager.user);
  const {tenantID} = useAppSelector((state) => state.tenantManager.tenant);

  const [loading, setLoading] = useState<boolean>(true);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedEmployeesCheckboxId, setSelectedEmployeesCheckboxId] = useState<string[]>([]);
  const [employees, setEmployees] = useState<ImportUser[]>([]);
  const [displayEmployees, setDisplayEmployees] = useState<ImportUser[]>([]);

  let alternateLoginLink: string =
    process.env.REACT_APP_CLIENT_URL + process.env.PUBLIC_URL +
    CONSTANTS.UI_ALTERNATE_LOGIN_ACTION +
    serviceProvider +
    CONSTANTS.TENANT_ID_PARAM +
    tenantID + CONSTANTS.APP_ID_PARAM;

  const labels = getDataFromAppStore(appConstants.LABEL_INFO_KEY);

  useEffect(() => {
    async function getListOfEmployeesFromProvider() {
      try {
        const listUsersRequest: ListUsersRequest = {
          'employeeId': employeeID,
          'tenantId': tenantID,
          'serviceProvider': serviceProvider.toUpperCase()
        }
        const {employees: employeeListFromProvider} = await dispatch(listUsersFromProvider(listUsersRequest)).unwrap();

        const tmpEmployeeList = employeeListFromProvider.filter(employee => employee.email !== workEmailID);
        setEmployees(tmpEmployeeList);
        setDisplayEmployees(tmpEmployeeList);
        setLoading(false);
      } catch (error) {
        setEmployees([]);
        setDisplayEmployees([]);
        setLoading(false);
      }
    }

    getListOfEmployeesFromProvider();
  }, [dispatch, employeeID, serviceProvider, tenantID]);

  const handleCheck = async (event: ChangeEvent<HTMLInputElement>) => {
    const {id, checked} = event.target;
    if (checked && !selectedEmployeesCheckboxId.includes(id)) {
      const updatedSelectedEmployeesCheckboxIdArr = [...selectedEmployeesCheckboxId, id];
      setSelectedEmployeesCheckboxId(updatedSelectedEmployeesCheckboxIdArr);
      if (employees.length === updatedSelectedEmployeesCheckboxIdArr.length) {
        setAllSelected(true);
      }
    } else {
      setSelectedEmployeesCheckboxId(selectedEmployeesCheckboxId.filter(item => item !== id));
      setAllSelected(false);
    }
  };

  const handleAllCheck = async (event: ChangeEvent<HTMLInputElement>) => {
    setAllSelected(event.target.checked);
    if (event.target.checked) {
      if(employees.length === 0 && displayEmployees.length > 0){
        setEmployees(displayEmployees);
        setSelectedEmployeesCheckboxId(displayEmployees.map((value) => value.email));
      }else {
        setSelectedEmployeesCheckboxId(employees.map((value) => value.email));
      }
    } else {
      setSelectedEmployeesCheckboxId([]);
    }
  }

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    if (searchValue.length > 0) {
      setDisplayEmployees(employees
        .filter(employee => {
          const employeeName = employee.employeeFirstName + ' ' + employee.employeeLastName;
          return employeeName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        }));
    } else {
      setDisplayEmployees(employees);
    }
  }

  const handleInviteTeammates = async () => {
    setLoading(true);
    const importAndSendInvitesRequest: ImportAndSendInvitesRequest = {
      'employeeId': employeeID,
      'tenantId': tenantID,
      'serviceProvider': serviceProvider.toUpperCase(),
      'selectedEmployeeEmailIds': selectedEmployeesCheckboxId
    }
    await dispatch(importAndSendInvites(importAndSendInvitesRequest)).unwrap();
    closeModalAfterImportFunction();
  }

  return (
    <ModalWithNotch
      centered
      show={true}
      onHide={handleClose}
      notchIconProps={{onClick: handleClose, 
        className : classNames("invite-screen-notch-icon", styles.notchIcon)}}
      className={styles.modal}
      bsPrefix="invite-screen-modal"
      contentClassName={styles.modalContent}
      dialogClassName={styles.modalDialog}
      
    >
      <div className={styles.main}>
        <div className={styles.modalHeader}>
          <Label name='ui.employees.import.provider' args={[provider]}/>
        </div>
        <InputGroup className={styles.searchBar}>
          <InputGroup.Text className={styles.searchIcon}>
            <SearchShadeFourIcon/>
          </InputGroup.Text>
          <Form.Control type="text" onChange={handleSearch} placeholder={labels['ui.search-button.placeholder']}
                        disabled={loading || employees.length === 0}/>
        </InputGroup>
        {(employees.length > 0 || displayEmployees.length > 0) &&
            <div className={styles.allCheckbox}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleAllCheck}
                    checked={allSelected}
                />
                <label className="form-check-label">All</label>
            </div>
        }
        <Form className={styles.modalForm}>
          {loading
            ? <EmployeeListSkeleton/>
            :
            <>
              <UserListToShow selectedEmployeeChecks={selectedEmployeesCheckboxId}
                              importedUserList={displayEmployees}
                              handleCheck={handleCheck}
              />
            </>
          }
        </Form>
        <div>
          <Button variant="primary" onClick={handleInviteTeammates} disabled={loading || selectedEmployeesCheckboxId.length === 0}>
            <Label name='ui.employees.import.invite-button-text'/>
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.note}>
          <Label name="ui.employees.import.alternative-text" />
        </div>
        <LinkWrapper link={alternateLoginLink} setEmployees={setEmployees} setDisplayEmployees={setDisplayEmployees} serviceProvider={serviceProvider} tenantID={tenantID}/>
      </div>
    </ModalWithNotch>
  );
}
