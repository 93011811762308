import React from "react";
import { Image } from "react-bootstrap";
import styles from "../manageEmployeesComponent.module.css";

export default function ImageRenderer({
  data
}: $TSFixMe) {
  const { photoUrl = null } = data;
  if (!photoUrl) {
    return <div className={styles.imageRendererContainer}>-</div>;
  }
  return (
    <div className={styles.imageRendererContainer}>
      <Image width={36} height={36} src={photoUrl} roundedCircle />
    </div>
  );
}
