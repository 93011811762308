import axios from "axios";
import {AlternateLoginResponse} from "../../types/userTypes";

export async function validateAlternateAccountAndImportDirectory({serviceProvider, authCode, tenantId, verificationToken}
                                                                     : { serviceProvider: string, authCode: string, tenantId: number, verificationToken?: string }) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/validateAlternateAccountAndImportDirectory",
      {
        serviceProvider,
        authCode,
        tenantId,
          vt: verificationToken
      }
    );
    return response.data as AlternateLoginResponse;
  } catch (error: any) {
    return error?.response.status;
  }
}

export async function fetchAuthCodeURL(
  {serviceProvider, authCode, idToken, tenantId, app, verificationToken, channel}
    : { serviceProvider: string, authCode: string, idToken: string, tenantId: number, app: string, verificationToken?: string,
  channel?: string})
{
  try {
    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/fetchAuthCodeURL",
      {
        serviceProvider,
        authCode,
        tenantId,
        idToken,
        app,
          vt: verificationToken,
          channel
      }
    );
    return response.data as string;
  } catch (error: any) {
    return error?.response.status;
  }
}

export async function saveDetailsForAppLogin(verificationToken: string) {
    try {
        await axios.get(process.env.REACT_APP_SERVER_URL + "/saveDetailsForAppLogin/" + verificationToken);
    } catch (error: any) {
        return error?.response.status;
    }
}

export async function generateAppLoginToken() {
    try {
        const response = await axios.get(process.env.REACT_APP_SERVER_URL + "/generateAppLoginToken");
        return response.data;
    } catch (error: any) {
        console.error(error);
        return error?.response.status;
    }
}

export async function establishAppLogin(verificationToken: string) {
    try {
        await axios.get(process.env.REACT_APP_SERVER_URL + "/establishAppLogin/" + verificationToken);
    } catch (error: any) {
        console.error(error);
        return error?.response.status;
    }
}


/** Uses loginWithEmail endpoint to return if there is tenant already present for a
 * given email domain
 */
export const triggerLoginWithEmail = async ({
  email,
  employeeFirstName,
  employeeLastName
}: {
  email: string;
  employeeFirstName?: string,
  employeeLastName?:  string
}) => {
  try {
    const link = process.env.REACT_APP_SERVER_URL + "/loginWithEmail";
    const response = await axios.post(link, { email , employeeFirstName ,employeeLastName });
    return response.status;
  } catch (error: any) {
    console.log(error);
    return error?.response.data.errorCode;
  }
};

/** Uses loginWithEmail endpoint which sends an email to the user if the email is valid a new OTP */
export const resendOTP = async ({ email }: { email: string }) => {
  try {
    const link = process.env.REACT_APP_SERVER_URL + "/loginWithEmail";
    await axios.post(link, { email });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/** Endpoint to verify if the OTP which user sends is valid/expired/invalid */
export const verifyOTP = async ({
  otp,
  email,
  referrer
}: {
  otp: string;
  email: string;
  referrer: string;
}) => {
  try {
    const link = process.env.REACT_APP_SERVER_URL + "/verifyOTP";
    const response = await axios.post(link, { otp, email, referrer });
    return { success: true, data: response.data };
  } catch (error: any) {
    if(error.response.data.errorCode === "1") {
      return {
        success : true,
        data : "Tenant not found"
      }
    }
    if(error.response.data.errorCode === "603") {
      return {
        success : true,
        data : "Workspace Already Joined"
      }
    }
    if (error.response.data.errorCode === "801") {
      return {
        success: false,
        data: "This is an invalid code, please try again",
      };
    }
    if (error.response.data.errorCode === "802") {
      return {
        success: false,
        data: "OTP expired, please try again",
      };
    }
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};

export async function pulseLogout() {
    const response = await axios.post(process.env.REACT_APP_PULSE_BACKEND_URL + "/auth/logout");
    return response.data;
}

/**
 * Log out from UI
 */
export async function uiLogout() {
    const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/logout");
    return response.data;
}

export async function checkFirstAndLastNameExist(email:string) {
  const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/ep", {
    email
  });
  return response.data;
}